import { reactive } from 'vue';
import useValidators from '@/app/validators/validator';
import ErrorsCollection from '@/app/model/ErrorsCollection';

const errors = reactive(new ErrorsCollection());
const {
  isEmpty, isUrl,
} = useValidators();

export default function webEnvironmentFormValidator(webEnvironment) {
  const validateIntegrationMethodField = () => {
    const property = 'integration_method';
    const value = webEnvironment.integrationMethod;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank_select');
    }

    return errors;
  };

  const validateCMSModuleField = () => {
    const property = 'module';
    const value = webEnvironment.module;
    errors.clearErrors(property);

    if (webEnvironment.canDisplayModule() && isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank_select');
    }

    return errors;
  };

  const validateIntegratorField = () => {
    const property = 'integrator';
    const value = webEnvironment.integrator;
    errors.clearErrors(property);

    if (webEnvironment.canDisplayIntegrator() && isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank_select');
    }

    return errors;
  };

  const validateNotifyUrlProductionField = () => {
    const property = 'notify_url_production';
    const value = webEnvironment.notifyUrlProduction;
    errors.clearErrors(property);

    if (webEnvironment.canDisplayNotifyUrlProduction()) {
      if (isEmpty(value)) {
        return errors.addError(property, 'validation.not_blank');
      }

      if (!isUrl(value)) {
        return errors.addError(property, 'validation.invalid_url');
      }
    }

    return errors;
  };

  const areValidFields = () => {
    validateIntegrationMethodField();
    validateCMSModuleField();
    validateIntegratorField();
    validateNotifyUrlProductionField();

    return (errors.errors.length === 0);
  };

  return {
    errors,
    validateCMSModuleField,
    validateIntegrationMethodField,
    validateIntegratorField,
    validateNotifyUrlProductionField,
    areValidFields,
  };
}
