import { i18n } from '@/app/service/i18n';
import { REFRESH_TOKEN_ACTION, LOGOUT_ACTION, ADD_LOGIN_MESSAGE_ACTION } from '@/security/store/actions';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import store from '@/app/store';
import Login from '../ui/Login.vue';
import ForgotPassword from '../ui/ForgotPassword.vue';
import ChangePassword from '../ui/ChangePassword.vue';

const routes = [
  {
    path: '/:locale/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: '/:locale/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: { requiresAuth: false },
  },
  {
    path: '/:locale/change-password/:token',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { requiresAuth: false },
  },
];

const beforeEachIsAuthenticated = async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      await store.dispatch(REFRESH_TOKEN_ACTION)
        .catch(() => next({
          path: `/${i18n.global.locale}/login`,
        }));
    }

    if (store.getters.isAuthenticated && !store.getters.isAuthorized) {
      await store.dispatch(LOGOUT_ACTION);
      store.dispatch(ADD_LOGIN_MESSAGE_ACTION, {
        type: MessageTypeEnum.RESPONSE,
        content: {
          status: 403,
          statusText: 'Unauthorized',
          data: {
            code: 403,
            message: 'error.unauthorized',
          },
        },
      });
      next({
        path: `/${i18n.global.locale}/login`,
      });
    }
  }
};

export default {
  routes,
  beforeEachIsAuthenticated,
};
