import Address from '@/app/model/Address';
import Money from '@/app/model/Money';

export default class SellingPoint {
  constructor(
    socialReason = null,
    businessName = null,
    identificationNumber = null,
    socialCapital = 0.00,
    nafCode = null,
    address = null,
    legalType = null,
  ) {
    this.socialReason = socialReason;
    this.businessName = businessName;
    this.identificationNumber = identificationNumber;
    this.socialCapital = socialCapital;
    this.nafCode = nafCode;
    this.address = address;
    this.legalType = legalType;
  }

  toJson() {
    return {
      socialReason: this.socialReason,
      businessName: this.businessName,
      identificationNumber: this.identificationNumber,
      socialCapital: Money.toJson(this.socialCapital),
      nafCode: this.nafCode,
      address: this.address,
      legalType: this.legalType,
    };
  }

  static fromJson(json) {
    return new SellingPoint(
      'socialReason' in json ? json.socialReason : null,
      'businessName' in json ? json.businessName : null,
      'identificationNumber' in json ? json.identificationNumber : null,
      'socialCapital' in json && json.socialCapital !== null ? Money.fromJson(json.socialCapital) : 0.00,
      'nafCode' in json ? json.nafCode : null,
      'address' in json ? Address.fromJson(json.address) : null,
      'legalType' in json ? json.legalType : null,
    );
  }
}
