import MimeEnum from '@/app/enum/MimeEnum';

export default function useValidators() {
  const isEmpty = (value) => (value === null || value === '' || value.length === 0);
  const isValidSizeFile = (value) => (value.size <= 10000000);
  const isValidMimeFile = (value) => MimeEnum.getValue().indexOf(value.type) !== -1;
  const isValidExcelFile = (value) => MimeEnum.getExcelValues().indexOf(value.type) !== -1;
  const isValidMinLength = (value, min) => (value.length < min);
  const isPositive = (value) => value > 0;
  const isInteger = (value) => Number(value) === value && value % 1 === 0;

  const isOnlyNumbers = (value) => {
    const regex = /^[0-9]*$/;
    return regex.test(value);
  };

  const isUrl = (value) => {
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9-_.:/]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
    return regex.test(value);
  };

  const isSecured = (value) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.+])[A-Za-z\d@$!%*#?&.+]{8,}$/;
    return regex.test(value);
  };

  const isSocietyNumber = (value) => {
    const regex = /^[0-9]{14}$/;
    return regex.test(value);
  };

  const isInternationalCompanyNumber = (value) => {
    const regex = /^[a-zA-Z0-9]{1,50}$/;
    return regex.test(value);
  };

  const isInternationalPostalCode = (value) => {
    const regex = /^[a-zA-Z0-9-]{1,10}$/;
    return regex.test(value);
  };

  const isAgentCode = (value) => {
    const regex = /^[0-9]{8}$/;
    return regex.test(value);
  };

  const isNafCode = (value) => {
    const regex = /^[0-9]{4,4}[A-Z]{1,1}$/;
    return regex.test(value);
  };

  const isValidEmail = (value) => {
    const regex = /^[\w.+_-]+@[\w\-_]+(?:\.[\w\-_]+)*$/;
    return regex.test(value);
  };

  const isValidString = (value) => {
    const regex = /^[-A-Za-zà-ż ]*$/;
    return regex.test(value);
  };

  const isValidStringNumber = (value) => {
    const regex = /^[-A-Za-zà-ż0-9-_ ]*$/;
    return regex.test(value);
  };

  const isValidPhone = (value) => {
    let regex = '';

    switch (value.prefix) {
      case '+31':
        regex = /^[0-9]{9}$/;
        break;
      case '+32':
        regex = /^0?[1-9][0-9]{7,9}$/;
        break;
      case '+33':
        regex = /^0?[1-9][0-9]{8}$/;
        break;
      case '+34':
        regex = /^[1-9][0-9]{8}$/;
        break;
      case '+39':
        regex = /^[0-9]{10}$/;
        break;
      case '+49':
        regex = /^[1-9]\d{7,11}$/;
        break;
      case '+351':
        regex = /^(2|9|21)[0-9]{7,9}$/;
        break;
      default:
        return regex;
    }

    return regex.test(value.number);
  };

  const isValidPrefixPhone = (value) => {
    const regex = /^\+(31|32|33|34|39|49|351)$/;
    return regex.test(value.prefix);
  };

  const isValidIban = (value) => {
    const country = (value.length >= 2) ? value.substring(0, 2) : null;
    let regex = '';

    switch (country) {
      case 'FR':
        regex = /FR\d{2}\d{5}\d{5}[\dA-Za-z]{11}\d{2}/;
        break;
      case 'ES':
        regex = /ES\d{2}\d{4}\d{4}\d{1}\d{1}\d{10}/;
        break;
      case 'BE':
        regex = /BE\d{2}\d{3}\d{7}\d{2}/;
        break;
      case 'PT':
        regex = /PT[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){5}([0-9]{1})\s?/;
        break;
      case 'IT':
        regex = /IT[a-zA-Z0-9]{2}\s?([a-zA-Z]{1})([0-9]{3}\s?)([0-9]{4}\s?){1}([0-9]{3})([a-zA-Z0-9]{1}\s?)([a-zA-Z0-9]{4}\s?){2}([a-zA-Z0-9]{3})\s?/;
        break;
      case 'DE':
        regex = /DE[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){4}([0-9]{2})\s?/;
        break;
      case 'NL':
        regex = /NL[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([0-9]{4}\s?){2}([0-9]{2})\s?/;
        break;
      case 'GB':
        regex = /GB[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([0-9]{4}\s?){3}([0-9]{2})\s?/;
        break;
      default:
        return regex;
    }

    return regex.test(value);
  };

  const isAcceptedIban = (value) => {
    const country = (value.length >= 2) ? value.substring(0, 2) : null;
    return (country === 'FR' || country === 'ES' || country === 'BE' || country === 'IT'
      || country === 'PT' || country === 'DE' || country === 'NL' || country === 'GB');
  };

  const isValidBic = (value) => {
    const regex = /^[A-Za-z]{6}[0-9A-Z]{2,5}$/;
    return regex.test(value);
  };

  const isIpV4 = (value) => {
    // eslint-disable-next-line vue/max-len, max-len
    const regex = /^(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])(\.(?!$)|$)){4}$/;
    return regex.test(value);
  };

  const isIpV6 = (value) => {
    // eslint-disable-next-line vue/max-len, max-len
    const regex = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;
    return regex.test(value);
  };

  const isPrivateIp = (value) => {
    // eslint-disable-next-line vue/max-len, max-len
    const regex = /(^127\.)|(^192\.168\.)|(^10\.)|(^172\.1[6-9]\.)|(^172\.2[0-9]\.)|(^172\.3[0-1]\.)|(^::1$)|(^[fF][cCdD])/;
    return regex.test(value);
  };

  const isIpV4V6 = (value) => isIpV4(value) || isIpV6(value);

  return {
    isEmpty,
    isSocietyNumber,
    isNafCode,
    isValidMimeFile,
    isValidExcelFile,
    isValidSizeFile,
    isValidString,
    isValidStringNumber,
    isValidPhone,
    isValidPrefixPhone,
    isValidBic,
    isValidIban,
    isValidEmail,
    isValidMinLength,
    isAcceptedIban,
    isUrl,
    isPositive,
    isIpV4,
    isIpV6,
    isIpV4V6,
    isPrivateIp,
    isInteger,
    isOnlyNumbers,
    isAgentCode,
    isSecured,
    isInternationalPostalCode,
    isInternationalCompanyNumber,
  };
}
