<template>
  <div class="pt-4 mb-3">
    <div class="form-floating">
      <input :id="id" v-model="input"
             v-maska="maskInput"
             type="text"
             class="form-control form-control-lg"
             :class="(errors)?'is-invalid':''"
             :placeholder="placeholder"
             @maska="onMaska"
             @focusout="validateInput"
      >
      <label :for="id" :class="{ 'required-star': required }">
        {{ label }}
      </label>
    </div>

    <ErrorDisplay v-if="errors" :errors="errors" />

    <div v-if="help" class="form-text bg-grey p-3 mt-0 d-flex">
      <p class="mb-0">
        <IconInfoCircle class="me-2" />
      </p>
      <p class="mb-0" v-html="help" />
    </div>
  </div>
</template>

<script>
import { watch, ref, computed } from 'vue';
import { maska } from 'maska';
import IconInfoCircle from '@/app/ui/components/icon/IconInfoCircle.vue';
import ErrorDisplay from './ErrorDisplay.vue';

export default {
  name: 'InputText',
  directives: {
    maska,
  },
  components: {
    IconInfoCircle,
    ErrorDisplay,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    helper: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    validators: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    help: {
      type: String,
      default: null,
    },
    maskValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:masked', 'update:modelValue'],
  setup(props, { emit }) {
    const input = ref(props.modelValue);
    const validateInput = () => props.validators.forEach((validator) => validator());
    const maskInput = computed(() => props.maskValue);

    function onMaska(event) {
      const maskValue = event.target.dataset.maskRawValue;
      const value = (!maskValue) ? null : maskValue;
      emit('update:modelValue', value);
    }

    watch(input, (value) => {
      if (!maskInput.value) {
        emit('update:modelValue', value);
      }
    });

    return {
      maskInput,
      input,
      onMaska,
      validateInput,
    };
  },
};
</script>
