import LegalRepresent from '@/onBoardingInternational/model/LegalRepresent';

export default class LegalRepresents {
  constructor(representatives) {
    this.representatives = representatives;
  }

  addEmptyLegalRepresent(onBoarding) {
    this.representatives.push(LegalRepresent.createEmpty(onBoarding));
  }

  canAddRepresent() {
    return this.representatives.length < 4;
  }

  haveRepresents() {
    return this.representatives.length > 0;
  }

  static fromJson(json) {
    return new LegalRepresents(
      json.length > 0 ? json.map((legalRepresent) => LegalRepresent.fromJson(legalRepresent)) : [],
    );
  }
}
