<template>
  <Layout :menu-index="1">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="row mb-3">
        <div class="col-12 col-md-9">
          <h2>{{ $t('title.company_information') }}</h2>
        </div>
      </div>

      <div v-if="hasMessage" class="row">
        <div class="col-12 col-md-9">
          <Alert v-for="(message, index) in messages" :key="index" :msg="message" :index="index" />
        </div>
      </div>

      <div v-if="onBoardingInternational" class="row">
        <div class="col-12 col-md-9">
          <form autocomplete="off"
                novalidate
                @submit.prevent
          >
            <InputSelect
              id="country"
              v-model="onBoardingInternational.company.country"
              :required="true"
              :label="$t('label.country')"
              :placeholder="$t('placeholder.country')"
              :options="getCountriesChoices"
              :validators="[validationForm.validateCountryField]"
              :errors="validationForm.errors.getErrorsFromProperty('country')"
            />

            <InputText
              id="business_name"
              v-model="onBoardingInternational.company.businessName"
              :required="true"
              :label="$t('label.business_name')"
              :placeholder="$t('placeholder.business_name')"
              :validators="[validationForm.validateBusinessNameField]"
              :errors="validationForm.errors.getErrorsFromProperty('businessName')"
            />

            <InputText
              id="social_reason"
              v-model="onBoardingInternational.company.socialReason"
              :required="true"
              :label="$t('label.social_reason')"
              :placeholder="$t('placeholder.social_reason')"
              :validators="[validationForm.validateSocialReasonField]"
              :errors="validationForm.errors.getErrorsFromProperty('socialReason')"
            />

            <InputText
              id="identification_number"
              v-model="onBoardingInternational.company.identificationNumber"
              :required="true"
              :label="$t('label.identification_number')"
              :placeholder="$t('placeholder.identification_number')"
              :validators="[validationForm.validateIdentificationNumberNumberField]"
              :errors="validationForm.errors.getErrorsFromProperty('identificationNumber')"
            />

            <InputMoney
              id="share_capital"
              v-model="onBoardingInternational.company.shareCapital"
              :required="true"
              :precision="2"
              :label="$t('label.share_capital')"
              :placeholder="$t('placeholder.share_capital')"
              :validators="[validationForm.validateShareCapitalField]"
              :errors="validationForm.errors.getErrorsFromProperty('shareCapital')"
            />

            <InputText
              id="address"
              v-model="onBoardingInternational.company.address"
              :required="true"
              :label="$t('label.address')"
              :placeholder="$t('placeholder.address')"
              :validators="[validationForm.validateAddressField]"
              :errors="validationForm.errors.getErrorsFromProperty('address')"
            />

            <InputText
              id="postal_code"
              v-model="onBoardingInternational.company.postalCode"
              :required="true"
              :label="$t('label.postal_code')"
              :placeholder="$t('placeholder.postal_code')"
              :validators="[validationForm.validatePostalCodeField]"
              :errors="validationForm.errors.getErrorsFromProperty('postalCode')"
            />

            <InputText
              id="city"
              v-model="onBoardingInternational.company.city"
              :required="true"
              :label="$t('label.city')"
              :placeholder="$t('placeholder.city')"
              :validators="[validationForm.validateCityField]"
              :errors="validationForm.errors.getErrorsFromProperty('city')"
            />

            <LegalNotice :country="onBoardingInternational.company.country" />

            <div class="mt-3 float-end">
              <a href="#"
                 class="btn btn-lg btn-light text-dark rounded-3 me-2"
                 @click="onSave"
              >
                {{ $t('action.save') }}
              </a>

              <button
                type="submit"
                class="btn btn-lg btn-primary text-white rounded-3"
                @click="onSaveAndContinue"
              >
                {{ $t('action.continue') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Loading from '@/app/ui/components/Loading.vue';
import InputText from '@/app/ui/components/form/InputText.vue';
import InputSelect from '@/app/ui/components/form/InputSelect.vue';
import InputMoney from '@/app/ui/components/form/InputMoney.vue';
import Alert from '@/app/ui/components/Alert.vue';
import Layout from '@/onBoardingInternational/ui/components/Layout.vue';
import LegalNotice from '@/onBoardingInternational/ui/components/LegalNotice.vue';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import CountryEnum from '@/app/enum/CountryEnum';
import StateEnum from '@/onBoardingInternational/enum/StateEnum';
import { ADD_MESSAGE_ACTION, TOGGLE_LOADING_ACTION } from '@/app/store/actions';
import CompanyFormValidator from '@/onBoardingInternational/validators/companyFormValidator';
import {
  GET_ON_BOARDING_INTERNATIONAL_ACTION,
  PATCH_ON_BOARDING_INTERNATIONAL_ACTION,
  GOTO_STEP_ON_BOARDING_INTERNATIONAL_ACTION,
} from '@/onBoardingInternational/store/actions';

export default {
  name: 'CompanyInternational',
  components: {
    Loading,
    Alert,
    InputText,
    InputMoney,
    InputSelect,
    Layout,
    LegalNotice,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isContinue = ref(false);
    const onBoardingInternational = computed(() => store.getters.getOnBoardingInternational);
    const hasMessage = computed(() => store.getters.hasMessage);
    const messages = computed(() => store.getters.currentMessages);
    const isLoading = computed(() => store.getters.isLoading);
    const getCountriesChoices = computed(() => CountryEnum.getChoices());
    const validationForm = (computed(() => CompanyFormValidator(onBoardingInternational.value)));

    onMounted(async () => {
      await store.dispatch(TOGGLE_LOADING_ACTION);
      await store.dispatch(GET_ON_BOARDING_INTERNATIONAL_ACTION);
      await store.dispatch(TOGGLE_LOADING_ACTION);
    });

    function submit() {
      store.dispatch(TOGGLE_LOADING_ACTION);
      store.dispatch(PATCH_ON_BOARDING_INTERNATIONAL_ACTION, onBoardingInternational.value)
        .then(async () => {
          await store.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.SUCCESS });
          if (isContinue.value === true) {
            await store.dispatch(GOTO_STEP_ON_BOARDING_INTERNATIONAL_ACTION, StateEnum.VOUCHERS);
            await router.push({ name: 'VouchersInternational' });
          }
        }).finally(() => {
          store.dispatch(TOGGLE_LOADING_ACTION);
        });
    }

    function onSave() {
      isContinue.value = false;
      submit();
    }

    function onSaveAndContinue() {
      if (validationForm.value.areValidFields()) {
        isContinue.value = true;
        submit();
      }
    }

    return {
      hasMessage,
      messages,
      validationForm,
      isLoading,
      onSave,
      onSaveAndContinue,
      onBoardingInternational,
      getCountriesChoices,
    };
  },
};
</script>
