export const GET_ON_BOARDING_ACTIVITY_ACTION = 'getOnBoardingActivityAction';
export const PUT_ON_BOARDING_ACTIVITY_ACTION = 'putOnBoardingActivityAction';

export const GET_ON_BOARDING_INFORMATION_ACTION = 'getOnBoardingInformationAction';
export const PUT_ON_BOARDING_INFORMATION_ACTION = 'putOnBoardingInformationAction';
export const GET_ON_BOARDING_SOCIETY_TYPES_ACTION = 'getOnBoardingSocietyTypes';

export const GET_ON_BOARDING_PRODUCTS_ACTIVITY_ACTION = 'getOnBoardingProductsActivityAction';
export const PATCH_ON_BOARDING_PRODUCTS_ACTIVITY_ACTION = 'patchOnBoardingProductsActivityAction';

export const CANCEL_ON_BOARDING_PRODUCT_ACTION = 'cancelOnBoardingProductAction';
export const ADD_ON_BOARDING_EMPTY_PRODUCT_ACTION = 'addOnBoardingEmptyProductAction';
export const REMOVE_ON_BOARDING_PRODUCT_ACTION = 'removeOnBoardingProductActionAction';
export const SAVE_ON_BOARDING_PRODUCT_ACTION = 'saveOnBoardingProductAction';

export const GET_ON_BOARDING_BANK_ACTION = 'getOnBoardingBank';
export const PUT_ON_BOARDING_BANK_ACTION = 'putOnBoardingBank';

export const GET_ON_BOARDING_LEGAL_REPRESENTS_ACTIVITY_ACTION = 'getOnBoardingLegalRepresents';
export const PATCH_ON_BOARDING_LEGAL_REPRESENTS_ACTIVITY_ACTION = 'putOnBoardingLegalRepresents';

export const CANCEL_ON_BOARDING_LEGAL_REPRESENT_ACTION = 'cancelOnBoardingLegalRepresentAction';
export const ADD_ON_BOARDING_EMPTY_LEGAL_REPRESENT_ACTION = 'addOnBoardingEmptyLegalRepresentAction';
export const REMOVE_ON_BOARDING_LEGAL_REPRESENT_ACTION = 'removeOnBoardingLegalRepresentAction';
export const SAVE_ON_BOARDING_LEGAL_REPRESENT_ACTION = 'saveOnBoardingLegalRepresentAction';

export const GET_ON_BOARDING_VOUCHER_ACTION = 'getOnBoardingVoucher';
export const PUT_ON_BOARDING_VOUCHER_ACTION = 'putOnBoardingVoucher';

export const GET_ON_BOARDING_PAYMENT_ACTION = 'getOnBoardingPayment';
export const PUT_ON_BOARDING_PAYMENT_ACTION = 'putOnBoardingPayment';
