<template>
  <Layout :menu-index="3">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="row mb-3">
        <div class="col-12 col-md-9">
          <h2>{{ $t('title.legal_representatives') }}</h2>
        </div>
      </div>

      <div v-if="hasMessage" class="row">
        <div class="col-12 col-md-9">
          <Alert v-for="(message, index) in messages" :key="index" :msg="message" :index="index" />
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-9">
          <p>{{ $t('label.list_of_benefice') }}</p>
        </div>
      </div>

      <div v-if="legalRepresents" class="row">
        <div class="col-12 col-md-9">
          <div v-if="legalRepresents.haveRepresents()">
            <LegalRepresent v-for="(legalRepresent, index) in legalRepresents.representatives"
                            :key="index" :legal-represent="legalRepresent" :index="index"
            />
            <hr class="text-secondary">
          </div>

          <div class="mb-3 mt-2">
            <button
              v-if="legalRepresents.canAddRepresent()"
              type="button"
              class="btn btn-outline-secondary text-primary w-100 border-secondary mt-3"
              @click="addNewLegalRepresent"
            >
              <IconPlus height="24" width="24" />
              {{ $t('action.add_legal_represent') }}
            </button>
          </div>

          <LegalNotice :country="onBoardingInternational.company.country" />

          <div class="mt-3 float-end">
            <a href="#"
               class="btn btn-lg btn-light text-dark rounded-3 me-2"
               @click="onSave"
            >
              {{ $t('action.save') }}
            </a>

            <button
              type="submit"
              class="btn btn-lg btn-primary text-white rounded-3"
              @click="onSaveAndContinue"
            >
              {{ $t('action.continue') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Loading from '@/app/ui/components/Loading.vue';
import Alert from '@/app/ui/components/Alert.vue';
import IconPlus from '@/app/ui/components/icon/IconPlus.vue';
import Layout from '@/onBoardingInternational/ui/components/Layout.vue';
import LegalNotice from '@/onBoardingInternational/ui/components/LegalNotice.vue';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import { ADD_MESSAGE_ACTION, TOGGLE_LOADING_ACTION } from '@/app/store/actions';
import LegalRepresentsFormValidator from '@/onBoardingInternational/validators/legalRepresentsFormValidator';
import StateEnum from '@/onBoardingInternational/enum/StateEnum';
import {
  GET_ON_BOARDING_INTERNATIONAL_LEGAL_REPRESENTS_ACTION,
  PATCH_ON_BOARDING_INTERNATIONAL_ACTION,
  GOTO_STEP_ON_BOARDING_INTERNATIONAL_ACTION,
  ADD_ON_BOARDING_INTERNATIONAL_EMPTY_LEGAL_REPRESENT_ACTION,
  GET_ON_BOARDING_INTERNATIONAL_ACTION,
} from '@/onBoardingInternational/store/actions';
import LegalRepresent from '@/onBoardingInternational/ui/components/LegalRepresent.vue';

export default {
  name: 'LegalRepresentsInternational',
  components: {
    IconPlus,
    LegalRepresent,
    Loading,
    Alert,
    Layout,
    LegalNotice,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isContinue = ref(false);
    const onBoardingInternational = computed(() => store.getters.getOnBoardingInternational);
    const legalRepresents = computed(() => store.getters.getOnBoardingInternationalLegalRepresents);
    const hasMessage = computed(() => store.getters.hasMessage);
    const messages = computed(() => store.getters.currentMessages);
    const isLoading = computed(() => store.getters.isLoading);
    const validationForm = computed(() => LegalRepresentsFormValidator(legalRepresents.value));

    onMounted(async () => {
      await store.dispatch(TOGGLE_LOADING_ACTION);
      await store.dispatch(GET_ON_BOARDING_INTERNATIONAL_ACTION);
      await store.dispatch(GET_ON_BOARDING_INTERNATIONAL_LEGAL_REPRESENTS_ACTION);
      await store.dispatch(TOGGLE_LOADING_ACTION);
    });

    function submit() {
      store.dispatch(TOGGLE_LOADING_ACTION);
      store.dispatch(PATCH_ON_BOARDING_INTERNATIONAL_ACTION, onBoardingInternational.value)
        .then(async () => {
          await store.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.SUCCESS });
          if (isContinue.value === true) {
            await store.dispatch(GOTO_STEP_ON_BOARDING_INTERNATIONAL_ACTION, StateEnum.BANKING);
            await router.push({ name: 'Banking' });
          }
        }).finally(() => {
          store.dispatch(TOGGLE_LOADING_ACTION);
        });
    }

    function onSave() {
      isContinue.value = false;
      submit();
    }

    function onSaveAndContinue() {
      if (validationForm.value.areValidFields()) {
        isContinue.value = true;
        submit();
      } else {
        const errors = validationForm.value.errors.getErrorsFromProperty('legalRepresents');
        errors.forEach((error) => {
          store.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.DANGER, content: error.message });
        });
      }
    }

    function addNewLegalRepresent() {
      store.dispatch(ADD_ON_BOARDING_INTERNATIONAL_EMPTY_LEGAL_REPRESENT_ACTION);
    }

    return {
      hasMessage,
      messages,
      legalRepresents,
      isLoading,
      onSave,
      onSaveAndContinue,
      addNewLegalRepresent,
      onBoardingInternational,
    };
  },
};
</script>
