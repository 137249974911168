import Money from '@/app/model/Money';

export default class Company {
  constructor(
    country = null,
    socialReason = null,
    businessName = null,
    identificationNumber = null,
    shareCapital = 0.00,
    address = null,
    postalCode = null,
    city = null,
  ) {
    this.country = country;
    this.socialReason = socialReason;
    this.businessName = businessName;
    this.identificationNumber = identificationNumber;
    this.shareCapital = shareCapital;
    this.address = address;
    this.postalCode = postalCode;
    this.city = city;
  }

  toJson() {
    return {
      country: this.country,
      socialReason: this.socialReason,
      businessName: this.businessName,
      identificationNumber: this.identificationNumber,
      shareCapital: Money.toJson(this.shareCapital),
      address: this.address,
      postalCode: this.postalCode,
      city: this.city,
    };
  }

  static fromJson(json) {
    return new Company(
      'country' in json ? json.country : null,
      'socialReason' in json ? json.socialReason : null,
      'businessName' in json ? json.businessName : null,
      'identificationNumber' in json ? json.identificationNumber : null,
      'shareCapital' in json && json.shareCapital !== null ? Money.fromJson(json.shareCapital) : 0.00,
      'address' in json ? json.address : null,
      'postalCode' in json ? json.postalCode : null,
      'city' in json ? json.city : null,
    );
  }
}
