<template>
  <svg id="flag-icon-css-fr" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" :height="height">
    <g fill-rule="evenodd" stroke-width="1pt">
      <path fill="#fff" d="M0 0h512v512H0z" />
      <path fill="#00267f" d="M0 0h170.7v512H0z" />
      <path fill="#f31830" d="M341.3 0H512v512H341.3z" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Fr',
  props: {
    height: {
      type: Number,
      default: 16,
    },
  },
};
</script>
