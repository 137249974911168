export default class PaymentFacility {
  constructor(
    enable = false,
    label = null,
    upperBoundary = false,
    upperBoundaryValue = null,
    lowerBoundary = false,
    lowerBoundaryValue = null,
  ) {
    this.enable = enable;
    this.label = label;
    this.upperBoundary = upperBoundary;
    this.upperBoundaryValue = upperBoundaryValue;
    this.lowerBoundary = lowerBoundary;
    this.lowerBoundaryValue = lowerBoundaryValue;
  }

  static createEmpty() {
    return new PaymentFacility();
  }

  toJson() {
    return {
      enable: this.enable,
      label: this.label,
      upperBoundary: this.upperBoundary,
      upperBoundaryValue: this.upperBoundaryValue,
      lowerBoundary: this.lowerBoundary,
      lowerBoundaryValue: this.lowerBoundaryValue,
    };
  }

  static fromJson(json) {
    return new PaymentFacility(
      json.enable,
      json.label,
      json.upperBoundary,
      json.upperBoundaryValue,
      json.lowerBoundary,
      json.lowerBoundaryValue,
    );
  }
}
