const IntegratorEnum = {
  LIMONETIK: 'LIMONETIK',
  PAYLINE: 'PAYLINE',
  ORCHESTRA: 'ORCHESTRA',
  WORDLINE: 'WORDLINE',

  getReadableValue(value) {
    const val = this.getChoices().find((element) => element.value === value);

    return val !== undefined ? val.label : value;
  },

  getChoices() {
    return [
      { value: this.LIMONETIK, label: 'Limonetik' },
      { value: this.PAYLINE, label: 'Payline' },
      { value: this.ORCHESTRA, label: 'Orchestra' },
      { value: this.WORDLINE, label: 'Wordline' },
    ];
  },
};

export default IntegratorEnum;
