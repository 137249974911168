import LegalRepresent from '@/onBoarding/model/LegalRepresent';

export default class LegalRepresentsActivity {
  constructor(
    uuid = null,
    iri = null,
    legalRepresents = [],
  ) {
    this.uuid = uuid;
    this.iri = iri;
    this.legalRepresents = legalRepresents;
  }

  toJson() {
    return {
      uuid: this.uuid,
      iri: this.iri,
    };
  }

  static createEmpty() {
    return new LegalRepresentsActivity();
  }

  addEmptyLegalRepresent() {
    this.legalRepresents.push(LegalRepresent.createEmpty(this.iri));
  }

  static fromJson(json) {
    const legalRepresentsActivityIri = json['@id'];
    return new LegalRepresentsActivity(
      json.uuid,
      legalRepresentsActivityIri,
      json.legalRepresents.length > 0
        ? json.legalRepresents.map((legalRepresent) => LegalRepresent.fromJson(legalRepresent,
          legalRepresentsActivityIri))
        : [LegalRepresent.createEmpty(legalRepresentsActivityIri)],
    );
  }
}
