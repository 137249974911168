import { reactive } from 'vue';
import useValidators from '@/app/validators/validator';
import ErrorsCollection from '@/app/model/ErrorsCollection';
import Media from '@/app/model/Media';

const {
  isEmpty,
  isSocietyNumber,
  isPositive,
  isNafCode,
  isOnlyNumbers,
} = useValidators();

const errors = reactive(new ErrorsCollection());

export default function sellingPointFormValidator(onBoardingSellingPoint) {
  const validateSocialReasonField = () => {
    const property = 'socialReason';
    const value = onBoardingSellingPoint.sellingPoint.socialReason;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    return errors;
  };

  const validateBusinessNameField = () => {
    const property = 'businessName';
    const value = onBoardingSellingPoint.sellingPoint.businessName;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    return errors;
  };

  const validateIdentificationNumberNumberField = () => {
    const property = 'identificationNumber';
    const value = onBoardingSellingPoint.sellingPoint.identificationNumber;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isSocietyNumber(value)) {
      return errors.addError(property, 'validation.invalid_society_number');
    }

    return errors;
  };

  const validateSocialCapitalField = () => {
    const property = 'socialCapital';
    const value = onBoardingSellingPoint.sellingPoint.socialCapital;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isPositive(value)) {
      return errors.addError(property, 'validation.must_positive');
    }

    return errors;
  };

  const validateLegalTypeField = () => {
    const property = 'legalType';
    errors.clearErrors(property);

    if (isEmpty(onBoardingSellingPoint.sellingPoint.legalType)) {
      return errors.addError(property, 'validation.not_blank_select');
    }

    return errors;
  };

  const validateNafCodeField = () => {
    const property = 'nafCode';
    const value = onBoardingSellingPoint.sellingPoint.nafCode;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isNafCode(value)) {
      return errors.addError(property, 'validation.invalid_naf_code');
    }

    return errors;
  };

  const validateStreetField = () => {
    const property = 'street';
    const value = onBoardingSellingPoint.sellingPoint.address.street;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    return errors;
  };

  const validateCityField = () => {
    const property = 'city';
    const value = onBoardingSellingPoint.sellingPoint.address.city;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    return errors;
  };

  const validatePostalCodeField = () => {
    const property = 'postalCode';
    const value = onBoardingSellingPoint.sellingPoint.address.postalCode;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isOnlyNumbers(value)) {
      return errors.addError(property, 'validation.must_only_numbers');
    }

    return errors;
  };

  const validateCountryField = () => {
    const property = 'country';
    errors.clearErrors(property);

    if (isEmpty(onBoardingSellingPoint.sellingPoint.address.country)) {
      return errors.addError(property, 'validation.not_blank_select');
    }

    return errors;
  };

  const validateKBisField = () => {
    const property = 'kBis';
    errors.clearErrors(property);

    if (!(onBoardingSellingPoint.kBis instanceof Media)) {
      return errors.addError(property, 'validation.not_blank_file');
    }

    return errors;
  };

  const validateStatusField = () => {
    const property = 'legalStatus';
    errors.clearErrors(property);

    if (!(onBoardingSellingPoint.legalStatus instanceof Media)) {
      return errors.addError(property, 'validation.not_blank_file');
    }

    return errors;
  };

  const areValidFields = () => {
    validateSocialReasonField();
    validateBusinessNameField();
    validateIdentificationNumberNumberField();
    validateSocialCapitalField();
    validateNafCodeField();
    validateStreetField();
    validateCityField();
    validatePostalCodeField();
    validateCountryField();
    validateKBisField();
    validateStatusField();
    validateLegalTypeField();

    return errors.hasErrors() === false;
  };

  return {
    errors,
    validateBusinessNameField,
    validateSocialReasonField,
    validateIdentificationNumberNumberField,
    validateSocialCapitalField,
    validateNafCodeField,
    validateStreetField,
    validateCityField,
    validatePostalCodeField,
    validateCountryField,
    validateLegalTypeField,
    areValidFields,
  };
}
