<template>
  <Header />
  <div class="container-fluid">
    <div class="row ">
      <nav id="sidebarMenu" class="col-md-3 d-md-block bg-light sidebar collapse position-md-fixed">
        <Menu :menu-index="menuIndex" />
      </nav>

      <main class="col-12 col-md-9 ms-sm-auto px-md-5 my-5">
        <slot />
      </main>
    </div>
  </div>
</template>

<script>
import Header from '@/onBoarding/ui/components/Header.vue';
import Menu from '@/onBoarding/ui/components/Menu.vue';

export default {
  name: 'Activity',
  components: {
    Header,
    Menu,
  },
  props: {
    menuIndex: {
      type: Number,
      default: 1,
    },
  },
};
</script>
