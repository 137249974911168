<template>
  <Layout :menu-index="5">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="row mb-3">
        <div class="col-12 col-md-9">
          <h2>{{ $t('title.legal_representative') }}</h2>
        </div>
      </div>

      <div v-if="hasMessage" class="row">
        <div class="col-12 col-md-9">
          <Alert v-for="(message, index) in messages" :key="index" :msg="message" :index="index" />
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-9">
          <p>{{ $t('label.list_of_benefice') }}</p>
        </div>
      </div>

      <div v-if="legalRepresentsActivity" class="row">
        <div class="col-12 col-md-9">
          <LegalRepresent v-for="(legalRepresent, index) in legalRepresentsActivity.legalRepresents"
                          :key="index" :legal-represent="legalRepresent" :index="index"
          />

          <hr class="text-secondary">

          <div class="mb-3 mt-2">
            <button
              v-if="legalRepresentsActivity.legalRepresents.length < 4"
              type="button"
              class="btn btn-outline-secondary text-primary w-100 border-secondary mt-3"
              @click="addNewLegalRepresent"
            >
              <IconPlus height="24" width="24" />
              {{ $t('action.add_legal_represent') }}
            </button>
          </div>

          <LegalNotice />

          <div class="mt-3 float-end">
            <a href="#"
               class="btn btn-lg btn-light text-dark rounded-3 me-2"
               @click="onSave"
            >
              {{ $t('action.save') }}
            </a>

            <button
              type="submit"
              class="btn btn-lg btn-primary text-white rounded-3"
              @click="onSaveAndContinue"
            >
              {{ $t('action.continue') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import Alert from '@/app/ui/components/Alert.vue';
import IconPlus from '@/app/ui/components/icon/IconPlus.vue';
import Layout from '@/onBoarding/ui/components/Layout.vue';
import LegalNotice from '@/app/ui/components/LegalNotice.vue';
import Loading from '@/app/ui/components/Loading.vue';
import LegalRepresent from '@/onBoarding/ui/components/LegalRepresent.vue';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import {
  ADD_ON_BOARDING_EMPTY_LEGAL_REPRESENT_ACTION,
  GET_ON_BOARDING_LEGAL_REPRESENTS_ACTIVITY_ACTION,
  PATCH_ON_BOARDING_LEGAL_REPRESENTS_ACTIVITY_ACTION,
} from '@/onBoarding/store/activity/actions';
import {
  ADD_MESSAGE_ACTION,
  TOGGLE_LOADING_ACTION,
} from '@/app/store/actions';
import legalRepresentsActivityFormValidator from '@/onBoarding/validators/activity/legalRepresentsActivityFormValidator';

export default {
  name: 'LegalRepresents',
  components: {
    IconPlus,
    LegalRepresent,
    Loading,
    Alert,
    Layout,
    LegalNotice,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const onBoarding = ref(store.getters.getOnBoarding);
    const hasMessage = computed(() => store.getters.hasMessage);
    const messages = computed(() => store.getters.currentMessages);
    const isLoading = computed(() => store.getters.isLoading);
    const legalRepresentsActivity = (
      computed(() => store.getters.getOnBoardingLegalRepresentsActivity));
    const validationForm = (
      computed(() => legalRepresentsActivityFormValidator(legalRepresentsActivity.value)));
    const isContinue = ref(false);

    function submit() {
      store.dispatch(TOGGLE_LOADING_ACTION);
      store.dispatch(PATCH_ON_BOARDING_LEGAL_REPRESENTS_ACTIVITY_ACTION, {
        legalRepresentsActivity: legalRepresentsActivity.value,
        isContinue: isContinue.value,
      }).then(() => {
        store.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.SUCCESS });
        if (isContinue.value === true) {
          router.push({ name: 'Bank' });
        }
      }).finally(() => {
        store.dispatch(TOGGLE_LOADING_ACTION);
      });
    }

    function onSave() {
      isContinue.value = false;
      submit();
    }

    function onSaveAndContinue() {
      if (validationForm.value.areValidFields()) {
        isContinue.value = true;
        submit();
      } else {
        const errors = validationForm.value.errors.getErrorsFromProperty('representsActivity');
        errors.forEach((error) => {
          store.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.DANGER, content: error.message });
        });
      }
    }

    function addNewLegalRepresent() {
      store.dispatch(ADD_ON_BOARDING_EMPTY_LEGAL_REPRESENT_ACTION);
    }

    onMounted(async () => {
      await store.dispatch(TOGGLE_LOADING_ACTION);
      await store.dispatch(GET_ON_BOARDING_LEGAL_REPRESENTS_ACTIVITY_ACTION,
        onBoarding.value.legalRepresentsActivityUuid);
      await store.dispatch(TOGGLE_LOADING_ACTION);
    });

    return {
      hasMessage,
      messages,
      legalRepresentsActivity,
      isLoading,
      onSave,
      onSaveAndContinue,
      addNewLegalRepresent,
    };
  },
};
</script>
