import Menu from '@/onBoarding/model/Menu';

export default class MenuInterface {
  constructor(
  ) {
    this.activityItems = [
      new Menu('/activity', 1, 'menu.activity', { workflowStep: 'information_activity' }),
      new Menu('/product', 2, 'menu.product', { workflowStep: 'products_activity' }),
      new Menu('/information', 3, 'menu.information', { workflowStep: 'information_society' }),
      new Menu('/voucher', 4, 'menu.vouchers', { workflowStep: 'vouchers_society' }),
      new Menu('/legal-represent', 5, 'menu.legal_representative', { workflowStep: 'legal_represents' }),
      new Menu('/bank', 6, 'menu.bank', { workflowStep: 'banking_information' }),
      new Menu('/final-activity', 7, 'menu.final', { workflowStep: 'final_activity' }),
    ];
    this.societyItems = [
      new Menu('/commercial', 1, 'menu.commercial', { workflowStep: 'commercial_activity' }),
      new Menu('/web-environment', 2, 'menu.web_environment', { workflowStep: 'web_environment' }),
      new Menu('/retailer-environment', 3, 'menu.retailer_environment', { workflowStep: 'retailer_environment' }),
      new Menu('/fraud-society', 4, 'menu.fraud', { workflowStep: 'fraud_society' }),
      new Menu('/final-society', 5, 'menu.final', { workflowStep: 'final_society' }),
    ];
    this.societyRetailerItems = [
      new Menu('/commercial', 1, 'menu.commercial', { workflowStep: 'commercial_activity' }),
      new Menu('/retailer-environment', 3, 'menu.retailer_environment', { workflowStep: 'retailer_environment' }),
      new Menu('/fraud-society', 4, 'menu.product_society', { workflowStep: 'fraud_society' }),
      new Menu('/final-society', 5, 'menu.final', { workflowStep: 'final_society' }),
    ];
    this.societyWebItems = [
      new Menu('/commercial', 1, 'menu.commercial', { workflowStep: 'commercial_activity' }),
      new Menu('/web-environment', 2, 'menu.web_environment', { workflowStep: 'web_environment' }),
      new Menu('/fraud-society', 4, 'menu.fraud', { workflowStep: 'fraud_society' }),
      new Menu('/final-society', 5, 'menu.final', { workflowStep: 'final_society' }),
    ];
  }

  getItems(onBoarding) {
    if (this.activityItems.some((menu) => menu.metas.workflowStep === onBoarding.step)) {
      return this.activityItems;
    }

    if (!onBoarding.isWebActivity && onBoarding.isRetailerActivity) {
      return this.societyRetailerItems;
    }

    if (onBoarding.isWebActivity && !onBoarding.isRetailerActivity) {
      return this.societyWebItems;
    }
    return this.societyItems;
  }
}
