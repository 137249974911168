<template>
  <router-link
    :to="getTo()"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  name: 'LocalizedLink',
  props: {
    to: {
      type: String,
      default: '',
    },
  },
  methods: {
    getTo() {
      const { locale } = this.$route.params;
      // we strip leading and trailing slashes and prefix
      // the current locale
      return `/${locale}/${this.to.replace(/^\/|\/$/g, '')}`;
    },
  },
};
</script>
