import TurnoverCountry from '@/onBoarding/model/TurnoverCountry';

export default class Activity {
  constructor(
    uuid,
    activity,
    description,
    annualTurnover = 0,
    annualTurnoversByCountry = [],
    averageCart = 0,
    growth = 0.0,
    website = '',
  ) {
    this.uuid = uuid;
    this.activity = activity;
    this.description = description;
    this.annualTurnover = annualTurnover;
    this.annualTurnoversByCountry = annualTurnoversByCountry;
    this.averageCart = averageCart;
    this.growth = growth;
    this.website = website;
  }

  toJson() {
    return {
      activityArea: this.activity,
      description: this.description,
      averageBasket: this.averageCart,
      growth: this.growth == null ? 0.0 : this.growth,
      countriesTurnover: this.annualTurnoversByCountry.map(
        (countryTurnover) => countryTurnover.toJson(),
      ),
      annualTurnover: this.annualTurnover,
      website: this.website,
    };
  }

  static createEmpty() {
    return new Activity();
  }

  static fromJson(json) {
    return new Activity(
      'uuid' in json ? json.uuid : null,
      'activityArea' in json ? json.activityArea : null,
      'description' in json ? json.description : null,
      'annualTurnover' in json ? json.annualTurnover : null,
      'countriesTurnover' in json
        ? json.countriesTurnover.map((countryTurnover) => TurnoverCountry.fromJson(countryTurnover))
        : [],
      'averageBasket' in json ? json.averageBasket : null,
      'growth' in json && json.growth !== null ? json.growth : 0.0,
      'website' in json ? json.website : null,
    );
  }
}
