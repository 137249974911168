export const FORGOT_PASSWORD_ACTION = 'forgotPassword';
export const CHECK_TOKEN_PASSWORD_ACTION = 'checkTokenPassword';
export const LOGIN_ACTION = 'login';
export const CHANGE_PASSWORD_ACTION = 'changePassword';
export const LOGOUT_ACTION = 'logout';
export const CHECK_AUTH_ACTION = 'checkAuth';
export const DECODE_TOKEN_ACTION = 'decodeToken';
export const REFRESH_TOKEN_ACTION = 'refreshAccessToken';

export const ADD_LOGIN_MESSAGE_ACTION = 'addLoginMessageAction';
export const REMOVE_LOGIN_MESSAGE_ACTION = 'removeLoginMessageAction';
export const CLEAR_LOGIN_MESSAGES_ACTION = 'clearLoginMessageAction';
