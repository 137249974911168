<template>
  <div
    v-if="hasIpRangeTypeValue"
    :key="componentUniqueKey"
    class="row mb-3"
  >
    <div
      :class="(displayIpRange)?'col-4':'col-9'"
    >
      <InputText
        id="ip_range_from"
        v-model="productionIpServer.ipRange.from"
        :required="true"
        :label="$t('label.ip_range_from')"
        :placeholder="$t('placeholder.ip_range_from')"
        :validators="[validationForm.validateIpRangeFrom]"
        :errors="validationForm.errors.getErrorsFromProperty('ip_range_from')"
      />
    </div>
    <div
      v-if="displayIpRange"
      class="col-1 range-to"
    >
      <p class="text-center">
        {{ $t('sentence.to') }}
      </p>
    </div>
    <div
      v-if="displayIpRange"
      class="col-4"
    >
      <InputText
        id="ip_range_to"
        v-model="productionIpServer.ipRange.to"
        :required="true"
        :label="$t('label.ip_range_to')"
        :placeholder="$t('placeholder.ip_range_to')"
        :validators="[validationForm.validateIpRangeTo]"
        :errors="validationForm.errors.getErrorsFromProperty('ip_range_to')"
      />
    </div>
    <div class="col-3 range-add">
      <button
        class="btn btn-lg btn-success text-dark rounded-3 me-2"
        @click="onAddIpProductionServer"
      >
        {{ $t('action.add') }}
      </button>
    </div>
  </div>
</template>

<script>

import {
  computed, reactive, ref, watch,
} from 'vue';
import { useStore } from 'vuex';
import InputText from '@/app/ui/components/form/InputText.vue';
import ProductionIpServerFormValidator from '@/onBoarding/validators/society/productionIpServerFormValidator';
import {
  SAVE_ON_BOARDING_PRODUCTION_IP_SERVER_ACTION,
} from '@/onBoarding/store/society/actions';
import ProductionIpServer from '@/onBoarding/model/ProductionIpServer';
import IpRange from '@/app/model/IpRange';

export default {
  name: 'ProductionIpServerForm',
  components: {
    InputText,
  },
  props: {
    webEnvironment: {
      type: Object,
      default: () => {},
    },
    displayIpRange: {
      type: Boolean,
      default: false,
    },
    hasIpRangeTypeValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const componentUniqueKey = ref(Date.now());
    const productionIpServer = reactive(new ProductionIpServer(
      null,
      new IpRange(null, null),
      props.webEnvironment.iri,
    ));

    const validationForm = computed(
      () => ProductionIpServerFormValidator(productionIpServer, props.displayIpRange),
    );

    function onAddIpProductionServer() {
      if (validationForm.value.areValidFields()) {
        store.dispatch(SAVE_ON_BOARDING_PRODUCTION_IP_SERVER_ACTION, productionIpServer)
          .then(() => {
            productionIpServer.ipRange.from = null;
            productionIpServer.ipRange.to = null;
            componentUniqueKey.value = Date.now();
          });
      }
    }

    watch(() => props.displayIpRange, (isIpRange) => {
      validationForm.value.clearErrors(['ip_range_from', 'ip_range_to']);
      if (!isIpRange) {
        productionIpServer.ipRange.to = null;
      }
    });

    return {
      validationForm,
      onAddIpProductionServer,
      productionIpServer,
      componentUniqueKey,
    };
  },
};
</script>
