export default class TurnoverCountry {
  constructor(
    country,
    turnover,
  ) {
    this.country = country;
    this.turnover = turnover;
    this.uniquIndex = Math.random().toString(36).substr(2);
  }

  toJson() {
    return {
      country: this.country,
      annualTurnover: this.turnover,
    };
  }

  static createEmpty() {
    return new TurnoverCountry(
      null,
      null,
    );
  }

  static fromJson(json) {
    return new TurnoverCountry(
      json.country,
      json.annualTurnover,
    );
  }
}
