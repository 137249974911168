import { i18n } from '@/app/service/i18n';
import CountryEnum from '@/app/enum/CountryEnum';

const PaymentMethodEnum = {

  X3: '3X',
  X4: '4X',
  X10: '10X',
  DEFERRED_CASH: 'DEFERRED_CASH',
  X3_DEFERRED: 'X3_DEFERRED',

  getReadableValue(value) {
    const val = this.getChoices().find((element) => element.value === value);

    return val !== undefined ? val.label : value;
  },

  getChoices() {
    return [
      { value: this.X3, label: i18n.global.tc('label.payment_method_erk_3x') },
      { value: this.X4, label: i18n.global.tc('label.payment_method_erk_4x') },
      { value: this.DEFERRED_CASH, label: i18n.global.tc('label.payment_method_erk_1x_diff') },
      { value: this.X3_DEFERRED, label: i18n.global.tc('label.payment_method_erk_3x_diff') },
      { value: this.X10, label: i18n.global.tc('label.payment_method_ccl_10x') },
    ];
  },

  getChoicesByCountry(country) {
    switch (country) {
      case CountryEnum.SPAIN:
        return [
          { value: this.X3, label: i18n.global.tc('label.payment_method_erk_3x') },
          { value: this.X4, label: i18n.global.tc('label.payment_method_erk_4x') },
        ];
      case CountryEnum.BELGIUM:
        return [
          { value: this.X3, label: i18n.global.tc('label.payment_method_erk_3x') },
        ];
      case CountryEnum.FRANCE:
      default:
        return this.getChoices();
    }
  },
};

export default PaymentMethodEnum;
