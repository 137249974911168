<template>
  <div class="container-fluid bg-light">
    <div
      v-if="isLoading"
      class="row justify-content-center align-items-center"
      style="height:100vh"
    >
      Loading...
    </div>
    <div v-else>
      <slot />
    </div>
  </div>
</template>

<script>
import getEventBus from '@/app/event/bus';

export default {
  name: 'Layout',
  data: () => ({
    isLoading: false,
  }),
  mounted() {
    const EventBus = getEventBus();
    EventBus.on('i18n-load-start', () => {
      (this.isLoading = true);
    });
    EventBus.on('i18n-load-complete', () => {
      (this.isLoading = false);
    });
  },
};
</script>
