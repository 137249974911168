import { i18n } from '@/app/service/i18n';

const CustomerFeesEnum = {
  WITH: 'WITH_FEES',
  WITHOUT: 'WITHOUT_FEES',
  WITHOUT_AND_WITH: 'WITH_AND_WITHOUT_FEES',

  getReadableValue(value) {
    const val = this.getChoices().find((element) => element.value === value);

    return val !== undefined ? val.label : value;
  },

  getChoices() {
    return [
      { value: this.WITH, label: i18n.global.tc('label.with_customer_fees') },
      { value: this.WITHOUT, label: i18n.global.tc('label.without_customer_fees') },
      { value: this.WITHOUT_AND_WITH, label: i18n.global.tc('label.without_and_with_customer_fees') },
    ];
  },
};

export default CustomerFeesEnum;
