<template>
  <div class="pt-4 mb-3">
    <div class="form-floating">
      <div :class="{ 'select-has-value': input }">
        <Multiselect
          :id="id"
          v-model="input"
          class="p-0 form-control"
          :class="(errors || isInvalid)?'is-invalid': ''"
          :aria-label="label"
          :mode="mode"
          :placeholder="placeholder"
          :options="options"
          :no-options-text="$t('no_options')"
          :no-results-text="$t('no_results')"
        />
      </div>
      <label :for="id" :class="{ 'required-star': required }">
        {{ label }}
      </label>
    </div>

    <ErrorDisplay v-if="errors" :errors="errors" />
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import Multiselect from '@vueform/multiselect';
import ErrorDisplay from './ErrorDisplay.vue';

export default {
  name: 'InputSelect',
  components: {
    ErrorDisplay,
    Multiselect,
  },
  props: {
    modelValue: {
      type: [String, Array, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    validators: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'single',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const input = ref(props.modelValue);
    const validateInput = () => props.validators.forEach((validator) => validator());

    watch(input, (value) => {
      emit('update:modelValue', value);
      validateInput();
    });

    return {
      input,
      validateInput,
    };
  },
};
</script>
