import IntegrationMethodEnum from '@/onBoarding/enum/IntegrationMethodEnum';
import IntegratorEnum from '@/onBoarding/enum/IntegratorEnum';
import ProductionIpServer from '@/onBoarding/model/ProductionIpServer';

export default class WebEnvironment {
  constructor(
    uuid = null,
    integrationMethod = null,
    module = null,
    integrator = null,
    notifyUrlProduction = null,
    productionIpServer = [],
    iri = null,
  ) {
    this.uuid = uuid;
    this.integrationMethod = integrationMethod;
    this.module = module;
    this.integrator = integrator;
    this.notifyUrlProduction = notifyUrlProduction;
    this.productionIpServer = productionIpServer;
    this.iri = iri;
  }

  isNew() {
    return this.uuid === null;
  }

  isEmpty() {
    return this.uuid === null
      && this.integrationMethod === null
      && this.module === null
      && this.integrator === null
      && this.notifyUrlProduction === null;
  }

  toJson() {
    return {
      uuid: this.uuid,
      integrationMethod: this.integrationMethod,
      module: this.module,
      integrator: this.integrator,
      notifyUrlProduction: this.notifyUrlProduction,
    };
  }

  resetWebEnvironment() {
    this.module = null;
    this.integrator = null;
    this.notifyUrlProduction = null;
  }

  canDisplayModule() {
    return this.integrationMethod === IntegrationMethodEnum.CMS_MODULE;
  }

  canDisplayIntegrator() {
    return this.integrationMethod === IntegrationMethodEnum.INTEGRATOR;
  }

  canDisplayNotifyUrlProduction() {
    return this.integrationMethod !== null
    && (this.integrationMethod === IntegrationMethodEnum.WEB_SERVICE
      || (this.integrator !== IntegratorEnum.LIMONETIK && this.integrator !== null)
    );
  }

  canDisplayProductionIpServer() {
    return this.integrationMethod !== null
    && this.integrator !== IntegratorEnum.LIMONETIK;
  }

  canDisplayFundraisingJournals() {
    return this.integrationMethod !== null
    && this.integrator !== IntegratorEnum.LIMONETIK
    && this.integrator !== IntegratorEnum.PAYLINE;
  }

  addProductionIpServer(productionIpServer) {
    this.productionIpServer.push(productionIpServer);
  }

  static createEmpty() {
    return new WebEnvironment();
  }

  static fromJson(json) {
    const webEnvironmentIri = '@id' in json ? json['@id'] : null;

    return new WebEnvironment(
      'uuid' in json ? json.uuid : null,
      'integrationMethod' in json ? json.integrationMethod : null,
      'module' in json ? json.module : null,
      'integrator' in json ? json.integrator : null,
      'notifyUrlProduction' in json ? json.notifyUrlProduction : null,
      json.productionIpServer.length > 0
        ? json.productionIpServer.map((productionIp) => ProductionIpServer.fromJson(productionIp))
        : [],
      webEnvironmentIri,
    );
  }
}
