export default class Menu {
  constructor(
    to,
    index,
    label,
    metas = [],
  ) {
    this.to = to;
    this.index = index;
    this.label = label;
    this.metas = metas;
  }

  static createEmpty() {
    return new Menu();
  }

  getTo() {
    if (this.to === null) {
      return '';
    }

    return this.to;
  }

  getIndex() {
    if (this.index === null) {
      return 0;
    }

    return this.index;
  }

  getLabel() {
    if (this.label === null) {
      return '';
    }

    return this.label;
  }
}
