const ModulesEnum = {
  MAGENTO: 'MAGENTO',
  PRESTASHOP: 'PRESTASHOP',

  getReadableValue(value) {
    const val = this.getChoices().find((element) => element.value === value);

    return val !== undefined ? val.label : value;
  },

  getChoices() {
    return [
      { value: this.MAGENTO, label: 'Magento' },
      { value: this.PRESTASHOP, label: 'Prestashop' },
    ];
  },
};

export default ModulesEnum;
