<template>
  <div class="row">
    <div class="col-12">
      <ContactShow v-if="isEdit === false" :contact="contact" :index="index" :edit="toggleEdit" />
      <ContactForm v-if="isEdit === true" :contact="contact" :index="index" :cancel="onCancel" />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import ContactForm from '@/onBoarding/ui/components/ContactForm.vue';
import ContactShow from '@/onBoarding/ui/components/ContactShow.vue';
import { CANCEL_ON_BOARDING_CONTACT_ACTION } from '@/onBoarding/store/society/actions';

export default {
  name: 'Contact',
  components: {
    ContactShow,
    ContactForm,
  },
  props: {
    contact: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const isEdit = ref(true);
    const store = useStore();

    onMounted(() => {
      if (typeof props.contact.isEmpty === 'function' && props.contact.isEmpty() === false) {
        isEdit.value = false;
      }
    });

    function toggleEdit() {
      isEdit.value = !isEdit.value;
    }

    function onCancel() {
      isEdit.value = !isEdit.value;
      store.dispatch(CANCEL_ON_BOARDING_CONTACT_ACTION, props.index);
    }

    return {
      isEdit,
      toggleEdit,
      onCancel,
    };
  },
};
</script>
