export default class Banking {
  constructor(
    accountOwner = null,
    iban = null,
    bic = null,
  ) {
    this.accountOwner = accountOwner;
    this.iban = iban;
    this.bic = bic;
  }

  toJson() {
    return {
      accountOwner: this.accountOwner,
      iban: this.iban,
      bic: this.bic,
    };
  }

  static fromJson(json) {
    return new Banking(
      'accountOwner' in json ? json.accountOwner : null,
      'iban' in json ? json.iban : null,
      'bic' in json ? json.bic : null,
    );
  }
}
