<template>
  <Layout>
    <div class="row justify-content-center align-items-center vp-h-100">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4">
        <div class="card bg-white">
          <LogoFloaPay class="card-img-top mt-4" height="30" />
          <div class="card-body">
            <h5 class="card-title text-center mt-2 mb-4">
              {{ $t('title.forgot_password') }}
            </h5>

            <div v-if="messages" class="row">
              <div class="col-12">
                <Alert v-for="(message, index) in messages"
                       :key="index"
                       :msg="message"
                       :index="index"
                />
              </div>
            </div>

            <Loading v-if="isLoading" />

            <form v-else autocomplete="off" @submit.prevent="onSubmit">
              <InputText id="email"
                         v-model="email"
                         :label="$t('label.email')"
                         placeholder="name@example.com"
                         :required="true"
                         :validators="[validationForm.validateEmailField]"
                         :errors="validationForm.errors.getErrorsFromProperty('email')"
              />
              <div class="row">
                <div class="col text-start">
                  <LocalizedLink to="/login">
                    {{ $t('login') }}
                  </LocalizedLink>
                </div>
                <div class="col text-end">
                  <button type="submit" class="btn btn-primary text-white rounded-3">
                    {{ $t('action.reset_password') }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { TOGGLE_LOADING_ACTION } from '@/app/store/actions';
import { FORGOT_PASSWORD_ACTION, ADD_LOGIN_MESSAGE_ACTION, CLEAR_LOGIN_MESSAGES_ACTION } from '@/security/store/actions';
import { i18n } from '@/app/service/i18n';
import Layout from '@/security/ui/components/Layout.vue';
import Loading from '@/app/ui/components/Loading.vue';
import LocalizedLink from '@/app/ui/components/LocalizedLink.vue';
import Alert from '@/app/ui/components/Alert.vue';
import InputText from '@/app/ui/components/form/InputText.vue';
import LogoFloaPay from '@/app/ui/components/LogoFloaPay.vue';
import ForgotPasswordFormValidator from '@/security/validators/forgotPasswordFormValidator';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';

export default {
  name: 'ForgotPassword',
  components: {
    Alert,
    Layout,
    LogoFloaPay,
    Loading,
    InputText,
    LocalizedLink,
  },
  setup() {
    const router = useRouter();
    const email = ref(null);
    const password = ref(null);
    const store = useStore();
    const messages = computed(() => store.getters.currentLoginMessages);
    const isLoading = computed(() => store.getters.isLoading);
    const validationForm = computed(() => ForgotPasswordFormValidator(email.value));

    onMounted(async () => {
      await store.dispatch(CLEAR_LOGIN_MESSAGES_ACTION);
    });

    function onSubmit() {
      if (validationForm.value.areValidFields()) {
        store.dispatch(TOGGLE_LOADING_ACTION);
        store.dispatch(FORGOT_PASSWORD_ACTION, {
          email: email.value,
          userLocale: i18n.global.locale,
        }).then(() => {
          store.dispatch(ADD_LOGIN_MESSAGE_ACTION, { type: MessageTypeEnum.SUCCESS, content: 'flash.password_change_ask' });

          router.push({
            path: `/${i18n.global.locale}/login`,
          });
        }).finally(() => {
          store.dispatch(TOGGLE_LOADING_ACTION);
        });
      }
    }

    return {
      email,
      password,
      isLoading,
      messages,
      onSubmit,
      validationForm,
    };
  },
};
</script>
