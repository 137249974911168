<template>
  <a href="#"
     class="text-decoration-none lh-lg"
     @click.prevent="goToStep(menu)"
  >
    <div v-if="menuIndex === menu.getIndex()" class="d-flex justify-content-start my-3">
      <small>
        <IconChevronRight
          class="text-dark pb-1"
          height="16"
          width="16"
          style="stroke-width: 15%;"
        />
      </small>
      <small class="step-active ms-2">{{ $t(menu.getLabel()) }}</small>
    </div>

    <div v-if="menuIndex > menu.getIndex()" class="d-flex justify-content-start my-3">
      <small>
        <IconCheckCircle
          height="16"
          width="16"
        />
      </small>
      <small class="step-valid ms-2"> {{ $t(menu.getLabel()) }}</small>
    </div>

    <div v-if="menuIndex < menu.getIndex()" class="d-flex justify-content-start my-3">
      <small class="step ms-4">{{ $t(menu.getLabel()) }}</small>
    </div>
  </a>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { i18n } from '@/app/service/i18n';
import { GOTO_STEP_ON_BOARDING_INTERNATIONAL_ACTION } from '@/onBoardingInternational/store/actions';
import { TOGGLE_LOADING_ACTION } from '@/app/store/actions';
import IconCheckCircle from '@/app/ui/components/icon/IconCheckCircle.vue';
import IconChevronRight from '@/app/ui/components/icon/IconChevronRight.vue';
import router from '@/app/router';

export default {
  name: 'MenuTitle',
  components: {
    IconCheckCircle,
    IconChevronRight,
  },
  props: {
    menuIndex: {
      type: Number,
      default: 1,
    },
    menu: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const menuIndex = ref(props.menuIndex);

    function goToStep(menu) {
      if (menuIndex.value > menu.getIndex()) {
        store.dispatch(TOGGLE_LOADING_ACTION);
        store.dispatch(GOTO_STEP_ON_BOARDING_INTERNATIONAL_ACTION, menu.metas.workflowStep)
          .then(async () => {
            await router.push(`/${i18n.global.locale}${menu.getTo()}`);
          }).finally(() => {
            store.dispatch(TOGGLE_LOADING_ACTION);
          });
      }
    }

    return {
      goToStep,
    };
  },
};
</script>
