export default class SocietyType {
  constructor(
    label,
    value,
  ) {
    this.label = label;
    this.value = value;
  }

  static createEmpty() {
    return new SocietyType();
  }

  static fromJson(data) {
    return new SocietyType(
      data.label,
      data.code,
    );
  }
}
