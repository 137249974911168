<template>
  <svg id="flag-icon-css-be" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" :height="height">
    <g fill-rule="evenodd" stroke-width="1pt">
      <path d="M0 0h170.7v512H0z" />
      <path fill="#ffd90c" d="M170.7 0h170.6v512H170.7z" />
      <path fill="#f31830" d="M341.3 0H512v512H341.3z" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Be',
  props: {
    height: {
      type: Number,
      default: 16,
    },
  },
};
</script>
