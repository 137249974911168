import { reactive } from 'vue';
import useValidators from '@/app/validators/validator';
import ErrorsCollection from '@/app/model/ErrorsCollection';

const errors = reactive(new ErrorsCollection());
const { isSecured } = useValidators();

export default function changePasswordFormValidator(password, confirmationPassword) {
  const validatePasswordField = () => {
    const property = 'password';
    errors.clearErrors(property);

    if (!isSecured(password)) {
      errors.addError(property, 'validation.password_not_secured');
    }
  };

  const validatePasswordConfirmationField = () => {
    const property = 'confirmationPassword';
    errors.clearErrors(property);

    if (password !== confirmationPassword) {
      errors.addError(property, 'validation.not_password_different');
    }
  };

  const areValidFields = () => {
    validatePasswordField();
    validatePasswordConfirmationField();

    return errors.hasErrors() === false;
  };

  return {
    errors,
    validatePasswordField,
    validatePasswordConfirmationField,
    areValidFields,
  };
}
