const REFRESH_TOKEN_KEY = 'floa_onboarding_refresh_token';

export const getRefreshToken = () => window.localStorage.getItem(REFRESH_TOKEN_KEY);

export const saveRefreshToken = (refreshToken) => {
  window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
};

export const destroyRefreshToken = () => window.localStorage.removeItem(REFRESH_TOKEN_KEY);

export default { getRefreshToken, saveRefreshToken, destroyRefreshToken };
