import { ref } from 'vue';

export function formattedValue(input) {
  return ref(Number(input.value).toLocaleString('fr', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }));
}
export function reverteFormatted(value) {
  return parseFloat(value.replace(',', '.').replace(/\s/g, ''));
}
