export const SET_ON_BOARDING_COMMERCIAL_ACTIVITY_MUTATION = 'setOnBoardingCommercialActivityMutation';
export const ADD_ON_BOARDING_EMPTY_CONTACT_MUTATION = 'addOnBoardingEmptyContactMutation';
export const REMOVE_ON_BOARDING_CONTACT_MUTATION = 'removeOnBoardingContactMutation';

export const SET_ON_BOARDING_RETAILER_ENVIRONMENT_MUTATION = 'setOnBoardingRetailerEnvironmentMutation';

export const SET_ON_BOARDING_WEB_ENVIRONMENT_MUTATION = 'setOnBoardingWebEnvironmentMutation';
export const REMOVE_ON_BOARDING_PRODUCTION_IP_SERVER_MUTATION = 'removeOnBoardingProductionIpServerMutation';
export const SET_ON_BOARDING_PRODUCTION_IP_SERVER_MUTATION = 'setOnBoardingProductionIpServerMutation';

export const SET_ON_BOARDING_FRAUD_MUTATION = 'setOnBoardingFraudMutation';
