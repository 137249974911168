export default class Phone {
  constructor(
    prefix = '+33',
    number = null,
  ) {
    this.number = number;
    this.prefix = prefix;
  }

  static createEmpty() {
    return new Phone();
  }

  static fromJson(json) {
    return new Phone(
      'prefix' in json ? json.prefix : '+33',
      'number' in json ? json.number : null,
    );
  }

  toJson() {
    return {
      prefix: this.prefix,
      number: this.number,
    };
  }
}
