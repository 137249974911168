import Media from '@/app/model/Media';

export default class Fraud {
  constructor(
    uuid,
    nomenclature,
    fraudManagement,
    solutionUsed,
  ) {
    this.uuid = uuid;
    this.nomenclature = nomenclature;
    this.fraudManagement = fraudManagement;
    this.solutionUsed = solutionUsed;
  }

  toJson() {
    return {
      uuid: this.uuid,
      nomenclature: this.nomenclature instanceof Media ? this.nomenclature.toJson() : null,
      fraudManagement: this.fraudManagement,
      solutionUsed: this.solutionUsed,
    };
  }

  static createEmpty() {
    return new Fraud();
  }

  static fromJson(json) {
    return new Fraud(
      'uuid' in json ? json.uuid : null,
      'nomenclature' in json && json.nomenclature !== null ? Media.fromJson(json.nomenclature) : null,
      'fraudManagement' in json ? json.fraudManagement : null,
      'solutionUsed' in json ? json.solutionUsed : null,
    );
  }
}
