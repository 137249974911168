import { i18n } from '@/app/service/i18n';

const ContactTypeEnum = {
  PARTNERSHIP_MANAGER: 'PARTNERSHIP_MANAGER',
  FINANCIAL_SERVICE: 'FINANCIAL_SERVICE',
  CUSTOMER_RELATIONSHIP: 'CUSTOMER_RELATIONSHIP',
  FRAUD_SERVICE: 'FRAUD_SERVICE',

  getChoices() {
    return [
      { value: this.PARTNERSHIP_MANAGER, label: i18n.global.tc('label.partnership_manager') },
      { value: this.FINANCIAL_SERVICE, label: i18n.global.tc('label.finance_accounting') },
      { value: this.CUSTOMER_RELATIONSHIP, label: i18n.global.tc('label.customer_relationship') },
      { value: this.FRAUD_SERVICE, label: i18n.global.tc('label.fraud') },
    ];
  },
};

export default ContactTypeEnum;
