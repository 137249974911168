import { i18n } from '@/app/service/i18n';

const IdentificationTypeEnum = {
  IDCARD: 'IDCARD',
  PASSPORT: 'PASSPORT',
  RESIDENCE_PERMIT: 'RESIDENCE_PERMIT',

  getReadableValue(value) {
    const val = this.getChoices().find((element) => element.value === value);

    return val !== undefined ? val.label : value;
  },

  getChoices() {
    return [
      { value: this.IDCARD, label: i18n.global.tc('label.idcard') },
      { value: this.PASSPORT, label: i18n.global.tc('label.passport') },
      { value: this.RESIDENCE_PERMIT, label: i18n.global.tc('label.residence_permit') },
    ];
  },
};

export default IdentificationTypeEnum;
