import { i18n } from '@/app/service/i18n';

const StoreNetworkEnum = {
  WITH: true,
  WITHOUT: false,

  getReadableValue(value) {
    const val = this.getChoices().find((element) => element.value === value);

    return val !== undefined ? val.label : value;
  },

  getChoices() {
    return [
      { value: this.WITH, label: i18n.global.tc('label.finance_pdv') },
      { value: this.WITHOUT, label: i18n.global.tc('label.finance_rib') },
    ];
  },
};

export default StoreNetworkEnum;
