<template>
  <InputUpload
    :id="id"
    v-model="input"
    :action="mediaAction"
    :required="required"
    :label="label"
    :accepted="accepted"
    :errors="errors"
  />
</template>

<script>
import { ref, watch } from 'vue';
import InputUpload from '@/app/ui/components/form/InputUpload.vue';
import { ADD_MEDIA_ACTION } from '@/app/store/actions';

export default {
  name: 'InputMediaOnBoarding',
  components: {
    InputUpload,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    informationLabel: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    accepted: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const mediaAction = ref(ADD_MEDIA_ACTION);
    const input = ref(props.modelValue);

    watch(input, (value) => emit('update:modelValue', value));

    return {
      mediaAction,
      input,
    };
  },
};
</script>
