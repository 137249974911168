import { reactive } from 'vue';
import ErrorsCollection from '@/app/model/ErrorsCollection';
import ContactTypeEnum from '@/onBoarding/enum/ContactTypeEnum';
import PaymentFacilityFormValidator from '@/onBoarding/validators/society/paymentFacilityFormValidator';

const errors = reactive(new ErrorsCollection());

export default function commercialFormValidator(commercialActivity) {
  const validateContactsFields = () => {
    const property = 'contacts';
    errors.clearErrors(property);

    const existPartnershipManager = commercialActivity.contacts.filter(
      (contact) => contact.type === ContactTypeEnum.PARTNERSHIP_MANAGER,
    );

    if (existPartnershipManager.length === 0) {
      return errors.addError(property, 'validation.need_add_partnership_manager');
    }

    return errors;
  };

  const validatePaymentFacilityFields = () => {
    const property = 'paymentFacility';
    const value = commercialActivity.paymentFacility;
    errors.clearErrors(property);

    if (value.enable) {
      if (!value.lowerBoundary && !value.upperBoundary) {
        return errors.addError(property, 'validation.payment_solution_required');
      }

      if (!PaymentFacilityFormValidator(value).areValidFields()) {
        return errors.addError(property, '');
      }
    }

    return errors;
  };

  const areValidFields = () => {
    validateContactsFields();
    validatePaymentFacilityFields();

    return (errors.errors.length === 0);
  };

  return {
    errors,
    areValidFields,
  };
}
