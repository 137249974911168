<template>
  <div v-if="hasCountryTurnovers" class="row mb-3 ps-2">
    <div class="col border-start">
      <h5>{{ $t('label.turnover_by_country') }}</h5>
      <ErrorDisplay v-if="errors" :errors="errors" />
      <CountryTurnoversForm
        v-for="(countryTurnover, index) in countryTurnovers"
        :key="index"
        :country-turnover="countryTurnover"
        :index="index"
        :validators="validators"
        :remove="removeCountryTurnover"
      />
    </div>
  </div>

  <div class="mb-3 mt-2">
    <button
      type="button"
      class="btn btn-outline-secondary text-primary w-100 border-secondary"
      @click="addCountryTurnover"
    >
      <IconPlus height="24" width="24" />
      {{ $t('action.detail_turnover_by_country') }}
    </button>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import ErrorDisplay from '@/app/ui/components/form/ErrorDisplay.vue';
import IconPlus from '@/app/ui/components/icon/IconPlus.vue';
import CountryTurnoversForm from '@/onBoarding/ui/components/CountryTurnoverForm.vue';
import TurnoverCountry from '@/onBoarding/model/TurnoverCountry';

export default {
  name: 'CountryTurnovers',
  components: {
    CountryTurnoversForm,
    IconPlus,
    ErrorDisplay,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Array,
      default: () => null,
    },
    validators: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const countryTurnovers = ref(props.modelValue);
    const hasCountryTurnovers = computed(() => countryTurnovers.value.length > 0);

    const addCountryTurnover = () => {
      countryTurnovers.value = [...countryTurnovers.value, TurnoverCountry.createEmpty()];
    };

    const removeCountryTurnover = (turnover) => {
      countryTurnovers.value.splice(countryTurnovers.value.indexOf(turnover), 1);
    };

    watch(countryTurnovers, (value) => emit('update:modelValue', value));

    return {
      addCountryTurnover,
      removeCountryTurnover,
      countryTurnovers,
      hasCountryTurnovers,
    };
  },
};
</script>
