<template>
  <Layout>
    <div
      class="row justify-content-center align-items-center"
      style="height:100vh"
    >
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4">
        <div class="card bg-white">
          <LogoFloaPay class="card-img-top mt-4" height="30" />
          <div class="card-body">
            <h5 class="card-title text-center mt-2 mb-4">
              {{ $t('title.login') }}
            </h5>

            <div v-if="messages" class="row">
              <div class="col-12">
                <Alert v-for="(message, index) in messages"
                       :key="index"
                       :msg="message"
                       :index="index"
                />
              </div>
            </div>

            <Loading v-if="isLoading" />

            <form v-else autocomplete="off" @submit.prevent="onSubmit">
              <InputText id="email"
                         v-model="email"
                         label="Email"
                         placeholder="name@example.com"
                         :required="true"
                         :validators="[validationForm.validateEmailField]"
                         :errors="validationForm.errors.getErrorsFromProperty('email')"
              />
              <InputPassword id="password"
                             v-model="password"
                             label="Mot de passe"
                             placeholder="Mot de passe"
                             :required="true"
                             :validators="[validationForm.validatePasswordField]"
                             :errors="validationForm.errors.getErrorsFromProperty('password')"
              />

              <div class="row justify-content-md-center mb-3">
                <div class="col-md-3">
                  <LocaleSwitcher />
                </div>
              </div>

              <div class="row">
                <div class="col text-start">
                  <LocalizedLink to="/forgot-password">
                    {{ $t('title.forgot_password') }}
                  </LocalizedLink>
                </div>
                <div class="col text-end">
                  <button type="submit" class="btn btn-primary text-white rounded-3">
                    {{ $t('action.login') }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { TOGGLE_LOADING_ACTION } from '@/app/store/actions';
import { LOGOUT_ACTION, LOGIN_ACTION } from '@/security/store/actions';
import { RESET_ON_BOARDING_ACTION } from '@/onBoarding/store/actions';
import Layout from '@/security/ui/components/Layout.vue';
import Loading from '@/app/ui/components/Loading.vue';
import LocalizedLink from '@/app/ui/components/LocalizedLink.vue';
import Alert from '@/app/ui/components/Alert.vue';
import LocaleSwitcher from '@/app/ui/components/LocaleSwitcher.vue';
import InputText from '@/app/ui/components/form/InputText.vue';
import LogoFloaPay from '@/app/ui/components/LogoFloaPay.vue';
import InputPassword from '@/app/ui/components/form/InputPassword.vue';
import LoginFormValidator from '@/security/validators/loginFormValidator';

export default {
  name: 'Login',
  components: {
    Alert,
    Layout,
    LogoFloaPay,
    Loading,
    InputText,
    InputPassword,
    LocalizedLink,
    LocaleSwitcher,
  },
  setup() {
    const email = ref(null);
    const password = ref(null);
    const store = useStore();
    const router = useRouter();
    const messages = computed(() => store.getters.currentLoginMessages);
    const isLoading = computed(() => store.getters.isLoading);
    const validationForm = computed(() => LoginFormValidator(email.value, password.value));

    onMounted(() => {
      store.dispatch(LOGOUT_ACTION);
      store.dispatch(RESET_ON_BOARDING_ACTION);
    });

    function onSubmit() {
      if (validationForm.value.areValidFields()) {
        store.dispatch(TOGGLE_LOADING_ACTION);
        store.dispatch(LOGIN_ACTION, {
          email: email.value,
          password: password.value,
        }).then(() => router.push({ name: 'Activity' }))
          .finally(() => {
            store.dispatch(TOGGLE_LOADING_ACTION);
          });
      }
    }

    return {
      email,
      password,
      isLoading,
      messages,
      onSubmit,
      validationForm,
    };
  },
};
</script>
