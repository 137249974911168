import Menu from '@/onBoardingSellingPoint/model/Menu';

const PREFIX = '/selling-point';

export default class MenuInterface {
  constructor(
  ) {
    this.activityItems = [

      new Menu(
        `${PREFIX}`, 1, 'menu.information',
        { workflowStep: 'selling_point' },
      ),
      new Menu(
        `${PREFIX}/legal-represents`, 2, 'menu.legal_representatives',
        { workflowStep: 'legal_represents' },
      ),
      new Menu(
        `${PREFIX}/banking`, 3, 'menu.bank',
        { workflowStep: 'banking' },
      ),
      new Menu(
        `${PREFIX}/final`, 4, 'menu.final',
        { workflowStep: 'final_selling_point' },
      ),
    ];
  }

  getItems() {
    return this.activityItems;
  }
}
