import { i18n } from '@/app/service/i18n';

const IntegrationMethodEnum = {
  CMS_MODULE: 'CMS_MODULE',
  INTEGRATOR: 'INTEGRATOR',
  WEB_SERVICE: 'WEB_SERVICE',

  getReadableValue(value) {
    const val = this.getChoices().find((element) => element.value === value);

    return val !== undefined ? val.label : value;
  },

  getChoices() {
    return [
      { value: this.CMS_MODULE, label: i18n.global.tc('label.cms_module') },
      { value: this.INTEGRATOR, label: i18n.global.tc('label.integrator') },
      { value: this.WEB_SERVICE, label: i18n.global.tc('label.web_service') },
    ];
  },
};

export default IntegrationMethodEnum;
