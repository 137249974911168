import { reactive } from 'vue';
import ErrorsCollection from '@/app/model/ErrorsCollection';

const errors = reactive(new ErrorsCollection());

export default function productsActivityFormValidator(productsActivity) {
  const validateProductActivityField = () => {
    const property = 'productActivity';
    const nullProducts = productsActivity.products.filter((product) => product.id === null);
    errors.clearErrors(property);

    if (nullProducts.length === productsActivity.products.length) {
      return errors.addError(property, 'validation.need_add_a_product');
    }

    return errors;
  };

  const areValidFields = () => {
    validateProductActivityField();

    return (errors.errors.length === 0);
  };

  return {
    errors,
    areValidFields,
  };
}
