<template>
  <div class="row border-start">
    <div class="col-12">
      <h6 class="text-dark">
        {{ $t('title.product_to_integrate') }}
      </h6>
    </div>

    <form autocomplete="off" novalidate @submit.prevent>
      <div class="col-12">
        <InputSelect
          id="country"
          v-model="productForm.country"
          :required="true"
          :label="$t('label.country')"
          :placeholder="$t('placeholder.country')"
          :options="getCountriesChoices"
          :validators="[validationForm.validateCountryField]"
          :errors="validationForm.errors.getErrorsFromProperty('country')"
        />

        <div class="mb-3">
          <InputSelect
            id="paymentMethod"
            v-model="productForm.paymentMethods"
            mode="tags"
            :required="true"
            :label="$t('label.payment_methods')"
            :options="getPaymentMethodsChoices(productForm.country)"
            :validators="[validationForm.validatePaymentMethodsField]"
            :errors="validationForm.errors.getErrorsFromProperty('paymentMethods')"
          />
        </div>

        <InputSelect
          id="channel"
          v-model="productForm.channel"
          :required="true"
          :label="$t('label.channel')"
          :placeholder="$t('placeholder.channel')"
          :options="getChannelsChoices"
          :validators="[validationForm.validateChannelField]"
          :errors="validationForm.errors.getErrorsFromProperty('channel')"
        />

        <InputGroupRadio id="customer_fees"
                         v-model="productForm.customerFees"
                         :required="true"
                         :label="$t('label.customer_fees')"
                         :choices="getCustomerFeesChoices"
                         :validators="[validationForm.validateCustomerFeesField]"
                         :errors="validationForm.errors.getErrorsFromProperty('customerFees')"
        />

        <InputGroupRadio id="payment_capture_mode"
                         v-model="productForm.paymentCaptureMode"
                         :required="true"
                         :label="$t('label.payment_capture_mode')"
                         :choices="getPaymentCaptureModeChoices"
                         :validators="[validationForm.validatePaymentCaptureModeField]"
                         :errors="validationForm.errors.getErrorsFromProperty('paymentCaptureMode')"
                         :tooltip="$t('tooltip.validation_mode')"
        />
      </div>

      <div class="col-12 mb-3">
        <button type="submit"
                class="btn btn-lg btn-success text-dark rounded-3 me-2"
                @click="onSaveProduct"
        >
          {{ $t('action.add') }}
        </button>

        <button class="btn btn-lg btn-light text-dark rounded-3 me-2"
                @click="cancel"
        >
          {{ $t('action.cancel') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { computed, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import InputGroupRadio from '@/app/ui/components/form/InputGroupRadio.vue';
import InputSelect from '@/app/ui/components/form/InputSelect.vue';
import ChannelEnum from '@/onBoarding/enum/ChannelEnum';
import CountryEnum from '@/app/enum/CountryEnum';
import CustomerFeesEnum from '@/onBoarding/enum/CustomerFeesEnum';
import PaymentCaptureModeEnum from '@/onBoarding/enum/PaymentCaptureModeEnum';
import PaymentMethodEnum from '@/onBoarding/enum/PaymentMethodEnum';
import { SAVE_ON_BOARDING_PRODUCT_ACTION } from '@/onBoarding/store/activity/actions';
import { ADD_MESSAGE_ACTION } from '@/app/store/actions';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import ProductFormValidator from '@/onBoarding/validators/activity/productFormValidator';

export default {
  name: 'ProductForm',
  components: {
    InputGroupRadio,
    InputSelect,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    cancel: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const validationForm = computed(() => ProductFormValidator(props.product));
    const getChannelsChoices = computed(() => ChannelEnum.getChoices());
    const getCountriesChoices = computed(() => CountryEnum.getChoices());
    const getCustomerFeesChoices = computed(() => CustomerFeesEnum.getChoices());
    const getPaymentCaptureModeChoices = computed(() => PaymentCaptureModeEnum.getChoices());
    const getPaymentMethodsChoices = (country) => PaymentMethodEnum.getChoicesByCountry(country);
    const productForm = reactive(props.product);

    function onSaveProduct() {
      if (validationForm.value.areValidFields()) {
        store.dispatch(SAVE_ON_BOARDING_PRODUCT_ACTION, productForm)
          .then(() => {
            store.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.SUCCESS });
            props.cancel();
          });
      }
    }

    watch(
      () => productForm.country,
      (country) => {
        productForm.paymentMethods = country === CountryEnum.BELGIUM
          ? [PaymentMethodEnum.X3] : [];
      },
    );

    return {
      getChannelsChoices,
      getCountriesChoices,
      getCustomerFeesChoices,
      getPaymentCaptureModeChoices,
      getPaymentMethodsChoices,
      productForm,
      onSaveProduct,
      validationForm,
    };
  },
};
</script>
