import { i18n } from '@/app/service/i18n';

export default class Errors {
  constructor(
    fieldId = null,
    code = null,
  ) {
    this.fieldId = fieldId;
    this.code = code;
    this.message = i18n.global.tc(code);
  }
}
