import http from '@/app/service/http';

const API_PREFIX = '/api';
const VERSION_PREFIX = '/v1';
const ONBOARDING_PREFIX = '/prospect';
const BASE_API_URL_PREFIX = `${process.env.VUE_APP_API_BASE_URL}${API_PREFIX}`;
const BASE_API_URL = `${BASE_API_URL_PREFIX}${VERSION_PREFIX}${ONBOARDING_PREFIX}`;

export default {
  healthcheck() {
    return http.client.get(`${BASE_API_URL_PREFIX}/docs`);
  },
  addMedia(media) {
    const data = new FormData();
    data.append('file', media);

    return http.client.post(`${BASE_API_URL}/media`, data);
  },
  addOnBoarding() {
    return http.client.post(`${BASE_API_URL}/on-boardings`, {});
  },
  getOnBoarding() {
    return http.client.get(`${BASE_API_URL}/on-boardings`);
  },
  postStepBoarding(onBoarding, step) {
    return http.client.post(
      `${BASE_API_URL}/on-boardings/${onBoarding.uuid}/workflow`,
      { step },
    );
  },
  getActivity(uuid) {
    return http.client.get(`${BASE_API_URL}/information-activities/${uuid}`);
  },
  putActivity(activity, isContinue) {
    return http.client.patch(
      `${BASE_API_URL}/information-activities/${activity.uuid}?continue=${isContinue ? 1 : 0}`,
      activity.toJson(), {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
    );
  },
  getProductsActivity(uuid) {
    return http.client.get(`${BASE_API_URL}/products-activities/${uuid}`);
  },
  patchProductsActivity(productsActivity, isContinue) {
    return http.client.patch(
      `${BASE_API_URL}/products-activities/${productsActivity.uuid}?continue=${isContinue ? 1 : 0}`,
      productsActivity, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
    );
  },
  postProduct(product) {
    return http.client.post(
      `${BASE_API_URL}/products`, product, {
        headers: {
          'Content-Type': 'application/ld+json',
        },
      },
    );
  },
  putProduct(product) {
    return http.client.put(`${BASE_API_URL}/products/${product.id}`, product);
  },
  deleteProduct(productId) {
    return http.client.delete(`${BASE_API_URL}/products/${productId}`);
  },
  getInformation(uuid) {
    return http.client.get(`${BASE_API_URL}/information-societies/${uuid}`);
  },
  putInformation(information, isContinue) {
    return http.client.patch(
      `${BASE_API_URL}/information-societies/${information.uuid}?continue=${isContinue ? 1 : 0}`,
      information, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
    );
  },
  getSocietyTypes() {
    return http.client.get(`${BASE_API_URL}/society-types`);
  },
  getVoucher(uuid) {
    return http.client.get(`${BASE_API_URL}/vouchers-societies/${uuid}`);
  },
  putVoucher(voucher, isContinue) {
    return http.client.patch(
      `${BASE_API_URL}/vouchers-societies/${voucher.uuid}?continue=${isContinue ? 1 : 0}`,
      voucher, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
    );
  },
  getLegalRepresentsActivity(uuid) {
    return http.client.get(`${BASE_API_URL}/legal-represents-activities/${uuid}`);
  },
  putLegalRepresentsActivity(legalRepresent, isContinue) {
    return http.client.patch(
      `${BASE_API_URL}/legal-represents-activities/${legalRepresent.uuid}?continue=${isContinue ? 1 : 0}`,
      legalRepresent, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
    );
  },
  postLegalRepresent(legalRepresent) {
    return http.client.post(
      `${BASE_API_URL}/legal-represents`, legalRepresent, {
        headers: {
          'Content-Type': 'application/ld+json',
        },
      },
    );
  },
  putLegalRepresent(legalRepresent) {
    return http.client.put(`${BASE_API_URL}/legal-represents/${legalRepresent.id}`, legalRepresent);
  },
  deleteLegalRepresent(legalRepresentId) {
    return http.client.delete(`${BASE_API_URL}/legal-represents/${legalRepresentId}`);
  },
  getBank(uuid) {
    return http.client.get(`${BASE_API_URL}/banking-informations/${uuid}`);
  },
  putBank(bank, isContinue) {
    return http.client.patch(
      `${BASE_API_URL}/banking-informations/${bank.uuid}?continue=${isContinue ? 1 : 0}`,
      bank, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
    );
  },
  getCommercialActivity(uuid) {
    return http.client.get(`${BASE_API_URL}/commercial-activities/${uuid}`);
  },
  putCommercialActivity(commercialActivity, isContinue) {
    return http.client.patch(
      `${BASE_API_URL}/commercial-activities/${commercialActivity.uuid}?continue=${isContinue ? 1 : 0}`,
      commercialActivity, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
    );
  },
  postContact(contact) {
    return http.client.post(
      `${BASE_API_URL}/contacts`, contact, {
        headers: {
          'Content-Type': 'application/ld+json',
        },
      },
    );
  },
  putContact(contact) {
    return http.client.put(`${BASE_API_URL}/contacts/${contact.id}`, contact);
  },
  deleteContact(contact) {
    return http.client.delete(`${BASE_API_URL}/contacts/${contact}`);
  },
  getRetailerEnvironment(uuid) {
    return http.client.get(`${BASE_API_URL}/retailer-environments/${uuid}`);
  },
  putRetailerEnvironment(retailerEnvironment, isContinue) {
    return http.client.patch(
      `${BASE_API_URL}/retailer-environments/${retailerEnvironment.uuid}?continue=${isContinue ? 1 : 0}`,
      retailerEnvironment, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
    );
  },
  getWebEnvironment(uuid) {
    return http.client.get(`${BASE_API_URL}/web-environments/${uuid}`);
  },
  putWebEnvironment(webEnvironment, isContinue) {
    return http.client.patch(
      `${BASE_API_URL}/web-environments/${webEnvironment.uuid}?continue=${isContinue ? 1 : 0}`,
      webEnvironment, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
    );
  },
  deleteProductionIpServer(productionIpServer) {
    return http.client.delete(`${BASE_API_URL}/production-ip-servers/${productionIpServer}`);
  },
  postProductionIpServer(productionIpServer) {
    return http.client.post(
      `${BASE_API_URL}/production-ip-servers`, productionIpServer, {
        headers: {
          'Content-Type': 'application/ld+json',
        },
      },
    );
  },
  putProductionIpServer(productionIpServer) {
    return http.client.put(`${BASE_API_URL}/production-ip-servers/${productionIpServer.id}`, productionIpServer);
  },
  getFraudSociety(uuid) {
    return http.client.get(`${BASE_API_URL}/fraud-societies/${uuid}`);
  },
  putFraudSociety(fraudSociety, isContinue) {
    return http.client.patch(
      `${BASE_API_URL}/fraud-societies/${fraudSociety.uuid}?continue=${isContinue ? 1 : 0}`,
      fraudSociety, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
    );
  },
  postFraudSociety(fraud) {
    return http.client.post(`${BASE_API_URL}/onboarding/fraud`, {
      ...fraud,
    });
  },
  putFraud(fraud) {
    return http.client.put(`${BASE_API_URL}/onboarding/fraud/${fraud.id}`, {
      ...fraud,
    });
  },
};
