import onBoardingConnector from '@/onBoarding/connector/onBoarding';
import { ADD_MESSAGE_ACTION } from '@/app/store/actions';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import WebEnvironment from '@/onBoarding/model/WebEnvironment';
import RetailerEnvironment from '@/onBoarding/model/RetailerEnvironment';
import ProductionIpServer from '@/onBoarding/model/ProductionIpServer';
import Fraud from '@/onBoarding/model/Fraud';
import Commercial from '@/onBoarding/model/Commercial';
import { GET_ON_BOARDING_ACTION } from '@/onBoarding/store/actions';
import {
  SET_ON_BOARDING_COMMERCIAL_ACTIVITY_MUTATION,
  ADD_ON_BOARDING_EMPTY_CONTACT_MUTATION,
  REMOVE_ON_BOARDING_CONTACT_MUTATION,
  SET_ON_BOARDING_WEB_ENVIRONMENT_MUTATION,
  REMOVE_ON_BOARDING_PRODUCTION_IP_SERVER_MUTATION,
  SET_ON_BOARDING_PRODUCTION_IP_SERVER_MUTATION,
  SET_ON_BOARDING_RETAILER_ENVIRONMENT_MUTATION,
  SET_ON_BOARDING_FRAUD_MUTATION,
} from '@/onBoarding/store/society/mutations';
import {
  GET_ON_BOARDING_COMMERCIAL_ACTIVITY_ACTION,
  PATCH_ON_BOARDING_COMMERCIAL_ACTIVITY_ACTION,
  ADD_ON_BOARDING_EMPTY_CONTACT_ACTION,
  SAVE_ON_BOARDING_CONTACT_ACTION,
  CANCEL_ON_BOARDING_CONTACT_ACTION,
  REMOVE_ON_BOARDING_CONTACT_ACTION,
  GET_ON_BOARDING_WEB_ENVIRONMENT_ACTION,
  PATCH_ON_BOARDING_WEB_ENVIRONMENT_ACTION,
  REMOVE_ON_BOARDING_PRODUCTION_IP_SERVER_ACTION,
  SAVE_ON_BOARDING_PRODUCTION_IP_SERVER_ACTION,
  GET_ON_BOARDING_RETAILER_ENVIRONMENT_ACTION,
  SAVE_ON_BOARDING_RETAILER_ENVIRONMENT_ACTION,
  GET_ON_BOARDING_FRAUD_ACTION,
  SAVE_ON_BOARDING_FRAUD_ACTION,
} from '@/onBoarding/store/society/actions';

const state = {
  commercialActivity: null,
  webEnvironment: null,
  retailerEnvironment: null,
  fraudSociety: null,
};

const getters = {
  getOnBoardingCommercialActivity(currentState) {
    return currentState.commercialActivity;
  },
  getOnBoardingRetailerEnvironment(currentState) {
    return currentState.retailerEnvironment;
  },
  getOnBoardingWebEnvironment(currentState) {
    return currentState.webEnvironment;
  },
  getOnBoardingFraudSociety(currentState) {
    return currentState.fraudSociety;
  },
};

const actions = {
  [GET_ON_BOARDING_COMMERCIAL_ACTIVITY_ACTION](context, uuid) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.getCommercialActivity(uuid)
        .then(({ data }) => {
          const commercialActivity = Commercial.fromJson(data);
          context.commit(
            SET_ON_BOARDING_COMMERCIAL_ACTIVITY_MUTATION,
            commercialActivity,
          );
          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject();
        });
    });
  },
  [PATCH_ON_BOARDING_COMMERCIAL_ACTIVITY_ACTION](context, payload) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.putCommercialActivity(
        payload.commercialActivity.toJson(), payload.isContinue,
      )
        .then(async ({ data }) => {
          const commercialActivity = Commercial.fromJson(data);
          context.commit(
            SET_ON_BOARDING_COMMERCIAL_ACTIVITY_MUTATION,
            commercialActivity,
          );

          if (payload.isContinue) {
            await context.dispatch(GET_ON_BOARDING_ACTION);
          }

          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [SAVE_ON_BOARDING_CONTACT_ACTION](context, contact) {
    return new Promise((resolve, reject) => {
      if (contact.isNew() === true) {
        onBoardingConnector.postContact(contact.toJson())
          .then(async ({ data }) => {
            await context.dispatch(GET_ON_BOARDING_COMMERCIAL_ACTIVITY_ACTION,
              context.getters.getOnBoardingCommercialActivity.uuid);
            resolve(data);
          })
          .catch(({ response }) => {
            context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
            reject(response);
          });
      } else {
        onBoardingConnector.putContact(contact.toJson())
          .then(async ({ data }) => {
            await context.dispatch(GET_ON_BOARDING_COMMERCIAL_ACTIVITY_ACTION,
              context.getters.getOnBoardingCommercialActivity.uuid);
            resolve(data);
          })
          .catch(({ response }) => {
            context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
            reject(response);
          });
      }
    });
  },
  [ADD_ON_BOARDING_EMPTY_CONTACT_ACTION](context, type) {
    context.commit(ADD_ON_BOARDING_EMPTY_CONTACT_MUTATION, type);
  },
  async [CANCEL_ON_BOARDING_CONTACT_ACTION](context, indexToRemove) {
    const contactToRemove = context.getters.getOnBoardingCommercialActivity.contacts[indexToRemove];
    if (contactToRemove.uuid === null) {
      context.commit(REMOVE_ON_BOARDING_CONTACT_MUTATION, contactToRemove);
    }
    await context.dispatch(GET_ON_BOARDING_COMMERCIAL_ACTIVITY_ACTION,
      context.getters.getOnBoardingCommercialActivity.uuid);
  },
  [REMOVE_ON_BOARDING_CONTACT_ACTION](context, contactToRemove) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.deleteContact(contactToRemove.uuid)
        .then(({ data }) => {
          context.commit(REMOVE_ON_BOARDING_CONTACT_MUTATION, contactToRemove);
          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [GET_ON_BOARDING_RETAILER_ENVIRONMENT_ACTION](context, uuid) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.getRetailerEnvironment(uuid)
        .then(({ data }) => {
          const retailerEnvironment = RetailerEnvironment.fromJson(data);
          context.commit(SET_ON_BOARDING_RETAILER_ENVIRONMENT_MUTATION, retailerEnvironment);
          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [SAVE_ON_BOARDING_RETAILER_ENVIRONMENT_ACTION](context, payload) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.putRetailerEnvironment(
        payload.retailerEnvironment.toJson(), payload.isContinue,
      )
        .then(async ({ data }) => {
          const retailerEnvironment = RetailerEnvironment.fromJson(data);
          context.commit(SET_ON_BOARDING_RETAILER_ENVIRONMENT_MUTATION, retailerEnvironment);

          if (payload.isContinue) {
            await context.dispatch(GET_ON_BOARDING_ACTION);
          }

          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [GET_ON_BOARDING_WEB_ENVIRONMENT_ACTION](context, uuid) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.getWebEnvironment(uuid)
        .then(({ data }) => {
          const webEnvironment = WebEnvironment.fromJson(data);
          context.commit(
            SET_ON_BOARDING_WEB_ENVIRONMENT_MUTATION,
            webEnvironment,
          );
          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject();
        });
    });
  },
  [PATCH_ON_BOARDING_WEB_ENVIRONMENT_ACTION](context, payload) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.putWebEnvironment(
        payload.webEnvironment.toJson(), payload.isContinue,
      )
        .then(async ({ data }) => {
          const webEnvironment = WebEnvironment.fromJson(data);
          context.commit(
            SET_ON_BOARDING_WEB_ENVIRONMENT_MUTATION,
            webEnvironment,
          );

          if (payload.isContinue) {
            await context.dispatch(GET_ON_BOARDING_ACTION);
          }

          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [SAVE_ON_BOARDING_PRODUCTION_IP_SERVER_ACTION](context, productionIpServerObject) {
    return new Promise((resolve, reject) => {
      if (productionIpServerObject.isNew() === true) {
        onBoardingConnector.postProductionIpServer(productionIpServerObject.toJson())
          .then(({ data }) => {
            const productionIpServerToAdd = ProductionIpServer.fromJson(
              data,
              productionIpServerObject.webEnvironment,
            );
            context.commit(
              SET_ON_BOARDING_PRODUCTION_IP_SERVER_MUTATION,
              productionIpServerToAdd,
            );
            resolve(data);
          })
          .catch(({ response }) => {
            context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
            reject(response);
          });
      }
    });
  },
  [REMOVE_ON_BOARDING_PRODUCTION_IP_SERVER_ACTION](context, productionIpServerToRemove) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.deleteProductionIpServer(productionIpServerToRemove.uuid)
        .then(({ data }) => {
          context.commit(
            REMOVE_ON_BOARDING_PRODUCTION_IP_SERVER_MUTATION,
            productionIpServerToRemove,
          );
          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [GET_ON_BOARDING_FRAUD_ACTION](context, uuid) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.getFraudSociety(uuid)
        .then(({ data }) => {
          const fraudSociety = Fraud.fromJson(data);
          context.commit(SET_ON_BOARDING_FRAUD_MUTATION, fraudSociety);
          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [SAVE_ON_BOARDING_FRAUD_ACTION](context, payload) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.putFraudSociety(payload.fraudSociety.toJson(), payload.isContinue)
        .then(async ({ data }) => {
          const fraudSociety = Fraud.fromJson(data);
          context.commit(SET_ON_BOARDING_FRAUD_MUTATION, fraudSociety);

          if (payload.isContinue) {
            await context.dispatch(GET_ON_BOARDING_ACTION);
          }

          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_ON_BOARDING_COMMERCIAL_ACTIVITY_MUTATION](currentState, commercialActivity) {
    currentState.commercialActivity = commercialActivity;
  },
  [ADD_ON_BOARDING_EMPTY_CONTACT_MUTATION](currentState, type) {
    currentState.commercialActivity.addEmptyContact(type);
  },
  [REMOVE_ON_BOARDING_CONTACT_MUTATION](currentState, contactToRemove) {
    currentState.commercialActivity.contacts = currentState.commercialActivity.contacts.filter(
      (contact) => contact.uuid !== contactToRemove.uuid,
    );
  },
  [SET_ON_BOARDING_RETAILER_ENVIRONMENT_MUTATION](currentState, retailerEnvironment) {
    currentState.retailerEnvironment = retailerEnvironment;
  },
  [SET_ON_BOARDING_WEB_ENVIRONMENT_MUTATION](currentState, webEnvironment) {
    currentState.webEnvironment = webEnvironment;
  },
  [REMOVE_ON_BOARDING_PRODUCTION_IP_SERVER_MUTATION](currentState, productionIpServerToRemove) {
    currentState.webEnvironment.productionIpServer = currentState.webEnvironment
      .productionIpServer.filter(
        (productionIpServer) => productionIpServer.uuid !== productionIpServerToRemove.uuid,
      );
  },
  [SET_ON_BOARDING_PRODUCTION_IP_SERVER_MUTATION](currentState, productionIpServerToAdd) {
    currentState.webEnvironment.addProductionIpServer(productionIpServerToAdd);
  },
  [SET_ON_BOARDING_FRAUD_MUTATION](currentState, fraud) {
    currentState.fraudSociety = fraud;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
