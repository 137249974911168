import CountryEnum from '@/app/enum/CountryEnum';

export default class Address {
  constructor(
    street = null,
    additional = null,
    postalCode = null,
    city = null,
    country = CountryEnum.FRANCE,
  ) {
    this.street = street;
    this.additional = additional;
    this.postalCode = postalCode;
    this.city = city;
    this.country = country;
  }

  static createEmpty() {
    return new Address();
  }

  static fromJson(json) {
    return new Address(
      json.street,
      json.additional,
      json.postalCode,
      json.city,
      json.country,
    );
  }

  toJson() {
    return {
      street: this.street,
      additional: this.additional,
      postalCode: this.postalCode,
      city: this.city,
      country: this.country,
    };
  }
}
