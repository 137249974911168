import { i18n } from '@/app/service/i18n';

const LegalTypeEnum = {
  SA: 'SA',
  SAS: 'SAS',
  SARL: 'SARL',
  SCP: 'SCP',
  EURL: 'EURL',
  EI: 'EI',
  EIRL: 'EIRL',
  SCA: 'SCA',
  SASU: 'SASU',
  SCOP: 'SCOP',
  SELARL: 'SELARL',
  SNC: 'SNC',
  SNS: 'SNS',
  OTHER: 'OTHER',

  getReadableValue(value) {
    const val = this.getChoices().find((element) => element.value === value);

    return val !== undefined ? val.label : value;
  },

  getChoices() {
    return [
      { value: this.SA, label: i18n.global.tc('label.SA') },
      { value: this.SAS, label: i18n.global.tc('label.SAS') },
      { value: this.SARL, label: i18n.global.tc('label.SARL') },
      { value: this.SCP, label: i18n.global.tc('label.SCP') },
      { value: this.EURL, label: i18n.global.tc('label.EURL') },
      { value: this.EI, label: i18n.global.tc('label.EI') },
      { value: this.EIRL, label: i18n.global.tc('label.EIRL') },
      { value: this.SCA, label: i18n.global.tc('label.SCA') },
      { value: this.SASU, label: i18n.global.tc('label.SASU') },
      { value: this.SCOP, label: i18n.global.tc('label.SCOP') },
      { value: this.SELARL, label: i18n.global.tc('label.SELARL') },
      { value: this.SNC, label: i18n.global.tc('label.SNC') },
      { value: this.SNS, label: i18n.global.tc('label.SNS') },
      { value: this.OTHER, label: i18n.global.tc('label.other') },
    ];
  },
};

export default LegalTypeEnum;
