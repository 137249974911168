<template>
  <div class="form-check mb-3">
    <input :id="id" v-model="input"
           class="form-check-input"
           type="checkbox"
           :class="(errors)?'is-invalid':''"
    >
    <label class="form-check-label" :for="id">
      {{ label }}
    </label>

    <ErrorDisplay v-if="errors" :errors="errors" />
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import ErrorDisplay from './ErrorDisplay.vue';

export default {
  name: 'InputCheckbox',
  components: {
    ErrorDisplay,
  },
  props: {
    modelValue: {
      type: [String, Boolean],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    validators: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const input = ref(props.modelValue);
    const validateInput = () => props.validators.forEach((validator) => validator());

    watch(input, (value) => {
      emit('update:modelValue', value);
      validateInput();
    });

    return {
      input,
      validateInput,
    };
  },
};
</script>
