import http from '@/app/service/http';

const API_PREFIX = '/api';
const VERSION_PREFIX = '/v1';
const ON_BOARDING_PREFIX = '/prospects';
const BASE_API_URL_PREFIX = `${process.env.VUE_APP_API_BASE_URL}${API_PREFIX}`;
const BASE_API_URL = `${BASE_API_URL_PREFIX}${VERSION_PREFIX}${ON_BOARDING_PREFIX}`;

export default {
  addMediaInternational(media) {
    const data = new FormData();
    data.append('file', media);

    return http.client.post(`${BASE_API_URL}/on-boardings-internationals/medias`, data);
  },
  patchOnBoardingInternational(onBoardingInternational) {
    return http.client.patch(`${BASE_API_URL}/on-boardings-internationals/${onBoardingInternational.uuid}`,
      onBoardingInternational, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      });
  },
  getOnBoardingInternational() {
    return http.client.get(`${BASE_API_URL}/on-boardings-internationals`);
  },
  addOnBoardingInternational() {
    return http.client.post(`${BASE_API_URL}/on-boardings-internationals`, {});
  },
  postStepBoardingInternational(onBoardingInternationUuid, nextStep) {
    return http.client.post(`${BASE_API_URL}/on-boardings-internationals/${onBoardingInternationUuid}/workflow`, { nextStep });
  },
  getLegalRepresents(onBoardingInternationalUuid) {
    return http.client.get(`${BASE_API_URL}/on-boardings-internationals/${onBoardingInternationalUuid}/legal-represents`);
  },
  patchLegalRepresent(legalRepresent) {
    return http.client.patch(
      `${BASE_API_URL}/on-boardings-internationals/legal-represents/${legalRepresent.uuid}`, legalRepresent, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
    );
  },
  postLegalRepresent(legalRepresent) {
    return http.client.post(
      `${BASE_API_URL}/on-boardings-internationals/legal-represents`, legalRepresent, {
        headers: {
          'Content-Type': 'application/ld+json',
        },
      },
    );
  },
  deleteLegalRepresent(legalRepresent) {
    return http.client.delete(`${BASE_API_URL}/on-boardings-internationals/legal-represents/${legalRepresent.uuid}`);
  },
};
