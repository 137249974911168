<template>
  <p class="text-primary my-2">
    <small>{{ $t('title.personal_details') }}</small>
  </p>
  <table class="table table-bordered">
    <tbody>
      <tr @click="toggleExpand">
        <td class="border-end-0">
          {{ contact.getFriendlyCivility() }}
          {{ contact.getFullName() }}
        </td>
        <td class="border-start-0 text-end">
          <IconChevronRight
            v-if="isExpanded === false"
            width="16" height="16"
            class="me-1 mb-1 float-end"
          />
          <IconChevronDown
            v-if="isExpanded === true"
            width="16" height="16"
            class="me-1 mt-1 float-end"
          />
        </td>
      </tr>
      <tr v-if="isExpanded === true && contact.phone.number">
        <td class="border-end-0">
          {{ $t('label.phone') }}
        </td>
        <td class="border-start-0 text-end">
          {{ contact.getFriendlyPhone() }}
        </td>
      </tr>
      <tr v-if="isExpanded === true && contact.mobilePhone.number">
        <td class="border-end-0">
          {{ $t('label.mobile_phone') }}
        </td>
        <td class="border-start-0 text-end">
          {{ contact.getFriendlyMobilePhone() }}
        </td>
      </tr>
      <tr v-if="isExpanded === true">
        <td class="border-end-0">
          {{ $t('label.email') }}
        </td>
        <td class="border-start-0 text-end">
          {{ contact.email }}
        </td>
      </tr>
      <tr v-if="isExpanded === true">
        <td class="border-end-0">
          {{ $t('label.is_contact_technical_incident') }}
        </td>
        <td class="border-start-0 text-end align-middle">
          {{ contact.getFriendlyContactTechnicalIncident() }}
        </td>
      </tr>
      <tr v-if="isExpanded === true">
        <td class="border-end-0">
          {{ $t('label.is_contact_financing_incident') }}
        </td>
        <td class="border-start-0 text-end align-middle">
          {{ contact.getFriendlyContactFinancingIncident() }}
        </td>
      </tr>
      <tr>
        <td class="p-0 w-50">
          <button
            type="button"
            class="btn btn-outline-secondary text-primary w-100 border-0"
            @click="edit"
          >
            <IconPencil height="16" width="16" />
            {{ $t('action.edit') }}
          </button>
        </td>
        <td class="p-0 w-50">
          <button
            type="button"
            class="btn btn-outline-secondary text-primary w-100 border-0"
            @click="remove"
          >
            <IconTrash height="16" width="16" />
            {{ $t('action.delete') }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { useStore } from 'vuex';
import { ref } from 'vue';
import IconPencil from '@/app/ui/components/icon/IconPencil.vue';
import IconTrash from '@/app/ui/components/icon/IconTrash.vue';
import IconChevronRight from '@/app/ui/components/icon/IconChevronRight.vue';
import IconChevronDown from '@/app/ui/components/icon/IconChevronDown.vue';
import { REMOVE_ON_BOARDING_CONTACT_ACTION } from '@/onBoarding/store/society/actions';

export default {
  name: 'PersonShow',
  components: {
    IconPencil,
    IconTrash,
    IconChevronDown,
    IconChevronRight,
  },
  props: {
    contact: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    edit: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const isExpanded = ref(false);

    function remove() {
      store.dispatch(REMOVE_ON_BOARDING_CONTACT_ACTION, props.contact);
    }

    function toggleExpand() {
      isExpanded.value = !isExpanded.value;
    }

    return {
      remove,
      isExpanded,
      toggleExpand,
    };
  },
};
</script>
