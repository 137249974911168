<template>
  <form class="mb-4" autocomplete="off" novalidate @submit.prevent>
    <div class="row">
      <div class="col-4">
        <InputSelect
          id="civility"
          v-model="contactForm.civility"
          :label="$t('label.civility')"
          :placeholder="$t('placeholder.civility')"
          :options="getCivilitiesChoices"
          :validators="[validationForm.validateCivilityField]"
          :errors="validationForm.errors.getErrorsFromProperty('civility')"
          :required="true"
        />
      </div>
      <div class="col-4">
        <InputText
          id="lastname"
          v-model="contactForm.lastname"
          :label="$t('label.lastname')"
          :placeholder="$t('placeholder.lastname')"
          :validators="[validationForm.validateLastNameField]"
          :errors="validationForm.errors.getErrorsFromProperty('lastname')"
          :required="true"
        />
      </div>
      <div class="col-4">
        <InputText
          id="firstname"
          v-model="contactForm.firstname"
          :label="$t('label.firstname')"
          :placeholder="$t('placeholder.firstname')"
          :validators="[validationForm.validateFirstNameField]"
          :errors="validationForm.errors.getErrorsFromProperty('firstname')"
          :required="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <InputPhone
          id="phone"
          v-model="contactForm.phone"
          :label="$t('label.phone')"
          :placeholder="$t('placeholder.phone')"
          :validators="[validationForm.validatePhoneField]"
          :errors="validationForm.errors.getErrorsFromProperty('phone')"
        />
      </div>
      <div class="col-12">
        <InputPhone
          id="mobilePhone"
          v-model="contactForm.mobilePhone"
          :label="$t('label.mobile_phone')"
          :placeholder="$t('placeholder.mobile_phone')"
          :validators="[validationForm.validateMobilePhoneField]"
          :errors="validationForm.errors.getErrorsFromProperty('mobilePhone')"
        />
      </div>
      <div class="col-12">
        <p class="bg-light p-2 text-secondary">
          <IconInfoFill height="16" width="16" />
          <small class="ms-2">{{ $t('help.phone_number') }}</small>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <InputEmail
          id="email"
          v-model="contactForm.email"
          :label="$t('label.email')"
          :placeholder="$t('label.email')"
          :required="true"
          :validators="[validationForm.validateEmailField]"
          :errors="validationForm.errors.getErrorsFromProperty('email')"
        />
      </div>
    </div>

    <div v-if="contactForm.canShowEmergencyContact()" class="row">
      <div class="col-12">
        <h6 class="text-dark">
          {{ $t('title.emergency_contact') }}
        </h6>
      </div>

      <div
        v-if="contactForm.canShowTechnicalIncidentSelector()"
        class="col-12"
      >
        <InputCheckbox
          id="is_contact_technical_incident"
          v-model="contactForm.contactTechnicalIncident"
          :label="$t('label.is_contact_technical_incident')"
        />
      </div>

      <div
        v-if="contactForm.canShowFinancingIncidentSelector()"
        class="col-12"
      >
        <InputCheckbox
          id="is_contact_financing_incident"
          v-model="contactForm.contactFinancingIncident"
          :label="$t('label.is_contact_financing_incident')"
        />
      </div>
    </div>

    <div class="col-12 mb-3">
      <button type="submit"
              class="btn btn-lg btn-success text-dark rounded-3 me-2"
              @click="onSaveContact"
      >
        {{ $t('action.add') }}
      </button>

      <button class="btn btn-lg btn-light text-dark rounded-3 me-2"
              @click="cancel"
      >
        {{ $t('action.cancel') }}
      </button>
    </div>
  </form>
</template>

<script>
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';
import CivilityEnum from '@/app/enum/CivilityEnum';
import InputCheckbox from '@/app/ui/components/form/InputCheckbox.vue';
import InputSelect from '@/app/ui/components/form/InputSelect.vue';
import InputText from '@/app/ui/components/form/InputText.vue';
import InputPhone from '@/app/ui/components/form/InputPhone.vue';
import InputEmail from '@/app/ui/components/form/InputEmail.vue';
import IconInfoFill from '@/app/ui/components/icon/IconInfoFill.vue';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import { ADD_MESSAGE_ACTION } from '@/app/store/actions';
import { SAVE_ON_BOARDING_CONTACT_ACTION } from '@/onBoarding/store/society/actions';
import contactFormValidator from '@/onBoarding/validators/society/contactFormValidator';

export default {
  name: 'PersonForm',
  components: {
    InputCheckbox,
    InputSelect,
    InputText,
    InputPhone,
    InputEmail,
    IconInfoFill,
  },
  props: {
    contact: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    cancel: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const getCivilitiesChoices = computed(() => CivilityEnum.getChoices());
    const validationForm = computed(() => contactFormValidator(props.contact));
    const contactForm = reactive(props.contact);

    function onSaveContact() {
      if (validationForm.value.areValidFields()) {
        store.dispatch(SAVE_ON_BOARDING_CONTACT_ACTION, contactForm)
          .then(() => {
            store.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.SUCCESS });
            props.cancel();
          });
      }
    }

    return {
      getCivilitiesChoices,
      contactForm,
      onSaveContact,
      validationForm,
    };
  },
};
</script>
