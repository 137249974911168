import { createServer, Response } from 'miragejs';
import Media from '@/app/model/Media';
import CompanyTypeEnum from '@/app/enum/LegalTypeEnum';
import ActivityEnum from '@/onBoarding/enum/ActivityEnum';

createServer({
  routes() {
    this.urlPrefix = process.env.VUE_APP_API_BASE_URL;

    this.post('/api/v1/prospect/media',
      () => {
        const media = Media.createEmpty();
        media.id = '/api/v1/prospect/media/907c5e1d-dbab-4e14-b7fe-85f0c65d3b1b';
        media.originalFileName = '0e1f92f2ef7e573c1e7a6ee5e5b583b8_2.jpg';
        media.fileName = '605469423cab5635316007.jpg';
        media.contentUrl = '/media/605469423cab5635316007.jpg';
        media.uuid = '907c5e1d-dbab-4e14-b7fe-85f0c65d3b1b';

        return new Response(201, {}, media);
      });

    this.post('/api/authentication-token', (schema, request) => {
      const attrs = JSON.parse(request.requestBody);

      if (attrs.email === 'jean-michel.error@floapay.com') {
        return new Response(401, {}, {
          code: 401,
          message: 'Bad credentials',
          type: 'danger',
        });
      }

      return {
        // eslint-disable-next-line vue/max-len, max-len
        token: 'eyxxxGciOiJSUzI1NiIsInR5cCI6IkpXUyJ9.eyJleHAiOjE0NDI0MDM3NTgsImVtYWlsIjoid2VibWFzdGVyQGdlc2RpbmV0LmNvbSIsImlhdCI6IjE0NDI0MDM3MzgifQ.bo5pre_v0moCXVOZOj-s85gVnBLzdSdsltPn3XrkmJaE8eaBo_zcU2pnjs4dUc9hhwNZK8PL6SmSNcQuTUj4OMK7sUDfXr62a05Ds-UgQP8B2Kpc-ZOmSts_vhgo6xJNCy8Oub9-pRA_78WzUUxt294w0IArrNlgQAGewk65RSMThOif9G6L7HzBM4ajFZ-kMDypz2zVQea1kry-m-XXKNDbERCSHnMeV3rANN48SX645_WEvwaHy0agChR4hTnThzLof2bShA7j7HmnSPpODxQszS5ZBHdMgTvYhlcWJmwYswCWCTPl3lsqVq_UOFI5_4arpSNlUwZsichqxXVAHX5idZqCWtoaqAbvNQe2IpinYajoXw-MlYKvcN2TLUF_8sy529olLUagf4FCpCO6JFxovv0E7ll9tUOVvx9LlannqV8976q5XCOoXszKonZSH7DhsBlW5Emjv7PailbARZ-hfl4YlamyY2QbnxAswYycfoxqJxbbIKYGA8dlebdvMyC7m9VATnasTuKeEKS3mP5iyDgWALBHNYXm1FM-12zHBdN3PbOgxmy_OBGvk05thYFEf2WVmyedtFHy4TGlI0-otUTAf2swQAXWhKtkLWzokWWF7l5iNzam1kkEgql5EOztXHDZpmdKVHWBVNvN3J5ivPjjJBm6sGusf-radcw',
        // eslint-disable-next-line vue/max-len, max-len
        refresh_token: 'xxx00a7a9e970f9bbe076e05743e00648908c38366c551a8cdf524ba424fc3e520988f6320a54989bbe85931ffe1bfcc63e33fd8b45d58564039943bfbd8dxxx',
      };
    });

    // set this at true to test expired session use case
    const expiredSession = false;
    if (expiredSession === true) {
      this.post('/api/authentication-token-refresh', () => new Response(401, {}, {
        code: 401,
        message: 'Session expired',
        type: 'warning',
      }));
    }

    if (expiredSession === false) {
      this.post('/api/authentication-token-refresh', () => ({
        // eslint-disable-next-line vue/max-len, max-len
        token: 'eyxxxGciOiJSUzI1NiIsInR5cCI6IkpXUyJ9.eyJleHAiOjE0NDI0MDM3NTgsImVtYWlsIjoid2VibWFzdGVyQGdlc2RpbmV0LmNvbSIsImlhdCI6IjE0NDI0MDM3MzgifQ.bo5pre_v0moCXVOZOj-s85gVnBLzdSdsltPn3XrkmJaE8eaBo_zcU2pnjs4dUc9hhwNZK8PL6SmSNcQuTUj4OMK7sUDfXr62a05Ds-UgQP8B2Kpc-ZOmSts_vhgo6xJNCy8Oub9-pRA_78WzUUxt294w0IArrNlgQAGewk65RSMThOif9G6L7HzBM4ajFZ-kMDypz2zVQea1kry-m-XXKNDbERCSHnMeV3rANN48SX645_WEvwaHy0agChR4hTnThzLof2bShA7j7HmnSPpODxQszS5ZBHdMgTvYhlcWJmwYswCWCTPl3lsqVq_UOFI5_4arpSNlUwZsichqxXVAHX5idZqCWtoaqAbvNQe2IpinYajoXw-MlYKvcN2TLUF_8sy529olLUagf4FCpCO6JFxovv0E7ll9tUOVvx9LlannqV8976q5XCOoXszKonZSH7DhsBlW5Emjv7PailbARZ-hfl4YlamyY2QbnxAswYycfoxqJxbbIKYGA8dlebdvMyC7m9VATnasTuKeEKS3mP5iyDgWALBHNYXm1FM-12zHBdN3PbOgxmy_OBGvk05thYFEf2WVmyedtFHy4TGlI0-otUTAf2swQAXWhKtkLWzokWWF7l5iNzam1kkEgql5EOztXHDZpmdKVHWBVNvN3J5ivPjjJBm6sGusf-radcw',
        // eslint-disable-next-line vue/max-len, max-len
        refresh_token: 'xxx00a7a9e970f9bbe076e05743e00648908c38366c551a8cdf524ba424fc3e520988f6320a54989bbe85931ffe1bfcc63e33fd8b45d58564039943bfbd8dxxx',
      }));
    }

    let activity = {
      activity: ActivityEnum.HIGH_TECH,
      description: null,
      annualTurnover: null,
      annualTurnoversByCountry: [],
      averageCart: null,
      website: null,
    };

    this.get('/api/v1/prospect/onboarding/activity', () => new Response(200, {}, activity));

    this.put('/api/v1/prospect/onboarding/activity',
      (schema, request) => {
        activity = JSON.parse(request.requestBody);

        return new Response(201, {}, activity);
      });

    let legalRepresents = [];

    this.get('/api/v1/prospect/onboarding/legal-represents', () => new Response(200, {}, legalRepresents));

    this.put('/api/v1/prospect/onboarding/legal-represents',
      (schema, request) => {
        legalRepresents = JSON.parse(request.requestBody);

        return new Response(201, {}, legalRepresents);
      });

    this.get('/api/v1/prospect/onboarding/society_types', () => new Response(200, {}, [{
      value: 'sa',
      label: 'SA',
    },
    {
      value: 'sarl',
      label: 'SAS',
    },
    {
      value: 'sarl',
      label: 'SARL',
    },
    {
      value: 'scp',
      label: 'SCP',
    },
    {
      value: 'eurl',
      label: 'EURL',
    },
    {
      value: 'ei',
      label: 'EI',
    },
    {
      value: 'eirl',
      label: 'EIRL',
    },
    {
      value: 'sca',
      label: 'SCA',
    },
    {
      value: 'sasu',
      label: 'SASU',
    },
    {
      value: 'scop',
      label: 'SCOP',
    },
    {
      value: 'selarl',
      label: 'SELARL',
    },
    {
      value: 'snc',
      label: 'SNC',
    },
    {
      value: 'sns',
      label: 'SNS',
    },
    {
      value: 'autre',
      label: 'Autre',
    }]));

    let information = {
      companyName: null,
      companyType: CompanyTypeEnum.SARL,
      companySiret: null,
      capitalStock: null,
      country: 'FR',
      codeActivity: null,
      addressStreet: null,
      addressZipCode: null,
      addressCity: null,
    };

    this.get('/api/v1/prospect/onboarding/information', () => new Response(200, {}, information));

    this.put('/api/v1/prospect/onboarding/information',
      (schema, request) => {
        information = JSON.parse(request.requestBody);

        return new Response(201, {}, information);
      });

    let products = [];

    this.get('/api/v1/prospect/onboarding/products', () => new Response(
      200,
      {},
      products,
    ));

    this.post('/api/v1/prospect/onboarding/products',
      (schema, request) => {
        const newProduct = {
          ...JSON.parse(request.requestBody),
          id: Math.floor(Math.random() * Math.floor(10)),
        };

        products = [...products, newProduct];

        return new Response(201, {}, newProduct);
      });

    this.put('/api/v1/prospect/onboarding/products/:id', (schema, request) => {
      const index = products.findIndex((element) => element.id === parseInt(request.params.id, 10));
      products[index] = JSON.parse(request.requestBody);

      return new Response(201, {}, JSON.parse(request.requestBody));
    });

    this.del('/api/v1/prospect/onboarding/products/:id', (schema, request) => {
      products = products.filter((element) => element.id !== parseInt(request.params.id, 10));

      return new Response(204);
    });

    this.get('/api/v1/prospect/onboarding/bank', () => new Response(200, {}, {
      holder: 'Floa test',
      iban: 'FR12123451234512345678911111',
      bic: 'BICBIDDD112',
      rib: 'rib.pdf',
      finance_pdv: true,
      finance_rib: false,
    }));

    this.put('/api/v1/prospect/onboarding/bank',
      (schema, request) => {
        const bank = JSON.parse(request.requestBody);

        return new Response(201, {}, bank);
      });

    this.get('/api/v1/prospect/onboarding/voucher', () => new Response(200, {}, {
      kbis: new File([], ''),
      status: new File([], ''),
      logo: new File([], ''),
      tax: new File([], ''),
    }));

    this.put('/api/v1/prospect/onboarding/voucher',
      (schema, request) => new Response(201, {}, JSON.parse(request.requestBody)));

    this.get('/api/v1/prospect/onboarding/technical-selling-point', () => new Response(200, {}, {
      haveInternet: true,
      browserUse: 'Safari',
      browserVersion: 'v1.1.1',
      ipCallCenter: '127.0.0.1',
      authentication: 'authent test',
      cancel: 'cancel test',
    }));

    this.put('/api/v1/prospect/onboarding/technical-selling-point',
      (schema, request) => new Response(201, {}, JSON.parse(request.requestBody)));

    this.get('/api/v1/prospect/onboarding/payment', () => new Response(200, {}, {
      standardPayment: {
        card: {
          isSelected: true,
          basket: 100,
        },
        americanExpress: {
          isSelected: false,
          basket: 0,
        },
        check: {
          isSelected: false,
          basket: 0,
        },
        transfer: {
          isSelected: false,
          basket: 0,
        },
        moneyOrder: {
          isSelected: true,
          basket: 300,
        },
        paypal: {
          isSelected: false,
          basket: 0,
        },
        giftCard: {
          isSelected: false,
          basket: 0,
        },
        other: {
          isSelected: false,
          basket: 0,
        },
      },
      havePaymentFacility: true,
      facilityPayment: {
        label: 'Test label Facility',
        twoX: {
          isSelected: false,
          basket: 0,
        },
        threeX: {
          isSelected: true,
          basket: 150,
        },
        fourX: {
          isSelected: false,
          basket: 0,
        },
        tenX: {
          isSelected: false,
          basket: 0,
        },
      },
    }));

    this.put('/api/v1/prospect/onboarding/payment',
      (schema, request) => new Response(201, {}, JSON.parse(request.requestBody)));

    let persons = [];

    this.post('/api/v1/prospect/onboarding/persons',
      (schema, request) => {
        const newPerson = {
          ...JSON.parse(request.requestBody),
          id: Math.floor(Math.random() * Math.floor(10)),
        };

        persons = [...persons, newPerson];

        return new Response(201, {}, newPerson);
      });

    this.put('/api/v1/prospect/onboarding/persons/:id', (schema, request) => {
      const index = persons
        .findIndex((element) => element.id === parseInt(request.params.id, 10));
      persons[index] = JSON.parse(request.requestBody);

      return new Response(201, {}, JSON.parse(request.requestBody));
    });

    this.del('/api/v1/prospect/onboarding/persons/:id', (schema, request) => {
      persons = persons
        .filter((element) => element.id !== parseInt(request.params.id, 10));

      return new Response(204);
    });

    this.get('/api/v1/prospect/onboarding/persons', () => new Response(
      200,
      {},
      persons,
    ));

    let commercial = {};

    this.get('/api/v1/prospect/onboarding/commercial', () => new Response(
      200,
      {},
      commercial,
    ));

    this.post('/api/v1/prospect/onboarding/commercial', (schema, request) => {
      commercial = {
        ...JSON.parse(request.requestBody),
        id: Math.floor(Math.random() * Math.floor(10)),
      };

      return new Response(201, {}, commercial);
    });

    this.put('/api/v1/prospect/onboarding/commercial/:id', (schema, request) => {
      commercial = {
        ...JSON.parse(request.requestBody),
        id: parseInt(request.params.id, 10),
      };

      return new Response(200, {}, commercial);
    });

    let fraud = {};

    this.get('/api/v1/prospect/onboarding/fraud', () => new Response(
      200,
      {},
      fraud,
    ));

    this.post('/api/v1/prospect/onboarding/fraud', (schema, request) => {
      fraud = {
        ...JSON.parse(request.requestBody),
        id: Math.floor(Math.random() * Math.floor(10)),
      };

      return new Response(201, {}, fraud);
    });

    this.put('/api/v1/prospect/onboarding/fraud/:id', (schema, request) => {
      fraud = {
        ...JSON.parse(request.requestBody),
        id: parseInt(request.params.id, 10),
      };

      return new Response(200, {}, fraud);
    });

    this.get('/api/v1/prospects/selling-points/:uuid', () => new Response(200, {}, {
      uuid: 'a7bcc85d-705a-4b59-816e-7f062742a473',
      createdAt: '2021-08-01T05:27:26+00:00',
      updatedAt: '2021-08-24T15:27:26+00:00',
      state: 'company',
      kBis: null,
      rib: null,
      legalStatus: null,
      company: {
        uuid: 'a7bcc85d-705a-4b59-816e-7f062742a473',
        name: 'Company name',
      },
      sellingPoint: {
        socialReason: 'Social reason',
        businessName: 'Business name',
        identificationNumber: 12345678910123,
        socialCapital: 100000,
        nafCode: '1234A',
        address: {
          street: 'Rue Lucien Faure',
          additional: null,
          city: 'Bordeaux',
          postalCode: '33000',
          country: 'FR',
        },
      },
      banking: {
        accountOwner: 'Thomas Guek',
        iban: 'FR7630001007941234567890185',
        bic: 'ABNAFRPP',
      },
      legalRepresents: [],
    }));

    this.passthrough('/api/password-change-requests');
    this.passthrough('/api/password-change-requests/**');
    this.passthrough('/api/authentication-token');
    this.passthrough('/api/authentication-token-refresh');
    this.passthrough('/api/v1/prospect/on-boardings');
    this.passthrough('/api/v1/prospect/on-boardings/**/workflow');
    this.passthrough('/api/v1/prospect/information-activities/**');
    this.passthrough('/api/v1/prospect/society-types');
    this.passthrough('/api/v1/prospect/information-societies/**');
    this.passthrough('/api/v1/prospect/vouchers-societies/**');
    this.passthrough('/api/v1/prospect/media');
    this.passthrough('/api/v1/prospect/banking-informations/**');
    this.passthrough('/api/v1/prospect/products-activities/**');
    this.passthrough('/api/v1/prospect/products/**');
    this.passthrough('/api/v1/prospect/products');
    this.passthrough('/api/v1/prospect/legal-represents-activities/**');
    this.passthrough('/api/v1/prospect/legal-represents');
    this.passthrough('/api/v1/prospect/legal-represents/**');
    this.passthrough('/api/v1/prospect/contacts');
    this.passthrough('/api/v1/prospect/contacts/**');
    this.passthrough('/api/v1/prospect/commercial-activities/**');
    this.passthrough('/api/v1/prospect/web-environments/**');
    this.passthrough('/api/v1/prospect/production-ip-servers');
    this.passthrough('/api/v1/prospect/production-ip-servers/**');
    this.passthrough('/api/v1/prospect/retailer-environments/**');
    this.passthrough('/api/v1/prospect/fraud-societies/**');
    this.passthrough('/api/v1/prospects/on-boarding-selling-points/**/workflow');
    this.passthrough('/api/v1/prospects/on-boarding-selling-points/**');
    this.passthrough('/api/v1/prospects/on-boarding-selling-points');
    this.passthrough('/api/v1/prospects/on-boarding-selling-points/medias');
    this.passthrough('/api/v1/prospects/on-boarding-selling-points/**/legal-represents');
    this.passthrough('/api/v1/prospects/selling-points/**/legal-represents/**');
    this.passthrough('/api/v1/prospects/on-boardings-internationals/medias');
    this.passthrough('/api/v1/prospects/on-boardings-internationals/**');
    this.passthrough('/api/v1/prospects/on-boardings-internationals');
    this.passthrough('/api/v1/prospects/on-boardings-internationals/**/workflow');
    this.passthrough('/api/v1/prospects/on-boardings-internationals/**/legal-represents');
    this.passthrough('/api/v1/prospects/on-boardings-internationals/legal-represents/**');
    this.passthrough('/api/v1/prospects/on-boardings-internationals/legal-represents');
    this.passthrough('/api/docs');
    this.passthrough('/healthcheck');
  },
});
