<template>
  <h6 class="mb-3 text-primary">
    {{ $t('title.product_to_integrate') }}
  </h6>
  <table class="table table-bordered">
    <tbody>
      <tr>
        <td class="border-end-0">
          {{ $t('label.country') }}
        </td>
        <td class="border-start-0 text-end">
          {{ product.getFriendlyCountry() }}
        </td>
      </tr>
      <tr>
        <td class="border-end-0">
          {{ $t('label.payment_methods') }}
        </td>
        <td class="border-start-0 text-end">
          {{ product.getFriendlyPaymentMethods() }}
        </td>
      </tr>
      <tr>
        <td class="border-end-0">
          {{ $t('label.channel') }}
        </td>
        <td class="border-start-0 text-end">
          {{ product.getFriendlyChannel() }}
        </td>
      </tr>
      <tr>
        <td class="border-end-0">
          {{ $t('label.customer_fees') }}
        </td>
        <td class="border-start-0 text-end">
          {{ product.getFriendlyCustomerFees() }}
        </td>
      </tr>
      <tr>
        <td class="border-end-0">
          {{ $t('label.payment_capture_mode') }}
        </td>
        <td class="border-start-0 text-end">
          {{ product.getFriendlyPaymentCaptureMode() }}
        </td>
      </tr>
      <tr>
        <td class="p-0 w-50">
          <button
            type="button"
            class="btn btn-outline-secondary text-primary w-100 border-0"
            @click="edit"
          >
            <IconPencil height="16" width="16" />
            {{ $t('action.edit') }}
          </button>
        </td>
        <td class="p-0 w-50">
          <button
            type="button"
            class="btn btn-outline-secondary text-primary w-100 border-0"
            @click="remove"
          >
            <IconTrash height="16" width="16" />
            {{ $t('action.delete') }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { useStore } from 'vuex';
import IconPencil from '@/app/ui/components/icon/IconPencil.vue';
import IconTrash from '@/app/ui/components/icon/IconTrash.vue';
import { REMOVE_ON_BOARDING_PRODUCT_ACTION } from '@/onBoarding/store/activity/actions';

export default {
  name: 'ProductShow',
  components: {
    IconPencil,
    IconTrash,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    edit: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();

    function remove() {
      store.dispatch(REMOVE_ON_BOARDING_PRODUCT_ACTION, props.index);
    }

    return {
      remove,
    };
  },
};
</script>
