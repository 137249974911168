import securityConnector from '@/security/connector/security';
import { saveRefreshToken, destroyRefreshToken, getRefreshToken } from '@/security/service/jwt';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import MessagesCollection from '@/app/model/MessagesCollection';
import http from '@/app/service/http';
import { ADD_MESSAGE_ACTION } from '@/app/store/actions';
import { CLEAR_MESSAGES_MUTATION } from '@/app/store/mutations';
import {
  LOGIN_ACTION,
  LOGOUT_ACTION,
  CHECK_AUTH_ACTION,
  DECODE_TOKEN_ACTION,
  REFRESH_TOKEN_ACTION,
  FORGOT_PASSWORD_ACTION,
  CHANGE_PASSWORD_ACTION,
  ADD_LOGIN_MESSAGE_ACTION,
  REMOVE_LOGIN_MESSAGE_ACTION,
  CLEAR_LOGIN_MESSAGES_ACTION,
  CHECK_TOKEN_PASSWORD_ACTION,
} from '@/security/store/actions';

import {
  SET_AUTH_MUTATION,
  PURGE_AUTH_MUTATION,
  SET_ME_MUTATION,
  SET_DECODE_TOKEN_MUTATION,
  ADD_LOGIN_MESSAGE_MUTATION,
  REMOVE_LOGIN_MESSAGE_MUTATION,
  CLEAR_LOGIN_MESSAGES_MUTATION,
} from '@/security/store/mutations';

const state = {
  user: {},
  token: null,
  roles: [],
  apimToken: null,
  messagesCollection: new MessagesCollection(),
};

const getters = {
  currentLoginMessages(currentState) {
    return currentState.messagesCollection.messages;
  },
  currentApimToken(currentState) {
    return currentState.apimToken;
  },
  currentUser(currentState) {
    return currentState.user;
  },
  isAuthorized(currentState) {
    const authorizedRoles = [
      'ROLE_PROSPECT_SOCIETY',
      'ROLE_PROSPECT_COMPANY_INTERNATIONAL',
      'ROLE_PROSPECT_SELLING_POINT',
    ];
    return currentState.roles.some((role) => authorizedRoles.includes(role));
  },
  isAuthenticated(currentState) {
    return currentState.token !== null;
  },
  isProspectCompany(currentState) {
    const authorizedRoles = ['ROLE_PROSPECT_SOCIETY'];
    return currentState.roles.some((role) => authorizedRoles.includes(role));
  },
  isProspectSellingPoint(currentState) {
    const authorizedRoles = ['ROLE_PROSPECT_SELLING_POINT'];
    return currentState.roles.some((role) => authorizedRoles.includes(role));
  },
  isInternationalProspect(currentState) {
    const authorizedRoles = ['ROLE_PROSPECT_COMPANY_INTERNATIONAL'];
    return currentState.roles.some((role) => authorizedRoles.includes(role));
  },
};

const actions = {
  [ADD_LOGIN_MESSAGE_ACTION](context, payload) {
    context.commit(ADD_LOGIN_MESSAGE_MUTATION, payload);
  },
  [REMOVE_LOGIN_MESSAGE_ACTION](context, message) {
    context.commit(REMOVE_LOGIN_MESSAGE_MUTATION, message);
  },
  [CLEAR_LOGIN_MESSAGES_ACTION](context) {
    context.commit(CLEAR_LOGIN_MESSAGES_MUTATION);
  },
  [CHECK_TOKEN_PASSWORD_ACTION](context, payload) {
    return new Promise((resolve, reject) => {
      securityConnector.checkTokenPassword(payload.token)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject();
        });
    });
  },
  [CHANGE_PASSWORD_ACTION](context, payload) {
    return new Promise((resolve, reject) => {
      securityConnector.changePassword(payload.token, payload.password)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject();
        });
    });
  },
  [FORGOT_PASSWORD_ACTION](context, payload) {
    return new Promise((resolve, reject) => {
      securityConnector.forgotPassword(payload.email, payload.userLocale)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject();
        });
    });
  },
  [LOGIN_ACTION](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(CLEAR_LOGIN_MESSAGES_MUTATION);
      securityConnector.login(credentials.email, credentials.password)
        .then(async ({ data }) => {
          await context.dispatch(DECODE_TOKEN_ACTION, data.token);
          context.commit(SET_AUTH_MUTATION, data);
          http.setMiddlewareToken(data.token, context.getters.currentApimToken);
          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_LOGIN_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject();
        });
    });
  },
  [DECODE_TOKEN_ACTION](context, encodedToken) {
    const decoded = http.getDecodeToken(encodedToken);
    context.commit(SET_DECODE_TOKEN_MUTATION, decoded);
  },
  [LOGOUT_ACTION](context) {
    http.resetTokenFromClient();
    context.commit(PURGE_AUTH_MUTATION);
    context.commit(CLEAR_MESSAGES_MUTATION);
  },
  [CHECK_AUTH_ACTION](context) {
    if (getters.isAuthenticated()) {
      securityConnector.getMe()
        .then(({ data }) => {
          context.commit(SET_ME_MUTATION, data);
        })
        .catch(({ response }) => {
          context.commit(PURGE_AUTH_MUTATION);
          context.dispatch(ADD_LOGIN_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
        });
    } else {
      context.commit(PURGE_AUTH_MUTATION);
    }
  },
  [REFRESH_TOKEN_ACTION](context) {
    return new Promise((resolve, reject) => {
      if (getRefreshToken() !== null) {
        securityConnector.refresh(getRefreshToken())
          .then(async ({ data }) => {
            await context.dispatch(DECODE_TOKEN_ACTION, data.token);
            context.commit(SET_AUTH_MUTATION, data);
            http.setMiddlewareToken(data.token, context.getters.currentApimToken);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(PURGE_AUTH_MUTATION);
            context.dispatch(ADD_LOGIN_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
            reject();
          });
      } else {
        context.commit(PURGE_AUTH_MUTATION);
        reject();
      }
    });
  },
};

const mutations = {
  [ADD_LOGIN_MESSAGE_MUTATION](currentState, payload) {
    currentState.messagesCollection.addMessage(payload.type, payload.content);
  },
  [REMOVE_LOGIN_MESSAGE_MUTATION](currentState, message) {
    currentState.messagesCollection.messages = currentState.messagesCollection.messages.filter(
      (msg) => msg.key !== message.key,
    );
  },
  [CLEAR_LOGIN_MESSAGES_MUTATION](currentState) {
    currentState.messagesCollection.messages = [];
  },
  [SET_AUTH_MUTATION](currentState, login) {
    currentState.token = login.token;
    saveRefreshToken(login.refresh_token);
  },
  [SET_DECODE_TOKEN_MUTATION](currentState, decodedToken) {
    currentState.roles = decodedToken.roles;
    currentState.apimToken = decodedToken.apimToken;
  },
  [SET_ME_MUTATION](currentState, user) {
    currentState.user = user;
  },
  [PURGE_AUTH_MUTATION](currentState) {
    currentState.token = null;
    currentState.user = {};
    destroyRefreshToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
