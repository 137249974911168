<template>
  <Layout :menu-index="4">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="row mb-3">
        <div class="col-12 col-md-9">
          <h2>{{ $t('title.banking_details') }}</h2>
        </div>
      </div>

      <div v-if="hasMessage" class="row">
        <div class="col-12 col-md-9">
          <Alert v-for="(message, index) in messages" :key="index" :msg="message" :index="index" />
        </div>
      </div>

      <ModalDialogue
        ref="modalDialogue"
        :message="$t('modal_dialog.international_end_course_message')"
      />

      <div class="row">
        <div class="col-12 col-md-9">
          <p>{{ $t('label.store_financing') }}</p>
        </div>
      </div>

      <div v-if="onBoardingInternational" class="row">
        <div class="col-12 col-md-9">
          <form autocomplete="off"
                novalidate
                @submit.prevent
          >
            <InputText
              id="account_owner"
              v-model="onBoardingInternational.banking.accountOwner"
              :required="true"
              :label="$t('label.account_owner')"
              :placeholder="$t('placeholder.account_owner')"
              :validators="[validationForm.validateAccountOwnerField]"
              :errors="validationForm.errors.getErrorsFromProperty('accountOwner')"
            />

            <InputIban
              id="account_iban"
              v-model="onBoardingInternational.banking.iban"
              :required="true"
              :label="$t('label.account_iban')"
              :placeholder="$t('placeholder.account_iban')"
              :validators="[validationForm.validateIbanField]"
              :errors="validationForm.errors.getErrorsFromProperty('iban')"
            />

            <InputText
              id="account_bic"
              v-model="onBoardingInternational.banking.bic"
              :required="true"
              :label="$t('label.account_bic')"
              :placeholder="$t('placeholder.account_bic')"
              :validators="[validationForm.validateBicField]"
              :errors="validationForm.errors.getErrorsFromProperty('bic')"
              :mask-value="'AAAAAAXXXXX'"
            />

            <InputMediaOnBoardingInternational
              id="account_rib_file"
              v-model="onBoardingInternational.rib"
              :required="true"
              :label="$t('label.account_rib_file')"
              :accepted="accepted"
              :errors="validationForm.errors.getErrorsFromProperty('rib')"
            />

            <LegalNotice :country="onBoardingInternational.company.country" />

            <div class="mt-3 float-end">
              <a href="#"
                 class="btn btn-lg btn-light text-dark rounded-3 me-2"
                 @click="onSave"
              >
                {{ $t('action.save') }}
              </a>

              <button
                type="submit"
                class="btn btn-lg btn-primary text-white rounded-3"
                @click="onSaveAndContinue"
              >
                {{ $t('action.continue') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, onMounted, ref } from 'vue';
import Alert from '@/app/ui/components/Alert.vue';
import Loading from '@/app/ui/components/Loading.vue';
import Layout from '@/onBoardingInternational/ui/components/Layout.vue';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import LegalNotice from '@/onBoardingInternational/ui/components/LegalNotice.vue';
import InputText from '@/app/ui/components/form/InputText.vue';
import InputIban from '@/app/ui/components/form/InputIban.vue';
import { ADD_MESSAGE_ACTION, TOGGLE_LOADING_ACTION } from '@/app/store/actions';
import bankingFormValidator from '@/onBoardingInternational/validators/bankingFormValidator';
import InputMediaOnBoardingInternational from '@/onBoardingInternational/ui/components/form/InputMediaOnBoardingInternational.vue';
import AcceptedFileEnum from '@/app/enum/AcceptedFileEnum';
import StateEnum from '@/onBoardingInternational/enum/StateEnum';
import ModalDialogue from '@/app/ui/components/ModalDialogue.vue';
import {
  GET_ON_BOARDING_INTERNATIONAL_ACTION,
  PATCH_ON_BOARDING_INTERNATIONAL_ACTION,
  GOTO_STEP_ON_BOARDING_INTERNATIONAL_ACTION,
} from '@/onBoardingInternational/store/actions';

export default {
  name: 'BankingInternational',
  components: {
    Alert,
    Layout,
    Loading,
    InputText,
    LegalNotice,
    InputIban,
    InputMediaOnBoardingInternational,
    ModalDialogue,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const modalDialogue = ref(null);
    const onBoardingInternational = computed(() => store.getters.getOnBoardingInternational);
    const hasMessage = computed(() => store.getters.hasMessage);
    const messages = computed(() => store.getters.currentMessages);
    const isLoading = computed(() => store.getters.isLoading);
    const validationForm = computed(() => bankingFormValidator(onBoardingInternational.value));
    const isContinue = ref(false);
    const accepted = AcceptedFileEnum.DOCUMENT_ACCEPTED;

    onMounted(async () => {
      await store.dispatch(TOGGLE_LOADING_ACTION);
      await store.dispatch(GET_ON_BOARDING_INTERNATIONAL_ACTION);
      await store.dispatch(TOGGLE_LOADING_ACTION);
    });

    function submit() {
      store.dispatch(TOGGLE_LOADING_ACTION);
      store.dispatch(PATCH_ON_BOARDING_INTERNATIONAL_ACTION, onBoardingInternational.value)
        .then(async () => {
          await store.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.SUCCESS });
          if (isContinue.value === true) {
            await store.dispatch(GOTO_STEP_ON_BOARDING_INTERNATIONAL_ACTION, StateEnum.FINAL);
            await router.push({ name: 'FinalInternational' });
          }
        })
        .finally(() => {
          store.dispatch(TOGGLE_LOADING_ACTION);
        });
    }

    function onSave() {
      isContinue.value = false;
      submit();
    }

    async function onSaveAndContinue() {
      if (validationForm.value.areValidFields()) {
        await modalDialogue.value.showModal().then((response) => {
          if (response) {
            isContinue.value = true;
            submit();
          }
        });
      }
    }

    return {
      hasMessage,
      messages,
      onBoardingInternational,
      isLoading,
      onSave,
      onSaveAndContinue,
      validationForm,
      accepted,
      modalDialogue,
    };
  },
};
</script>
