import { reactive } from 'vue';
import ErrorsCollection from '@/app/model/ErrorsCollection';
import Media from '@/app/model/Media';

const errors = reactive(new ErrorsCollection());

export default function voucherFormValidator(voucher) {
  const validateKbisField = () => {
    const property = 'kbis';
    errors.clearErrors(property);

    if (!(voucher.kbis instanceof Media)) {
      return errors.addError(property, 'validation.not_blank_file');
    }

    return errors;
  };

  const validateStatusField = () => {
    const property = 'status';
    errors.clearErrors(property);

    if (!(voucher.status instanceof Media)) {
      return errors.addError(property, 'validation.not_blank_file');
    }

    return errors;
  };

  const areValidFields = () => {
    validateKbisField();
    validateStatusField();

    return (errors.errors.length === 0);
  };

  return {
    errors,
    areValidFields,
  };
}
