export default class Money {
  static toJson(value) {
    const amount = Math.round((parseFloat(value) * 100)).toString();

    return {
      currency: 'EUR',
      amount,
    };
  }

  static fromJson(json) {
    return json.amount === null ? 0.00 : (json.amount / 100);
  }
}
