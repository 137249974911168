import '@popperjs/core';
import 'bootstrap';
import '@/assets/scss/main.scss';
import '@/app/connector/stubapi';

import { createApp } from 'vue';
import Tooltip from 'bootstrap/js/src/tooltip';
import getEventBus from '@/app/event/bus';
import App from '@/app/ui/App.vue';
import router from '@/app/router';
import store from '@/app/store';
import { i18n } from '@/app/service/i18n';

const app = createApp(App)
  .use(i18n)
  .use(store)
  .use(router);

app.directive('tooltip', {
  beforeMount(el, binding) {
    return new Tooltip(el, {
      title: binding.value,
      placement: binding.arg,
      trigger: 'hover focus',
      delay: { show: 500, hide: 100 },
      html: true,
    });
  },
});

app.config.devtools = process.env.NODE_ENV === 'development';
app.config.globalProperties.emitter = getEventBus();
app.mount('#app');
