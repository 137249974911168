<template>
  <Layout>
    <div class="row">
      <div class="col d-flex justify-content-center">
        <LogoFloaPay height="90" />
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center">
        <HelloWorld msg="Welcome to OnBoarding App" />
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center">
        <p>{{ $t('i18n.test') }}</p>
      </div>
    </div>
  </Layout>
</template>

<script>
// @ is an alias to /src
import Layout from '@/app/ui/components/Layout.vue';
import LogoFloaPay from '@/app/ui/components/LogoFloaPay.vue';
import HelloWorld from '@/app/ui/components/HelloWorld.vue';

export default {
  name: 'Home',
  components: {
    Layout,
    LogoFloaPay,
    HelloWorld,
  },
};
</script>
