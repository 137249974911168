import store from '@/app/store';
import { i18n } from '@/app/service/i18n';
import LegalRepresents from '@/onBoardingSellingPoint/ui/LegalRepresents.vue';
import SellingPoint from '@/onBoardingSellingPoint/ui/SellingPoint.vue';
import Banking from '@/onBoardingSellingPoint/ui/Banking.vue';
import FinalSellingPoint from '@/onBoardingSellingPoint/ui/FinalSellingPoint.vue';

import {
  ADD_ON_BOARDING_SELLING_POINT_ACTION,
  GET_ON_BOARDING_SELLING_POINT_ACTION,
} from '@/onBoardingSellingPoint/store/actions';

const routes = [
  {
    path: '/:locale/selling-point',
    name: 'SellingPoint',
    component: SellingPoint,
    meta: {
      requiresAuth: true,
      stepName: 'selling_point',
    },
  },
  {
    path: '/:locale/selling-point/legal-represents',
    name: 'Contacts',
    component: LegalRepresents,
    meta: {
      requiresAuth: true,
      stepName: 'legal_represents',
    },
  },
  {
    path: '/:locale/selling-point/banking',
    name: 'Banking',
    component: Banking,
    meta: {
      requiresAuth: true,
      stepName: 'banking',
    },
  },
  {
    path: '/:locale/selling-point/final',
    name: 'FinalSellingPoint',
    component: FinalSellingPoint,
    meta: {
      requiresAuth: true,
      stepName: 'final_selling_point',
    },
  },
];

const beforeEachCheckRole = async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const onBoardingSellingPointRoutes = routes.find(
      (route) => to.fullPath === route.path.replace(':locale', to.params.locale),
    );

    if (store.getters.isProspectSellingPoint && !onBoardingSellingPointRoutes) {
      next({
        name: 'SellingPoint',
        params: {
          locale: i18n.global.locale,
        },
      });
    }
  }
};

const beforeEach = async (to, from, next) => {
  const currentRoute = routes.find((route) => to.fullPath === route.path.replace(':locale', to.params.locale));

  if (store.getters.isProspectSellingPoint) {
    if (currentRoute) {
      let onBoarding = store.getters.getOnBoardingSellingPoint;

      if (!onBoarding) {
        await store.dispatch(GET_ON_BOARDING_SELLING_POINT_ACTION)
          .then(async (data) => {
            onBoarding = data;
            if (!data) {
              onBoarding = await store.dispatch(ADD_ON_BOARDING_SELLING_POINT_ACTION);
            }
          }).catch(() => false);
      }

      if (to.meta.stepName && onBoarding.state !== to.meta.stepName) {
        const newPath = routes.find((route) => route.meta.stepName === onBoarding.state);
        next({
          name: newPath.name,
          params: {
            locale: i18n.global.locale,
          },
        });
      }
    }
  }
};

export default {
  routes,
  beforeEach,
  beforeEachCheckRole,
};
