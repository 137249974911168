import store from '@/app/store';
import { GET_ON_BOARDING_ACTION, ADD_ON_BOARDING_ACTION } from '@/onBoarding/store/actions';
import Activity from '@/onBoarding/ui/Activity.vue';
import Bank from '@/onBoarding/ui/Bank.vue';
import FinalActivity from '@/onBoarding/ui/FinalActivity.vue';
import Information from '@/onBoarding/ui/Information.vue';
import LegalRepresents from '@/onBoarding/ui/LegalRepresents.vue';
import Products from '@/onBoarding/ui/Products.vue';
import Sample from '@/onBoarding/ui/Sample.vue';
import Voucher from '@/onBoarding/ui/Voucher.vue';
import Commercial from '@/onBoarding/ui/Commercial.vue';
import WebEnvironment from '@/onBoarding/ui/WebEnvironment.vue';
import RetailerEnvironment from '@/onBoarding/ui/RetailerEnvironment.vue';
import Fraud from '@/onBoarding/ui/Fraud.vue';
import FinalSociety from '@/onBoarding/ui/FinalSociety.vue';
import { i18n } from '@/app/service/i18n';

const routes = [
  {
    path: '/:locale/activity',
    name: 'Activity',
    component: Activity,
    meta: {
      requiresAuth: true,
      stepName: 'information_activity',
    },
  },
  {
    path: '/:locale/bank',
    name: 'Bank',
    component: Bank,
    meta: {
      requiresAuth: true,
      stepName: 'banking_information',
    },
  },
  {
    path: '/:locale/final-activity',
    name: 'FinalActivity',
    component: FinalActivity,
    meta: {
      requiresAuth: true,
      stepName: 'final_activity',
    },
  },
  {
    path: '/:locale/information',
    name: 'Information',
    component: Information,
    meta: {
      requiresAuth: true,
      stepName: 'information_society',
    },
  },
  {
    path: '/:locale/legal-represent',
    name: 'LegalRepresents',
    component: LegalRepresents,
    meta: {
      requiresAuth: true,
      stepName: 'legal_represents',
    },
  },
  {
    path: '/:locale/product',
    name: 'Products',
    component: Products,
    meta: {
      requiresAuth: true,
      stepName: 'products_activity',
    },
  },
  {
    path: '/:locale/voucher',
    name: 'Voucher',
    component: Voucher,
    meta: {
      requiresAuth: true,
      stepName: 'vouchers_society',
    },
  },
  {
    path: '/:locale/sample',
    name: 'Sample',
    component: Sample,
    meta: {
      requiresAuth: true,
      stepName: '',
    },
  },
  {
    path: '/:locale/commercial',
    name: 'Commercial',
    component: Commercial,
    meta: {
      requiresAuth: true,
      stepName: 'commercial_activity',
    },
  },
  {
    path: '/:locale/web-environment',
    name: 'WebEnvironment',
    component: WebEnvironment,
    meta: {
      requiresAuth: true,
      stepName: 'web_environment',
    },
  },
  {
    path: '/:locale/retailer-environment',
    name: 'RetailerEnvironment',
    component: RetailerEnvironment,
    meta: {
      requiresAuth: true,
      stepName: 'retailer_environment',
    },
  },
  {
    path: '/:locale/fraud',
    name: 'Fraud',
    component: Fraud,
    meta: {
      requiresAuth: true,
      stepName: 'fraud_society',
    },
  },
  {
    path: '/:locale/final-society',
    name: 'FinalSociety',
    component: FinalSociety,
    meta: {
      requiresAuth: true,
      stepName: 'final_society',
    },
  },
];

const beforeEachCheckRole = async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const onboardingRoutes = routes.find(
      (route) => to.fullPath === route.path.replace(':locale', to.params.locale),
    );

    if (store.getters.isProspectCompany && !onboardingRoutes) {
      next({
        name: 'Activity',
        params: {
          locale: i18n.global.locale,
        },
      });
    }
  }
};

const beforeEach = async (to, from, next) => {
  const currentRoute = routes.find((route) => to.fullPath === route.path.replace(':locale', to.params.locale));

  if (store.getters.isProspectCompany) {
    if (currentRoute) {
      let onBoarding = store.getters.getOnBoarding;

      if (!onBoarding) {
        await store.dispatch(GET_ON_BOARDING_ACTION)
          .then(async (data) => {
            onBoarding = data;

            if (!data) {
              onBoarding = await store.dispatch(ADD_ON_BOARDING_ACTION);
            }
          })
          .catch(() => false);
      }

      if (to.meta.stepName && onBoarding.step !== to.meta.stepName) {
        const newPath = routes.find((route) => route.meta.stepName === onBoarding.step);
        next({
          name: newPath.name,
          params: {
            locale: i18n.global.locale,
          },
        });
      }
    }
  }
};

export default {
  routes,
  beforeEach,
  beforeEachCheckRole,
};
