export const ADD_ON_BOARDING_INTERNATIONAL_ACTION = 'addOnBoardingInternationalAction';
export const GET_ON_BOARDING_INTERNATIONAL_ACTION = 'getOnBoardingInternationalAction';
export const RESET_ON_BOARDING_INTERNATIONAL_ACTION = 'resetOnBoardingInternationalAction';
export const PATCH_ON_BOARDING_INTERNATIONAL_ACTION = 'patchOnBoardingInternationalAction';

export const GOTO_STEP_ON_BOARDING_INTERNATIONAL_ACTION = 'goToStepOnBoardingInternationalAction';

export const PATCH_ON_BOARDING_INTERNATIONAL_LEGAL_REPRESENTS_ACTION = 'patchOnBoardingInternationalLegalRepresentsAction';
export const GET_ON_BOARDING_INTERNATIONAL_LEGAL_REPRESENTS_ACTION = 'getOnBoardingInternationalLegalRepresentsAction';

export const ADD_ON_BOARDING_INTERNATIONAL_EMPTY_LEGAL_REPRESENT_ACTION = 'addOnBoardingInternationalEmptyLegalRepresentAction';
export const CANCEL_ON_BOARDING_INTERNATIONAL_LEGAL_REPRESENT_ACTION = 'cancelOnBoardingInternationalLegalRepresentAction';
export const REMOVE_ON_BOARDING_INTERNATIONAL_LEGAL_REPRESENT_ACTION = 'removeOnBoardingInternationalLegalRepresentAction';
export const SAVE_ON_BOARDING_INTERNATIONAL_LEGAL_REPRESENT_ACTION = 'saveOnBoardingInternationalLegalRepresentAction';
