import { createI18n } from 'vue-i18n';
import getEventBus from '@/app/event/bus';

const EventBus = getEventBus();
const loadedLanguages = [];

export const DEFAULT_LANGUAGE = 'fr';
export const FALLBACK_LANGUAGE = 'fr';
export const SUPPORTED_LANGUAGES = ['fr', 'en', 'es', 'nl', 'pt', 'it', 'de'];

export function getBrowserLocale(options = {}) {
  const defaultOptions = { countryCodeOnly: false };
  const opt = { ...defaultOptions, ...options };
  const navigatorLocale = navigator.languages !== undefined
    ? navigator.languages[0] : navigator.language;

  if (!navigatorLocale) {
    return DEFAULT_LANGUAGE;
  }

  return opt.countryCodeOnly
    ? navigatorLocale.trim().split(/[-_]/)[0] : navigatorLocale.trim();
}

export function supportedLocalesInclude(locale) {
  return Object.keys(SUPPORTED_LANGUAGES).includes(locale);
}

export function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true });

  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale;
  }

  return DEFAULT_LANGUAGE;
}

const startingLocale = getStartingLocale();

export const i18n = createI18n({
  locale: startingLocale, // set locale
  fallbackLocale: FALLBACK_LANGUAGE,
  messages: {},
});

export function changeLocale(locale) {
  EventBus.emit('i18n-load-start');

  if (loadedLanguages.length > 0 && i18n.global.locale === locale) {
    EventBus.emit('i18n-load-complete');

    return Promise.resolve(locale);
  }

  // If the language was already loaded
  if (loadedLanguages.includes(locale)) {
    i18n.global.locale = locale;
    EventBus.emit('i18n-load-complete');

    return Promise.resolve(locale);
  }

  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "translations-[locale]" */ '@/translations/' + locale + '.json') // eslint-disable-line
    .then((newMessages) => {
      i18n.global.setLocaleMessage(locale, newMessages.default);
      loadedLanguages.push(locale);
      i18n.global.locale = locale;
      EventBus.emit('i18n-load-complete');

      return Promise.resolve(locale);
    });
}

export function localiseDocument(locale, newTitle) {
  document.documentElement.lang = locale;
  document.title = newTitle;
}
