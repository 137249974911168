<template>
  <div class="mb-3">
    <label :for="id" class="form-label text-primary ps-3">
      <small :class="{ 'required-star': required }">{{ label }}</small>
    </label>
    <span v-if="tooltip" class="ms-2">
      <IconQuestionCircle v-tooltip:top="tooltip"
                          width="16" height="16" text-align="justify"
      />
    </span>
    <div v-if="question" class="form-text text-dark ps-3">
      <p>{{ question }}</p>
    </div>

    <div class="btn-group w-100 p-0" role="group">
      <template v-for="(choice, index) in choices" :key="index">
        <input :id="`${id}-${index}`"
               v-model="input"
               type="radio"
               class="btn-check"
               :class="(errors)?'is-invalid':''"
               :name="name"
               :value="choice.value"
               autocomplete="off"
               @click="validateInput"
        >
        <label class="btn btn-lg btn-outline-primary text-dark" :for="`${id}-${index}`">
          {{ choice.label }}
        </label>
      </template>
    </div>

    <ErrorDisplay v-if="errors" :errors="errors" />
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import ErrorDisplay from './ErrorDisplay.vue';
import IconQuestionCircle from '../icon/IconQuestionCircle.vue';

export default {
  name: 'InputGroupRadio',
  components: {
    IconQuestionCircle,
    ErrorDisplay,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    question: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    choices: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: [String, Boolean],
      default: null,
    },
    validators: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const input = ref(props.modelValue);
    const validateInput = () => props.validators.forEach((validator) => validator());

    watch(input, (value, key, index) => emit('update:modelValue', value, key, index), { deep: true });

    return {
      input,
      validateInput,
    };
  },
};
</script>
