<template>
  <Layout :menu-index="2">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="row mb-3">
        <div class="col-12 col-md-9">
          <h2>
            {{ $t('title.web_environment') }}
          </h2>
        </div>
      </div>
      <div v-if="hasMessage" class="row">
        <div class="col-12 col-md-9">
          <Alert v-for="(message, index) in messages" :key="index" :msg="message" :index="index" />
        </div>
      </div>

      <div v-if="webEnvironment" class="row">
        <div class="col-12 col-md-9">
          <form autocomplete="off"
                novalidate
                @submit.prevent
          >
            <InputSelect
              id="integration_method"
              v-model="webEnvironment.integrationMethod"
              :required="true"
              :label="$t('label.integration_methods')"
              :placeholder="$t('placeholder.integration_methods')"
              :options="getIntegrationMethod"
              :validators="[validationForm.validateIntegrationMethodField]"
              :errors="validationForm.errors.getErrorsFromProperty('integration_method')"
              @update:modelValue="resetWebEnvironment()"
            />

            <InputSelect
              v-if="webEnvironment.canDisplayModule()"
              id="module"
              v-model="webEnvironment.module"
              :required="true"
              :label="$t('label.cms_module_select')"
              :placeholder="$t('placeholder.cms_module_select')"
              :options="getModule"
              :validators="[validationForm.validateCMSModuleField]"
              :errors="validationForm.errors.getErrorsFromProperty('module')"
            />

            <InputSelect
              v-if="webEnvironment.canDisplayIntegrator()"
              id="integrator"
              v-model="webEnvironment.integrator"
              :required="true"
              :label="$t('label.integrator_select')"
              :placeholder="$t('placeholder.integrator_select')"
              :options="getIntegrator"
              :validators="[validationForm.validateIntegratorField]"
              :errors="validationForm.errors.getErrorsFromProperty('integrator')"
            />

            <template v-if="webEnvironment.canDisplayNotifyUrlProduction()
              || webEnvironment.canDisplayProductionIpServer()"
            >
              <Separator />

              <div class="row mb-3">
                <div class="col-12">
                  <h5>
                    {{ $t('title.production_environment') }}
                    <IconQuestionCircle v-tooltip:top="$t('tooltip.ip_production')"
                                        width="16" height="16"
                    />
                  </h5>
                </div>
              </div>

              <InputGroupRadio
                id="ipType"
                name="ip_type"
                :label="$t('label.production_ip_server')"
                :choices="getIpTypeChoices"
                @update:modelValue="changeIpRangeType"
              />

              <ErrorDisplay v-if="errors" :errors="errors" />
              <ProductionIpServerForm
                :web-environment="webEnvironment"
                :display-ip-range="displayIpRange"
                :has-ip-range-type-value="hasIpRangeTypeValue"
              />
              <Chip
                v-for="(productionIpServer, index) in webEnvironment.productionIpServer"
                :key="index"
                :value="productionIpServer.toString()"
                :model-value="productionIpServer"
                @update:modelValue="removeIpRange"
              />
              <InputText
                v-if="webEnvironment.canDisplayNotifyUrlProduction()"
                id="notify_url_production"
                :key="componentUniqueKey"
                v-model="webEnvironment.notifyUrlProduction"
                :required="true"
                :label="$t('label.notify_url_production')"
                :placeholder="$t('placeholder.notify_url_production')"
                :validators="[validationForm.validateNotifyUrlProductionField]"
                :errors="validationForm.errors.getErrorsFromProperty('notify_url_production')"
              />
            </template>

            <template v-if="webEnvironment.canDisplayFundraisingJournals()">
              <Separator />

              <div class="row mb-3">
                <div class="col-12">
                  <h5>{{ $t('title.fundraising_journals') }}</h5>
                </div>
              </div>

              <div class="d-flex p-2 bg-grey">
                <IconInfoCircle class="col-1 mt-sm-2" />
                <div class="col mt-sm-2">
                  <div>
                    {{ $t('information.fundraising_journals') }}
                  </div>
                </div>
              </div>
            </template>

            <div class="mt-3 float-end">
              <button
                type="submit"
                class="btn btn-lg btn-light text-dark rounded-3 me-2"
                @click="onSave"
              >
                {{ $t('action.save') }}
              </button>

              <button
                type="submit"
                class="btn btn-lg btn-primary text-white rounded-3"
                @click="onSaveAndContinue"
              >
                {{ $t('action.continue') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Layout from '@/onBoarding/ui/components/Layout.vue';
import Loading from '@/app/ui/components/Loading.vue';
import Alert from '@/app/ui/components/Alert.vue';
import Separator from '@/onBoarding/ui/components/Separator.vue';
import IconInfoCircle from '@/app/ui/components/icon/IconInfoCircle.vue';
import InputSelect from '@/app/ui/components/form/InputSelect.vue';
import InputText from '@/app/ui/components/form/InputText.vue';
import IconQuestionCircle from '@/app/ui/components/icon/IconQuestionCircle.vue';
import InputGroupRadio from '@/app/ui/components/form/InputGroupRadio.vue';
import ProductionIpServerForm from '@/onBoarding/ui/components/ProductionIpServerForm.vue';
import Chip from '@/app/ui/components/Chip.vue';
import IntegrationMethodEnum from '@/onBoarding/enum/IntegrationMethodEnum';
import ModuleEnum from '@/onBoarding/enum/ModuleEnum';
import IntegratorEnum from '@/onBoarding/enum/IntegratorEnum';
import IpTypeEnum from '@/app/enum/IpTypeEnum';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import WebEnvironmentFormValidator from '@/onBoarding/validators/society/webEnvironmentFormValidator';
import { ADD_MESSAGE_ACTION, TOGGLE_LOADING_ACTION } from '@/app/store/actions';
import {
  GET_ON_BOARDING_WEB_ENVIRONMENT_ACTION,
  PATCH_ON_BOARDING_WEB_ENVIRONMENT_ACTION, REMOVE_ON_BOARDING_PRODUCTION_IP_SERVER_ACTION,
} from '@/onBoarding/store/society/actions';

export default {
  name: 'WebEnvironment',
  components: {
    Layout,
    Loading,
    Alert,
    Separator,
    InputSelect,
    InputText,
    IconInfoCircle,
    IconQuestionCircle,
    InputGroupRadio,
    ProductionIpServerForm,
    Chip,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const onBoarding = ref(store.getters.getOnBoarding);
    const isLoading = computed(() => store.getters.isLoading);
    const messages = computed(() => store.getters.currentMessages);
    const hasMessage = computed(() => store.getters.hasMessage);
    const isContinue = ref(false);
    const componentUniqueKey = ref(Date.now());
    const getIntegrationMethod = IntegrationMethodEnum.getChoices();
    const getModule = ModuleEnum.getChoices();
    const getIntegrator = IntegratorEnum.getChoices();
    const webEnvironment = computed(() => store.getters.getOnBoardingWebEnvironment);
    const validationForm = computed(() => WebEnvironmentFormValidator(webEnvironment.value));
    const getIpTypeChoices = computed(() => IpTypeEnum.getChoices());
    const displayIpRange = ref(false);
    const hasIpRangeTypeValue = ref(false);

    onMounted(async () => {
      await store.dispatch(TOGGLE_LOADING_ACTION);
      await store.dispatch(GET_ON_BOARDING_WEB_ENVIRONMENT_ACTION,
        onBoarding.value.webEnvironmentUuid);
      await store.dispatch(TOGGLE_LOADING_ACTION);
    });

    function submit() {
      store.dispatch(TOGGLE_LOADING_ACTION);
      store.dispatch(PATCH_ON_BOARDING_WEB_ENVIRONMENT_ACTION, {
        webEnvironment: webEnvironment.value,
        isContinue: isContinue.value,
      }).then(() => {
        store.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.SUCCESS });
        if (isContinue.value === true) {
          router.push({ name: 'RetailerEnvironment' });
        }
      }).finally(() => {
        store.dispatch(TOGGLE_LOADING_ACTION);
      });
    }

    function onSave() {
      isContinue.value = false;
      submit();
    }

    function onSaveAndContinue() {
      if (validationForm.value.areValidFields()) {
        isContinue.value = true;
        submit();
      }
    }

    function resetWebEnvironment() {
      webEnvironment.value.resetWebEnvironment();
      componentUniqueKey.value = Date.now();
    }

    function changeIpRangeType(element) {
      hasIpRangeTypeValue.value = true;
      displayIpRange.value = element !== IpTypeEnum.SINGLE;
    }

    function removeIpRange(productionIpServer) {
      store.dispatch(
        REMOVE_ON_BOARDING_PRODUCTION_IP_SERVER_ACTION,
        productionIpServer,
      );
    }

    return {
      isLoading,
      hasMessage,
      getModule,
      messages,
      getIntegrationMethod,
      webEnvironment,
      validationForm,
      getIntegrator,
      onSave,
      onSaveAndContinue,
      resetWebEnvironment,
      componentUniqueKey,
      getIpTypeChoices,
      changeIpRangeType,
      displayIpRange,
      hasIpRangeTypeValue,
      removeIpRange,
    };
  },
};
</script>
