<template>
  <div class="mb-3">
    <label v-if="label" for="inputGroupFile02" class="form-label text-primary ps-3">
      <small :class="{ 'required-star': required }">{{ label }}</small>
      <span class="ms-2">
        <IconQuestionCircle v-tooltip:top="$t('tooltip.upload', { mime: accepted})"
                            width="16" height="16"
        />
      </span>
      <p v-if="informationLabel" for="inputGroupFile02" class="form-label text-secondary ps-1">
        <small>{{ informationLabel }}</small>
      </p>
    </label>

    <div class="input-group">
      <input :id="id"
             type="file"
             class="input-file"
             :accept="accepted"
             @change="emitFileChange"
      >
      <label
        :for="id"
        class="form-control form-control-lg"
        :class="getErrors(errors).class"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >

        <IconCloudArrowUpFill v-if="!isLoading" height="36" width="36" />
        <Loading v-if="isLoading" :size="50" />

        <div>
          <div v-if="input && input.originalFileName">
            {{ input.originalFileName }}
          </div>
          <span class="btn mt-1">{{ $t('action.choose_file') }}</span>
          <div class="sub-text mt-1">{{ $t('label.drag_here') }}</div>
        </div>
      </label>

      <ErrorDisplay v-if="getErrors(errors).messages" :errors="getErrors(errors).messages" />
    </div>

    <div v-if="help" class="form-text bg-grey p-3 mt-0 d-flex">
      <p class="mb-0">
        <IconInfoCircle class="me-2" />
      </p>
      <p class="mb-0" v-html="help" />
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import IconCloudArrowUpFill from '@/app/ui/components/icon/IconCloudArrowUpFill.vue';
import ErrorDisplay from '@/app/ui/components/form/ErrorDisplay.vue';
import IconQuestionCircle from '@/app/ui/components/icon/IconQuestionCircle.vue';
import FileFormValidator from '@/app/validators/fileFormValidator';
import Loading from '@/app/ui/components/Loading.vue';
import IconInfoCircle from '@/app/ui/components/icon/IconInfoCircle.vue';

export default {
  name: 'InputUpload',
  components: {
    Loading,
    ErrorDisplay,
    IconCloudArrowUpFill,
    IconQuestionCircle,
    IconInfoCircle,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    informationLabel: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    isSheet: {
      type: Boolean,
      default: false,
    },
    accepted: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
      required: true,
    },
    errors: {
      type: Array,
      default: () => null,
    },
    help: {
      type: String,
      default: null,
    },
  },
  emits: ['update:fileChecker', 'update:modelValue', 'update:errors'],
  setup(props, { emit }) {
    const store = useStore();
    const input = ref(props.modelValue);
    const fileErrors = ref(props.errors);
    const fileValidator = computed(() => FileFormValidator());
    const isLoading = ref(false);

    const applyChangeModel = async (file, element) => {
      input.value = null;
      fileErrors.value = null;

      if (fileValidator.value.areValidFields(file, props.id, props.isSheet)) {
        isLoading.value = true;
        await store.dispatch(props.action, file).then((data) => {
          input.value = data;
          element.classList.add('upload-valid');
        }).finally(() => {
          isLoading.value = false;
        });
      }

      fileErrors.value = fileValidator.value.errors.getErrorsFromProperty(props.id);

      emit('update:modelValue', input.value);
      emit('update:errors', fileValidator.value);
    };

    const emitFileChange = (event) => {
      const file = event.target.files[0];

      if (file) {
        applyChangeModel(file, event.target.nextElementSibling);
      }
    };

    const dragover = (event) => {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('input-file-overed')) {
        event.currentTarget.classList.add('input-file-overed');
      }
    };

    const dragleave = (event) => {
      event.preventDefault();
      if (event.currentTarget.classList.contains('input-file-overed')) {
        event.currentTarget.classList.remove('input-file-overed');
      }
    };

    const drop = (event) => {
      event.preventDefault();
      const file = event.dataTransfer.files[0];

      if (file) {
        applyChangeModel(file, event.target.parentElement);
        event.target.parentElement.classList.remove('input-file-overed');
      }
    };

    const getErrors = (parentErrors) => {
      const hasFileErrors = (fileErrors.value && fileErrors.value.length > 0);

      if (parentErrors && !input.value && !hasFileErrors) {
        return {
          class: 'is-invalid',
          messages: parentErrors,
        };
      }

      if (hasFileErrors && !input.value) {
        return {
          class: 'is-invalid',
          messages: fileErrors.value,
        };
      }

      if (input.value && !fileErrors.value) {
        return {
          class: 'upload-valid',
          messages: null,
        };
      }

      return {
        class: '',
        messages: null,
      };
    };

    return {
      isLoading,
      getErrors,
      emitFileChange,
      drop,
      dragover,
      dragleave,
      input,
      fileErrors,
    };
  },
};
</script>
