export default class IpRange {
  constructor(
    from = null,
    to = null,
  ) {
    this.from = from;
    this.to = to;
  }

  static createEmpty() {
    return new IpRange();
  }

  static fromJson(json) {
    return new IpRange(
      json.from,
      json.to,
    );
  }

  toJson() {
    return {
      from: this.from,
      to: this.to,
    };
  }
}
