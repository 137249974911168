import { reactive } from 'vue';
import ErrorsCollection from '@/app/model/ErrorsCollection';

const errors = reactive(new ErrorsCollection());

export default function legalRepresentsActivityFormValidator(legalRepresentsActivity) {
  const validateRepresentsActivityField = () => {
    const property = 'representsActivity';
    errors.clearErrors(property);
    const nullLegalRepresents = legalRepresentsActivity.legalRepresents.filter(
      (legalRepresent) => legalRepresent.id === null,
    );

    if (nullLegalRepresents.length === legalRepresentsActivity.legalRepresents.length) {
      return errors.addError(property, 'validation.need_add_a_represent');
    }

    if (legalRepresentsActivity.legalRepresents.length > 4) {
      return errors.addError(property, 'validation.max_represents');
    }

    return errors;
  };

  const areValidFields = () => {
    validateRepresentsActivityField();

    return (errors.errors.length === 0);
  };

  return {
    errors,
    areValidFields,
  };
}
