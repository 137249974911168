import Product from '@/onBoarding/model/Product';

export default class ProductsActivity {
  constructor(
    uuid,
    iri,
    products = [],
  ) {
    this.uuid = uuid;
    this.iri = iri;
    this.products = products;
  }

  toJson() {
    return {
      uuid: this.uuid,
      iri: this.iri,
    };
  }

  static createEmpty() {
    return new ProductsActivity();
  }

  addEmptyProduct() {
    this.products.push(Product.createEmpty(this.iri));
  }

  static fromJson(json) {
    const productsActivityIri = json['@id'];

    return new ProductsActivity(
      json.uuid,
      productsActivityIri,
      json.products.length > 0
        ? json.products.map((product) => Product.fromJson(product, productsActivityIri))
        : [Product.createEmpty(productsActivityIri)],
    );
  }
}
