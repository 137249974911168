import { reactive } from 'vue';
import useValidators from '@/app/validators/validator';
import ErrorsCollection from '@/app/model/ErrorsCollection';
import Media from '@/app/model/Media';

const { isEmpty } = useValidators();
const errors = reactive(new ErrorsCollection());

export default function fraudFormValidator(fraud, onBoarding) {
  const validateNomenclatureField = () => {
    const property = 'nomenclature';
    errors.clearErrors(property);

    if (!(fraud.nomenclature instanceof Media)) {
      return errors.addError(property, 'validation.not_blank_file');
    }

    return errors;
  };

  const validateFraudManagementField = () => {
    const property = 'fraudManagement';
    errors.clearErrors(property);

    if (onBoarding.isWebActivity && isEmpty(fraud.fraudManagement)) {
      return errors.addError(property, 'validation.not_blank');
    }

    return errors;
  };

  const validateSolutionUsedField = () => {
    const property = 'solutionUsed';
    errors.clearErrors(property);

    if (onBoarding.isWebActivity && fraud.fraudManagement && isEmpty(fraud.solutionUsed)) {
      return errors.addError(property, 'validation.not_blank');
    }

    return errors;
  };

  const areValidFields = () => {
    validateNomenclatureField();
    validateSolutionUsedField();
    validateFraudManagementField();

    return (errors.errors.length === 0);
  };

  return {
    errors,
    validateSolutionUsedField,
    validateFraudManagementField,
    areValidFields,
  };
}
