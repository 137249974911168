<template>
  <div class="pt-4 mb-3">
    <div class="form-floating">
      <textarea :id="id" v-model="input" rows="3"
                class="form-control form-control-lg"
                :class="(errors)?'is-invalid':''"
                :placeholder="placeholder"
                style="height: 100px"
                @focusout="validateInput"
      />
      <label :for="id" :class="{ 'required-star': required }">
        {{ label }}
      </label>
    </div>

    <ErrorDisplay v-if="errors" :errors="errors" />
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import ErrorDisplay from './ErrorDisplay.vue';

export default {
  name: 'InputTextArea',
  components: {
    ErrorDisplay,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    validators: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const input = ref(props.modelValue);
    const validateInput = () => props.validators.forEach((validator) => validator());

    watch(input, (value) => emit('update:modelValue', value), { deep: true });

    return {
      input,
      validateInput,
    };
  },
};
</script>
