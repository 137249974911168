<template>
  <Layout :menu-index="1">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="row mb-3">
        <div class="col-12 col-md-9">
          <h2>{{ $t('title.information_society') }}</h2>
        </div>
      </div>

      <div v-if="hasMessage" class="row">
        <div class="col-12 col-md-9">
          <Alert v-for="(message, index) in messages" :key="index" :msg="message" :index="index" />
        </div>
      </div>

      <div v-if="onBoardingSellingPoint" class="row">
        <div class="col-12 col-md-9">
          <form autocomplete="off"
                novalidate
                @submit.prevent
          >
            <div class="pt-4 mb-3">
              <div class="form-floating">
                <input
                  id="brand_name"
                  class="form-control form-control-lg"
                  type="text"
                  :value="onBoardingSellingPoint.company.name"
                  disabled
                >
                <label for="brand_name">
                  {{ $t('label.brand_name') }}
                </label>
              </div>
            </div>

            <InputText
              id="company_name"
              v-model="onBoardingSellingPoint.sellingPoint.socialReason"
              :required="true"
              :label="$t('label.company_name')"
              :placeholder="$t('placeholder.company_name')"
              :validators="[validationForm.validateSocialReasonField]"
              :errors="validationForm.errors.getErrorsFromProperty('socialReason')"
            />

            <InputText
              id="business_name"
              v-model="onBoardingSellingPoint.sellingPoint.businessName"
              :required="true"
              :label="$t('label.business_name')"
              :placeholder="$t('placeholder.business_name')"
              :validators="[validationForm.validateBusinessNameField]"
              :errors="validationForm.errors.getErrorsFromProperty('businessName')"
            />

            <InputText
              id="identification_number"
              v-model="onBoardingSellingPoint.sellingPoint.identificationNumber"
              :required="true"
              :label="$t('label.identification_number')"
              :placeholder="$t('placeholder.identification_number')"
              :validators="[validationForm.validateIdentificationNumberNumberField]"
              :errors="validationForm.errors.getErrorsFromProperty('identificationNumber')"
            />

            <InputText
              id="code_activity"
              v-model="onBoardingSellingPoint.sellingPoint.nafCode"
              :required="true"
              :label="$t('label.code_activity')"
              :placeholder="$t('placeholder.code_activity')"
              :validators="[validationForm.validateNafCodeField]"
              :errors="validationForm.errors.getErrorsFromProperty('nafCode')"
            />

            <InputMoney
              id="social_capital"
              v-model="onBoardingSellingPoint.sellingPoint.socialCapital"
              :required="true"
              :precision="2"
              :label="$t('label.social_capital')"
              :placeholder="$t('placeholder.social_capital')"
              :validators="[validationForm.validateSocialCapitalField]"
              :errors="validationForm.errors.getErrorsFromProperty('socialCapital')"
            />

            <InputSelect
              id="legal_type"
              v-model="onBoardingSellingPoint.sellingPoint.legalType"
              :required="true"
              :label="$t('label.company_type')"
              :placeholder="$t('placeholder.company_type')"
              :options="getLegalTypeChoices"
              :validators="[validationForm.validateLegalTypeField]"
              :errors="validationForm.errors.getErrorsFromProperty('legalType')"
            />

            <InputText
              id="address_street"
              v-model="onBoardingSellingPoint.sellingPoint.address.street"
              :required="true"
              :label="$t('label.address_street')"
              :placeholder="$t('placeholder.address_street')"
              :validators="[validationForm.validateStreetField]"
              :errors="validationForm.errors.getErrorsFromProperty('street')"
            />

            <InputText
              id="address_additional"
              v-model="onBoardingSellingPoint.sellingPoint.address.additional"
              :required="false"
              :label="$t('label.address_additional')"
              :placeholder="$t('placeholder.address_additional')"
            />

            <InputText
              id="address_zip_code"
              v-model="onBoardingSellingPoint.sellingPoint.address.postalCode"
              :required="true"
              :label="$t('label.address_zip_code')"
              :placeholder="$t('placeholder.address_zip_code')"
              :validators="[validationForm.validatePostalCodeField]"
              :errors="validationForm.errors.getErrorsFromProperty('postalCode')"
            />

            <InputText
              id="address_city"
              v-model="onBoardingSellingPoint.sellingPoint.address.city"
              :required="true"
              :label="$t('label.address_city')"
              :placeholder="$t('placeholder.address_city')"
              :validators="[validationForm.validateCityField]"
              :errors="validationForm.errors.getErrorsFromProperty('city')"
            />

            <InputSelect
              id="country"
              v-model="onBoardingSellingPoint.sellingPoint.address.country"
              :required="true"
              :label="$t('label.country')"
              :placeholder="$t('placeholder.country')"
              :options="getCountriesChoices"
              :validators="[validationForm.validateCountryField]"
              :errors="validationForm.errors.getErrorsFromProperty('country')"
            />

            <InputMediaOnBoardingSellingPoint
              id="kBis_upload"
              v-model="onBoardingSellingPoint.kBis"
              :required="true"
              :label="$t('label.kBis_or_extract')"
              :accepted="accepted"
              :errors="validationForm.errors.getErrorsFromProperty('kBis')"
            />

            <InputMediaOnBoardingSellingPoint
              id="status_upload"
              v-model="onBoardingSellingPoint.legalStatus"
              :required="true"
              :label="$t('label.legal_status')"
              :accepted="accepted"
              :errors="validationForm.errors.getErrorsFromProperty('legalStatus')"
            />

            <LegalNotice />

            <div class="mt-3 float-end">
              <a href="#"
                 class="btn btn-lg btn-light text-dark rounded-3 me-2"
                 @click="onSave"
              >
                {{ $t('action.save') }}
              </a>

              <button
                type="submit"
                class="btn btn-lg btn-primary text-white rounded-3"
                @click="onSaveAndContinue"
              >
                {{ $t('action.continue') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Loading from '@/app/ui/components/Loading.vue';
import InputText from '@/app/ui/components/form/InputText.vue';
import InputSelect from '@/app/ui/components/form/InputSelect.vue';
import InputMoney from '@/app/ui/components/form/InputMoney.vue';
import Alert from '@/app/ui/components/Alert.vue';
import Layout from '@/onBoardingSellingPoint/ui/components/Layout.vue';
import LegalNotice from '@/app/ui/components/LegalNotice.vue';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import CountryEnum from '@/app/enum/CountryEnum';
import StateEnum from '@/onBoardingSellingPoint/enum/StateEnum';
import InputMediaOnBoardingSellingPoint from '@/onBoardingSellingPoint/ui/components/form/InputMediaOnBoardingSellingPoint.vue';
import { ADD_MESSAGE_ACTION, TOGGLE_LOADING_ACTION } from '@/app/store/actions';
import InformationSocietyFormValidator from '@/onBoardingSellingPoint/validators/sellingPointFormValidator';
import AcceptedFileEnum from '@/app/enum/AcceptedFileEnum';
import LegalTypeEnum from '@/app/enum/LegalTypeEnum';
import {
  GET_ON_BOARDING_SELLING_POINT_ACTION,
  PATCH_ON_BOARDING_SELLING_POINT_ACTION,
  GOTO_STEP_ON_BOARDING_SELLING_POINT_ACTION,
} from '@/onBoardingSellingPoint/store/actions';

export default {
  name: 'SellingPoint',
  components: {
    Loading,
    Alert,
    InputText,
    InputMoney,
    InputSelect,
    InputMediaOnBoardingSellingPoint,
    Layout,
    LegalNotice,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isContinue = ref(false);
    const onBoardingSellingPoint = computed(() => store.getters.getOnBoardingSellingPoint);
    const hasMessage = computed(() => store.getters.hasMessage);
    const messages = computed(() => store.getters.currentMessages);
    const isLoading = computed(() => store.getters.isLoading);
    const getCountriesChoices = computed(() => CountryEnum.getChoices());
    const getLegalTypeChoices = computed(() => LegalTypeEnum.getChoices());
    const validationForm = (
      computed(() => InformationSocietyFormValidator(onBoardingSellingPoint.value)));
    const accepted = AcceptedFileEnum.DOCUMENT_ACCEPTED;

    onMounted(async () => {
      await store.dispatch(TOGGLE_LOADING_ACTION);
      await store.dispatch(GET_ON_BOARDING_SELLING_POINT_ACTION);
      await store.dispatch(TOGGLE_LOADING_ACTION);
    });

    function submit() {
      store.dispatch(TOGGLE_LOADING_ACTION);
      store.dispatch(PATCH_ON_BOARDING_SELLING_POINT_ACTION, onBoardingSellingPoint.value)
        .then(async () => {
          await store.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.SUCCESS });
          if (isContinue.value === true) {
            await store.dispatch(GOTO_STEP_ON_BOARDING_SELLING_POINT_ACTION, StateEnum.LEGAL_REPRESENTS);
            await router.push({ name: 'Contacts' });
          }
        }).finally(() => {
          store.dispatch(TOGGLE_LOADING_ACTION);
        });
    }

    function onSave() {
      isContinue.value = false;
      submit();
    }

    function onSaveAndContinue() {
      if (validationForm.value.areValidFields()) {
        isContinue.value = true;
        submit();
      }
    }

    return {
      hasMessage,
      messages,
      validationForm,
      isLoading,
      onSave,
      accepted,
      onSaveAndContinue,
      onBoardingSellingPoint,
      getCountriesChoices,
      getLegalTypeChoices,
    };
  },
};
</script>
