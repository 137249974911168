<template>
  <Layout :menu-index="4">
    <Final />
    <div class="m-5 col-md-5 text-center mx-auto">
      <h6 class="final-main">
        {{
          $t('final.selling_point.finish')
        }}
      </h6>
      <p>
        {{
          $t('final.selling_point.message_information')
        }}
      </p>
      <div>
        <div>
          {{
            $t('final.selling_point.thanks')
          }}
        </div>
        <div class="link-success">
          {{
            $t('final.selling_point.floa')
          }}
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/onBoardingSellingPoint/ui/components/Layout.vue';
import Final from '@/onBoardingSellingPoint/ui/components/Final.vue';

export default {
  name: 'FinalSellingPoint',
  components: {
    Final,
    Layout,
  },
};
</script>
