<template>
  <div class="row mb-3">
    <div class="col-12">
      <div class="badge rounded-pill bg-primary col-7 p-3">
        <div class="row">
          <div class="col-11 text-center">
            {{ value }}
          </div>
          <div class="col-1 text-end pe-cursor">
            <IconX height="16" width="16" @click="remove" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconX from '@/app/ui/components/icon/IconX.vue';

export default {
  name: 'Chip',
  components: {
    IconX,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Object,
      default: () => {
      },
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    function remove() {
      emit('update:modelValue', props.modelValue);
    }

    return {
      remove,
    };
  },
};
</script>
