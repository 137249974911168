export default class Company {
  constructor(
    uuid,
    name,
  ) {
    this.uuid = uuid;
    this.name = name;
  }

  toJson() {
    return {
      uuid: this.uuid,
      name: this.name,
    };
  }

  static fromJson(json) {
    return new Company(
      'uuid' in json ? json.uuid : null,
      'name' in json ? json.name : null,
    );
  }
}
