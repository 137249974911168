import { reactive } from 'vue';
import ErrorsCollection from '@/app/model/ErrorsCollection';
import Media from '@/app/model/Media';

const errors = reactive(new ErrorsCollection());

export default function vouchersFormValidator(onBoardingInternational) {
  const validateKBisField = () => {
    const property = 'kBis';
    errors.clearErrors(property);

    if (!(onBoardingInternational.kBis instanceof Media)) {
      return errors.addError(property, 'validation.not_blank_file');
    }

    return errors;
  };

  const validateStatusField = () => {
    const property = 'legalStatus';
    errors.clearErrors(property);

    if (!(onBoardingInternational.legalStatus instanceof Media)) {
      return errors.addError(property, 'validation.not_blank_file');
    }

    return errors;
  };

  const areValidFields = () => {
    validateStatusField();
    validateKBisField();

    return errors.hasErrors() === false;
  };

  return {
    errors,
    validateStatusField,
    validateKBisField,
    areValidFields,
  };
}
