import Media from '@/app/model/Media';

export default class Voucher {
  constructor(
    uuid,
    kbis,
    status,
    logo,
    tax,
  ) {
    this.uuid = uuid;
    this.kbis = kbis;
    this.status = status;
    this.logo = logo;
    this.tax = tax;
  }

  toJson() {
    return {
      uuid: this.uuid,
      kbis: this.kbis instanceof Media ? this.kbis.toJson() : null,
      status: this.status instanceof Media ? this.status.toJson() : null,
      logo: this.logo instanceof Media ? this.logo.toJson() : null,
      fiscal: this.tax instanceof Media ? this.tax.toJson() : null,
    };
  }

  static fromJson(json) {
    return new Voucher(
      json.uuid,
      json.kbis !== null ? Media.fromJson(json.kbis) : null,
      json.status !== null ? Media.fromJson(json.status) : null,
      json.logo !== null ? Media.fromJson(json.logo) : null,
      json.fiscal !== null ? Media.fromJson(json.fiscal) : null,
    );
  }

  static createEmpty() {
    return new Voucher(
      '',
      new File([], ''),
      new File([], ''),
      new File([], ''),
      new File([], ''),
    );
  }
}
