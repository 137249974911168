import { i18n } from '@/app/service/i18n';

const CivilityEnum = {
  MR: 'mr',
  MRS: 'mrs',

  getReadableValue(value) {
    return this.getChoices()
      .filter((element) => element.value === value)
      .map((element) => element.label)
      .pop();
  },

  getChoices() {
    return [
      { value: this.MR, label: i18n.global.tc('label.civility_mr') },
      { value: this.MRS, label: i18n.global.tc('label.civility_mrs') },
    ];
  },
};

export default CivilityEnum;
