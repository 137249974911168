<template>
  <div class="pt-4 mb-3">
    <div class="input-group form-floating">
      <input :id="id"
             ref="inputRef"
             type="text"
             class="form-control form-control-lg"
             :class="(errors || isInvalid)?'is-invalid': ''"
             :placeholder="placeholder"
             :min="min"
             step="1"
             @focusout="validateInput"
      >
      <span class="input-group-text">
        <IconEuro height="16" width="16" />
      </span>
      <label :for="id" :class="{ 'required-star': required }">
        {{ label }}
      </label>
    </div>

    <ErrorDisplay v-if="errors" :errors="errors" />
  </div>
</template>

<script>
import { watch } from 'vue';
import { useCurrencyInput } from 'vue-currency-input';
import IconEuro from '@/app/ui/components/icon/IconEuro.vue';
import ErrorDisplay from './ErrorDisplay.vue';

export default {
  name: 'InputMoney',
  components: {
    ErrorDisplay,
    IconEuro,
  },
  props: {
    modelValue: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    validators: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => null,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    precision: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const validateInput = () => props.validators.forEach((validator) => validator());
    const options = {
      currency: 'EUR',
      currencyDisplay: 'hidden',
      precision: props.precision,
    };
    const { inputRef, setValue } = useCurrencyInput(options);

    watch(
      () => props.modelValue,
      (value) => {
        setValue(value);
      },
    );

    return {
      inputRef,
      validateInput,
    };
  },
};
</script>
