import { reactive } from 'vue';
import useValidators from '@/app/validators/validator';
import ErrorsCollection from '@/app/model/ErrorsCollection';

const errors = reactive(new ErrorsCollection());
const { isEmpty, isPositive, isInteger } = useValidators();

export default function countryTurnoverFormValidator(countryTurnover) {
  const validateCountryField = () => {
    const property = `country-${countryTurnover.uniquIndex}`;
    errors.clearErrors(property);

    if (isEmpty(countryTurnover.country)) {
      return errors.addError(property, 'validation.not_blank_select');
    }

    return errors;
  };

  const validateTurnoverField = () => {
    const value = countryTurnover.turnover;
    const property = `turnover-${countryTurnover.uniquIndex}`;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isPositive(value)) {
      return errors.addError(property, 'validation.must_positive');
    }

    if (!isInteger(value)) {
      return errors.addError(property, 'validation.must_integer');
    }

    return errors;
  };

  const areValidFields = () => {
    validateCountryField();
    validateTurnoverField();

    return (errors.errors.length === 0);
  };

  return {
    errors,
    validateCountryField,
    validateTurnoverField,
    areValidFields,
  };
}
