import mitt from 'mitt';

let emitter = null;

export default function getEventBus() {
  if (emitter === null) {
    emitter = mitt();
  }

  return emitter;
}
