import { reactive } from 'vue';
import useValidators from '@/app/validators/validator';
import ErrorsCollection from '@/app/model/ErrorsCollection';

const errors = reactive(new ErrorsCollection());
const { isEmpty, isPositive, isInteger } = useValidators();

export default function paymentFacilityFormValidator(paymentFacility) {
  const validateLabelField = () => {
    const property = 'label';
    errors.clearErrors(property);

    if (isEmpty(paymentFacility.label)) {
      return errors.addError(property, 'validation.not_blank');
    }

    return errors;
  };

  const validateUpperBoundary = () => {
    errors.clearErrors('upperBoundaryValue');
    return errors;
  };

  const validateUpperBoundaryValueField = () => {
    const property = 'upperBoundaryValue';
    const value = paymentFacility.upperBoundaryValue;
    errors.clearErrors(property);

    if (paymentFacility.upperBoundary) {
      if (isEmpty(value)) {
        return errors.addError(property, 'validation.not_blank');
      }

      if (!isPositive(value)) {
        return errors.addError(property, 'validation.must_positive');
      }

      if (!isInteger(value)) {
        return errors.addError(property, 'validation.must_integer');
      }
    }

    return errors;
  };

  const validateLowerBoundary = () => {
    errors.clearErrors('lowerBoundaryValue');
    return errors;
  };

  const validateLowerBoundaryValueField = () => {
    const property = 'lowerBoundaryValue';
    const value = paymentFacility.lowerBoundaryValue;
    errors.clearErrors(property);

    if (paymentFacility.lowerBoundary) {
      if (isEmpty(value)) {
        return errors.addError(property, 'validation.not_blank');
      }

      if (!isPositive(value)) {
        return errors.addError(property, 'validation.must_positive');
      }

      if (!isInteger(value)) {
        return errors.addError(property, 'validation.must_integer');
      }
    }

    return errors;
  };

  const areValidFields = () => {
    validateLabelField();
    validateUpperBoundaryValueField();
    validateLowerBoundaryValueField();

    return (errors.errors.length === 0);
  };

  return {
    errors,
    validateLabelField,
    validateUpperBoundary,
    validateUpperBoundaryValueField,
    validateLowerBoundary,
    validateLowerBoundaryValueField,
    areValidFields,
  };
}
