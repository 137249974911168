import { createRouter, createWebHistory } from 'vue-router';
import { i18n, changeLocale, localiseDocument } from '@/app/service/i18n';
import routes from '@/app/router/routes';
import OnBoardingRouter from '@/onBoarding/router';
import OnBoardingSellingPointRouter from '@/onBoardingSellingPoint/router';
import OnBoardingInternationalRouter from '@/onBoardingInternational/router';
import SecurityRouter from '@/security/router';
import Root from './Root.vue';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: i18n.global.locale,
    },
    {
      path: '/:locale',
      component: Root,
      children: routes,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const { locale } = to.params;

  if (locale !== from.params.locale) {
    changeLocale(locale)
      .then(() => {
        localiseDocument(locale, i18n.global.t('app.title'));
      });
  }

  await SecurityRouter.beforeEachIsAuthenticated(to, from, next);
  await OnBoardingRouter.beforeEachCheckRole(to, from, next);
  await OnBoardingRouter.beforeEach(to, from, next);
  await OnBoardingSellingPointRouter.beforeEachCheckRole(to, from, next);
  await OnBoardingSellingPointRouter.beforeEach(to, from, next);
  await OnBoardingInternationalRouter.beforeEachCheckRole(to, from, next);
  await OnBoardingInternationalRouter.beforeEach(to, from, next);

  return next();
});

export default router;
