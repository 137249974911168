import Message from '@/app/model/Message';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import http from '@/app/service/http';

export default class MessagesCollection {
  constructor(messages = []) {
    this.messages = messages;
  }

  hasSameMessage(status, messageKey) {
    const sameMessage = this.messages.filter(
      (msg) => msg.status === status && msg.key === messageKey,
    );

    return (sameMessage.length !== 0);
  }

  addMessage(type, content) {
    switch (type) {
      case MessageTypeEnum.SUCCESS:
        this.createSuccess(content);
        break;
      case MessageTypeEnum.DANGER:
        this.createDanger(content);
        break;
      case MessageTypeEnum.WARNING:
        this.createWarning(content);
        break;
      case MessageTypeEnum.RESPONSE:
        this.createFromResponse(content);
        break;
      default:
        this.createDefault(content);
    }
  }

  createSuccess(messageKey = null) {
    const status = 200;
    const key = (!messageKey) ? 'flash.success' : messageKey;

    if (!this.hasSameMessage(status, key)) {
      this.messages.push(
        new Message(status, key, MessageTypeEnum.SUCCESS),
      );
    }
  }

  createWarning(messageKey = null) {
    const status = 400;
    const key = (!messageKey) ? 'flash.warning' : messageKey;

    if (!this.hasSameMessage(status, key)) {
      this.messages.push(
        new Message(status, key, MessageTypeEnum.WARNING),
      );
    }
  }

  createDanger(messageKey = null) {
    const status = 400;
    const key = (!messageKey) ? 'flash.error' : messageKey;

    if (!this.hasSameMessage(status, key)) {
      this.messages.push(
        new Message(status, key, MessageTypeEnum.DANGER),
      );
    }
  }

  createDefault(messageKey = null) {
    const status = 503;
    const key = (!messageKey) ? 'flash.error' : messageKey;

    if (!this.hasSameMessage(status, key)) {
      this.messages.push(
        new Message(status, key, MessageTypeEnum.DANGER),
      );
    }
  }

  createFromResponse(response) {
    if (response) {
      const key = !response.data.message ? response.data['hydra:description'] : response.data.message;

      if (!this.hasSameMessage(response.status, key)
        && http.isResponse(response) === true && response) {
        return this.messages.push(
          new Message(
            response.status,
            key,
            response.statusText === 'OK' ? MessageTypeEnum.SUCCESS : MessageTypeEnum.DANGER,
          ),
        );
      }
    }

    return this.createDefault();
  }
}
