import onBoardingConnector from '@/onBoardingSellingPoint/connector/onBoarding';
import OnBoarding from '@/onBoardingSellingPoint/model/OnBoarding';
import LegalRepresents from '@/onBoardingSellingPoint/model/LegalRepresents';
import MenuInterface from '@/onBoardingSellingPoint/model/MenuInterface';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import { ADD_MESSAGE_ACTION } from '@/app/store/actions';

import {
  RESET_ON_BOARDING_SELLING_POINT_ACTION,
  GET_ON_BOARDING_SELLING_POINT_ACTION,
  ADD_ON_BOARDING_SELLING_POINT_ACTION,
  PATCH_ON_BOARDING_SELLING_POINT_ACTION,
  GOTO_STEP_ON_BOARDING_SELLING_POINT_ACTION,
  GET_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENTS_ACTION,
  ADD_ON_BOARDING_SELLING_POINT_EMPTY_LEGAL_REPRESENT_ACTION,
  CANCEL_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENT_ACTION,
  REMOVE_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENT_ACTION,
  SAVE_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENT_ACTION,
} from '@/onBoardingSellingPoint/store/actions';

import {
  RESET_ON_BOARDING_SELLING_POINT_MUTATION,
  SET_ON_BOARDING_SELLING_POINT_MUTATION,
  SET_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENTS_MUTATION,
  ADD_ON_BOARDING_SELLING_POINT_EMPTY_LEGAL_REPRESENT_MUTATION,
  REMOVE_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENT_MUTATION,
} from '@/onBoardingSellingPoint/store/mutations';

const state = {
  onBoardingSellingPoint: null,
  legalRepresents: null,
  menuInterface: new MenuInterface(),
};

const getters = {
  getOnBoardingSellingPoint(currentState) {
    return currentState.onBoardingSellingPoint;
  },
  getOnBoardingSellingPointLegalRepresents(currentState) {
    return currentState.legalRepresents;
  },
  getOnBoardingSellingPointMenuInterface(currentState) {
    return currentState.menuInterface;
  },
};

const actions = {
  [GOTO_STEP_ON_BOARDING_SELLING_POINT_ACTION](context, step) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.postStepBoardingSellingPoint(context.getters.getOnBoardingSellingPoint.uuid, step)
        .then(({ data }) => {
          const onBoarding = OnBoarding.fromJson(data);
          context.commit(SET_ON_BOARDING_SELLING_POINT_MUTATION, onBoarding);
          resolve(onBoarding);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [PATCH_ON_BOARDING_SELLING_POINT_ACTION](context, onBoardingSellingPoint) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.patchOnBoardingSellingPoint(onBoardingSellingPoint.toJson())
        .then(async ({ data }) => {
          const onBoarding = OnBoarding.fromJson(data);
          console.log(onBoarding, 'GOTOSTEP');
          context.commit(SET_ON_BOARDING_SELLING_POINT_MUTATION, onBoarding);
          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [GET_ON_BOARDING_SELLING_POINT_ACTION](context) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.getOnBoardingSellingPoint()
        .then(({ data }) => {
          let onBoarding = null;
          if (data['hydra:member'].length > 0) {
            onBoarding = OnBoarding.fromJson(data['hydra:member'][0]);
            context.commit(SET_ON_BOARDING_SELLING_POINT_MUTATION, onBoarding);
          }
          resolve(onBoarding);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [ADD_ON_BOARDING_SELLING_POINT_ACTION](context) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.addOnBoardingSellingPoint()
        .then(({ data }) => {
          const onBoardingSellingPoint = OnBoarding.fromJson(data);
          context.commit(SET_ON_BOARDING_SELLING_POINT_MUTATION, onBoardingSellingPoint);
          resolve(onBoardingSellingPoint);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [GET_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENTS_ACTION](context) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.getOnBoardingLegalRepresents(context.getters.getOnBoardingSellingPoint.uuid)
        .then(({ data }) => {
          const legalRepresents = LegalRepresents.fromJson(data['hydra:member']);
          context.commit(SET_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENTS_MUTATION, legalRepresents);
          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [REMOVE_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENT_ACTION](context, indexToRemove) {
    return new Promise((resolve, reject) => {
      const legalRepresents = context.getters.getOnBoardingSellingPointLegalRepresents;
      const legalRepresentToRemove = legalRepresents.representatives[indexToRemove];
      onBoardingConnector.deleteLegalRepresent(legalRepresentToRemove)
        .then(({ data }) => {
          context.commit(REMOVE_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENT_MUTATION, indexToRemove);
          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [SAVE_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENT_ACTION](context, legalRepresent) {
    return new Promise((resolve, reject) => {
      if (legalRepresent.isNew() === true) {
        onBoardingConnector.postLegalRepresent(legalRepresent.toJson())
          .then(async ({ data }) => {
            await context.dispatch(GET_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENTS_ACTION);
            resolve(data);
          })
          .catch(({ response }) => {
            context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
            reject(response);
          });
      } else {
        onBoardingConnector.patchLegalRepresent(legalRepresent.toJson())
          .then(async ({ data }) => {
            await context.dispatch(GET_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENTS_ACTION);
            resolve(data);
          })
          .catch(({ response }) => {
            context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
            reject(response);
          });
      }
    });
  },
  async [CANCEL_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENT_ACTION](context, indexToRemove) {
    const legalRepresents = context.getters.getOnBoardingSellingPointLegalRepresents;
    const legalRepresentToRemove = legalRepresents.representatives[indexToRemove];

    if (legalRepresentToRemove.uuid === null) {
      context.commit(REMOVE_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENT_MUTATION, indexToRemove);
    }

    await context.dispatch(GET_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENTS_ACTION);
  },
  [ADD_ON_BOARDING_SELLING_POINT_EMPTY_LEGAL_REPRESENT_ACTION](context) {
    context.commit(ADD_ON_BOARDING_SELLING_POINT_EMPTY_LEGAL_REPRESENT_MUTATION, context.getters.getOnBoardingSellingPoint.iri);
  },
  [RESET_ON_BOARDING_SELLING_POINT_ACTION](context) {
    context.commit(RESET_ON_BOARDING_SELLING_POINT_MUTATION);
  },
};

const mutations = {
  [SET_ON_BOARDING_SELLING_POINT_MUTATION](currentState, onBoarding) {
    currentState.onBoardingSellingPoint = onBoarding;
  },
  [RESET_ON_BOARDING_SELLING_POINT_MUTATION](currentState) {
    currentState.onBoardingSellingPoint = null;
  },
  [SET_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENTS_MUTATION](currentState, legalRepresents) {
    currentState.legalRepresents = legalRepresents;
  },
  [ADD_ON_BOARDING_SELLING_POINT_EMPTY_LEGAL_REPRESENT_MUTATION](currentState, onBoardingSellingPointIri) {
    currentState.legalRepresents.addEmptyLegalRepresent(onBoardingSellingPointIri);
  },
  [REMOVE_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENT_MUTATION](currentState, indexToRemove) {
    currentState.legalRepresents.representatives = currentState
      .legalRepresents.representatives.filter(
        (_, i) => i !== indexToRemove,
      );
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
