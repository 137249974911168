import { i18n } from '@/app/service/i18n';

const IpTypeEnum = {
  SINGLE: 'SINGLE_IP_ADDRESS',
  MULTI: 'RANGE_IP_ADDRESS',

  getReadableValue(value) {
    return this.getChoices()
      .filter((element) => element.value === value)
      .map((element) => element.label)
      .pop();
  },

  getChoices() {
    return [
      { value: this.SINGLE, label: i18n.global.tc('ip_type.single') },
      { value: this.MULTI, label: i18n.global.tc('ip_type.multi') },
    ];
  },
};

export default IpTypeEnum;
