import { i18n } from '@/app/service/i18n';

const CountryEnum = {
  BELGIUM: 'BE',
  FRANCE: 'FR',
  SPAIN: 'ES',
  PORTUGAL: 'PT',
  ITALY: 'IT',
  GERMANY: 'DE',
  NETHERLANDS: 'NL',

  getReadableValue(value) {
    const val = this.getChoices().find((element) => element.value === value);

    return val !== undefined ? val.label : value;
  },

  getChoices() {
    return [
      { value: this.FRANCE, label: i18n.global.tc('country.FR') },
      { value: this.BELGIUM, label: i18n.global.tc('country.BE') },
      { value: this.SPAIN, label: i18n.global.tc('country.ES') },
      { value: this.PORTUGAL, label: i18n.global.tc('country.PT') },
      { value: this.ITALY, label: i18n.global.tc('country.IT') },
      { value: this.GERMANY, label: i18n.global.tc('country.DE') },
      { value: this.NETHERLANDS, label: i18n.global.tc('country.NL') },
    ];
  },

  getPhonePrefixes() {
    return [
      { value: this.FRANCE, label: '+33' },
      { value: this.BELGIUM, label: '+32' },
      { value: this.SPAIN, label: '+34' },
      { value: this.ITALY, label: '+39' },
      { value: this.PORTUGAL, label: '+351' },
      { value: this.GERMANY, label: '+49' },
      { value: this.NETHERLANDS, label: '+31' },
    ];
  },

  fromPrefix(prefix) {
    const code = this.getPhonePrefixes()
      .filter((phonePrefix) => phonePrefix.label === prefix)
      .map((phonePrefix) => phonePrefix.value).pop();

    if (code === undefined) {
      return this.FRANCE;
    }

    return code;
  },
};

export default CountryEnum;
