import { i18n } from '@/app/service/i18n';

export default class Message {
  constructor(
    status,
    key,
    type,
  ) {
    this.status = status;
    this.message = i18n.global.tc(key);
    this.type = type;
    this.key = key;
  }
}
