export default class OnBoarding {
  constructor(
    uuid,
    step,
    informationActivityUuid,
    productsActivityUuid,
    informationSocietyUuid,
    voucherSocietyUuid,
    legalRepresentsActivityUuid,
    bankingInformationUuid,
    commercialActivityUuid,
    webEnvironmentUuid,
    retailerEnvironmentUuid,
    fraudSocietyUuid,
    isWebActivity,
    isRetailerActivity,
  ) {
    this.uuid = uuid;
    this.step = step;
    this.informationActivityUuid = informationActivityUuid;
    this.productsActivityUuid = productsActivityUuid;
    this.informationSocietyUuid = informationSocietyUuid;
    this.voucherSocietyUuid = voucherSocietyUuid;
    this.legalRepresentsActivityUuid = legalRepresentsActivityUuid;
    this.bankingInformationUuid = bankingInformationUuid;
    this.commercialActivityUuid = commercialActivityUuid;
    this.webEnvironmentUuid = webEnvironmentUuid;
    this.retailerEnvironmentUuid = retailerEnvironmentUuid;
    this.fraudSocietyUuid = fraudSocietyUuid;
    this.isWebActivity = isWebActivity;
    this.isRetailerActivity = isRetailerActivity;
  }

  static fromJson(json) {
    return new OnBoarding(
      'uuid' in json ? json.uuid : null,
      'step' in json ? json.step : null,
      'informationActivity' in json.activity && !json.activity.informationActivity
        ? null : json.activity.informationActivity.uuid,
      'productsActivity' in json.activity && !json.activity.productsActivity
        ? null : json.activity.productsActivity.uuid,
      'informationSociety' in json.activity && !json.activity.informationSociety
        ? null : json.activity.informationSociety.uuid,
      'vouchersSociety' in json.activity && !json.activity.vouchersSociety
        ? null : json.activity.vouchersSociety.uuid,
      'legalRepresentsActivity' in json.activity && !json.activity.legalRepresentsActivity
        ? null : json.activity.legalRepresentsActivity.uuid,
      'bankingInformation' in json.activity && !json.activity.bankingInformation
        ? null : json.activity.bankingInformation.uuid,
      'commercialActivity' in json.society && !json.society.commercialActivity
        ? null : json.society.commercialActivity.uuid,
      'webEnvironment' in json.society && !json.society.webEnvironment
        ? null : json.society.webEnvironment.uuid,
      'retailerEnvironment' in json.society && !json.society.retailerEnvironment
        ? null : json.society.retailerEnvironment.uuid,
      'fraudSociety' in json.society && !json.society.fraudSociety
        ? null : json.society.fraudSociety.uuid,
      'webActivity' in json ? json.webActivity : null,
      'retailerActivity' in json ? json.retailerActivity : null,
    );
  }
}
