import Banking from '@/onBoardingInternational/model/Banking';
import Company from '@/onBoardingInternational/model/Company';
import Media from '@/app/model/Media';

export default class OnBoarding {
  constructor(
    uuid,
    state,
    company,
    banking,
    rib,
    kBis,
    legalStatus,
    logo,
    iri,
  ) {
    this.uuid = uuid;
    this.state = state;
    this.company = company;
    this.banking = banking;
    this.rib = rib;
    this.kBis = kBis;
    this.legalStatus = legalStatus;
    this.logo = logo;
    this.iri = iri;
  }

  toJson() {
    return {
      uuid: this.uuid,
      state: this.state,
      company: this.company instanceof Company ? this.company.toJson() : null,
      banking: this.banking instanceof Banking ? this.banking.toJson() : null,
      rib: this.rib instanceof Media ? this.rib.toJson() : null,
      kbis: this.kBis instanceof Media ? this.kBis.toJson() : null,
      legalStatus: this.legalStatus instanceof Media ? this.legalStatus.toJson() : null,
      logo: this.logo instanceof Media ? this.logo.toJson() : null,
    };
  }

  static fromJson(json) {
    return new OnBoarding(
      'uuid' in json ? json.uuid : null,
      'state' in json ? json.state : null,
      'company' in json ? Company.fromJson(json.company) : null,
      'banking' in json ? Banking.fromJson(json.banking) : null,
      'rib' in json && json.rib !== null ? Media.fromJson(json.rib) : null,
      'kbis' in json && json.kbis !== null ? Media.fromJson(json.kbis) : null,
      'legalStatus' in json && json.legalStatus !== null ? Media.fromJson(json.legalStatus) : null,
      'logo' in json && json.logo !== null ? Media.fromJson(json.logo) : null,
      '@id' in json ? json['@id'] : null,
    );
  }
}
