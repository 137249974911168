import store from '@/app/store';
import { i18n } from '@/app/service/i18n';
import Company from '@/onBoardingInternational/ui/Company.vue';
import Vouchers from '@/onBoardingInternational/ui/Vouchers.vue';
import Banking from '@/onBoardingInternational/ui/Banking.vue';
import FinalInternational from '@/onBoardingInternational/ui/FinalInternational.vue';
import LegalRepresents from '@/onBoardingInternational/ui/LegalRepresents.vue';
import StateEnum from '@/onBoardingInternational/enum/StateEnum';
import {
  ADD_ON_BOARDING_INTERNATIONAL_ACTION,
  GET_ON_BOARDING_INTERNATIONAL_ACTION,
} from '@/onBoardingInternational/store/actions';

const routes = [
  {
    path: '/:locale/international/company',
    name: 'CompanyInternational',
    component: Company,
    meta: {
      requiresAuth: true,
      stepName: StateEnum.COMPANY,
    },
  },
  {
    path: '/:locale/international/vouchers',
    name: 'VouchersInternational',
    component: Vouchers,
    meta: {
      requiresAuth: true,
      stepName: StateEnum.VOUCHERS,
    },
  },
  {
    path: '/:locale/international/legal-represents',
    name: 'LegalRepresentsInternational',
    component: LegalRepresents,
    meta: {
      requiresAuth: true,
      stepName: StateEnum.LEGAL_REPRESENTS,
    },
  },
  {
    path: '/:locale/international/banking',
    name: 'BankingInternational',
    component: Banking,
    meta: {
      requiresAuth: true,
      stepName: StateEnum.BANKING,
    },
  },
  {
    path: '/:locale/international/final',
    name: 'FinalInternational',
    component: FinalInternational,
    meta: {
      requiresAuth: true,
      stepName: StateEnum.FINAL,
    },
  },
];

const beforeEachCheckRole = async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const onBoardingInternationalRoutes = routes.find(
      (route) => to.fullPath === route.path.replace(':locale', to.params.locale),
    );

    if (store.getters.isInternationalProspect && !onBoardingInternationalRoutes) {
      next({
        name: 'CompanyInternational',
        params: {
          locale: i18n.global.locale,
        },
      });
    }
  }
};

const beforeEach = async (to, from, next) => {
  const currentRoute = routes.find((route) => to.fullPath === route.path.replace(':locale', to.params.locale));

  if (store.getters.isInternationalProspect) {
    if (currentRoute) {
      let onBoarding = store.getters.getOnBoardingInternational;

      if (!onBoarding) {
        await store.dispatch(GET_ON_BOARDING_INTERNATIONAL_ACTION)
          .then(async (data) => {
            onBoarding = data;
            if (!data) {
              onBoarding = await store.dispatch(ADD_ON_BOARDING_INTERNATIONAL_ACTION);
            }
          }).catch(() => false);
      }

      if (to.meta.stepName && onBoarding.state !== to.meta.stepName) {
        const newPath = routes.find((route) => route.meta.stepName === onBoarding.state);
        next({
          name: newPath.name,
          params: {
            locale: i18n.global.locale,
          },
        });
      }
    }
  }
};

export default {
  routes,
  beforeEach,
  beforeEachCheckRole,
};
