import Media from '@/app/model/Media';
import Phone from '@/app/model/Phone';
import CivilityEnum from '@/app/enum/CivilityEnum';
import IdentificationTypeEnum from '@/app/enum/IdentificationTypeEnum';

export default class LegalRepresent {
  constructor(
    id = null,
    civility = null,
    firstname = null,
    lastname = null,
    phone = Phone.createEmpty(),
    typeId = null,
    idCardFront = null,
    idCardBack = null,
    legalRepresentsActivity = null,
  ) {
    this.id = id;
    this.civility = civility;
    this.firstname = firstname;
    this.lastname = lastname;
    this.phone = phone;
    this.typeId = typeId;
    this.idCardFront = idCardFront;
    this.idCardBack = idCardBack;
    this.legalRepresentsActivity = legalRepresentsActivity;
  }

  isNew() {
    return this.id === null;
  }

  isEmpty() {
    return this.id === null
      && this.civility === null
      && this.firstname === null
      && this.lastname === null;
  }

  getFriendlyCivility() {
    return `${CivilityEnum.getReadableValue(this.civility)}`;
  }

  getFriendlyPhone() {
    return (this.phone.number) ? `${this.phone.prefix}${this.phone.number}` : '';
  }

  getFriendlyIdType() {
    return IdentificationTypeEnum.getReadableValue(this.typeId);
  }

  isPassportIdType() {
    return this.typeId === IdentificationTypeEnum.PASSPORT;
  }

  toJson() {
    return {
      id: this.id,
      civility: this.civility,
      firstname: this.firstname,
      lastname: this.lastname,
      phone: this.phone,
      typeId: this.typeId,
      idCardFront: this.idCardFront !== null ? this.idCardFront.toJson() : null,
      idCardBack: this.idCardBack !== null && this.isPassportIdType() === false
        ? this.idCardBack.toJson() : null,
      legalRepresentsActivity: this.legalRepresentsActivity,
    };
  }

  static createEmpty(iri = null) {
    const legalRepresent = new LegalRepresent();
    legalRepresent.legalRepresentsActivity = iri;
    return legalRepresent;
  }

  static fromJson(json, iri = null) {
    return new LegalRepresent(
      json.uuid,
      json.civility,
      json.firstname,
      json.lastname,
      json.phone,
      json.typeId,
      json.idCardFront !== null ? Media.fromJson(json.idCardFront) : null,
      json.idCardBack !== null ? Media.fromJson(json.idCardBack) : null,
      iri,
    );
  }
}
