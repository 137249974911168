import { reactive } from 'vue';
import useValidators from '@/app/validators/validator';
import ErrorsCollection from '@/app/model/ErrorsCollection';
import Media from '@/app/model/Media';

const errors = reactive(new ErrorsCollection());
const {
  isEmpty,
  isValidString,
  isValidBic,
  isValidIban,
  isAcceptedIban,
} = useValidators();

export default function bankingFormValidator(onBoardingInternational) {
  const validateAccountOwnerField = () => {
    const property = 'accountOwner';
    const value = onBoardingInternational.banking.accountOwner;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isValidString(value)) {
      return errors.addError(property, 'validation.must_not_contain_special_characters');
    }

    return errors;
  };

  const validateIbanField = () => {
    const property = 'iban';
    const value = onBoardingInternational.banking.iban;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isValidIban(value) && isAcceptedIban(value)) {
      return errors.addError(property, 'validation.invalid_iban');
    }

    if (!isAcceptedIban(value)) {
      return errors.addError(property, 'validation.unavailable_international_iban_country');
    }

    return errors;
  };

  const validateBicField = () => {
    const property = 'bic';
    const value = onBoardingInternational.banking.bic;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isValidBic(value)) {
      return errors.addError(property, 'validation.invalid_bic');
    }

    return errors;
  };

  const validateRibField = () => {
    const property = 'rib';
    errors.clearErrors(property);

    if (!(onBoardingInternational.rib instanceof Media)) {
      return errors.addError(property, 'validation.not_blank_file');
    }

    return errors;
  };

  const areValidFields = () => {
    validateAccountOwnerField();
    validateIbanField();
    validateBicField();
    validateRibField();

    return errors.hasErrors() === false;
  };

  return {
    errors,
    validateAccountOwnerField,
    validateIbanField,
    validateBicField,
    areValidFields,
  };
}
