import { reactive } from 'vue';
import useValidators from '@/app/validators/validator';
import ErrorsCollection from '@/app/model/ErrorsCollection';

const errors = reactive(new ErrorsCollection());
const {
  isEmpty, isIpV4, isIpV4V6, isPrivateIp,
} = useValidators();

export default function productionIpServerFormValidator(productionIpServer, hasIpRange) {
  const validateIpRangeTo = () => {
    const property = 'ip_range_to';
    const value = productionIpServer.ipRange.to;
    errors.clearErrors(property);

    if (hasIpRange) {
      if (isEmpty(value)) {
        return errors.addError(property, 'validation.not_blank');
      }

      if (!isIpV4(value)) {
        return errors.addError(property, 'validation.is_ip_v4');
      }
    }

    if (isPrivateIp(value)) {
      return errors.addError(property, 'validation.is_private_ip');
    }

    return errors;
  };

  const validateIpRangeFrom = () => {
    const property = 'ip_range_from';
    const value = productionIpServer.ipRange.from;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (hasIpRange) {
      if (!isIpV4(value)) {
        return errors.addError(property, 'validation.is_ip_v4');
      }
    }

    if (!hasIpRange) {
      if (!isIpV4V6(value)) {
        return errors.addError(property, 'validation.is_ip_v4_v6');
      }
    }

    if (isPrivateIp(value)) {
      return errors.addError(property, 'validation.is_private_ip');
    }

    return errors;
  };

  const areValidFields = () => {
    validateIpRangeFrom();
    validateIpRangeTo();

    return (errors.errors.length === 0);
  };

  const clearErrors = (properties) => {
    properties.forEach((property) => {
      errors.clearErrors(property);
    });
  };

  return {
    errors,
    validateIpRangeFrom,
    validateIpRangeTo,
    areValidFields,
    clearErrors,
  };
}
