<template>
  <header class="navbar sticky-top bg-white flex-md-nowrap lh-lg m-0 p-0">
    <LocalizedLink to="/login"
                   class="navbar-brand px-3 me-0 col-md-3 d-none d-md-block bg-light"
                   @click="logout"
    >
      <IconChevronLeft class="text-dark" />
      <small class="link-secondary step">{{ $t('menu.leave') }}</small>
    </LocalizedLink>
    <div class="col-12 col-md-9 border-bottom d-flex">
      <button class="navbar-toggler d-md-none collapsed flex-grow-0" type="button"
              data-bs-toggle="collapse"
              data-bs-target="#sidebarMenu"
              aria-controls="sidebarMenu"
              aria-expanded="false"
              aria-label="Toggle navigation"
      >
        <IconList width="16" height="16" />
      </button>
      <LocalizedLink to="/" class="w-100 ms-md-5 mb-2 flex-grow-1 text-center text-md-start">
        <LogoFloaPay />
      </LocalizedLink>
      <div class="d-flex col-12 col-md-1">
        <LocaleSwitcher />
      </div>
      <ul class="navbar-nav px-3 mb-2 d-none d-md-block flex-grow-0">
        <li class="nav-item text-nowrap">
          <IconChatLeft class="text-dark" />
          {{ $t('any_difficulties.heading') }}
          <a href="mailto:contact@support.cb4x.fr" class="link-primary text-decoration-none">
            {{ $t('any_difficulties.link') }}
          </a>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { useStore } from 'vuex';
import LocalizedLink from '@/app/ui/components/LocalizedLink.vue';
import LocaleSwitcher from '@/app/ui/components/LocaleSwitcher.vue';
import LogoFloaPay from '@/app/ui/components/LogoFloaPay.vue';
import IconChatLeft from '@/app/ui/components/icon/IconChatLeft.vue';
import IconChevronLeft from '@/app/ui/components/icon/IconChevronLeft.vue';
import IconList from '@/app/ui/components/icon/IconList.vue';
import { LOGOUT_ACTION } from '@/security/store/actions';
import { RESET_ON_BOARDING_ACTION } from '@/onBoarding/store/actions';

export default {
  name: 'Header',
  components: {
    LocalizedLink,
    LocaleSwitcher,
    LogoFloaPay,
    IconChatLeft,
    IconChevronLeft,
    IconList,
  },
  setup() {
    const store = useStore();

    function logout() {
      store.dispatch(RESET_ON_BOARDING_ACTION);
      store.dispatch(LOGOUT_ACTION);
    }

    return {
      logout,
    };
  },
};
</script>
