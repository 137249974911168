<template>
  <div class="row" :class="parentClass">
    <div class="col-12">
      <p class="fw-bold mb-2">
        <label :class="{ 'required-star': required }">
          {{ label }}
        </label>
      </p>
      <ErrorDisplay v-if="errors" :errors="errors" />
      <Contact
        v-for="(contact, index) in contacts"
        :key="index" :contact="contact" :index="index"
      />

      <div v-if="contacts.length < 2 " class="mt-3">
        <button type="button"
                class="btn btn-outline-secondary text-primary w-100 border-secondary"
                @click="addNewContact(type)"
        >
          <IconPlus height="24" width="24" />
          {{ $t('action.add_person') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import ErrorDisplay from '@/app/ui/components/form/ErrorDisplay.vue';
import { ADD_ON_BOARDING_EMPTY_CONTACT_ACTION } from '@/onBoarding/store/society/actions';
import Contact from '@/onBoarding/ui/components/Contact.vue';
import IconPlus from '@/app/ui/components/icon/IconPlus.vue';

export default {
  name: 'ContactsGroup',
  components: {
    IconPlus,
    Contact,
    ErrorDisplay,
  },
  props: {
    contacts: {
      type: Array,
      default: () => null,
    },
    type: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    parentClass: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();

    function addNewContact(type) {
      store.dispatch(ADD_ON_BOARDING_EMPTY_CONTACT_ACTION, type);
    }

    return {
      addNewContact,
    };
  },
};
</script>
