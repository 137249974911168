import { reactive } from 'vue';
import useValidators from '@/app/validators/validator';
import ErrorsCollection from '@/app/model/ErrorsCollection';
import Media from '@/app/model/Media';

const errors = reactive(new ErrorsCollection());
const {
  isEmpty,
  isValidString,
  isValidBic,
  isValidIban,
  isAcceptedIban,
} = useValidators();

export default function bankFormValidator(bank) {
  const validateHolderField = () => {
    const property = 'holder';
    const value = bank.holder;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isValidString(value)) {
      return errors.addError(property, 'validation.must_not_contain_special_characters');
    }

    return errors;
  };

  const validateIbanField = () => {
    const property = 'iban';
    const value = bank.iban;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isValidIban(value) && isAcceptedIban(value)) {
      return errors.addError(property, 'validation.invalid_iban');
    }

    if (!isAcceptedIban(value)) {
      return errors.addError(property, 'validation.unavailable_iban_country');
    }

    return errors;
  };

  const validateBicField = () => {
    const property = 'bic';
    const value = bank.bic;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isValidBic(value)) {
      return errors.addError(property, 'validation.invalid_bic');
    }

    return errors;
  };

  const validateRibField = () => {
    const property = 'rib';
    errors.clearErrors(property);

    if (!(bank.rib instanceof Media)) {
      return errors.addError(property, 'validation.not_blank_file');
    }

    return errors;
  };

  const validateStoreNetworkField = () => {
    const property = 'storeNetwork';
    errors.clearErrors(property);

    if (isEmpty(bank.storeNetwork)) {
      return errors.addError(property, 'validation.not_blank');
    }

    return errors;
  };

  const areValidFields = () => {
    validateHolderField();
    validateIbanField();
    validateBicField();
    validateRibField();
    validateStoreNetworkField();

    return (errors.errors.length === 0);
  };

  return {
    errors,
    validateHolderField,
    validateIbanField,
    validateBicField,
    areValidFields,
    validateStoreNetworkField,
  };
}
