<template>
  <div class="mt-3 text-secondary">
    {{ $t('information.legal_notice') }}
    <a href="#" @click.prevent="onGetML">
      {{ $t('action.click_here') }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'LegalNotice',
  setup() {
    function onGetML() {
      window.open('/doc/mentions_legales.pdf', '_blank');
    }

    return {
      onGetML,
    };
  },
};
</script>
