<template>
  <Layout :menu-index="4">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="row mb-3">
        <div class="col-12 col-md-9">
          <h2 v-if="onBoarding.isWebActivity">
            {{ $t('title.product_fraud_society') }}
          </h2>
          <h2 v-else>
            {{ $t('title.product_society') }}
          </h2>
        </div>
      </div>

      <div v-if="hasMessage" class="row">
        <div class="col-12 col-md-9">
          <Alert v-for="(message, index) in messages" :key="index" :msg="message" :index="index" />
        </div>
      </div>

      <div v-if="fraud" class="row">
        <div class="col-12 col-md-9">
          <form autocomplete="off"
                novalidate
                @submit.prevent
          >
            <InputMediaOnBoarding
              id="fraud_nomenclature"
              v-model="fraud.nomenclature"
              :label="$t('label.nomenclature')"
              :accepted="accepted"
              :required="true"
              :is-sheet="true"
              :errors="validationForm.errors.getErrorsFromProperty('nomenclature')"
              :help="$t('help.nomenclature')"
            />

            <div v-if="onBoarding.isWebActivity">
              <Separator />

              <InputGroupRadio
                id="fraud_fraudManagement"
                v-model="fraud.fraudManagement"
                :choices="getChoices"
                :required="true"
                :label="$t('label.fraud_management')"
                :question="$t('question.fraud_management')"
                :validators="[validationForm.validateFraudManagementField]"
                :errors="validationForm.errors.getErrorsFromProperty('fraudManagement')"
              />

              <InputText
                v-if="fraud.fraudManagement"
                id="fraud_solutionUsed"
                v-model="fraud.solutionUsed"
                :required="true"
                :label="$t('label.solution_use')"
                :placeholder="$t('label.solution_use')"
                :validators="[validationForm.validateSolutionUsedField]"
                :errors="validationForm.errors.getErrorsFromProperty('solutionUsed')"
              />
            </div>

            <div class="mt-3 float-end">
              <button
                type="submit"
                class="btn btn-lg btn-light text-dark rounded-3 me-2"
                @click="onSave"
              >
                {{ $t('action.save') }}
              </button>

              <button type="submit"
                      class="btn btn-lg btn-primary text-white rounded-3"
                      @click="onSaveAndContinue"
              >
                {{ $t('action.continue') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import Layout from '@/onBoarding/ui/components/Layout.vue';
import Separator from '@/onBoarding/ui/components/Separator.vue';
import Alert from '@/app/ui/components/Alert.vue';
import Loading from '@/app/ui/components/Loading.vue';
import InputText from '@/app/ui/components/form/InputText.vue';
import InputMediaOnBoarding from '@/onBoarding/ui/components/form/InputMediaOnBoarding.vue';
import InputGroupRadio from '@/app/ui/components/form/InputGroupRadio.vue';
import FraudFormValidator from '@/onBoarding/validators/society/fraudFormValidator';
import YesNoEnum from '@/app/enum/YesNoEnum';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import { ADD_MESSAGE_ACTION, TOGGLE_LOADING_ACTION } from '@/app/store/actions';
import { GET_ON_BOARDING_FRAUD_ACTION, SAVE_ON_BOARDING_FRAUD_ACTION } from '@/onBoarding/store/society/actions';
import AcceptedFileEnum from '@/app/enum/AcceptedFileEnum';

export default {
  name: 'Fraud',
  components: {
    InputText,
    Alert,
    Layout,
    Separator,
    Loading,
    InputMediaOnBoarding,
    InputGroupRadio,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const getChoices = computed(() => YesNoEnum.getChoices());
    const onBoarding = ref(store.getters.getOnBoarding);
    const isLoading = computed(() => store.getters.isLoading);
    const messages = computed(() => store.getters.currentMessages);
    const hasMessage = computed(() => store.getters.hasMessage);
    const fraud = computed(() => store.getters.getOnBoardingFraudSociety);
    const validationForm = computed(() => FraudFormValidator(fraud.value, onBoarding.value));
    const accepted = AcceptedFileEnum.SPREADSHEET_ACCEPTED;
    const isContinue = ref(false);

    onMounted(async () => {
      await store.dispatch(TOGGLE_LOADING_ACTION);
      await store.dispatch(GET_ON_BOARDING_FRAUD_ACTION,
        onBoarding.value.fraudSocietyUuid);
      await store.dispatch(TOGGLE_LOADING_ACTION);
    });

    function submit() {
      store.dispatch(TOGGLE_LOADING_ACTION);
      store.dispatch(SAVE_ON_BOARDING_FRAUD_ACTION, {
        fraudSociety: fraud.value,
        isContinue: isContinue.value,
      }).then(() => {
        store.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.SUCCESS });
        if (isContinue.value === true) {
          router.push({ name: 'FinalSociety' });
        }
      }).finally(() => {
        store.dispatch(TOGGLE_LOADING_ACTION);
      });
    }

    function onSave() {
      isContinue.value = false;
      submit();
    }

    function onSaveAndContinue() {
      if (validationForm.value.areValidFields()) {
        isContinue.value = true;
        submit();
      }
    }

    return {
      onBoarding,
      getChoices,
      isLoading,
      messages,
      hasMessage,
      onSave,
      onSaveAndContinue,
      fraud,
      validationForm,
      accepted,
    };
  },
};
</script>
