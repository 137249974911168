<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
       class="bi bi-chevron-left" viewBox="0 0 16 16"
  >
    <!-- eslint-disable max-len vue/max-len  -->
    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
  </svg>
</template>

<script>
export default {
  name: 'IconChevronLeft',
};
</script>
