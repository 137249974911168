import Errors from './Errors';

export default class ErrorsCollection {
  constructor(errors = []) {
    this.errors = errors;
  }

  addError(property, code) {
    const sameCodeError = this.errors.filter(
      (er) => er.fieldId === property && er.code === code,
    );

    if (sameCodeError.length === 0) {
      this.errors.push(new Errors(
        property,
        code,
      ));
    }
  }

  hasErrors() {
    return this.errors.length > 0;
  }

  clearErrors(property) {
    this.errors.forEach((er, index) => {
      if (er.fieldId === property) {
        this.errors.splice(index, index + 1);
      }
    });
  }

  getErrorsFromProperty(property) {
    const errors = this.errors.filter((er) => er.fieldId === property);
    return (errors.length === 0) ? null : errors;
  }
}
