import { reactive } from 'vue';
import useValidators from '@/app/validators/validator';
import ErrorsCollection from '@/app/model/ErrorsCollection';

const {
  isEmpty, isValidMimeFile,
  isValidSizeFile, isValidExcelFile,
} = useValidators();

const errors = reactive(new ErrorsCollection());

export default function fileFormValidator() {
  const validateFileField = (file, property, isSheet = false) => {
    errors.clearErrors(property);

    if (!isEmpty(file) && !isValidSizeFile(file)) {
      return errors.addError(property, 'validation.invalid_size_file');
    }

    if (!isEmpty(file) && !isSheet && !isValidMimeFile(file)) {
      return errors.addError(property, 'validation.invalid_mime_file');
    }

    if (!isEmpty(file) && isSheet && !isValidExcelFile(file)) {
      return errors.addError(property, 'validation.invalid_excel_file');
    }

    return errors;
  };

  const areValidFields = (file, property, isSheet) => {
    validateFileField(file, property, isSheet);

    return (errors.errors.length === 0);
  };

  return {
    errors,
    validateFileField,
    areValidFields,
  };
}
