<template>
  <router-view />
</template>

<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import http from '@/app/service/http';
import { LOGOUT_ACTION } from '@/security/store/actions';

export default {
  name: 'App',
  setup() {
    const router = useRouter();
    const store = useStore();

    http.client.interceptors.response.use(undefined, (err) => new Promise(() => {
      if (err.response.status === 401) {
        store.dispatch(LOGOUT_ACTION);
        router.push({ name: 'Login' });
      }

      throw err;
    }));

    return {};
  },
};
</script>
