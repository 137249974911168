export const ADD_ON_BOARDING_SELLING_POINT_ACTION = 'addOnBoardingSellingPointAction';
export const GET_ON_BOARDING_SELLING_POINT_ACTION = 'getOnBoardingSellingPointAction';
export const RESET_ON_BOARDING_SELLING_POINT_ACTION = 'resetOnBoardingSellingPointAction';
export const PATCH_ON_BOARDING_SELLING_POINT_ACTION = 'patchOnBoardingSellingPointAction';

export const GOTO_STEP_ON_BOARDING_SELLING_POINT_ACTION = 'goToStepOnBoardingSellingPointAction';

export const PATCH_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENTS_ACTION = 'patchOnBoardingSellingPointLegalRepresentsAction';
export const GET_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENTS_ACTION = 'getOnBoardingSellingPointLegalRepresentsAction';

export const ADD_ON_BOARDING_SELLING_POINT_EMPTY_LEGAL_REPRESENT_ACTION = 'addOnBoardingSellingPointEmptyLegalRepresentAction';
export const CANCEL_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENT_ACTION = 'cancelOnBoardingSellingPointLegalRepresentAction';
export const REMOVE_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENT_ACTION = 'removeOnBoardingSellingPointLegalRepresentAction';
export const SAVE_ON_BOARDING_SELLING_POINT_LEGAL_REPRESENT_ACTION = 'saveOnBoardingSellingPointLegalRepresentAction';
