const MimeEnum = {
  PDF: 'application/pdf',
  GIF: 'image/gif',
  JPG: 'image/jpg',
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  BMP: 'image/bmp',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV: 'text/csv',

  getReadableValue(value) {
    return this.getChoices()
      .filter((element) => element.value === value)
      .map((element) => element.label)
      .pop();
  },

  getValue() {
    return [
      this.PDF,
      this.GIF,
      this.JPG,
      this.JPEG,
      this.PNG,
      this.BMP,
    ];
  },

  getExcelValues() {
    return [
      this.XLS,
      this.XLSX,
      this.CSV,
    ];
  },
};

export default MimeEnum;
