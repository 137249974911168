import { i18n } from '@/app/service/i18n';
import Phone from '@/app/model/Phone';
import CivilityEnum from '@/app/enum/CivilityEnum';
import ContactTypeEnum from '@/onBoarding/enum/ContactTypeEnum';
import YesNoEnum from '@/app/enum/YesNoEnum';

export default class Contact {
  constructor(
    uuid = null,
    civility = null,
    firstname = null,
    lastname = null,
    phone = Phone.createEmpty(),
    mobilePhone = Phone.createEmpty(),
    email = null,
    type = null,
    contactTechnicalIncident = false,
    contactFinancingIncident = false,
    commercialActivity = null,
  ) {
    this.uuid = uuid;
    this.civility = civility;
    this.firstname = firstname;
    this.lastname = lastname;
    this.phone = phone;
    this.mobilePhone = mobilePhone;
    this.email = email;
    this.type = type;
    this.contactTechnicalIncident = contactTechnicalIncident;
    this.contactFinancingIncident = contactFinancingIncident;
    this.commercialActivity = commercialActivity;
  }

  isNew() {
    return this.uuid === null;
  }

  isEmpty() {
    return this.uuid === null
      && this.civility === null
      && this.firstname === null
      && this.lastname === null
      && this.email === null;
  }

  getFullName() {
    return `${this.lastname} ${this.firstname}`;
  }

  getFriendlyCivility() {
    return `${CivilityEnum.getReadableValue(this.civility)}`;
  }

  getFriendlyPhone() {
    return (this.phone.number) ? `${this.phone.prefix}${this.phone.number}` : '';
  }

  getFriendlyMobilePhone() {
    return (this.mobilePhone.number) ? `${this.mobilePhone.prefix}${this.mobilePhone.number}` : '';
  }

  getFriendlyContactFinancingIncident() {
    return i18n.global.tc(YesNoEnum.getReadableValue(this.contactFinancingIncident));
  }

  getFriendlyContactTechnicalIncident() {
    return i18n.global.tc(YesNoEnum.getReadableValue(this.contactTechnicalIncident));
  }

  canShowTechnicalIncidentSelector() {
    return this.type !== ContactTypeEnum.FINANCIAL_SERVICE && this.type !== ContactTypeEnum.FRAUD_SERVICE;
  }

  canShowFinancingIncidentSelector() {
    return this.type !== ContactTypeEnum.CUSTOMER_RELATIONSHIP && this.type !== ContactTypeEnum.FRAUD_SERVICE;
  }

  canShowEmergencyContact() {
    return this.canShowFinancingIncidentSelector() || this.canShowTechnicalIncidentSelector();
  }

  toJson() {
    return {
      id: this.uuid,
      civility: this.civility,
      firstname: this.firstname,
      lastname: this.lastname,
      phone: this.phone.toJson(),
      mobilePhone: this.mobilePhone.toJson(),
      email: this.email,
      type: this.type,
      contactTechnicalIncident: this.contactTechnicalIncident,
      contactFinancingIncident: this.contactFinancingIncident,
      commercialActivity: this.commercialActivity,
    };
  }

  static createEmpty(type, iri = null) {
    const contact = new Contact();
    contact.commercialActivity = iri;
    contact.type = type;
    this.defaultSelector(contact);
    return contact;
  }

  static defaultSelector(contact) {
    switch (contact.type) {
      case ContactTypeEnum.PARTNERSHIP_MANAGER:
        contact.contactTechnicalIncident = true;
        contact.contactFinancingIncident = true;
        break;
      case ContactTypeEnum.FINANCIAL_SERVICE:
        contact.contactTechnicalIncident = false;
        contact.contactFinancingIncident = true;
        break;
      case ContactTypeEnum.CUSTOMER_RELATIONSHIP:
        contact.contactTechnicalIncident = true;
        contact.contactFinancingIncident = false;
        break;
      case ContactTypeEnum.FRAUD_SERVICE:
        contact.contactTechnicalIncident = false;
        contact.contactFinancingIncident = false;
        break;
      default:
    }
    return contact;
  }

  static fromJson(json, iri = null) {
    return new Contact(
      'uuid' in json ? json.uuid : null,
      'civility' in json ? json.civility : null,
      'firstname' in json ? json.firstname : null,
      'lastname' in json ? json.lastname : null,
      'phone' in json ? Phone.fromJson(json.phone) : null,
      'mobilePhone' in json ? Phone.fromJson(json.mobilePhone) : null,
      'email' in json ? json.email : null,
      'type' in json ? json.type : null,
      'contactTechnicalIncident' in json ? json.contactTechnicalIncident : null,
      'contactFinancingIncident' in json ? json.contactFinancingIncident : null,
      iri,
    );
  }
}
