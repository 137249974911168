export default class RetailerEnvironment {
  constructor(
    uuid = null,
    cancelProcess = false,
  ) {
    this.uuid = uuid;
    this.cancelProcess = cancelProcess;
  }

  static createEmpty() {
    return new RetailerEnvironment();
  }

  toJson() {
    return {
      uuid: this.uuid,
      cancelProcess: this.cancelProcess,
    };
  }

  static fromJson(json) {
    return new RetailerEnvironment(
      'uuid' in json ? json.uuid : null,
      'cancelProcess' in json ? json.cancelProcess : null,
    );
  }
}
