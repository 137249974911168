import { reactive } from 'vue';
import useValidators from '@/app/validators/validator';
import ErrorsCollection from '@/app/model/ErrorsCollection';
import Media from '@/app/model/Media';

const {
  isEmpty,
  isValidString,
  isValidPhone,
  isValidPrefixPhone,
} = useValidators();

const errors = reactive(new ErrorsCollection());

export default function legalRepresentFormValidator(legalRepresent) {
  const validateCivilityField = () => {
    const property = 'civility';
    errors.clearErrors(property);

    if (isEmpty(legalRepresent.civility)) {
      return errors.addError(property, 'validation.not_blank_select');
    }

    return errors;
  };

  const validateFirstnameField = () => {
    const property = 'firstName';
    const value = legalRepresent.firstname;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isValidString(value)) {
      return errors.addError(property, 'validation.must_not_contain_special_characters');
    }

    return errors;
  };

  const validateLastNameField = () => {
    const property = 'lastName';
    const value = legalRepresent.lastname;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isValidString(value)) {
      return errors.addError(property, 'validation.must_not_contain_special_characters');
    }

    return errors;
  };

  const validatePhoneField = () => {
    const property = 'phone';
    const value = legalRepresent.phone;
    errors.clearErrors(property);

    if (isEmpty(value.number)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isValidPhone(value)) {
      return errors.addError(property, 'validation.invalid_phone');
    }

    if (!isValidPrefixPhone(value)) {
      return errors.addError(property, 'validation.invalid_prefix_phone');
    }

    return errors;
  };

  const validateTypeIdField = () => {
    const property = 'typeId';
    errors.clearErrors(property);

    if (isEmpty(legalRepresent.typeId)) {
      return errors.addError(property, 'validation.not_blank_select');
    }

    return errors;
  };

  const validateIdCardFrontField = () => {
    const property = 'idCardFront';
    errors.clearErrors(property);

    if (!(legalRepresent.idCardFront instanceof Media)) {
      return errors.addError(property, 'validation.not_blank_file');
    }

    return errors;
  };

  const validateIdCardBackField = () => {
    const property = 'idCardBack';
    errors.clearErrors(property);

    if (!(legalRepresent.idCardBack instanceof Media)
      && !legalRepresent.isPassportIdType()) {
      return errors.addError(property, 'validation.not_blank_file');
    }

    return errors;
  };

  const areValidFields = () => {
    validateCivilityField();
    validateFirstnameField();
    validateLastNameField();
    validatePhoneField();
    validateTypeIdField();
    validateIdCardFrontField();
    validateIdCardBackField();

    return (errors.errors.length === 0);
  };

  return {
    errors,
    validateCivilityField,
    validateFirstnameField,
    validateLastNameField,
    validatePhoneField,
    validateTypeIdField,
    areValidFields,
  };
}
