<template>
  <div class="row mt-5">
    <div class="col-12">
      <h5 class="px-3">
        {{ $t('menu.welcome') }}
      </h5>
      <p class="px-3">
        {{ $t('menu.info') }}
      </p>
    </div>
  </div>
  <div class="col mt-0 m-lg-3">
    <ul class="list-unstyled">
      <li v-for="(menu, index) in items" :key="index">
        <MenuTitle
          :menu-index="menuIndex"
          :menu="menu"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import MenuTitle from './MenuTitle.vue';

export default {
  name: 'Header',
  components: {
    MenuTitle,
  },
  props: {
    menuIndex: {
      type: Number,
      default: 1,
    },
  },
  setup() {
    const store = useStore();
    const onBoarding = ref(store.getters.getOnBoarding);
    const menu = computed(() => store.getters.getMenuInterface);
    const items = menu.value.getItems(onBoarding.value);

    return {
      items,
    };
  },
};
</script>
