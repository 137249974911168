<template>
  <div class="mt-3 text-secondary">
    {{ $t('information.legal_notice') }}
    <a href="#" @click.prevent="openLegalNotice()">
      {{ $t('action.click_here') }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'LegalNotice',
  props: {
    country: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    function openLegalNotice() {
      let path = 'https://www.floapay.com/images/PDF/20210330_Politique_de_confidentialit_FLOA_Bank_EN.pdf';

      switch (props.country) {
        case 'ES':
          path = 'https://www.floapay.com/images/PDF/20210614_POLITIQUE_CONFIDENTIALITE_FLOA_BANK_vES.pdf';
          break;
        case 'BE':
          path = 'https://www.floabank.fr/images/pdf/Politique_de_confidentialit_et_cookie.pdf';
          break;
        case 'FR':
          path = 'https://www.floabank.fr/images/pdf/Politique_de_confidentialit_et_cookie.pdf';
          break;
        case 'PT':
          path = 'https://www.floapay.com/images/PDF/Politique_de_confidentialit_EN_pour_PORT.pdf';
          break;
        case 'IT':
          path = 'https://www.floapay.com/images/PDF/Privacy_Policy_IT.pdf';
          break;
        default:
      }

      return window.open(path, '_blank');
    }

    return {
      openLegalNotice,
    };
  },
};
</script>
