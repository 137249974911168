import CountryEnum from '@/app/enum/CountryEnum';
import Money from '@/app/model/Money';

export default class Information {
  constructor(
    uuid,
    businessName,
    socialReason,
    societyType,
    societyNumber,
    shareCapital = 0.00,
    country = CountryEnum.FRANCE,
    nafCode = null,
    address = null,
    postalCode = null,
    city = null,
  ) {
    this.uuid = uuid;
    this.businessName = businessName;
    this.socialReason = socialReason;
    this.societyType = societyType;
    this.societyNumber = societyNumber;
    this.shareCapital = shareCapital;
    this.country = country;
    this.nafCode = nafCode;
    this.address = address;
    this.postalCode = postalCode;
    this.city = city;
  }

  toJson() {
    return {
      uuid: this.uuid,
      businessName: this.businessName,
      socialReason: this.socialReason,
      societyType: this.societyType,
      societyNumber: this.societyNumber,
      shareCapital: Money.toJson(this.shareCapital),
      country: this.country,
      nafCode: this.nafCode,
      address: this.address,
      postalCode: this.postalCode,
      city: this.city,
    };
  }

  static createEmpty() {
    return new Information();
  }

  static fromJson(json) {
    return new Information(
      'uuid' in json ? json.uuid : null,
      'businessName' in json ? json.businessName : null,
      'socialReason' in json ? json.socialReason : null,
      'societyType' in json ? json.societyType : null,
      'societyNumber' in json ? json.societyNumber : null,
      'shareCapital' in json && json.shareCapital !== null ? Money.fromJson(json.shareCapital) : 0.00,
      'country' in json ? json.country : null,
      'nafCode' in json ? json.nafCode : null,
      'address' in json ? json.address : null,
      'postalCode' in json ? json.postalCode : null,
      'city' in json ? json.city : null,
    );
  }
}
