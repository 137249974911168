import { reactive } from 'vue';
import useValidators from '@/app/validators/validator';
import ErrorsCollection from '@/app/model/ErrorsCollection';

const errors = reactive(new ErrorsCollection());
const { isEmpty } = useValidators();

export default function retailerEnvironmentFormValidator(retailerEnvironment) {
  const validateCancelProcessField = () => {
    const property = 'cancelProcess';

    if (isEmpty(retailerEnvironment.cancelProcess)) {
      return errors.addError(property, 'validation.not_blank_select');
    }

    return errors;
  };

  const areValidFields = () => {
    validateCancelProcessField();

    return (errors.errors.length === 0);
  };

  return {
    errors,
    validateCancelProcessField,
    areValidFields,
  };
}
