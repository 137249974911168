<template>
  <div v-if="errors" class="d-block invalid-feedback my-2">
    <ul class="list-unstyled mb-0">
      <li v-for="(error, index) in errors" :key="index">
        {{ error.message }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ErrorDisplay',
  props: {
    errors: {
      type: Array,
      default: () => null,
    },
  },
};
</script>
