import { i18n } from '@/app/service/i18n';
import ChannelEnum from '@/onBoarding/enum/ChannelEnum';
import CustomerFeesEnum from '@/onBoarding/enum/CustomerFeesEnum';
import PaymentCaptureModeEnum from '@/onBoarding/enum/PaymentCaptureModeEnum';
import PaymentMethodEnum from '@/onBoarding/enum/PaymentMethodEnum';
import CountryEnum from '@/app/enum/CountryEnum';

export default class Product {
  constructor(
    id = null,
    channel = null,
    paymentMethods = null,
    customerFees = CustomerFeesEnum.WITH,
    paymentCaptureMode = false,
    country = null,
    productsActivity = null,
  ) {
    this.id = id;
    this.channel = channel;
    this.paymentMethods = paymentMethods;
    this.customerFees = customerFees;
    this.paymentCaptureMode = paymentCaptureMode;
    this.country = country;
    this.productsActivity = productsActivity;
  }

  getFriendlyChannel() {
    if (this.channel === null) {
      return '';
    }

    return i18n.global.tc(ChannelEnum.getReadableValue(this.channel));
  }

  getFriendlyPaymentMethods() {
    if (Array.isArray(this.paymentMethods) === false) {
      this.paymentMethods = [this.paymentMethods];
    }

    const paymentMethods = this.paymentMethods.map((element) => {
      const readableElement = PaymentMethodEnum.getReadableValue(element);

      return i18n.global.tc(readableElement);
    });

    return paymentMethods.join(', ');
  }

  getFriendlyCustomerFees() {
    return i18n.global.tc(CustomerFeesEnum.getReadableValue(this.customerFees));
  }

  getFriendlyPaymentCaptureMode() {
    return i18n.global.tc(PaymentCaptureModeEnum.getReadableValue(this.paymentCaptureMode));
  }

  getFriendlyCountry() {
    if (this.country === null) {
      return '';
    }

    return i18n.global.tc(CountryEnum.getReadableValue(this.country));
  }

  isNew() {
    return this.id === null;
  }

  isEmpty() {
    return this.id === null
      && this.channel === null
      && this.paymentMethods === null
      && this.paymentCaptureMode === false
      && this.country === null;
  }

  toJson() {
    return {
      id: this.id,
      channel: this.channel,
      paymentMethods: [...this.paymentMethods],
      customerFees: this.customerFees,
      paymentCaptureMode: this.paymentCaptureMode,
      country: this.country,
      productsActivity: this.productsActivity,
    };
  }

  isEqual(product) {
    return !!(this.channel === product.channel
      && this.paymentMethods === product.paymentMethods
      && this.customerFees === product.customerFees
      && this.paymentCaptureMode === product.paymentCaptureMode
      && this.country === product.country
      && this.productsActivity === product.productsActivity
    );
  }

  stringify() {
    return JSON.stringify({
      channel: this.channel,
      paymentMethods: [...this.paymentMethods],
      customerFees: this.customerFees,
      paymentCaptureMode: this.paymentCaptureMode,
      productsActivity: this.productsActivity,
      country: this.country,
    });
  }

  static createEmpty(iri = null) {
    const product = new Product();
    product.productsActivity = iri;
    return product;
  }

  static fromJson(json, iri = null) {
    return new Product(
      json.uuid,
      json.channel,
      json.paymentMethods,
      json.customerFees,
      json.paymentCaptureMode,
      json.country,
      iri,
    );
  }
}
