import { i18n } from '@/app/service/i18n';

const PaymentCaptureModeEnum = {
  AUTO: true,
  MANUAL: false,

  getReadableValue(value) {
    const val = this.getChoices().find((element) => element.value === value);

    return val !== undefined ? val.label : value;
  },

  getChoices() {
    return [
      { value: this.AUTO, label: i18n.global.tc('label.auto') },
      { value: this.MANUAL, label: i18n.global.tc('label.manual') },
    ];
  },
};

export default PaymentCaptureModeEnum;
