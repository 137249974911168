import IpRange from '@/app/model/IpRange';

export default class ProductionIpServer {
  constructor(
    uuid = null,
    ipRange = IpRange.createEmpty(),
    webEnvironment = null,
  ) {
    this.uuid = uuid;
    this.ipRange = ipRange;
    this.webEnvironment = webEnvironment;
  }

  isNew() {
    return this.uuid === null;
  }

  isEmpty() {
    return this.uuid === null;
  }

  toString() {
    if (this.ipRange.to !== null) {
      return `${this.ipRange.from} - ${this.ipRange.to}`;
    }

    return this.ipRange.from;
  }

  toJson() {
    return {
      ipRange: this.ipRange,
      webEnvironment: this.webEnvironment,
    };
  }

  static createEmpty(iri = null) {
    const productionIpServer = new ProductionIpServer();
    productionIpServer.ipRange = new IpRange();
    productionIpServer.webEnvironment = iri;
    return productionIpServer;
  }

  static fromJson(json, iri = null) {
    return new ProductionIpServer(
      'uuid' in json ? json.uuid : null,
      'ipRange' in json ? json.ipRange : null,
      iri,
    );
  }
}
