export default class Media {
  constructor(
    id,
    originalFileName,
    fileName,
    contentUrl,
    uuid,
  ) {
    this.id = id;
    this.originalFileName = originalFileName;
    this.fileName = fileName;
    this.contentUrl = contentUrl;
    this.uuid = uuid;
  }

  static fromJson(json) {
    return new Media(
      json['@id'],
      json.originalFileName,
      json.fileName,
      json.contentUrl,
      json.uuid,
    );
  }

  static createEmpty() {
    return new Media(
      '',
      '',
      '',
      '',
      '',
    );
  }

  toJson() {
    return this.id !== '' ? this.id : null;
  }
}
