<template>
  <Layout :menu-index="5">
    <Final />
    <div class="col m-5 col-md-5 text-center mx-auto">
      <h6 class="final-main">
        {{
          $t('final.society.finish')
        }}
      </h6>
      <p>
        {{
          $t('final.society.message_information')
        }}
      </p>
      <div>
        <div>
          {{
            $t('final.society.thanks')
          }}
        </div>
        <div class="link-success">
          {{
            $t('final.society.floa')
          }}
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/onBoarding/ui/components/Layout.vue';
import Final from '@/onBoarding/ui/components/Final.vue';

export default {
  name: 'FinalSociety',
  components: {
    Final,
    Layout,
  },
};
</script>
