const PhonePrefixEnum = {
  NETHERLANDS: '+31',
  BELGIUM: '+32',
  FRANCE: '+33',
  SPAIN: '+34',
  ITALY: '+39',
  GERMANY: '+49',
  PORTUGAL: '+351',

  getCountryCodes() {
    return [
      { value: this.FRANCE, label: 'FR' },
      { value: this.BELGIUM, label: 'BE' },
      { value: this.SPAIN, label: 'ES' },
      { value: this.ITALY, label: 'IT' },
      { value: this.PORTUGAL, label: 'PT' },
      { value: this.GERMANY, label: 'DE' },
      { value: this.NETHERLANDS, label: 'NL' },
    ];
  },

  fromCountry(country) {
    const code = this.getCountryCodes()
      .filter((countryCode) => countryCode.label === country)
      .map((countryCode) => countryCode.value).pop();

    if (code === undefined) {
      return this.FRANCE;
    }

    return code;
  },
};

export default PhonePrefixEnum;
