import onBoardingConnector from '@/onBoarding/connector/onBoarding';
import onBoardingSellingPointConnector from '@/onBoardingSellingPoint/connector/onBoarding';
import onBoardingInternationalConnector from '@/onBoardingInternational/connector/onBoarding';
import Media from '@/app/model/Media';
import MessagesCollection from '@/app/model/MessagesCollection';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';

import {
  ADD_MESSAGE_ACTION,
  ADD_MEDIA_ACTION,
  ADD_MEDIA_SELLING_POINT_ACTION,
  ADD_MEDIA_INTERNATIONAL_ACTION,
  REMOVE_MESSAGE_ACTION,
  CLEAR_MESSAGES_ACTION,
  TOGGLE_LOADING_ACTION,
  TOGGLE_MODAL_ACTION,
} from '@/app/store/actions';

import {
  ADD_MESSAGE_MUTATION,
  REMOVE_MESSAGE_MUTATION,
  CLEAR_MESSAGES_MUTATION,
  TOGGLE_LOADING_MUTATION,
  TOGGLE_MODAL_MUTATION,
} from '@/app/store/mutations';

const state = {
  isVisibleModal: false,
  isLoading: false,
  messagesCollection: new MessagesCollection(),
};

const getters = {
  getMessagesCollection(currentState) {
    return currentState.messagesCollection;
  },
  currentMessages(currentState) {
    return currentState.messagesCollection.messages;
  },
  hasMessage(currentState) {
    return currentState.messagesCollection.messages.length > 0;
  },
  isLoading(currentState) {
    return currentState.isLoading;
  },
  isVisibleModal(currentState) {
    return currentState.isVisibleModal;
  },
};

const actions = {
  [ADD_MESSAGE_ACTION](context, payload) {
    context.commit(ADD_MESSAGE_MUTATION, payload);
  },
  [ADD_MEDIA_ACTION](context, file) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.addMedia(file)
        .then(({ data }) => {
          const media = Media.fromJson(data);
          resolve(media);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject();
        });
    });
  },
  [ADD_MEDIA_SELLING_POINT_ACTION](context, file) {
    return new Promise((resolve, reject) => {
      onBoardingSellingPointConnector.addMediaSellingPoint(file)
        .then(({ data }) => {
          const media = Media.fromJson(data);
          resolve(media);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject();
        });
    });
  },
  [ADD_MEDIA_INTERNATIONAL_ACTION](context, file) {
    return new Promise((resolve, reject) => {
      onBoardingInternationalConnector.addMediaInternational(file)
        .then(({ data }) => {
          const media = Media.fromJson(data);
          resolve(media);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject();
        });
    });
  },
  [REMOVE_MESSAGE_ACTION](context, message) {
    context.commit(REMOVE_MESSAGE_MUTATION, message);
  },
  [CLEAR_MESSAGES_ACTION](context) {
    context.commit(CLEAR_MESSAGES_MUTATION);
  },
  [TOGGLE_LOADING_ACTION](context) {
    context.commit(TOGGLE_LOADING_MUTATION);
  },
  [TOGGLE_MODAL_ACTION](context) {
    context.commit(TOGGLE_MODAL_MUTATION);
  },
};

const mutations = {
  [ADD_MESSAGE_MUTATION](currentState, payload) {
    currentState.messagesCollection.addMessage(payload.type, payload.content);
  },
  [REMOVE_MESSAGE_MUTATION](currentState, message) {
    currentState.messagesCollection.messages = currentState.messagesCollection.messages.filter(
      (msg) => msg.key !== message.key,
    );
  },
  [CLEAR_MESSAGES_MUTATION](currentState) {
    currentState.messagesCollection.messages = [];
  },
  [TOGGLE_LOADING_MUTATION](currentState) {
    currentState.isLoading = !currentState.isLoading;
  },
  [TOGGLE_MODAL_MUTATION](currentState) {
    currentState.isVisibleModal = !currentState.isVisibleModal;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
