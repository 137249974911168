<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <LocalizedLink
        to="/"
        class="navbar-brand"
      >
        Floa
      </LocalizedLink>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon" />
      </button>
      <div
        id="navbarNav"
        class="collapse navbar-collapse"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <LocalizedLink
              to="/"
              class="nav-link"
            >
              {{ $t('home') }}
            </LocalizedLink>
          </li>
          <li class="nav-item">
            <LocalizedLink
              to="/login"
              class="nav-link"
            >
              {{ $t('login') }}
            </LocalizedLink>
          </li>
        </ul>
      </div>
      <div class="d-flex">
        <LocaleSwitcher />
      </div>
    </div>
  </nav>
</template>

<script>
import LocalizedLink from '@/app/ui/components/LocalizedLink.vue';
import LocaleSwitcher from '@/app/ui/components/LocaleSwitcher.vue';

export default {
  name: 'Header',
  components: {
    LocaleSwitcher,
    LocalizedLink,
  },
};
</script>
