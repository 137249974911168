<template>
  <div class="row">
    <div class="col">
      <h5>{{ label }}</h5>
      <hr class="my-4">
      <ContactsGroup
        :parent-class="'my-3'"
        :contacts="getContactsByType(contactType.PARTNERSHIP_MANAGER)"
        :type="contactType.PARTNERSHIP_MANAGER"
        :label="$t('label.partnership_manager')"
        :required="true"
        :errors="errors"
      />

      <ContactsGroup
        :parent-class="'my-3'"
        :contacts="getContactsByType(contactType.FINANCIAL_SERVICE)"
        :type="contactType.FINANCIAL_SERVICE"
        :label="$t('label.finance_accounting')"
      />

      <ContactsGroup
        :parent-class="'my-3'"
        :contacts="getContactsByType(contactType.CUSTOMER_RELATIONSHIP)"
        :type="contactType.CUSTOMER_RELATIONSHIP"
        :label="$t('label.customer_relationship')"
      />

      <ContactsGroup
        v-if="isWebActivity"
        :parent-class="'mt-3'"
        :contacts="getContactsByType(contactType.FRAUD_SERVICE)"
        :type="contactType.FRAUD_SERVICE"
        :label="$t('label.fraud')"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import ContactsGroup from '@/onBoarding/ui/components/ContactsGroup.vue';
import ContactTypeEnum from '@/onBoarding/enum/ContactTypeEnum';

export default {
  name: 'ContactsInformation',
  components: {
    ContactsGroup,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Array,
      default: () => null,
    },
    label: {
      type: String,
      default: '',
    },
    isWebActivity: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const contactType = ref(ContactTypeEnum);

    function getContactsByType(type) {
      return props.modelValue.filter((contact) => contact.type === type);
    }

    return {
      contactType,
      getContactsByType,
    };
  },
};
</script>
