import ContactTypeEnum from '@/onBoarding/enum/ContactTypeEnum';
import Contact from '@/onBoarding/model/Contact';
import PaymentFacility from '@/onBoarding/model/PaymentFacility';

export default class Commercial {
  constructor(
    uuid = null,
    iri = null,
    contacts = [],
    paymentFacility = new PaymentFacility(),
  ) {
    this.uuid = uuid;
    this.iri = iri;
    this.contacts = contacts;
    this.paymentFacility = paymentFacility;
  }

  toJson() {
    return {
      uuid: this.uuid,
      iri: this.iri,
      paymentFacility: this.paymentFacility.toJson(),
    };
  }

  addEmptyContact(type) {
    this.contacts.push(Contact.createEmpty(type, this.iri));
  }

  static fromJson(json) {
    const commercialActivityIri = '@id' in json ? json['@id'] : null;
    const contacts = 'contacts' in json ? json.contacts : [];

    return new Commercial(
      'uuid' in json ? json.uuid : null,
      commercialActivityIri,
      contacts.length > 0
        ? json.contacts.map((contact) => Contact.fromJson(contact,
          commercialActivityIri))
        : [Contact.createEmpty(ContactTypeEnum.PARTNERSHIP_MANAGER, commercialActivityIri)],
      'paymentFacility' in json
        ? PaymentFacility.fromJson(json.paymentFacility)
        : new PaymentFacility(),
    );
  }
}
