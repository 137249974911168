<template>
  <h6 class="mb-3 text-primary">
    {{ $t('title.legal_representative') }}
  </h6>
  <table class="table table-bordered">
    <tbody>
      <tr>
        <td class="border-end-0">
          {{ $t('label.civility') }}
        </td>
        <td class="border-start-0 text-end">
          {{ legalRepresent.getFriendlyCivility() }}
        </td>
      </tr>
      <tr>
        <td class="border-end-0">
          {{ $t('label.firstname') }}
        </td>
        <td class="border-start-0 text-end">
          {{ legalRepresent.firstname }}
        </td>
      </tr>
      <tr>
        <td class="border-end-0">
          {{ $t('label.lastname') }}
        </td>
        <td class="border-start-0 text-end">
          {{ legalRepresent.lastname }}
        </td>
      </tr>
      <tr>
        <td class="border-end-0">
          {{ $t('label.phone') }}
        </td>
        <td class="border-start-0 text-end">
          {{ legalRepresent.getFriendlyPhone() }}
        </td>
      </tr>
      <tr>
        <td class="border-end-0">
          {{ $t('label.choice_identity_document') }}
        </td>
        <td class="border-start-0 text-end">
          {{ legalRepresent.getFriendlyIdType() }}
        </td>
      </tr>
      <tr>
        <td class="p-0 w-50">
          <button
            type="button"
            class="btn btn-outline-secondary text-primary w-100 border-0"
            @click="edit"
          >
            <IconPencil height="16" width="16" />
            {{ $t('action.edit') }}
          </button>
        </td>
        <td class="p-0 w-50">
          <button
            type="button"
            class="btn btn-outline-secondary text-primary w-100 border-0"
            @click="remove"
          >
            <IconTrash height="16" width="16" />
            {{ $t('action.delete') }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { useStore } from 'vuex';
import IconPencil from '@/app/ui/components/icon/IconPencil.vue';
import IconTrash from '@/app/ui/components/icon/IconTrash.vue';
import { REMOVE_ON_BOARDING_LEGAL_REPRESENT_ACTION } from '@/onBoarding/store/activity/actions';

export default {
  name: 'LegalRepresentShow',
  components: {
    IconPencil,
    IconTrash,
  },
  props: {
    legalRepresent: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    edit: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    function remove() {
      store.dispatch(REMOVE_ON_BOARDING_LEGAL_REPRESENT_ACTION, props.index);
    }

    return {
      remove,
    };
  },
};
</script>
