<template>
  <div v-if="isVisibleModal"
       class="modal d-block bg-grey"
       tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t('modal_dialog.ask') }}
          </h5>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="cancel"
          />
        </div>
        <div class="modal-body">
          <p>{{ message }}</p>
        </div>
        <div class="modal-footer">
          <button type="button"
                  class="btn btn-lg btn-outline-primary text-dark"
                  data-dismiss="modal"
                  @click="cancel"
          >
            {{ $t('modal_dialog.cancel') }}
          </button>
          <button type="button"
                  class="btn btn-lg btn-outline-primary text-dark"
                  @click="confirm"
          >
            {{ $t('modal_dialog.continue') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { TOGGLE_MODAL_ACTION } from '@/app/store/actions';

export default {
  name: 'ModalDialogue',
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  setup() {
    const store = useStore();
    const isVisibleModal = computed(() => store.getters.isVisibleModal);
    let resolvePromise = null;

    function toggleModal() {
      store.dispatch(TOGGLE_MODAL_ACTION);
    }

    function showModal() {
      toggleModal();
      return new Promise((resolve) => {
        resolvePromise = resolve;
      });
    }

    function confirm() {
      toggleModal();
      resolvePromise(true);
    }

    function cancel() {
      toggleModal();
      resolvePromise(false);
    }

    return {
      confirm,
      cancel,
      isVisibleModal,
      showModal,
    };
  },
};
</script>
