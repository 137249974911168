<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="157" height="21.79" viewBox="0 0 157 21.79">
    <g transform="translate(-15 -41.228)">
      <!-- eslint-disable max-len vue/max-len  -->
      <path
        d="M27.278,47.7H17.44A2.4,2.4,0,0,0,15,50.14V63.992a2.44,2.44,0,1,0,4.88,0V59.466h6.3a2.243,2.243,0,0,0,0-4.486h-6.3V52.226h7.4a2.263,2.263,0,0,0,0-4.525"
        transform="translate(0 -3.925)"
        fill="#009fff"
      />
      <path
        d="M72.93,61.506H66.28V49.74a2.44,2.44,0,0,0-4.88,0V63.592a2.4,2.4,0,0,0,2.44,2.44h9.09a2.263,2.263,0,0,0,0-4.525"
        transform="translate(-28.141 -3.683)"
        fill="#009fff"
      />
      <path
        d="M106.876,56.423a4.752,4.752,0,0,1,4.762-4.958,4.835,4.835,0,0,1,4.84,5.037v.039a4.752,4.752,0,0,1-4.762,4.958,4.835,4.835,0,0,1-4.84-5.037v-.039Zm4.8-9.523a9.628,9.628,0,0,0-9.877,9.6v.039a9.45,9.45,0,0,0,9.8,9.523,9.628,9.628,0,0,0,9.877-9.6v-.039a9.427,9.427,0,0,0-9.8-9.523"
        transform="translate(-52.642 -3.44)"
        fill="#009fff"
      />
      <path
        d="M171.5,57.655v.866c0,1.928-1.771,3.306-4.25,3.306-1.377,0-2.991-.59-2.991-2.2v-.079c0-2.243,2.558-2.479,3.7-2.479a11.89,11.89,0,0,1,3.542.59m2.833-9.13c-1.377-1.417-3.542-2.125-6.257-2.125a15.7,15.7,0,0,0-5.9,1.063,2.277,2.277,0,0,0-1.417,2.086,2.2,2.2,0,0,0,2.2,2.2,3.181,3.181,0,0,0,.787-.118h.039a9.78,9.78,0,0,1,3.778-.669c2.519,0,3.817,1.063,3.9,3.148a15.151,15.151,0,0,0-4.289-.551c-4.919,0-7.87,2.322-7.87,6.218v.079c0,3.542,2.794,5.982,6.808,5.982a7.9,7.9,0,0,0,5.352-1.928,2.524,2.524,0,0,0,4.919-.708V54.349c0-2.519-.669-4.525-2.046-5.824"
        transform="translate(-87.515 -3.137)"
        fill="#009fff"
      />
      <path
        d="M379.633,63.738a1.5,1.5,0,1,0,1.5,1.5,1.495,1.495,0,0,0-1.5-1.5m0,4.329a2.833,2.833,0,1,1,2.833-2.833,2.822,2.822,0,0,1-2.833,2.833"
        transform="translate(-219.424 -12.841)"
        fill="#00ff87"
      />
      <path
        d="M381.426,52.064l-1.259-.512a16.282,16.282,0,0,0,.669-2.2c.433-1.81.394-3.148-.079-3.778a1.619,1.619,0,0,0-2.2,0c-.472.63-.512,1.968-.079,3.778l-1.3.315c-.551-2.282-.433-3.975.354-4.919a2.585,2.585,0,0,1,2.125-.944,2.675,2.675,0,0,1,2.164.944c.787.984.866,2.637.354,4.919a24.264,24.264,0,0,1-.748,2.4"
        transform="translate(-219.445 -1.56)"
        fill="#00ff87"
      />
      <path
        d="M366.9,55.956c-2.007-1.259-3.109-2.479-3.227-3.738a2.48,2.48,0,0,1,.866-2.164,2.673,2.673,0,0,1,2.164-.866c1.22.157,2.479,1.22,3.738,3.227a14.211,14.211,0,0,1,1.141,2.2l-1.22.512a18.663,18.663,0,0,0-1.062-2.007c-.984-1.613-1.968-2.519-2.755-2.637a1.619,1.619,0,0,0-1.535,1.535c.079.787,1.023,1.771,2.6,2.755Z"
        transform="translate(-211.447 -4.823)"
        fill="#00ff87"
      />
      <path
        d="M361.645,68.193a3.871,3.871,0,0,1-2.4-.669A2.585,2.585,0,0,1,358.3,65.4a2.526,2.526,0,0,1,.944-2.125c.944-.748,2.6-.866,4.84-.354l.354.079.236.079c1.063.275,1.81.59,1.85.59l-.512,1.259a16.287,16.287,0,0,0-2.2-.669l-.315-.079c-1.653-.354-2.873-.315-3.463.157a1.619,1.619,0,0,0,0,2.2c.63.472,1.968.512,3.778.079l.315,1.3a15.358,15.358,0,0,1-2.479.275"
        transform="translate(-208.204 -12.967)"
        fill="#00ff87"
      />
      <path
        d="M393.58,68.293a3.871,3.871,0,0,0,2.4-.669,2.585,2.585,0,0,0,.944-2.125,2.526,2.526,0,0,0-.944-2.125c-.944-.748-2.6-.866-4.84-.354l-.354.079-.236.079c-1.062.275-1.81.59-1.85.59l.512,1.259a16.285,16.285,0,0,1,2.2-.669l.315-.079c1.653-.354,2.873-.315,3.463.157a1.619,1.619,0,0,1,0,2.2c-.63.472-1.968.512-3.778.079l-.393.866a21.364,21.364,0,0,0,2.558.708"
        transform="translate(-226.641 -13.027)"
        fill="#00ff87"
      />
      <path
        d="M366.442,77.81a2.54,2.54,0,0,1-1.889-.866,2.673,2.673,0,0,1-.866-2.164c.157-1.22,1.22-2.479,3.227-3.738a14.208,14.208,0,0,1,2.2-1.141l.512,1.22a18.664,18.664,0,0,0-2.007,1.063c-1.613.984-2.519,1.968-2.637,2.755a1.619,1.619,0,0,0,1.535,1.535c.787-.079,1.771-1.023,2.755-2.6l1.141.708c-1.259,2.007-2.479,3.109-3.738,3.227h-.236"
        transform="translate(-211.467 -17.389)"
        fill="#00ff87"
      />
      <path
        d="M379.7,82.725a2.526,2.526,0,0,1-2.125-.944c-.748-.944-.866-2.6-.354-4.84l.079-.275.079-.315c.275-1.062.59-1.81.59-1.85l1.259.512a16.832,16.832,0,0,0-.669,2.164l-.079.315c-.354,1.653-.315,2.873.157,3.463a1.619,1.619,0,0,0,2.2,0c.472-.63.512-1.968.079-3.778l1.3-.315c.551,2.282.433,3.975-.354,4.919a2.613,2.613,0,0,1-2.164.944"
        transform="translate(-219.489 -20.179)"
        fill="#00ff87"
      />
      <path
        d="M389.455,77.749h-.275c-1.22-.157-2.479-1.22-3.738-3.227a14.21,14.21,0,0,1-1.141-2.2l1.22-.512a18.666,18.666,0,0,0,1.063,2.007c.984,1.613,1.968,2.519,2.755,2.637a1.619,1.619,0,0,0,1.535-1.535c-.079-.787-1.023-1.771-2.6-2.755a9.965,9.965,0,0,0-2.4-1.023l.236-1.338a10.409,10.409,0,0,1,2.873,1.181c2.007,1.259,3.109,2.519,3.227,3.738a2.48,2.48,0,0,1-.866,2.164,2.54,2.54,0,0,1-1.889.866"
        transform="translate(-223.973 -17.329)"
        fill="#00ff87"
      />
      <path
        d="M399.006,42.594h-.157A3.684,3.684,0,0,0,396.8,44.6s-.551,1.141-1.062,2.2a24.053,24.053,0,0,0,2.243-.944c1.181-.669,2.007-1.5,2.086-2.086a.906.906,0,0,0-.354-.826.864.864,0,0,0-.708-.354M393.3,49.087l.551-1.377c.433-.984,1.692-3.581,1.731-3.7.551-1.141,1.692-2.558,3.03-2.755a2.3,2.3,0,0,1,2.046.708,2.241,2.241,0,0,1,.748,1.968c-.2,1.417-1.81,2.558-2.755,3.069h0a29.533,29.533,0,0,1-3.935,1.574Z"
        transform="translate(-229.431)"
        fill="#00ff87"
      />
      <path
        d="M231.306,54.06c0,2.44-2.046,4.093-5.116,4.093h-4.6V49.928h4.722c3.03,0,5,1.377,5,4.053ZM226.465,48h-5.942a1.05,1.05,0,0,0-1.023,1.063V65.708a1.043,1.043,0,1,0,2.086,0V60.081h4.525c3.975,0,7.32-2.086,7.32-6.1V53.9c0-3.66-2.755-5.9-6.965-5.9"
        transform="translate(-124.025 -4.107)"
        fill="#00ff87"
      />
      <path
        d="M275.367,57.277v2.007c0,3.148-2.951,5.549-6.847,5.549-2.6,0-5.234-1.417-5.234-4.093v-.079c0-2.6,2.282-4.171,5.942-4.171a25.659,25.659,0,0,1,6.139.787M269.5,47.4a13.992,13.992,0,0,0-6.1,1.377,1,1,0,0,0-.59.905.985.985,0,0,0,.944.944,1.026,1.026,0,0,0,.433-.118,11.69,11.69,0,0,1,5.194-1.22c3.817,0,5.942,1.968,5.942,5.509v.669a22.456,22.456,0,0,0-5.982-.748c-5.037,0-8.146,2.322-8.146,6.1V60.9a5.168,5.168,0,0,0,2.322,4.407,8.537,8.537,0,0,0,4.919,1.456,8.445,8.445,0,0,0,6.926-3.266v1.968a.985.985,0,1,0,1.968,0V54.719a7.149,7.149,0,0,0-1.889-5.273A8.2,8.2,0,0,0,269.5,47.4"
        transform="translate(-149.315 -3.743)"
        fill="#00ff87"
      />
      <path
        d="M323.765,47.7c-.472,0-.708.275-.984.63l-6.218,8.894-6.139-8.894a1.264,1.264,0,0,0-1.023-.63,1.086,1.086,0,0,0-1.1,1.023,1.381,1.381,0,0,0,.236.708l6.926,9.72v6.375a1.063,1.063,0,0,0,2.125,0V59.073l6.887-9.6a1.711,1.711,0,0,0,.275-.787.928.928,0,0,0-.984-.984"
        transform="translate(-177.88 -3.925)"
        fill="#00ff87"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'LogoFloaPay',
};
</script>
