import { reactive } from 'vue';
import ErrorsCollection from '@/app/model/ErrorsCollection';

const errors = reactive(new ErrorsCollection());

export default function legalRepresentsFormValidator(legalRepresents) {
  const validateLegalRepresentsField = () => {
    const property = 'legalRepresents';
    errors.clearErrors(property);
    const nullLegalRepresents = legalRepresents.representatives.filter(
      (representative) => representative.uuid === null,
    );

    if (nullLegalRepresents.length === legalRepresents.representatives.length) {
      return errors.addError(property, 'validation.need_add_a_represent');
    }

    if (legalRepresents.representatives.length > 4) {
      return errors.addError(property, 'validation.max_represents');
    }

    return errors;
  };

  const areValidFields = () => {
    validateLegalRepresentsField();

    return (errors.errors.length === 0);
  };

  return {
    errors,
    areValidFields,
  };
}
