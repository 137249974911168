import { reactive } from 'vue';
import useValidators from '@/app/validators/validator';
import ErrorsCollection from '@/app/model/ErrorsCollection';

const errors = reactive(new ErrorsCollection());
const { isEmpty, isValidEmail } = useValidators();

export default function loginFormValidator(email, password) {
  const validateEmailField = () => {
    const property = 'email';
    const value = email;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      errors.addError(property, 'validation.not_blank');
    }

    if (!isEmpty(value) && !isValidEmail(value)) {
      errors.addError(property, 'validation.invalid_email');
    }
  };

  const validatePasswordField = () => {
    const property = 'password';
    errors.clearErrors(property);

    if (isEmpty(password)) {
      errors.addError(property, 'validation.not_blank');
    }
  };

  const areValidFields = () => {
    validateEmailField();
    validatePasswordField();

    return errors.hasErrors() === false;
  };

  return {
    errors,
    validateEmailField,
    validatePasswordField,
    areValidFields,
  };
}
