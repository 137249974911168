<template>
  <Layout :menu-index="8">
    <div class="row mb-3">
      <div class="col-12 col-md-9">
        <h2>Sample components</h2>
      </div>
    </div>

    <div class="row">
      <form action="" class="col-12 col-md-9">
        <InputMoney id="annual_turnover" :label="$t('label.annual_turnover')"
                    :placeholder="$t('label.annual_turnover')"
        />

        <InputMoney id="average_cart" :label="$t('label.average_cart')"
                    :placeholder="$t('label.average_cart')"
        />

        <InputUrl id="website" :label="$t('label.website')"
                  :placeholder="$t('label.website')"
        />

        <InputTextArea id="activity_description" :label="$t('label.activity_description')"
                       :placeholder="$t('label.activity_description')"
        />

        <InputEmail id="email" :label="$t('label.email')"
                    :placeholder="$t('label.email')"
        />

        <InputSelect id="activity" :label="$t('label.activity')"
                     :placeholder="$t('label.activity')"
                     :options="getActivityChoices"
        />

        <InputGroupRadio id="customer_fees"
                         name="customer_fees"
                         :label="$t('label.customer_fees')"
                         :choices="getCustomerFees"
        />

        <InputMediaOnBoarding label="KBIS" />

        <InputCheckbox id="express_delivery"
                       name="express_delivery"
                       :label="$t('label.express_delivery')"
        />

        <InputPhone id="phone"
                    name="phone"
                    :label="$t('label.phone')"
                    :placeholder="$t('placeholder.phone')"
        />

        <div class="mt-3 float-end">
          <button type="submit" class="btn btn-lg btn-light text-dark rounded-3 me-2">
            {{ $t('action.save') }}
          </button>

          <button type="submit" class="btn btn-lg btn-primary text-white rounded-3">
            {{ $t('action.continue') }}
          </button>
        </div>
      </form>
    </div>
  </Layout>
</template>

<script>
import { computed } from 'vue';
import Layout from '@/onBoarding/ui/components/Layout.vue';
import InputCheckbox from '@/app/ui/components/form/InputCheckbox.vue';
import InputEmail from '@/app/ui/components/form/InputEmail.vue';
import InputGroupRadio from '@/app/ui/components/form/InputGroupRadio.vue';
import InputMoney from '@/app/ui/components/form/InputMoney.vue';
import InputPhone from '@/app/ui/components/form/InputPhone.vue';
import InputSelect from '@/app/ui/components/form/InputSelect.vue';
import InputTextArea from '@/app/ui/components/form/InputTextArea.vue';
import InputMediaOnBoarding from '@/onBoarding/ui/components/form/InputMediaOnBoarding.vue';
import InputUrl from '@/app/ui/components/form/InputUrl.vue';
import ActivityEnum from '@/onBoarding/enum/ActivityEnum';
import CustomerFeesEnum from '@/onBoarding/enum/CustomerFeesEnum';
import CountryEnum from '@/app/enum/CountryEnum';

export default {
  name: 'Sample',
  components: {
    Layout,
    InputCheckbox,
    InputEmail,
    InputGroupRadio,
    InputMoney,
    InputPhone,
    InputSelect,
    InputTextArea,
    InputUrl,
    InputMediaOnBoarding,
  },
  setup() {
    const getCountriesChoices = computed(() => CountryEnum.getChoices());

    return {
      getCountriesChoices,
    };
  },
  computed: {
    getActivityChoices() {
      return ActivityEnum.getChoices();
    },
    getCustomerFees() {
      return CustomerFeesEnum.getChoices();
    },
  },
};
</script>
