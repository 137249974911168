<template>
  <div class="locale-switcher">
    <div class="dropdown">
      <button id="dropdownPhonePrefix"
              class="btn border border-light-dark dropdown-toggle btn-lg w-100 rounded-0"
              type="button" data-bs-toggle="dropdown" aria-expanded="false"
      >
        <Flag :code="currentLocale().toUpperCase()" :height="16" /> {{ currentLocale() }}
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownPhonePrefix">
        <li v-for="locale in locales" :key="locale">
          <span class="dropdown-item" @click="setLocale(locale)">
            <Flag :code="locale.toUpperCase()" :height="16" /> {{ locale }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { SUPPORTED_LANGUAGES } from '@/app/service/i18n';
import Flag from '@/app/ui/components/flag/Flag.vue';

export default {
  name: 'LocaleSwitcher',
  components: {
    Flag,
  },
  data: () => ({ locales: SUPPORTED_LANGUAGES }),
  methods: {
    currentLocale() {
      return this.$router.currentRoute.value.params.locale;
    },
    setLocale(newLocale) {
      this.$router.push({
        name: this.$router.currentRoute.value.name,
        params: {
          ...this.$router.currentRoute.value.params,
          ...{ locale: newLocale },
        },
      });
    },
  },
};
</script>
