<template>
  <Layout>
    <div class="row justify-content-center align-items-center vp-h-100">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4">
        <div class="card bg-white">
          <LogoFloaPay class="card-img-top mt-4" height="30" />
          <div class="card-body">
            <h5 class="card-title text-center mt-2 mb-4">
              {{ $t('title.change_password') }}
            </h5>

            <div v-if="messages" class="row">
              <div class="col-12">
                <Alert v-for="(message, index) in messages"
                       :key="index"
                       :msg="message"
                       :index="index"
                />
              </div>
            </div>

            <Loading v-if="isLoading" />

            <form v-else autocomplete="off" @submit.prevent="onSubmit">
              <InputPassword
                id="password"
                v-model="password"
                placeholder="name@example.com"
                :label="$t('label.password')"
                :required="true"
                :validators="[validationForm.validatePasswordField]"
                :errors="validationForm.errors.getErrorsFromProperty('password')"
              />
              <InputPassword
                id="confirmationPassword"
                v-model="confirmationPassword"
                placeholder="name@example.com"
                :label="$t('label.confirmation_password')"
                :required="true"
                :validators="[validationForm.validatePasswordConfirmationField]"
                :errors="validationForm.errors.getErrorsFromProperty('confirmationPassword')"
              />
              <div class="mb-3 text-end">
                <button
                  :disabled="canChange"
                  type="submit"
                  class="btn btn-primary text-white rounded-3"
                >
                  {{ $t('action.change_password') }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { TOGGLE_LOADING_ACTION } from '@/app/store/actions';
import {
  CHANGE_PASSWORD_ACTION,
  CHECK_TOKEN_PASSWORD_ACTION,
  ADD_LOGIN_MESSAGE_ACTION,
  CLEAR_LOGIN_MESSAGES_ACTION,
} from '@/security/store/actions';
import { i18n } from '@/app/service/i18n';
import Layout from '@/security/ui/components/Layout.vue';
import Loading from '@/app/ui/components/Loading.vue';
import Alert from '@/app/ui/components/Alert.vue';
import InputPassword from '@/app/ui/components/form/InputPassword.vue';
import LogoFloaPay from '@/app/ui/components/LogoFloaPay.vue';
import ChangePasswordFormValidator from '@/security/validators/changePasswordFormValidator';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';

export default {
  name: 'ChangePassword',
  components: {
    Alert,
    Layout,
    LogoFloaPay,
    Loading,
    InputPassword,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const password = ref(null);
    const confirmationPassword = ref(null);
    const store = useStore();
    const messages = computed(() => store.getters.currentLoginMessages);
    const isLoading = computed(() => store.getters.isLoading);
    const { token } = route.params;
    const validationForm = computed(() => ChangePasswordFormValidator(
      password.value,
      confirmationPassword.value,
    ));
    const canChange = computed(() => password.value === ''
        || password.value === null
        || confirmationPassword.value === ''
        || confirmationPassword.value === null
        || confirmationPassword.value !== password.value);

    onMounted(async () => {
      await store.dispatch(CLEAR_LOGIN_MESSAGES_ACTION);
      await store.dispatch(CHECK_TOKEN_PASSWORD_ACTION, { token })
        .catch(() => router.push({
          path: `/${i18n.global.locale}/login`,
        }));
    });

    function onSubmit() {
      if (validationForm.value.areValidFields()) {
        store.dispatch(TOGGLE_LOADING_ACTION);
        store.dispatch(CHANGE_PASSWORD_ACTION, {
          token,
          password: password.value,
        }).then(() => {
          store.dispatch(ADD_LOGIN_MESSAGE_ACTION, { type: MessageTypeEnum.SUCCESS, content: 'flash.password_change_success' });
          router.push({ name: 'Login' });
        }).finally(() => {
          store.dispatch(TOGGLE_LOADING_ACTION);
        });
      }
    }

    return {
      password,
      confirmationPassword,
      isLoading,
      messages,
      onSubmit,
      validationForm,
      canChange,
    };
  },
};
</script>
