<template>
  <div class="row my-3 ps-2">
    <div class="col-12">
      <hr>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Separator',
};
</script>
