import SecurityRouter from '@/security/router';
import OnBoardingRouter from '@/onBoarding/router';
import OnBoardingSellingPoint from '@/onBoardingSellingPoint/router';
import OnBoardingInternational from '@/onBoardingInternational/router';
import CommonRoute from '@/app/router/common';

// The commonRoute are obligatorily last because they are the ones that contain the page 404 route.
const routes = [].concat(
  SecurityRouter.routes,
  OnBoardingRouter.routes,
  OnBoardingSellingPoint.routes,
  OnBoardingInternational.routes,
  CommonRoute,
);

export default routes;
