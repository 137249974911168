export const GET_ON_BOARDING_COMMERCIAL_ACTIVITY_ACTION = 'getOnBoardingCommercialActivityAction';
export const PATCH_ON_BOARDING_COMMERCIAL_ACTIVITY_ACTION = 'patchOnBoardingCommercialActivityAction';

export const SAVE_ON_BOARDING_CONTACT_ACTION = 'saveOnBoardingContactAction';
export const CANCEL_ON_BOARDING_CONTACT_ACTION = 'cancelOnBoardingContactAction';
export const REMOVE_ON_BOARDING_CONTACT_ACTION = 'removeOnBoardingContactAction';
export const ADD_ON_BOARDING_EMPTY_CONTACT_ACTION = 'addOnBoardingEmptyContact';

export const GET_ON_BOARDING_RETAILER_ENVIRONMENT_ACTION = 'getOnBoardingRetailerEnvironmentAction';
export const SAVE_ON_BOARDING_RETAILER_ENVIRONMENT_ACTION = 'saveOnBoardingRetailerEnvironmentAction';

export const GET_ON_BOARDING_WEB_ENVIRONMENT_ACTION = 'getOnBoardingWebEnvironmentAction';
export const PATCH_ON_BOARDING_WEB_ENVIRONMENT_ACTION = 'patchOnBoardingWebEnvironmentAction';
export const REMOVE_ON_BOARDING_PRODUCTION_IP_SERVER_ACTION = 'removeOnBoardingProductIpServerAction';
export const SAVE_ON_BOARDING_PRODUCTION_IP_SERVER_ACTION = 'saveOnBoardingProductIpServerAction';

export const GET_ON_BOARDING_FRAUD_ACTION = 'getOnBoardingFraudAction';
export const SAVE_ON_BOARDING_FRAUD_ACTION = 'saveOnBoardingFraudAction';
