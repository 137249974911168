import onBoardingConnector from '@/onBoarding/connector/onBoarding';
import Information from '@/onBoarding/model/Information';
import Activity from '@/onBoarding/model/Activity';
import ProductsActivity from '@/onBoarding/model/ProductsActivity';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import Bank from '@/onBoarding/model/Bank';
import Voucher from '@/onBoarding/model/Voucher';
import LegalRepresent from '@/onBoarding/model/LegalRepresent';
import SocietyType from '@/onBoarding/model/SocietyType';
import LegalRepresentsActivity from '@/onBoarding/model/LegalRepresentsActivity';

import {
  ADD_ON_BOARDING_EMPTY_LEGAL_REPRESENT_ACTION,
  ADD_ON_BOARDING_EMPTY_PRODUCT_ACTION,
  CANCEL_ON_BOARDING_LEGAL_REPRESENT_ACTION,
  CANCEL_ON_BOARDING_PRODUCT_ACTION,
  GET_ON_BOARDING_ACTIVITY_ACTION,
  GET_ON_BOARDING_BANK_ACTION,
  GET_ON_BOARDING_INFORMATION_ACTION,
  GET_ON_BOARDING_LEGAL_REPRESENTS_ACTIVITY_ACTION,
  GET_ON_BOARDING_PRODUCTS_ACTIVITY_ACTION,
  GET_ON_BOARDING_SOCIETY_TYPES_ACTION,
  GET_ON_BOARDING_VOUCHER_ACTION,
  PATCH_ON_BOARDING_LEGAL_REPRESENTS_ACTIVITY_ACTION,
  PATCH_ON_BOARDING_PRODUCTS_ACTIVITY_ACTION,
  PUT_ON_BOARDING_ACTIVITY_ACTION,
  PUT_ON_BOARDING_BANK_ACTION,
  PUT_ON_BOARDING_INFORMATION_ACTION,
  PUT_ON_BOARDING_VOUCHER_ACTION,
  REMOVE_ON_BOARDING_LEGAL_REPRESENT_ACTION,
  REMOVE_ON_BOARDING_PRODUCT_ACTION,
  SAVE_ON_BOARDING_LEGAL_REPRESENT_ACTION,
  SAVE_ON_BOARDING_PRODUCT_ACTION,
} from '@/onBoarding/store/activity/actions';

import {
  ADD_ON_BOARDING_EMPTY_LEGAL_REPRESENT_MUTATION,
  ADD_ON_BOARDING_EMPTY_PRODUCT_MUTATION,
  REMOVE_ON_BOARDING_LEGAL_REPRESENT_MUTATION,
  REMOVE_ON_BOARDING_PRODUCT_MUTATION,
  SET_ON_BOARDING_ACTIVITY_MUTATION,
  SET_ON_BOARDING_BANK_MUTATION,
  SET_ON_BOARDING_INFORMATION_MUTATION,
  SET_ON_BOARDING_LEGAL_REPRESENTS_ACTIVITY_MUTATION,
  SET_ON_BOARDING_PRODUCTS_ACTIVITY_MUTATION,
  SET_ON_BOARDING_SOCIETY_TYPES_MUTATION,
  SET_ON_BOARDING_VOUCHER_MUTATION,
} from '@/onBoarding/store/activity/mutations';

import { GET_ON_BOARDING_ACTION } from '@/onBoarding/store/actions';
import { ADD_MESSAGE_ACTION } from '@/app/store/actions';

const state = {
  activity: null,
  information: null,
  productsActivity: null,
  societyTypes: [],
  bank: Bank.createEmpty(),
  voucher: Voucher.createEmpty(),
  legalRepresentsActivity: null,
};

const getters = {
  getOnBoardingActivity(currentState) {
    return currentState.activity;
  },
  getOnBoardingInformation(currentState) {
    return currentState.information;
  },
  getOnBoardingSocietyTypes(currentStage) {
    return currentStage.societyTypes;
  },
  getOnBoardingProductsActivity(currentState) {
    return currentState.productsActivity;
  },
  getOnBoardingBank(currentState) {
    return currentState.bank;
  },
  getOnBoardingVoucher(currentState) {
    return currentState.voucher;
  },
  getOnBoardingLegalRepresentsActivity(currentStage) {
    return currentStage.legalRepresentsActivity;
  },
};

const actions = {
  [GET_ON_BOARDING_ACTIVITY_ACTION](context, uuid) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.getActivity(uuid)
        .then(({ data }) => {
          const informationActivity = Activity.fromJson(data);
          context.commit(SET_ON_BOARDING_ACTIVITY_MUTATION, informationActivity);
          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [PUT_ON_BOARDING_ACTIVITY_ACTION](context, payload) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.putActivity(payload.activity, payload.isContinue)
        .then(async ({ data }) => {
          const informationActivity = Activity.fromJson(data);
          context.commit(SET_ON_BOARDING_ACTIVITY_MUTATION, informationActivity);

          if (payload.isContinue) {
            await context.dispatch(GET_ON_BOARDING_ACTION);
          }

          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [GET_ON_BOARDING_PRODUCTS_ACTIVITY_ACTION](context, uuid) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.getProductsActivity(uuid)
        .then(({ data }) => {
          const productsActivity = ProductsActivity.fromJson(data);
          context.commit(SET_ON_BOARDING_PRODUCTS_ACTIVITY_MUTATION, productsActivity);
          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [PATCH_ON_BOARDING_PRODUCTS_ACTIVITY_ACTION](context, payload) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.patchProductsActivity(
        payload.productsActivity.toJson(), payload.isContinue,
      )
        .then(async ({ data }) => {
          const productsActivity = ProductsActivity.fromJson(data);
          context.commit(SET_ON_BOARDING_PRODUCTS_ACTIVITY_MUTATION, productsActivity);

          if (payload.isContinue) {
            await context.dispatch(GET_ON_BOARDING_ACTION);
          }

          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [SAVE_ON_BOARDING_PRODUCT_ACTION](context, product) {
    return new Promise((resolve, reject) => {
      if (product.isNew() === true) {
        onBoardingConnector.postProduct(product.toJson())
          .then(async ({ data }) => {
            await context.dispatch(GET_ON_BOARDING_PRODUCTS_ACTIVITY_ACTION,
              context.getters.getOnBoardingProductsActivity.uuid);
            resolve(data);
          })
          .catch(({ response }) => {
            context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
            reject(response);
          });
      } else {
        onBoardingConnector.putProduct(product.toJson())
          .then(async ({ data }) => {
            await context.dispatch(GET_ON_BOARDING_PRODUCTS_ACTIVITY_ACTION,
              context.getters.getOnBoardingProductsActivity.uuid);
            resolve(data);
          })
          .catch(({ response }) => {
            context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
            reject(response);
          });
      }
    });
  },
  [ADD_ON_BOARDING_EMPTY_PRODUCT_ACTION](context) {
    context.commit(ADD_ON_BOARDING_EMPTY_PRODUCT_MUTATION);
  },
  async [CANCEL_ON_BOARDING_PRODUCT_ACTION](context, indexToRemove) {
    const productToRemove = context.getters.getOnBoardingProductsActivity.products[indexToRemove];
    if (productToRemove.id === null) {
      context.commit(REMOVE_ON_BOARDING_PRODUCT_MUTATION, indexToRemove);
    }
    await context.dispatch(GET_ON_BOARDING_PRODUCTS_ACTIVITY_ACTION,
      context.getters.getOnBoardingProductsActivity.uuid);
  },
  [REMOVE_ON_BOARDING_PRODUCT_ACTION](context, indexToRemove) {
    return new Promise((resolve, reject) => {
      const productToRemove = context.getters.getOnBoardingProductsActivity.products[indexToRemove];
      onBoardingConnector.deleteProduct(productToRemove.id)
        .then(({ data }) => {
          context.commit(REMOVE_ON_BOARDING_PRODUCT_MUTATION, indexToRemove);
          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [GET_ON_BOARDING_INFORMATION_ACTION](context, uuid) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.getInformation(uuid)
        .then(({ data }) => {
          const informationSociety = Information.fromJson(data);
          context.commit(SET_ON_BOARDING_INFORMATION_MUTATION, informationSociety);
          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [PUT_ON_BOARDING_INFORMATION_ACTION](context, payload) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.putInformation(
        payload.information.toJson(), payload.isContinue,
      )
        .then(async ({ data }) => {
          const informationSociety = Information.fromJson(data);
          context.commit(SET_ON_BOARDING_INFORMATION_MUTATION, informationSociety);

          if (payload.isContinue) {
            await context.dispatch(GET_ON_BOARDING_ACTION);
          }

          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [GET_ON_BOARDING_SOCIETY_TYPES_ACTION](context) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.getSocietyTypes()
        .then(({ data }) => {
          const societyTypes = data['hydra:member'].map((element) => SocietyType.fromJson(element));
          context.commit(SET_ON_BOARDING_SOCIETY_TYPES_MUTATION, societyTypes);
          resolve(societyTypes);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [GET_ON_BOARDING_BANK_ACTION](context, uuid) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.getBank(uuid)
        .then(({ data }) => {
          const bankingInformation = Bank.fromJson(data);
          context.commit(SET_ON_BOARDING_BANK_MUTATION, bankingInformation);
          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject();
        });
    });
  },
  [PUT_ON_BOARDING_BANK_ACTION](context, payload) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.putBank(payload.bank.toJson(), payload.isContinue)
        .then(async ({ data }) => {
          const bankingInformation = Bank.fromJson(data);
          context.commit(SET_ON_BOARDING_BANK_MUTATION, bankingInformation);

          if (payload.isContinue) {
            await context.dispatch(GET_ON_BOARDING_ACTION);
          }

          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject();
        });
    });
  },
  [GET_ON_BOARDING_VOUCHER_ACTION](context, uuid) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.getVoucher(uuid)
        .then(({ data }) => {
          const voucher = Voucher.fromJson(data);
          context.commit(SET_ON_BOARDING_VOUCHER_MUTATION, voucher);
          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject();
        });
    });
  },
  [PUT_ON_BOARDING_VOUCHER_ACTION](context, payload) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.putVoucher(payload.voucher.toJson(), payload.isContinue)
        .then(async ({ data }) => {
          const vouchers = Voucher.fromJson(data);
          context.commit(SET_ON_BOARDING_VOUCHER_MUTATION, vouchers);
          if (payload.isContinue) {
            await context.dispatch(GET_ON_BOARDING_ACTION);
          }

          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [GET_ON_BOARDING_LEGAL_REPRESENTS_ACTIVITY_ACTION](context, uuid) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.getLegalRepresentsActivity(uuid)
        .then(({ data }) => {
          const legalRepresentsActivity = LegalRepresentsActivity.fromJson(data);
          context.commit(
            SET_ON_BOARDING_LEGAL_REPRESENTS_ACTIVITY_MUTATION,
            legalRepresentsActivity,
          );
          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject();
        });
    });
  },
  [PATCH_ON_BOARDING_LEGAL_REPRESENTS_ACTIVITY_ACTION](context, payload) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.putLegalRepresentsActivity(
        payload.legalRepresentsActivity.toJson(), payload.isContinue,
      )
        .then(async ({ data }) => {
          const legalRepresentsActivity = LegalRepresentsActivity.fromJson(data);
          context.commit(
            SET_ON_BOARDING_LEGAL_REPRESENTS_ACTIVITY_MUTATION,
            legalRepresentsActivity,
          );

          if (payload.isContinue) {
            await context.dispatch(GET_ON_BOARDING_ACTION);
          }

          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject();
        });
    });
  },
  [ADD_ON_BOARDING_EMPTY_LEGAL_REPRESENT_ACTION](context) {
    context.commit(ADD_ON_BOARDING_EMPTY_LEGAL_REPRESENT_MUTATION, LegalRepresent.createEmpty());
  },
  async [CANCEL_ON_BOARDING_LEGAL_REPRESENT_ACTION](context, indexToRemove) {
    const legalRepresentsActivity = context.getters.getOnBoardingLegalRepresentsActivity;
    const legalRepresentToRemove = legalRepresentsActivity.legalRepresents[indexToRemove];

    if (legalRepresentToRemove.id === null) {
      context.commit(REMOVE_ON_BOARDING_LEGAL_REPRESENT_MUTATION, indexToRemove);
    }

    await context.dispatch(GET_ON_BOARDING_LEGAL_REPRESENTS_ACTIVITY_ACTION,
      context.getters.getOnBoardingLegalRepresentsActivity.uuid);
  },
  [REMOVE_ON_BOARDING_LEGAL_REPRESENT_ACTION](context, indexToRemove) {
    return new Promise((resolve, reject) => {
      const legalRepresentsActivity = context.getters.getOnBoardingLegalRepresentsActivity;
      const legalRepresentToRemove = legalRepresentsActivity.legalRepresents[indexToRemove];
      onBoardingConnector.deleteLegalRepresent(legalRepresentToRemove.id)
        .then(({ data }) => {
          context.commit(REMOVE_ON_BOARDING_LEGAL_REPRESENT_MUTATION, indexToRemove);
          resolve(data);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [SAVE_ON_BOARDING_LEGAL_REPRESENT_ACTION](context, legalRepresent) {
    return new Promise((resolve, reject) => {
      if (legalRepresent.isNew() === true) {
        onBoardingConnector.postLegalRepresent(legalRepresent.toJson())
          .then(async ({ data }) => {
            await context.dispatch(GET_ON_BOARDING_LEGAL_REPRESENTS_ACTIVITY_ACTION,
              context.getters.getOnBoardingLegalRepresentsActivity.uuid);
            resolve(data);
          })
          .catch(({ response }) => {
            context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
            reject(response);
          });
      } else {
        onBoardingConnector.putLegalRepresent(legalRepresent.toJson())
          .then(async ({ data }) => {
            await context.dispatch(GET_ON_BOARDING_LEGAL_REPRESENTS_ACTIVITY_ACTION,
              context.getters.getOnBoardingLegalRepresentsActivity.uuid);
            resolve(data);
          })
          .catch(({ response }) => {
            context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
            reject(response);
          });
      }
    });
  },
};

const mutations = {
  [ADD_ON_BOARDING_EMPTY_PRODUCT_MUTATION](currentState) {
    currentState.productsActivity.addEmptyProduct();
  },
  [REMOVE_ON_BOARDING_PRODUCT_MUTATION](currentState, indexToRemove) {
    currentState.productsActivity.products = currentState.productsActivity.products.filter(
      (_, i) => i !== indexToRemove,
    );
  },
  [SET_ON_BOARDING_ACTIVITY_MUTATION](currentState, activity) {
    currentState.activity = activity;
  },
  [SET_ON_BOARDING_INFORMATION_MUTATION](currentState, information) {
    currentState.information = information;
  },
  [SET_ON_BOARDING_PRODUCTS_ACTIVITY_MUTATION](currentState, productsActivity) {
    currentState.productsActivity = productsActivity;
  },
  [SET_ON_BOARDING_SOCIETY_TYPES_MUTATION](currentState, societyTypes) {
    currentState.societyTypes = societyTypes;
  },
  [SET_ON_BOARDING_BANK_MUTATION](currentState, bank) {
    currentState.bank = bank;
  },
  [SET_ON_BOARDING_VOUCHER_MUTATION](currentState, voucher) {
    currentState.voucher = voucher;
  },
  [SET_ON_BOARDING_LEGAL_REPRESENTS_ACTIVITY_MUTATION](currentState, legalRepresentsActivity) {
    currentState.legalRepresentsActivity = legalRepresentsActivity;
  },
  [ADD_ON_BOARDING_EMPTY_LEGAL_REPRESENT_MUTATION](currentState) {
    currentState.legalRepresentsActivity.addEmptyLegalRepresent();
  },
  [REMOVE_ON_BOARDING_LEGAL_REPRESENT_MUTATION](currentState, indexToRemove) {
    currentState.legalRepresentsActivity.legalRepresents = currentState
      .legalRepresentsActivity.legalRepresents.filter(
        (_, i) => i !== indexToRemove,
      );
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
