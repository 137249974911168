import { reactive } from 'vue';
import useValidators from '@/app/validators/validator';
import ErrorsCollection from '@/app/model/ErrorsCollection';

const errors = reactive(new ErrorsCollection());
const {
  isEmpty,
  isSocietyNumber,
  isNafCode,
  isPositive,
  isOnlyNumbers,
} = useValidators();

export default function informationFormValidator(information) {
  const validateBusinessNameField = () => {
    const property = 'businessName';
    errors.clearErrors(property);

    if (isEmpty(information.businessName)) {
      return errors.addError(property, 'validation.not_blank');
    }

    return errors;
  };

  const validateSocialReasonField = () => {
    const property = 'socialReason';
    errors.clearErrors(property);

    if (isEmpty(information.socialReason)) {
      return errors.addError(property, 'validation.not_blank');
    }

    return errors;
  };

  const validateSocietyTypeField = () => {
    const property = 'societyType';
    errors.clearErrors(property);

    if (isEmpty(information.societyType)) {
      return errors.addError(property, 'validation.not_blank_select');
    }

    return errors;
  };

  const validateSocietyNumberField = () => {
    const property = 'societyNumber';
    const value = information.societyNumber;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isSocietyNumber(value)) {
      return errors.addError(property, 'validation.invalid_society_number');
    }

    return errors;
  };

  const validateShareCapitalField = () => {
    const property = 'shareCapital';
    const value = information.shareCapital;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isPositive(value)) {
      return errors.addError(property, 'validation.must_positive');
    }

    return errors;
  };

  const validateCountryField = () => {
    const property = 'country';
    errors.clearErrors(property);

    if (isEmpty(information.country)) {
      return errors.addError(property, 'validation.not_blank_select');
    }

    return errors;
  };

  const validateNafCodeField = () => {
    const property = 'nafCode';
    const value = information.nafCode;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isNafCode(value)) {
      return errors.addError(property, 'validation.invalid_naf_code');
    }

    return errors;
  };

  const validateAddressField = () => {
    const property = 'address';
    errors.clearErrors(property);

    if (isEmpty(information.address)) {
      return errors.addError(property, 'validation.not_blank');
    }

    return errors;
  };

  const validatePostalCodeField = () => {
    const property = 'postalCode';
    const value = information.postalCode;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isOnlyNumbers(value)) {
      return errors.addError(property, 'validation.must_only_numbers');
    }

    return errors;
  };

  const validateCityField = () => {
    const property = 'city';
    errors.clearErrors(property);

    if (isEmpty(information.city)) {
      return errors.addError(property, 'validation.not_blank');
    }

    return errors;
  };

  const areValidFields = () => {
    validateBusinessNameField();
    validateSocialReasonField();
    validateSocietyTypeField();
    validateSocietyNumberField();
    validateShareCapitalField();
    validateCountryField();
    validateNafCodeField();
    validateAddressField();
    validatePostalCodeField();
    validateCityField();

    return (errors.errors.length === 0);
  };

  return {
    errors,
    validateBusinessNameField,
    validateSocialReasonField,
    validateSocietyTypeField,
    validateSocietyNumberField,
    validateShareCapitalField,
    validateCountryField,
    validateNafCodeField,
    validateAddressField,
    validatePostalCodeField,
    validateCityField,
    areValidFields,
  };
}
