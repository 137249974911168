import Phone from '@/app/model/Phone';
import Media from '@/app/model/Media';
import IdentificationTypeEnum from '@/app/enum/IdentificationTypeEnum';
import CivilityEnum from '@/app/enum/CivilityEnum';
import PhonePrefixEnum from '@/app/enum/PhonePrefixEnum';

export default class LegalRepresent {
  constructor(
    uuid = null,
    civility = null,
    firstname = null,
    lastname = null,
    phone = Phone.createEmpty(),
    email = null,
    identificationType = null,
    idCardFront = null,
    idCardBack = null,
    onBoardingIri = null,
  ) {
    this.uuid = uuid;
    this.civility = civility;
    this.firstname = firstname;
    this.lastname = lastname;
    this.phone = phone;
    this.email = email;
    this.identificationType = identificationType;
    this.idCardFront = idCardFront;
    this.idCardBack = idCardBack;
    this.onBoardingIri = onBoardingIri;
  }

  isNew() {
    return this.uuid === null;
  }

  isEmpty() {
    return this.civility === null
      && this.firstname === null
      && this.lastname === null
      && this.phone.number === null
      && this.email === null
      && this.identificationType === null
      && this.idCardFront === null
      && this.idCardBack === null;
  }

  getFriendlyCivility() {
    return `${CivilityEnum.getReadableValue(this.civility)}`;
  }

  getFriendlyPhone() {
    return (this.phone.number) ? `${this.phone.prefix}${this.phone.number}` : '';
  }

  getFriendlyIdType() {
    return IdentificationTypeEnum.getReadableValue(this.identificationType);
  }

  isPassportIdType() {
    return this.identificationType === IdentificationTypeEnum.PASSPORT;
  }

  toJson() {
    return {
      uuid: this.uuid,
      civility: this.civility,
      firstname: this.firstname,
      lastname: this.lastname,
      phone: this.phone.toJson(),
      email: this.email,
      identificationType: this.identificationType,
      onBoarding: this.onBoardingIri,
      idCardFront: this.idCardFront instanceof Media ? this.idCardFront.toJson() : null,
      idCardBack: this.idCardBack instanceof Media && this.isPassportIdType() === false
        ? this.idCardBack.toJson() : null,
    };
  }

  static createEmpty(onBoarding) {
    const legalRepresent = new LegalRepresent();
    legalRepresent.phone.prefix = PhonePrefixEnum.fromCountry(onBoarding.company.country);
    legalRepresent.onBoardingIri = onBoarding.iri;
    return legalRepresent;
  }

  static fromJson(json) {
    return new LegalRepresent(
      'uuid' in json ? json.uuid : null,
      'civility' in json ? json.civility : null,
      'firstname' in json ? json.firstname : null,
      'lastname' in json ? json.lastname : null,
      'phone' in json ? Phone.fromJson(json.phone) : null,
      'email' in json ? json.email : null,
      'identificationType' in json ? json.identificationType : null,
      'idCardFront' in json && json.idCardFront !== null ? Media.fromJson(json.idCardFront) : null,
      'idCardBack' in json && json.idCardBack !== null ? Media.fromJson(json.idCardBack) : null,
      'onBoarding' in json && json.onBoarding !== null ? json.onBoarding : null,
    );
  }
}
