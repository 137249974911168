import axios from 'axios';
import jwtDecode from 'jwt-decode';

let tokenInterceptor = null;

const client = axios.create({
  headers: {
    'Content-Type': 'application/ld+json',
  },
});

function setMiddlewareToken(token, apimToken) {
  if (tokenInterceptor !== null) {
    client.interceptors.request.eject(tokenInterceptor);
  }

  tokenInterceptor = client.interceptors.request.use((config) => {
    config.headers.Authorization = `bearer ${apimToken}`;
    config.headers['X-Auth-Token'] = `bearer ${token}`;
    return config;
  });
}

function resetTokenFromClient() {
  client.interceptors.request.eject(tokenInterceptor);
}

function getDecodeToken(token) {
  return jwtDecode(token);
}

const isResponse = (response) => {
  if (response === undefined) {
    return false;
  }

  return 'data' in response && 'status' in response && 'statusText' in response;
};

export default {
  setMiddlewareToken,
  resetTokenFromClient,
  getDecodeToken,
  isResponse,
  client,
};
