import http from '@/app/service/http';

export default {
  changePassword(token, password) {
    return http.client.post(`${process.env.VUE_APP_API_BASE_URL}/api/password-change-requests/reset-password`, {
      token,
      password,
    });
  },
  checkTokenPassword(token) {
    return http.client.get(`${process.env.VUE_APP_API_BASE_URL}/api/password-change-requests/${token}`);
  },
  forgotPassword(email, userLocale) {
    return http.client.post(`${process.env.VUE_APP_API_BASE_URL}/api/password-change-requests`, {
      email,
      userLocale,
    });
  },
  login(email, password) {
    return http.client.post(`${process.env.VUE_APP_API_BASE_URL}/api/authentication-token`, {
      identifier: email,
      password,
    });
  },
  refresh(refresh) {
    return http.client.post(`${process.env.VUE_APP_API_BASE_URL}/api/authentication-token-refresh`, {
      refresh_token: refresh,
    });
  },
  getMe() {
    return http.client.get(`${process.env.VUE_APP_API_BASE_URL}/api/me`, () => ({
      id: '1',
      firstname: 'Jean-Michel',
      lastname: 'Prospect',
      email: 'jean-michel.prospect@floapay.com',
      roles: ['ROLE_PROSPECT_SOCIETY'],
    }));
  },
};
