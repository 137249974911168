<template>
  <!-- eslint-disable max-len vue/max-len  -->
  <svg class="bi bi-chevron-right" width="16" height="16" viewBox="0 0 16 16"
       fill="currentColor" xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z"
          fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconChevronRight',
};
</script>
