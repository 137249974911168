import Home from '../ui/Home.vue';
import Error404 from '../ui/Error404.vue';

export default [
  {
    path: '/:locale/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: '/:locale/:pathMatch(.*)',
    name: 'Error404',
    component: Error404,
    meta: { requiresAuth: false },
  },
];
