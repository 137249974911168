import { reactive } from 'vue';
import useValidators from '@/app/validators/validator';
import ErrorsCollection from '@/app/model/ErrorsCollection';

const errors = reactive(new ErrorsCollection());
const { isEmpty } = useValidators();

export default function productFormValidator(product) {
  const validateCountryField = () => {
    const property = 'country';
    errors.clearErrors(property);

    if (isEmpty(product.country)) {
      return errors.addError(property, 'validation.not_blank_select');
    }

    return errors;
  };

  const validatePaymentMethodsField = () => {
    const property = 'paymentMethods';
    errors.clearErrors(property);

    if (isEmpty(product.paymentMethods)) {
      return errors.addError(property, 'validation.not_blank_select');
    }

    return errors;
  };

  const validateChannelField = () => {
    const property = 'channel';
    errors.clearErrors(property);

    if (isEmpty(product.channel)) {
      return errors.addError(property, 'validation.not_blank_select');
    }

    return errors;
  };

  const validateCustomerFeesField = () => {
    const property = 'customerFees';
    errors.clearErrors(property);

    if (isEmpty(product.customerFees)) {
      return errors.addError(property, 'validation.not_blank_select');
    }

    return errors;
  };

  const validatePaymentCaptureModeField = () => {
    const property = 'paymentCaptureMode';
    errors.clearErrors(property);

    if (isEmpty(product.paymentCaptureMode)) {
      return errors.addError(property, 'validation.not_blank_select');
    }

    return errors;
  };

  const areValidFields = () => {
    validateChannelField();
    validatePaymentMethodsField();
    validateCustomerFeesField();
    validatePaymentCaptureModeField();
    validateCountryField();

    return (errors.errors.length === 0);
  };

  return {
    errors,
    validateChannelField,
    validatePaymentMethodsField,
    validateCustomerFeesField,
    validatePaymentCaptureModeField,
    validateCountryField,
    areValidFields,
  };
}
