<template>
  <div class="row mb-3">
    <div class="col-12">
      <LegalRepresentShow
        v-if="isEdit === false"
        :legal-represent="legalRepresent"
        :index="index"
        :edit="toggleEdit"
      />
      <LegalRepresentForm v-if="isEdit === true"
                          :legal-represent="legalRepresent"
                          :index="index"
                          :cancel="onCancel"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import LegalRepresentForm from '@/onBoardingInternational/ui/components/LegalRepresentForm.vue';
import LegalRepresentShow from '@/onBoardingInternational/ui/components/LegalRepresentShow.vue';
import { CANCEL_ON_BOARDING_INTERNATIONAL_LEGAL_REPRESENT_ACTION } from '@/onBoardingInternational/store/actions';

export default {
  name: 'LegalRepresent',
  components: {
    LegalRepresentForm,
    LegalRepresentShow,
  },
  props: {
    legalRepresent: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const isEdit = ref(true);
    const store = useStore();

    onMounted(() => {
      if (typeof props.legalRepresent.isEmpty === 'function' && props.legalRepresent.isEmpty() === false) {
        isEdit.value = false;
      }
    });

    function toggleEdit() {
      isEdit.value = !isEdit.value;
    }

    function onCancel() {
      isEdit.value = !isEdit.value;
      store.dispatch(CANCEL_ON_BOARDING_INTERNATIONAL_LEGAL_REPRESENT_ACTION, props.index);
    }

    return {
      isEdit,
      toggleEdit,
      onCancel,
    };
  },
};
</script>
