<template>
  <Layout :menu-index="4">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="row mb-3">
        <div class="col-12 col-md-9">
          <h3>
            {{ $t('title.vouchers') }}
          </h3>
        </div>
      </div>

      <div v-if="hasMessage" class="row">
        <div class="col-12 col-md-9">
          <Alert v-for="(message, index) in messages" :key="index" :msg="message" :index="index" />
        </div>
      </div>

      <div v-if="errors" class="row">
        <div class="col-12 col-lg-8">
          <ErrorDisplay :errors="errors" />
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-9">
          <form autocomplete="off"
                novalidate
                @submit.prevent
          >
            <InputMediaOnBoarding
              id="kbis_upload"
              v-model="voucher.kbis"
              :required="true"
              :label="$t('label.kBis_or_extract')"
              :accepted="accepted"
              :errors="validationForm.errors.getErrorsFromProperty('kbis')"
            />
            <InputMediaOnBoarding
              id="status_upload"
              v-model="voucher.status"
              :required="true"
              :label="$t('label.legal_status')"
              :accepted="accepted"
              :errors="validationForm.errors.getErrorsFromProperty('status')"
            />
            <InputMediaOnBoarding
              id="logo_upload"
              v-model="voucher.logo"
              :label="$t('label.logo_optional')"
              :accepted="accepted"
            />
            <InputMediaOnBoarding
              id="tax_package_upload"
              v-model="voucher.tax"
              :label="$t('label.tax_package_optional')"
              :information-label="$t('label.information_tax_package')"
              :accepted="accepted"
            />

            <LegalNotice />

            <div class="mt-3 float-end">
              <button
                type="submit"
                class="btn btn-lg btn-light text-dark rounded-3 me-2"
                @click="onSave"
              >
                {{ $t('action.save') }}
              </button>

              <button
                type="submit"
                class="btn btn-lg btn-primary text-white rounded-3"
                @click="onSaveAndContinue"
              >
                {{ $t('action.continue') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import Layout from '@/onBoarding/ui/components/Layout.vue';
import Loading from '@/app/ui/components/Loading.vue';
import InputMediaOnBoarding from '@/onBoarding/ui/components/form/InputMediaOnBoarding.vue';
import Alert from '@/app/ui/components/Alert.vue';
import LegalNotice from '@/app/ui/components/LegalNotice.vue';
import { ADD_MESSAGE_ACTION, TOGGLE_LOADING_ACTION } from '@/app/store/actions';
import { GET_ON_BOARDING_VOUCHER_ACTION, PUT_ON_BOARDING_VOUCHER_ACTION } from '@/onBoarding/store/activity/actions';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import VoucherFormValidator from '@/onBoarding/validators/activity/voucherFormValidator';
import ErrorDisplay from '@/app/ui/components/form/ErrorDisplay.vue';
import AcceptedFileEnum from '@/app/enum/AcceptedFileEnum';

export default {
  name: 'Voucher',
  components: {
    ErrorDisplay,
    Alert,
    Layout,
    Loading,
    InputMediaOnBoarding,
    LegalNotice,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const onBoarding = ref(store.getters.getOnBoarding);
    const isLoading = computed(() => store.getters.isLoading);
    const hasMessage = computed(() => store.getters.hasMessage);
    const messages = computed(() => store.getters.currentMessages);
    const voucher = computed(() => store.getters.getOnBoardingVoucher);
    const isContinue = ref(false);
    const errors = ref([]);
    const accepted = AcceptedFileEnum.DOCUMENT_ACCEPTED;
    const validationForm = computed(() => VoucherFormValidator(voucher.value));

    onMounted(async () => {
      await store.dispatch(TOGGLE_LOADING_ACTION);
      await store.dispatch(GET_ON_BOARDING_VOUCHER_ACTION,
        onBoarding.value.voucherSocietyUuid);
      await store.dispatch(TOGGLE_LOADING_ACTION);
    });

    function submit() {
      store.dispatch(TOGGLE_LOADING_ACTION);
      store.dispatch(PUT_ON_BOARDING_VOUCHER_ACTION, {
        voucher: voucher.value,
        isContinue: isContinue.value,
      }).then(() => {
        store.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.SUCCESS });
        if (isContinue.value === true) {
          router.push({ name: 'LegalRepresents' });
        }
      }).finally(() => {
        store.dispatch(TOGGLE_LOADING_ACTION);
      });
    }

    function onSave() {
      isContinue.value = false;
      submit();
    }

    function onSaveAndContinue() {
      if (validationForm.value.areValidFields()) {
        isContinue.value = true;
        submit();
      }
    }

    return {
      messages,
      hasMessage,
      isLoading,
      voucher,
      errors,
      accepted,
      onSave,
      validationForm,
      onSaveAndContinue,
    };
  },
};
</script>
