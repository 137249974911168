import Media from '@/app/model/Media';

export default class Bank {
  constructor(
    uuid,
    holder,
    iban,
    bic,
    rib,
    storeNetwork,
  ) {
    this.uuid = uuid;
    this.holder = holder;
    this.iban = iban;
    this.bic = bic;
    this.rib = rib;
    this.storeNetwork = storeNetwork;
  }

  toJson() {
    return {
      uuid: this.uuid,
      accountOwner: this.holder,
      iban: this.iban,
      bic: this.bic,
      rib: this.rib instanceof Media ? this.rib.toJson() : null,
      storeNetwork: this.storeNetwork,
    };
  }

  static createEmpty() {
    return new Bank(
      '',
      '',
      '',
      '',
      '',
      '',
    );
  }

  static fromJson(json) {
    return new Bank(
      'uuid' in json ? json.uuid : null,
      'accountOwner' in json ? json.accountOwner : null,
      'iban' in json ? json.iban : null,
      'bic' in json ? json.bic : null,
      'rib' in json && json.rib !== null ? Media.fromJson(json.rib) : null,
      'storeNetwork' in json ? json.storeNetwork : null,
    );
  }
}
