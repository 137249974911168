<template>
  <Layout :menu-index="1">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="row mb-3">
        <div class="col-12 col-md-9">
          <h2>
            {{ $t('title.activity') }}
          </h2>
        </div>
      </div>

      <div v-if="hasMessage" class="row">
        <div class="col-12 col-md-9">
          <Alert v-for="(message, index) in messages" :key="index" :msg="message" :index="index" />
        </div>
      </div>

      <div v-if="activity" class="row">
        <div class="col-12 col-md-9">
          <form autocomplete="off"
                novalidate
                @submit.prevent
          >
            <InputSelect
              id="activity"
              v-model="activity.activity"
              :required="true"
              :label="$t('label.activity')"
              :placeholder="$t('label.activity')"
              :options="getActivityChoices"
              :validators="[validationForm.validateActivityField]"
              :errors="validationForm.errors.getErrorsFromProperty('activity')"
            />

            <InputTextArea
              id="activity_description"
              v-model="activity.description"
              :required="true"
              :label="$t('label.activity_description')"
              :placeholder="$t('label.activity_description')"
              :validators="[validationForm.validateDescriptionField]"
              :errors="validationForm.errors.getErrorsFromProperty('description')"
            />

            <InputMoney
              id="annual_turnover"
              v-model.number="activity.annualTurnover"
              :required="true"
              :label="$t('label.annual_turnover')"
              :placeholder="$t('label.annual_turnover')"
              :validators="[validationForm.validateAnnualTurnoverField]"
              :errors="validationForm.errors.getErrorsFromProperty('turnover')"
            />

            <CountryTurnovers
              v-model="activity.annualTurnoversByCountry"
              :validators="[validationForm.validateCountryTurnoversField]"
              :errors="validationForm.errors.getErrorsFromProperty('countryTurnovers')"
            />

            <InputPercent
              id="growth"
              v-model="activity.growth"
              suffix="%"
              :label="$t('label.growth')"
              :placeholder="$t('label.growth')"
              :validators="[validationForm.validateGrowthField]"
              :errors="validationForm.errors.getErrorsFromProperty('growth')"
            />

            <InputMoney
              id="average_cart"
              v-model="activity.averageCart"
              :required="true"
              :label="$t('label.average_cart')"
              :placeholder="$t('label.average_cart')"
              :validators="[validationForm.validateAverageCartField]"
              :errors="validationForm.errors.getErrorsFromProperty('average')"
            />

            <InputUrl
              id="website"
              v-model="activity.website"
              :label="$t('label.website')"
              :placeholder="$t('label.website')"
              :validators="[validationForm.validateWebsiteField]"
              :errors="validationForm.errors.getErrorsFromProperty('website')"
            />

            <div class="mt-3 float-end">
              <button
                type="submit"
                class="btn btn-lg btn-light text-dark rounded-3 me-2"
                @click="onSave"
              >
                {{ $t('action.save') }}
              </button>

              <button
                type="submit"
                class="btn btn-lg btn-primary text-white rounded-3"
                @click="onSaveAndContinue"
              >
                {{ $t('action.continue') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Alert from '@/app/ui/components/Alert.vue';
import Layout from '@/onBoarding/ui/components/Layout.vue';
import Loading from '@/app/ui/components/Loading.vue';
import InputMoney from '@/app/ui/components/form/InputMoney.vue';
import InputPercent from '@/app/ui/components/form/InputPercent.vue';
import InputSelect from '@/app/ui/components/form/InputSelect.vue';
import InputTextArea from '@/app/ui/components/form/InputTextArea.vue';
import InputUrl from '@/app/ui/components/form/InputUrl.vue';
import CountryTurnovers from '@/onBoarding/ui/components/CountryTurnovers.vue';
import ActivityEnum from '@/onBoarding/enum/ActivityEnum';
import ActivityFormValidator from '@/onBoarding/validators/activity/activityFormValidator';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import { ADD_MESSAGE_ACTION, TOGGLE_LOADING_ACTION } from '@/app/store/actions';
import { GET_ON_BOARDING_ACTIVITY_ACTION, PUT_ON_BOARDING_ACTIVITY_ACTION } from '@/onBoarding/store/activity/actions';

export default {
  name: 'Activity',
  components: {
    InputPercent,
    Alert,
    Layout,
    InputMoney,
    Loading,
    InputSelect,
    InputTextArea,
    InputUrl,
    CountryTurnovers,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isLoading = computed(() => store.getters.isLoading);
    const onBoarding = ref(store.getters.getOnBoarding);
    const activity = computed(() => store.getters.getOnBoardingActivity);
    const getActivityChoices = computed(() => ActivityEnum.getChoices());
    const validationForm = computed(() => ActivityFormValidator(activity.value));
    const hasMessage = computed(() => store.getters.hasMessage);
    const messages = computed(() => store.getters.currentMessages);
    const isContinue = ref(false);

    onMounted(async () => {
      await store.dispatch(TOGGLE_LOADING_ACTION);
      await store.dispatch(GET_ON_BOARDING_ACTIVITY_ACTION,
        onBoarding.value.informationActivityUuid);
      await store.dispatch(TOGGLE_LOADING_ACTION);
    });

    function submit() {
      store.dispatch(TOGGLE_LOADING_ACTION);
      store.dispatch(PUT_ON_BOARDING_ACTIVITY_ACTION, {
        activity: activity.value,
        isContinue: isContinue.value,
      }).then(() => {
        store.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.SUCCESS });
        if (isContinue.value === true) {
          router.push({ name: 'Products' });
        }
      }).finally(() => {
        store.dispatch(TOGGLE_LOADING_ACTION);
      });
    }

    function onSave() {
      if (validationForm.value.areValidFields()) {
        isContinue.value = false;
        submit();
      }
    }

    function onSaveAndContinue() {
      if (validationForm.value.areValidFields()) {
        isContinue.value = true;
        submit();
      }
    }

    return {
      getActivityChoices,
      isLoading,
      hasMessage,
      onSave,
      onSaveAndContinue,
      activity,
      messages,
      validationForm,
    };
  },
};
</script>
