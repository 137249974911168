import Menu from '@/onBoardingInternational/model/Menu';
import StateEnum from '@/onBoardingInternational/enum/StateEnum';

const PREFIX = '/international';

export default class MenuInterface {
  constructor(
  ) {
    this.activityItems = [

      new Menu(
        `${PREFIX}/company`, 1, 'menu.information',
        { workflowStep: StateEnum.COMPANY },
      ),
      new Menu(
        `${PREFIX}/vouchers`, 2, 'menu.vouchers',
        { workflowStep: StateEnum.VOUCHERS },
      ),
      new Menu(
        `${PREFIX}/legal-represents`, 3, 'menu.legal_representatives',
        { workflowStep: StateEnum.LEGAL_REPRESENTS },
      ),
      new Menu(
        `${PREFIX}/banking`, 4, 'menu.bank',
        { workflowStep: StateEnum.BANKING },
      ),
      new Menu(
        `${PREFIX}/final`, 5, 'menu.final',
        { workflowStep: StateEnum.FINAL },
      ),
    ];
  }

  getItems() {
    return this.activityItems;
  }
}
