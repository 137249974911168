<template>
  <Layout :menu-index="5">
    <Final />
    <div class="m-5 col-md-5 text-center mx-auto">
      <h6 class="final-main">
        {{ $t('final.international.finish') }}
      </h6>
      <p>
        {{ $t('final.international.message_information') }}
      </p>
      <div>
        <div>
          {{ $t('final.international.thanks') }}
        </div>
        <div class="link-success">
          {{ $t('final.international.floa') }}
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/onBoardingInternational/ui/components/Layout.vue';
import Final from '@/onBoardingInternational/ui/components/Final.vue';

export default {
  name: 'FinalInternational',
  components: {
    Final,
    Layout,
  },
};
</script>
