<template>
  <div class="pt-4 mb-3">
    <div class="input-group form-floating">
      <input :id="id"
             v-model="formattedCurrencyValue"
             v-maska="maskInput"
             type="text"
             class="form-control form-control-lg"
             :class="(errors)?'is-invalid':''"
             :placeholder="placeholder"
             @paste="onPasteFormatted"
             @focusout="validateInput"
             @blur="focusOut"
             @focus="focusIn"
      >
      <span v-if="suffix" class="input-group-text fw-bold">
        {{ suffix }}
      </span>
      <label :for="id">
        {{ label }}
      </label>
    </div>

    <ErrorDisplay v-if="errors" :errors="errors" />
  </div>
</template>

<script>
import { reactive, ref, watch } from 'vue';
import { maska } from 'maska';
// eslint-disable-next-line import/extensions
import { formattedValue, reverteFormatted } from '@/app/service/number.js';
import ErrorDisplay from './ErrorDisplay.vue';

export default {
  name: 'InputPercent',
  directives: {
    maska,
  },
  components: {
    ErrorDisplay,
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    validators: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:masked', 'update:modelValue'],
  setup(props, { emit }) {
    const maskInput = ref('');
    const input = ref(props.modelValue);
    const formattedCurrencyValue = reactive(formattedValue(input));
    const validateInput = () => props.validators.forEach((validator) => validator());

    watch(formattedCurrencyValue, (value) => {
      let unformattedValue = value;
      if (typeof value === 'string' || value instanceof String) {
        unformattedValue = reverteFormatted(value);
      }
      emit('update:modelValue', unformattedValue);
    });

    function focusIn() {
      formattedCurrencyValue.value = reverteFormatted(formattedCurrencyValue.value);
      maskInput.value = '#*.##';
    }

    function focusOut() {
      formattedCurrencyValue.value = Number(formattedCurrencyValue.value).toLocaleString('fr', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
      maskInput.value = '';
    }

    function onPasteFormatted(event) {
      formattedCurrencyValue.value = reverteFormatted(event.clipboardData.getData('text'));
    }

    return {
      formattedCurrencyValue,
      input,
      maskInput,
      onPasteFormatted,
      validateInput,
      focusOut,
      focusIn,
    };
  },
};
</script>
