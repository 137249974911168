export const SET_ON_BOARDING_ACTIVITY_MUTATION = 'setOnBoardingActivityMutation';

export const SET_ON_BOARDING_INFORMATION_MUTATION = 'setOnBoardingInformationMutation';

export const SET_ON_BOARDING_PRODUCTS_ACTIVITY_MUTATION = 'setOnBoardingProductsActivityMutation';
export const REMOVE_ON_BOARDING_PRODUCT_MUTATION = 'removeOnBoardingProductMutation';
export const ADD_ON_BOARDING_EMPTY_PRODUCT_MUTATION = 'addOnBoardingEmptyProductMutation';

export const SET_ON_BOARDING_BANK_MUTATION = 'setOnBoardingBank';

export const SET_ON_BOARDING_SOCIETY_TYPES_MUTATION = 'setOnBoardingSocietyTypes';

export const SET_ON_BOARDING_VOUCHER_MUTATION = 'setOnBoardingVoucher';

export const SET_ON_BOARDING_LEGAL_REPRESENTS_ACTIVITY_MUTATION = 'setOnBoardingLegalRepresentsActivityMutation';
export const ADD_ON_BOARDING_EMPTY_LEGAL_REPRESENT_MUTATION = 'addOnBoardingEmptyLegalRepresentMutation';
export const REMOVE_ON_BOARDING_LEGAL_REPRESENT_MUTATION = 'removeOnBoardingLegalRepresentMutation';
