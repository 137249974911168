<template>
  <div class="mb-3">
    <label :for="id" class="form-label text-primary ps-3" :class="{ 'required-star': required }">
      <small>{{ label }}</small>
    </label>
    <div class="input-group w-100 p-0">
      <InputPrefixPhone
        v-model="input.prefix"
        class="w-25"
        @click="validateInput"
      />
      <input :id="id" v-model="input.number" type="text"
             class="form-control form-control-lg w-75"
             :class="(errors)?'is-invalid':''"
             :placeholder="placeholder"
             @focusout="validateInput"
      >
    </div>

    <ErrorDisplay v-if="errors" :errors="errors" />
  </div>
</template>

<script>
import { watch, ref } from 'vue';
import InputPrefixPhone from '@/app/ui/components/form/InputPrefixPhone.vue';
import ErrorDisplay from './ErrorDisplay.vue';

export default {
  name: 'InputPhone',
  components: {
    ErrorDisplay,
    InputPrefixPhone,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    validators: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const input = ref(props.modelValue);
    const validateInput = () => props.validators.forEach((validator) => validator());

    watch(input, (value) => emit('update:modelValue', value), { deep: true });

    return {
      input,
      validateInput,
    };
  },
};
</script>
