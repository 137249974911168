<template>
  <div class="dropdown w-25">
    <button id="dropdownPhonePrefix"
            class="btn border border-light-dark dropdown-toggle btn-lg w-100 rounded-0"
            type="button" data-bs-toggle="dropdown" aria-expanded="false"
    >
      <Flag :code="CountryEnum.fromPrefix(input)" :height="16" /> {{ input }}
    </button>
    <ul class="dropdown-menu text-center" aria-labelledby="dropdownPhonePrefix">
      <li>
        <span class="dropdown-item" @click="setPrefix('+32')">
          <Flag code="BE" :height="16" /> +32
        </span>
      </li>
      <li>
        <span class="dropdown-item" @click="setPrefix('+33')">
          <Flag code="FR" :height="16" /> +33
        </span>
      </li>
      <li>
        <span class="dropdown-item" @click="setPrefix('+34')">
          <Flag code="ES" :height="16" /> +34
        </span>
      </li>
      <li>
        <span class="dropdown-item" @click="setPrefix('+39')">
          <Flag code="IT" :height="16" /> +39
        </span>
      </li>
      <li>
        <span class="dropdown-item" @click="setPrefix('+49')">
          <Flag code="DE" :height="16" /> +49
        </span>
      </li>
      <li>
        <span class="dropdown-item" @click="setPrefix('+31')">
          <Flag code="NL" :height="16" /> +31
        </span>
      </li>
      <li>
        <span class="dropdown-item" @click="setPrefix('+351')">
          <Flag code="PT" :height="16" /> +351
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import Flag from '@/app/ui/components/flag/Flag.vue';
import CountryEnum from '@/app/enum/CountryEnum';

export default {
  name: 'InputPrefixPhone',
  components: {
    Flag,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const input = ref(props.modelValue);
    watch(input, (value) => emit('update:modelValue', value), { deep: true });

    const setPrefix = (prefix) => {
      input.value = prefix;
    };

    return {
      input,
      setPrefix,
      CountryEnum,
    };
  },
};
</script>
