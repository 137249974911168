<template>
  <Layout :menu-index="7">
    <Final />
    <div class="m-5 col-md-5 text-center mx-auto">
      <h6 class="final-main">
        {{
          $t('final.activity.finish')
        }}
      </h6>
      <p>
        {{
          $t('final.activity.message_information')
        }}
      </p>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/onBoarding/ui/components/Layout.vue';
import Final from '@/onBoarding/ui/components/Final.vue';

export default {
  name: 'FinalActivity',
  components: {
    Final,
    Layout,
  },
};
</script>
