<template>
  <svg id="flag-icons-it" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" :height="height">
    <g fill-rule="evenodd" stroke-width="1pt">
      <path fill="#fff" d="M0 0h640v480H0z" />
      <path fill="#009246" d="M0 0h213.3v480H0z" />
      <path fill="#ce2b37" d="M426.7 0H640v480H426.7z" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'It',
  props: {
    height: {
      type: Number,
      default: 16,
    },
  },
};
</script>
