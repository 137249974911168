import { reactive } from 'vue';
import useValidators from '@/app/validators/validator';
import ErrorsCollection from '@/app/model/ErrorsCollection';

const {
  isEmpty,
  isInternationalCompanyNumber,
  isPositive,
  isInternationalPostalCode,
} = useValidators();

const errors = reactive(new ErrorsCollection());

export default function companyFormValidator(onBoardingInternational) {
  const validateCountryField = () => {
    const property = 'country';
    errors.clearErrors(property);

    if (isEmpty(onBoardingInternational.company.country)) {
      return errors.addError(property, 'validation.not_blank_select');
    }

    return errors;
  };

  const validateSocialReasonField = () => {
    const property = 'socialReason';
    const value = onBoardingInternational.company.socialReason;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    return errors;
  };

  const validateBusinessNameField = () => {
    const property = 'businessName';
    const value = onBoardingInternational.company.businessName;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    return errors;
  };

  const validateIdentificationNumberNumberField = () => {
    const property = 'identificationNumber';
    const value = onBoardingInternational.company.identificationNumber;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isInternationalCompanyNumber(value)) {
      return errors.addError(property, 'validation.invalid_international_company_number');
    }

    return errors;
  };

  const validateShareCapitalField = () => {
    const property = 'shareCapital';
    const value = onBoardingInternational.company.shareCapital;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isPositive(value)) {
      return errors.addError(property, 'validation.must_positive');
    }

    return errors;
  };

  const validateAddressField = () => {
    const property = 'address';
    const value = onBoardingInternational.company.address;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    return errors;
  };

  const validateCityField = () => {
    const property = 'city';
    const value = onBoardingInternational.company.city;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    return errors;
  };

  const validatePostalCodeField = () => {
    const property = 'postalCode';
    const value = onBoardingInternational.company.postalCode;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isInternationalPostalCode(value)) {
      return errors.addError(property, 'validation.invalid_internation_postal');
    }

    return errors;
  };

  const areValidFields = () => {
    validateSocialReasonField();
    validateBusinessNameField();
    validateIdentificationNumberNumberField();
    validateShareCapitalField();
    validateAddressField();
    validateCityField();
    validatePostalCodeField();
    validateCountryField();

    return errors.hasErrors() === false;
  };

  return {
    errors,
    validateBusinessNameField,
    validateSocialReasonField,
    validateIdentificationNumberNumberField,
    validateShareCapitalField,
    validateAddressField,
    validateCityField,
    validatePostalCodeField,
    validateCountryField,
    areValidFields,
  };
}
