<template>
  <div class="row row-cols-auto align-items-start gx-2 turnover-details">
    <div class="col-12 col-md-5">
      <InputSelect
        id="country"
        v-model="countryTurnoverForm.country"
        :required="true"
        :label="$t('label.country')"
        :placeholder="$t('placeholder.country')"
        :options="getCountriesChoices"
        :validators="[validationForm.validateCountryField].concat(validators)"
        :errors="validationForm.errors.getErrorsFromProperty(
          `country-${countryTurnoverForm.uniquIndex}`
        )"
      />
    </div>
    <div class="col-12 col-md-6">
      <InputMoney
        id="annual_turnover"
        v-model="countryTurnoverForm.turnover"
        :required="true"
        :label="$t('label.annual_turnover')"
        :placeholder="$t('label.annual_turnover')"
        :validators="[validationForm.validateTurnoverField].concat(validators)"
        :errors="validationForm.errors.getErrorsFromProperty(
          `turnover-${countryTurnoverForm.uniquIndex}`
        )"
      />
    </div>
    <div class="col-12 col-md-1">
      <div class="pt-4 mb-3 form-floating">
        <button
          type="button"
          class="btn btn-lg btn-outline-secondary text-secondary
        border-secondary px-2 turnover-details-remove form-control form-control-lg"
          @click="remove(countryTurnoverForm)"
        >
          <IconX width="24" height="24" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import InputMoney from '@/app/ui/components/form/InputMoney.vue';
import InputSelect from '@/app/ui/components/form/InputSelect.vue';
import IconX from '@/app/ui/components/icon/IconX.vue';
import CountryEnum from '@/app/enum/CountryEnum';
import CountryTurnoverFormValidator from '@/onBoarding/validators/activity/countryTurnoverFormValidator';

export default {
  name: 'CountryTurnovers',
  components: {
    InputMoney,
    InputSelect,
    IconX,
  },
  props: {
    countryTurnover: {
      type: Object,
      default: () => {},
    },
    remove: {
      type: Function,
      default: () => {},
    },
    validators: {
      type: Array,
      default: () => {},
    },
  },
  setup(props) {
    const validationForm = computed(() => CountryTurnoverFormValidator(props.countryTurnover));
    const countryTurnoverForm = ref(props.countryTurnover);
    const getCountriesChoices = computed(() => CountryEnum.getChoices());

    return {
      getCountriesChoices,
      countryTurnoverForm,
      validationForm,
    };
  },
};
</script>
