<template>
  <Layout>
    <div class="row">
      <div class="col d-flex justify-content-center">
        <h1>404 Not found</h1>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/app/ui/components/Layout.vue';

export default {
  name: 'Error404',
  components: {
    Layout,
  },
};
</script>
