<template>
  <div class="row mb-3">
    <div class="col-12">
      <h5>{{ $t('label.payment_facility') }}</h5>
    </div>
    <div class="col-12">
      <div class="row row-cols-auto align-items-center gx-2 turnover-details">
        <div class="col-8">
          {{ $t('label.have_payment_facility') }}
        </div>
        <div class="col-4">
          <InputGroupRadio
            id="have_payment_facility"
            v-model="paymentFacilityForm.enable"
            :choices="getChoices"
            @update:modelValue="changeHavePayment()"
          />
        </div>
      </div>
    </div>
    <div v-if="paymentFacilityForm.enable === true" class="col-12">
      <InputText
        id="solution_use"
        :key="componentUniqueKey"
        v-model="paymentFacilityForm.label"
        :required="true"
        :label="$t('label.solution_use')"
        :placeholder="$t('label.solution_use')"
        :validators="[validationForm.validateLabelField]"
        :errors="validationForm.errors.getErrorsFromProperty('label')"
      />
      <div class="row row-cols-auto align-items-center gx-2 turnover-details">
        <ErrorDisplay v-if="errors" :errors="errors" />
        <div class="col-6 mt-4">
          <InputCheckbox
            id="payment_type_2x"
            v-model="paymentFacilityForm.lowerBoundary"
            :label="$t('label.payment_under_4x')"
            :validators="[validationForm.validateLowerBoundary]"
          />
        </div>
        <div class="col-6">
          <InputMoney
            id="payment_type_2x_basket"
            v-model="paymentFacilityForm.lowerBoundaryValue"
            :label="$t('label.average_basket')"
            :placeholder="$t('label.average_basket')"
            :validators="[validationForm.validateLowerBoundaryValueField]"
            :errors="validationForm.errors.getErrorsFromProperty('lowerBoundaryValue')"
          />
        </div>
      </div>
      <div class="row row-cols-auto align-items-center gx-2 turnover-details">
        <div class="col-6 mt-4">
          <InputCheckbox
            id="payment_type_3x"
            v-model="paymentFacilityForm.upperBoundary"
            :label="$t('label.payment_upper_4x')"
            :validators="[validationForm.validateUpperBoundary]"
          />
        </div>
        <div class="col-6">
          <InputMoney
            id="payment_type_3x_basket"
            v-model="paymentFacilityForm.upperBoundaryValue"
            :label="$t('label.average_basket')"
            :placeholder="$t('label.average_basket')"
            :validators="[validationForm.validateUpperBoundaryValueField]"
            :errors="validationForm.errors.getErrorsFromProperty('upperBoundaryValue')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref } from 'vue';
import ErrorDisplay from '@/app/ui/components/form/ErrorDisplay.vue';
import InputMoney from '@/app/ui/components/form/InputMoney.vue';
import InputCheckbox from '@/app/ui/components/form/InputCheckbox.vue';
import InputText from '@/app/ui/components/form/InputText.vue';
import InputGroupRadio from '@/app/ui/components/form/InputGroupRadio.vue';
import YesNoEnum from '@/app/enum/YesNoEnum';
import PaymentFacilityFormValidator from '@/onBoarding/validators/society/paymentFacilityFormValidator';

export default {
  name: 'LegalRepresent',
  components: {
    InputMoney,
    InputCheckbox,
    InputText,
    InputGroupRadio,
    ErrorDisplay,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {
      },
    },
    errors: {
      type: Array,
      default: () => null,
    },
  },
  setup(props) {
    const validationForm = computed(() => PaymentFacilityFormValidator(props.modelValue));
    const getChoices = computed(() => YesNoEnum.getChoices());
    const paymentFacilityForm = reactive(props.modelValue);
    const componentUniqueKey = ref(Date.now());

    function changeHavePayment() {
      componentUniqueKey.value = Date.now();
    }

    return {
      getChoices,
      paymentFacilityForm,
      validationForm,
      componentUniqueKey,
      changeHavePayment,
    };
  },
};
</script>
