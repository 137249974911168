<template>
  <Be v-if="code === 'BE'" :height="height" />
  <Fr v-if="code === 'FR'" :height="height" />
  <Es v-if="code === 'ES'" :height="height" />
  <Nl v-if="code === 'NL'" :height="height" />
  <En v-if="code === 'EN'" :height="height" />
  <It v-if="code === 'IT'" :height="height" />
  <Pt v-if="code === 'PT'" :height="height" />
  <De v-if="code === 'DE'" :height="height" />
</template>

<script>
import Be from './Be.vue';
import Fr from './Fr.vue';
import Nl from './Nl.vue';
import Es from './Es.vue';
import En from './En.vue';
import It from './It.vue';
import Pt from './Pt.vue';
import De from './De.vue';

export default {
  name: 'Flag',
  components: {
    Be,
    Fr,
    Nl,
    Es,
    En,
    Pt,
    It,
    De,
  },
  props: {
    code: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 16,
    },
  },
};
</script>
