import { i18n } from '@/app/service/i18n';

const ActivityEnum = {
  AUTO_MOTO_PIECES_DETACHEES: 'AUTO_MOTO_PIECES_DETACHEES',
  AUTO_MOTO_SERVICES_AUTOMOBILES: 'AUTO_MOTO_SERVICES_AUTOMOBILES',
  AUTO_MOTO_VEHICULE_ENTRETIEN: 'AUTO_MOTO_VEHICULE_ENTRETIEN',
  AUTRES_SERVICES: 'AUTRES_SERVICES',
  BEAUTY: 'BEAUTY',
  CHAUSSURES: 'CHAUSSURES',
  DECO_MOBILIER: 'DECO_MOBILIER',
  ENERGY_SUPPLIER: 'ENERGY_SUPPLIER',
  FIOUL: 'FIOUL',
  FOOD: 'FOOD',
  FORMATION: 'FORMATION',
  HIGH_TECH: 'HIGH_TECH',
  JEUX_VIDEO: 'JEUX_VIDEO',
  JEWELRY: 'JEWELRY',
  MAISON_EQUIPEMENT: 'MAISON_EQUIPEMENT',
  MAISON_EXTERIEUR: 'MAISON_EXTERIEUR',
  MEDICAL_AUTRES: 'MEDICAL_AUTRES',
  MEDICAL_SANTE_ANIMAL: 'MEDICAL_SANTE_ANIMAL',
  MODE: 'MODE',
  NON_REFERENCE: 'NON_REFERENCE',
  OCCASION: 'OCCASION',
  OPTICAL_HEALTH: 'OPTICAL_HEALTH',
  POMPES_FUNEBRES: 'POMPES_FUNEBRES',
  RELOCATION: 'RELOCATION',
  SOLUTIONS_PAIEMENT: 'SOLUTIONS_PAIEMENT',
  SPORT_GENERALISTES: 'SPORT_GENERALISTES',
  SPORT_SPECIALISTES: 'SPORT_SPECIALISTES',
  TELEPHONIE: 'TELEPHONIE',
  TOURISME_CROISIERES: 'TOURISME_CROISIERES',
  TOURISME_LOCATION_VEHICULE: 'TOURISME_LOCATION_VEHICULE',
  TOURISME_RESIDENCE_PLEIN_AIR: 'TOURISME_RESIDENCE_PLEIN_AIR',
  TOURISME_RESIDENCES_HOTELS: 'TOURISME_RESIDENCES_HOTELS',
  TOURISME_VOLS_SEJOURS: 'TOURISME_VOLS_SEJOURS',
  TRANSPORT_MARITIME: 'TRANSPORT_MARITIME',

  getChoices() {
    return [
      { value: this.FOOD, label: i18n.global.t('label.food') },
      { value: this.AUTO_MOTO_PIECES_DETACHEES, label: i18n.global.t('label.auto_moto_pieces_detachees') },
      { value: this.AUTO_MOTO_SERVICES_AUTOMOBILES, label: i18n.global.t('label.auto_moto_services_automobiles') },
      { value: this.AUTO_MOTO_VEHICULE_ENTRETIEN, label: i18n.global.t('label.auto_moto_vehicule_entretien') },
      { value: this.AUTRES_SERVICES, label: i18n.global.t('label.autres_services') },
      { value: this.BEAUTY, label: i18n.global.t('label.beauty') },
      { value: this.JEWELRY, label: i18n.global.tc('label.jewelry') },
      { value: this.CHAUSSURES, label: i18n.global.tc('label.chaussures') },
      { value: this.DECO_MOBILIER, label: i18n.global.tc('label.deco_mobilier') },
      { value: this.RELOCATION, label: i18n.global.t('label.relocation') },
      { value: this.ENERGY_SUPPLIER, label: i18n.global.t('label.energy_supplier') },
      { value: this.FIOUL, label: i18n.global.t('label.fioul') },
      { value: this.FORMATION, label: i18n.global.t('label.formation') },
      { value: this.HIGH_TECH, label: i18n.global.tc('label.high_tech') },
      { value: this.JEUX_VIDEO, label: i18n.global.tc('label.jeux_video') },
      { value: this.MAISON_EQUIPEMENT, label: i18n.global.tc('label.maison_equipement') },
      { value: this.MAISON_EXTERIEUR, label: i18n.global.tc('label.maison_exterieur') },
      { value: this.MEDICAL_AUTRES, label: i18n.global.tc('label.medical_autres') },
      { value: this.MODE, label: i18n.global.tc('label.mode') },
      { value: this.OPTICAL_HEALTH, label: i18n.global.tc('label.optical_health') },
      { value: this.MEDICAL_SANTE_ANIMAL, label: i18n.global.tc('label.medical_sante_animal') },
      { value: this.NON_REFERENCE, label: i18n.global.tc('label.non_reference') },
      { value: this.OCCASION, label: i18n.global.tc('label.occasion') },
      { value: this.POMPES_FUNEBRES, label: i18n.global.tc('label.pompes_funebres') },
      { value: this.SOLUTIONS_PAIEMENT, label: i18n.global.tc('label.solutions_de_paiement') },
      { value: this.SPORT_GENERALISTES, label: i18n.global.tc('label.sport_generalistes') },
      { value: this.SPORT_SPECIALISTES, label: i18n.global.tc('label.sport_specialistes') },
      { value: this.TELEPHONIE, label: i18n.global.tc('label.telephonie') },
      { value: this.TOURISME_CROISIERES, label: i18n.global.tc('label.tourisme_croisieres') },
      { value: this.TOURISME_LOCATION_VEHICULE, label: i18n.global.tc('label.tourisme_location_de_vehicule') },
      { value: this.TOURISME_RESIDENCE_PLEIN_AIR, label: i18n.global.tc('label.tourisme_residence_de_plein_air') },
      { value: this.TOURISME_RESIDENCES_HOTELS, label: i18n.global.tc('label.tourisme_residences_hotels') },
      { value: this.TOURISME_VOLS_SEJOURS, label: i18n.global.tc('label.tourisme_vols_sejours') },
      { value: this.TRANSPORT_MARITIME, label: i18n.global.tc('label.transport_maritime') },
    ];
  },
};

export default ActivityEnum;
