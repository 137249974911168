<template>
  <div class="final-bandeaux mt-n5 mx-n5 text-center">
    <img
      src="@/app/ui/components/background/BackgroundFinal.png"
      :alt="$t('alt.logo')"
      style="max-height: 120%"
    >
  </div>

  <div class="m-5 col-md-5 mx-auto text-center">
    <h1 class="final-header heading">
      {{
        $t('final.congratulations')
      }}
    </h1>
  </div>
</template>

<script>

export default {
  name: 'Final',
  components: {
  },
};
</script>
