<template>
  <div class="row border-start">
    <form autocomplete="off" novalidate @submit.prevent>
      <div class="col-12">
        <InputSelect
          id="civility"
          v-model="legalRepresentForm.civility"
          :label="$t('label.civility')"
          :placeholder="$t('placeholder.civility')"
          :options="getCivilitiesChoices"
          :validators="[validationForm.validateCivilityField]"
          :errors="validationForm.errors.getErrorsFromProperty('civility')"
          :required="true"
        />
        <InputText
          id="firstname"
          v-model="legalRepresentForm.firstname"
          :required="true"
          :label="$t('label.firstname')"
          :placeholder="$t('placeholder.firstname')"
          :validators="[validationForm.validateFirstnameField]"
          :errors="validationForm.errors.getErrorsFromProperty('firstName')"
        />

        <InputText
          id="lastname"
          v-model="legalRepresentForm.lastname"
          :required="true"
          :label="$t('label.lastname')"
          :placeholder="$t('placeholder.lastname')"
          :validators="[validationForm.validateLastNameField]"
          :errors="validationForm.errors.getErrorsFromProperty('lastName')"
        />

        <InputPhone
          id="phone"
          v-model="legalRepresentForm.phone"
          :required="true"
          :label="$t('label.phone')"
          :placeholder="$t('placeholder.phone')"
          :validators="[validationForm.validatePhoneField]"
          :errors="validationForm.errors.getErrorsFromProperty('phone')"
        />

        <InputEmail
          id="email"
          v-model="legalRepresentForm.email"
          :label="$t('label.email')"
          :placeholder="$t('label.email')"
          :required="true"
          :validators="[validationForm.validateEmailField]"
          :errors="validationForm.errors.getErrorsFromProperty('email')"
        />

        <InputSelect
          id="identificationType"
          v-model="legalRepresentForm.identificationType"
          :required="true"
          :label="$t('label.identity_document')"
          :placeholder="$t('label.identity_document')"
          :options="getIdentificationTypeChoices"
          :validators="[validationForm.validateIdentificationTypeField]"
          :errors="validationForm.errors.getErrorsFromProperty('identificationType')"
        />

        <InputMediaOnBoardingInternational
          id="idCardFront"
          v-model="legalRepresentForm.idCardFront"
          :required="true"
          :label="$t('label.id_card_front')"
          :accepted="accepted"
          :errors="validationForm.errors.getErrorsFromProperty('idCardFront')"
        />

        <InputMediaOnBoardingInternational
          v-if="!legalRepresentForm.isPassportIdType()"
          id="idCardBack"
          v-model="legalRepresentForm.idCardBack"
          :required="true"
          :label="$t('label.id_card_back')"
          :accepted="accepted"
          :errors="validationForm.errors.getErrorsFromProperty('idCardBack')"
        />
      </div>

      <div class="col-12 mb-3">
        <button type="submit"
                class="btn btn-lg btn-success text-dark rounded-3 me-2"
                @click="onSaveLegalRepresent"
        >
          {{ $t('action.add') }}
        </button>

        <button class="btn btn-lg btn-light text-dark rounded-3 me-2"
                @click="cancel"
        >
          {{ $t('action.cancel') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';
import CivilityEnum from '@/app/enum/CivilityEnum';
import InputText from '@/app/ui/components/form/InputText.vue';
import InputEmail from '@/app/ui/components/form/InputEmail.vue';
import InputMediaOnBoardingInternational from '@/onBoardingInternational/ui/components/form/InputMediaOnBoardingInternational.vue';
import InputSelect from '@/app/ui/components/form/InputSelect.vue';
import InputPhone from '@/app/ui/components/form/InputPhone.vue';
import { ADD_MESSAGE_ACTION } from '@/app/store/actions';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import { SAVE_ON_BOARDING_INTERNATIONAL_LEGAL_REPRESENT_ACTION } from '@/onBoardingInternational/store/actions';
import LegalRepresentFormValidator from '@/onBoardingInternational/validators/legalRepresentFormValidator';
import IdentificationTypeEnum from '@/app/enum/IdentificationTypeEnum';
import AcceptedFileEnum from '@/app/enum/AcceptedFileEnum';

export default {
  name: 'LegalRepresentForm',
  components: {
    InputPhone,
    InputEmail,
    InputMediaOnBoardingInternational,
    InputText,
    InputSelect,
  },
  props: {
    legalRepresent: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    cancel: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const validationForm = computed(() => LegalRepresentFormValidator(props.legalRepresent));
    const legalRepresentForm = reactive(props.legalRepresent);
    const getIdentificationTypeChoices = computed(() => IdentificationTypeEnum.getChoices());
    const accepted = AcceptedFileEnum.DOCUMENT_ACCEPTED;
    const getCivilitiesChoices = computed(() => CivilityEnum.getChoices());

    function onSaveLegalRepresent() {
      if (validationForm.value.areValidFields()) {
        store.dispatch(SAVE_ON_BOARDING_INTERNATIONAL_LEGAL_REPRESENT_ACTION, legalRepresentForm)
          .then(() => {
            store.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.SUCCESS });
            props.cancel();
          });
      }
    }

    return {
      accepted,
      getCivilitiesChoices,
      getIdentificationTypeChoices,
      legalRepresentForm,
      onSaveLegalRepresent,
      validationForm,
    };
  },
};
</script>
