import { reactive } from 'vue';
import array from '@/app/service/array';
import useValidators from '@/app/validators/validator';
import ErrorsCollection from '@/app/model/ErrorsCollection';
import CountryTurnoverFormValidator from '@/onBoarding/validators/activity/countryTurnoverFormValidator';

const errors = reactive(new ErrorsCollection());
const {
  isEmpty,
  isUrl,
  isPositive,
  isInteger,
} = useValidators();

export default function activityFormValidator(activity) {
  const validateActivityField = () => {
    const property = 'activity';
    errors.clearErrors(property);

    if (isEmpty(activity.activity)) {
      return errors.addError(property, 'validation.not_blank_select');
    }

    return errors;
  };

  const validateDescriptionField = () => {
    const property = 'description';
    errors.clearErrors(property);

    if (isEmpty(activity.description)) {
      return errors.addError(property, 'validation.not_blank');
    }

    return errors;
  };

  const validateAverageCartField = () => {
    const property = 'average';
    const value = activity.averageCart;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isPositive(value)) {
      return errors.addError(property, 'validation.must_positive');
    }

    if (!isInteger(value)) {
      return errors.addError(property, 'validation.must_integer');
    }

    return errors;
  };

  const validateGrowthField = () => {
    const property = 'growth';
    const value = activity.growth;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    return errors;
  };

  const validateWebsiteField = () => {
    const property = 'website';
    const value = activity.website;
    errors.clearErrors(property);

    if (!isEmpty(value) && !isUrl(value)) {
      return errors.addError(property, 'validation.invalid_url');
    }

    return errors;
  };

  const validateCountryTurnoversField = async () => {
    let total = null;
    const countries = [];
    const property = 'countryTurnovers';
    const fieldValue = activity.annualTurnoversByCountry;
    errors.clearErrors(property);

    fieldValue.forEach((countryTurnover) => {
      countries.push(countryTurnover.country);

      if (countryTurnover.turnover) {
        total += countryTurnover.turnover;
      }

      const countryTurnoverValidators = CountryTurnoverFormValidator(countryTurnover);

      if (isEmpty(countryTurnover.country)) {
        countryTurnoverValidators.validateCountryField();
        errors.addError(property, '');
      }

      if (isEmpty(countryTurnover.turnover)) {
        countryTurnoverValidators.validateTurnoverField();
        errors.addError(property, '');
      }
    });

    if (total !== null && total !== activity.annualTurnover) {
      return errors.addError(property, 'validation.must_be_same_as_country');
    }
    if (array.hasDuplicate(countries)) {
      return errors.addError(property, 'validation.should_not_contain_same_country');
    }

    return errors;
  };

  const validateAnnualTurnoverField = async () => {
    const property = 'turnover';
    const value = activity.annualTurnover;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isPositive(value)) {
      return errors.addError(property, 'validation.must_positive');
    }

    if (!isInteger(value)) {
      return errors.addError(property, 'validation.must_integer');
    }

    await validateCountryTurnoversField();

    return errors;
  };

  const areValidFields = () => {
    validateActivityField();
    validateDescriptionField();
    validateAnnualTurnoverField();
    validateAverageCartField();
    validateWebsiteField();
    validateCountryTurnoversField();
    validateGrowthField();

    return (errors.errors.length === 0);
  };

  return {
    errors,
    validateActivityField,
    validateDescriptionField,
    validateAnnualTurnoverField,
    validateAverageCartField,
    validateWebsiteField,
    validateCountryTurnoversField,
    validateGrowthField,
    areValidFields,
  };
}
