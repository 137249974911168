import { createStore, createLogger } from 'vuex';
import onBoarding from '@/onBoarding/store';
import onBoardingActivity from '@/onBoarding/store/activity/store';
import onBoardingSociety from '@/onBoarding/store/society/store';
import onBoardingSellingPoint from '@/onBoardingSellingPoint/store';
import onBoardingInternational from '@/onBoardingInternational/store';
import security from '@/security/store';
import shared from '@/app/store/shared';

export default createStore({
  modules: {
    onBoarding,
    onBoardingActivity,
    onBoardingSociety,
    onBoardingSellingPoint,
    onBoardingInternational,
    security,
    shared,
  },
  plugins: process.env.NODE_ENV !== 'production'
    ? [createLogger()]
    : [],
});
