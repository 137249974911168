<template>
  <Layout :menu-index="1">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="row mb-3">
        <div class="col-12 col-md-9">
          <h2>{{ $t('title.commercial') }}</h2>
        </div>
      </div>

      <div v-if="hasMessage" class="row">
        <div class="col-12 col-md-9">
          <Alert v-for="(message, index) in messages" :key="index" :msg="message" :index="index" />
        </div>
      </div>

      <div v-if="commercialActivity" class="row">
        <div class="col-12 col-md-9">
          <form autocomplete="off"
                novalidate
                @submit.prevent
          >
            <ContactsInformation
              v-model="commercialActivity.contacts"
              :is-web-activity="onBoarding.isWebActivity"
              :label="$t('label.contact_information')"
              :errors="validationForm.errors.getErrorsFromProperty('contacts')"
            />

            <LegalNotice />

            <hr class="my-5">

            <PaymentFacility
              v-model="commercialActivity.paymentFacility"
              :errors="validationForm.errors.getErrorsFromProperty('paymentFacility')"
            />

            <div class="mt-3 float-end">
              <button
                type="submit"
                class="btn btn-lg btn-light text-dark rounded-3 me-2"
                @click="onSave"
              >
                {{ $t('action.save') }}
              </button>

              <button type="submit"
                      class="btn btn-lg btn-primary text-white rounded-3"
                      @click="onSaveAndContinue"
              >
                {{ $t('action.continue') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import Layout from '@/onBoarding/ui/components/Layout.vue';
import Alert from '@/app/ui/components/Alert.vue';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import Loading from '@/app/ui/components/Loading.vue';
import LegalNotice from '@/app/ui/components/LegalNotice.vue';
import { ADD_MESSAGE_ACTION, TOGGLE_LOADING_ACTION } from '@/app/store/actions';
import { GET_ON_BOARDING_COMMERCIAL_ACTIVITY_ACTION, PATCH_ON_BOARDING_COMMERCIAL_ACTIVITY_ACTION } from '@/onBoarding/store/society/actions';
import ContactsInformation from '@/onBoarding/ui/components/ContactsInformation.vue';
import PaymentFacility from '@/onBoarding/ui/components/PaymentFacility.vue';
import CommercialFormValidator from '@/onBoarding/validators/society/commercialFormValidator';

export default {
  name: 'Commercial',
  components: {
    ContactsInformation,
    PaymentFacility,
    Layout,
    Loading,
    Alert,
    LegalNotice,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const onBoarding = ref(store.getters.getOnBoarding);
    const isLoading = computed(() => store.getters.isLoading);
    const messages = computed(() => store.getters.currentMessages);
    const commercialActivity = computed(() => store.getters.getOnBoardingCommercialActivity);
    const validationForm = computed(() => CommercialFormValidator(commercialActivity.value));
    const hasMessage = computed(() => store.getters.hasMessage);
    const isContinue = ref(false);

    onMounted(async () => {
      await store.dispatch(TOGGLE_LOADING_ACTION);
      await store.dispatch(GET_ON_BOARDING_COMMERCIAL_ACTIVITY_ACTION,
        onBoarding.value.commercialActivityUuid);
      await store.dispatch(TOGGLE_LOADING_ACTION);
    });

    function submit() {
      store.dispatch(TOGGLE_LOADING_ACTION);
      store.dispatch(PATCH_ON_BOARDING_COMMERCIAL_ACTIVITY_ACTION, {
        commercialActivity: commercialActivity.value,
        isContinue: isContinue.value,
      }).then(() => {
        store.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.SUCCESS });
        if (isContinue.value === true) {
          router.push({ name: 'WebEnvironment' });
        }
      }).finally(() => {
        store.dispatch(TOGGLE_LOADING_ACTION);
      });
    }

    function onSave() {
      isContinue.value = false;
      submit();
    }

    function onSaveAndContinue() {
      if (validationForm.value.areValidFields()) {
        isContinue.value = true;
        submit();
      }
    }

    return {
      isLoading,
      commercialActivity,
      hasMessage,
      messages,
      onSave,
      onSaveAndContinue,
      validationForm,
      onBoarding,
    };
  },
};
</script>
