import { reactive } from 'vue';
import useValidators from '@/app/validators/validator';
import ErrorsCollection from '@/app/model/ErrorsCollection';

const {
  isEmpty,
  isValidString,
  isValidPhone,
  isValidPrefixPhone,
  isValidEmail,
} = useValidators();

const errors = reactive(new ErrorsCollection());

export default function contactFormValidator(contact) {
  const validateCivilityField = () => {
    const property = 'civility';
    errors.clearErrors(property);

    if (isEmpty(contact.civility)) {
      return errors.addError(property, 'validation.not_blank_select');
    }

    return errors;
  };

  const validateLastNameField = () => {
    const property = 'lastname';
    const value = contact.lastname;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isValidString(value)) {
      return errors.addError(property, 'validation.must_not_contain_special_characters');
    }

    return errors;
  };

  const validateFirstNameField = () => {
    const property = 'firstname';
    const value = contact.firstname;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isValidString(value)) {
      return errors.addError(property, 'validation.must_not_contain_special_characters');
    }

    return errors;
  };

  const validatePhoneField = () => {
    const property = 'phone';
    const value = contact.phone;
    errors.clearErrors(property);

    if (isEmpty(value.number) && isEmpty(contact.mobilePhone.number)) {
      return errors.addError(property, 'validation.need_one_phone');
    }

    if (!isEmpty(value.number) && !isValidPhone(value)) {
      return errors.addError(property, 'validation.invalid_phone');
    }

    if (!isValidPrefixPhone(value)) {
      return errors.addError(property, 'validation.invalid_prefix_phone');
    }

    return errors;
  };

  const validateMobilePhoneField = () => {
    const property = 'mobilePhone';
    const value = contact.mobilePhone;
    errors.clearErrors(property);

    if (isEmpty(value.number) && isEmpty(contact.phone.number)) {
      return errors.addError(property, 'validation.need_one_phone');
    }

    if (!isEmpty(value.number) && !isValidPhone(value)) {
      return errors.addError(property, 'validation.invalid_phone');
    }

    if (!isValidPrefixPhone(value)) {
      return errors.addError(property, 'validation.invalid_prefix_phone');
    }

    return errors;
  };

  const validateEmailField = () => {
    const property = 'email';
    const value = contact.email;
    errors.clearErrors(property);

    if (isEmpty(value)) {
      return errors.addError(property, 'validation.not_blank');
    }

    if (!isValidEmail(value)) {
      return errors.addError(property, 'validation.invalid_email');
    }

    return errors;
  };

  const areValidFields = () => {
    validateCivilityField();
    validateLastNameField();
    validateFirstNameField();
    validatePhoneField();
    validateMobilePhoneField();
    validateEmailField();
    return (errors.errors.length === 0);
  };

  return {
    errors,
    validateCivilityField,
    validateLastNameField,
    validatePhoneField,
    validateMobilePhoneField,
    validateFirstNameField,
    validateEmailField,
    areValidFields,
  };
}
