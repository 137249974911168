import onBoardingConnector from '@/onBoarding/connector/onBoarding';
import OnBoarding from '@/onBoarding/model/OnBoarding';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import MenuInterface from '@/onBoarding/model/MenuInterface';
import { ADD_MESSAGE_ACTION } from '@/app/store/actions';
import {
  RESET_ON_BOARDING_ACTION,
  GET_ON_BOARDING_ACTION,
  ADD_ON_BOARDING_ACTION,
  HEALTHCHECK_BOARDING_ACTION,
  GOTO_STEP_ON_BOARDING_ACTION,
} from '@/onBoarding/store/actions';

import {
  RESET_ON_BOARDING_MUTATION,
  SET_ON_BOARDING_MUTATION,
} from '@/onBoarding/store/mutations';

const state = {
  onBoarding: null,
  menuInterface: new MenuInterface(),
};

const getters = {
  getOnBoarding(currentState) {
    return currentState.onBoarding;
  },
  getMenuInterface(currentState) {
    return currentState.menuInterface;
  },
};

const actions = {
  [HEALTHCHECK_BOARDING_ACTION]() {
    return new Promise((resolve, reject) => {
      onBoardingConnector.healthcheck()
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GOTO_STEP_ON_BOARDING_ACTION](context, step) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.postStepBoarding(context.getters.getOnBoarding, step)
        .then(({ data }) => {
          const onBoarding = OnBoarding.fromJson(data);
          context.commit(SET_ON_BOARDING_MUTATION, onBoarding);
          resolve(onBoarding);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [GET_ON_BOARDING_ACTION](context) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.getOnBoarding()
        .then(({ data }) => {
          let onBoarding = null;
          if (data['hydra:member'].length > 0) {
            onBoarding = OnBoarding.fromJson(data['hydra:member'][0]);
            context.commit(SET_ON_BOARDING_MUTATION, onBoarding);
          }
          resolve(onBoarding);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [ADD_ON_BOARDING_ACTION](context) {
    return new Promise((resolve, reject) => {
      onBoardingConnector.addOnBoarding()
        .then(({ data }) => {
          const onBoarding = OnBoarding.fromJson(data);
          context.commit(SET_ON_BOARDING_MUTATION, onBoarding);
          resolve(onBoarding);
        })
        .catch(({ response }) => {
          context.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.RESPONSE, content: response });
          reject(response);
        });
    });
  },
  [RESET_ON_BOARDING_ACTION](context) {
    context.commit(RESET_ON_BOARDING_MUTATION);
  },
};

const mutations = {
  [SET_ON_BOARDING_MUTATION](currentState, onBoarding) {
    currentState.onBoarding = onBoarding;
  },
  [RESET_ON_BOARDING_MUTATION](currentState) {
    currentState.onBoarding = null;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
