<template>
  <div class="pt-4 mb-3">
    <div class="form-floating">
      <input :id="id" v-model="input"
             v-maska="maskaValue"
             type="text"
             class="form-control form-control-lg"
             :class="(errors)?'is-invalid':''"
             :placeholder="placeholder"
             :disabled="disabled"
             @maska="onMaska"
             @focusout="validateInput"
      >
      <label :for="id" :class="{ 'required-star': required }">
        {{ label }}
      </label>
    </div>

    <ErrorDisplay v-if="errors" :errors="errors" />

    <div v-if="help" class="form-text bg-grey p-3 mt-0 d-flex">
      <p class="mb-0">
        <IconInfoCircle class="me-2" />
      </p>
      <!-- eslint-disable-next-line -->
      <p class="mb-0" v-html="help" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { maska } from 'maska';
import IconInfoCircle from '@/app/ui/components/icon/IconInfoCircle.vue';
import ErrorDisplay from './ErrorDisplay.vue';

export default {
  name: 'InputIban',
  directives: {
    maska,
  },
  components: {
    IconInfoCircle,
    ErrorDisplay,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    helper: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    validators: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    help: {
      type: String,
      default: null,
    },
  },
  emits: ['update:masked', 'update:modelValue'],
  setup(props, { emit }) {
    const input = ref(props.modelValue);
    const validateInput = () => props.validators.forEach((validator) => validator());
    const maskaValue = ref('AA## #### #### ##XX XXXX XXXX X##');

    function onMaska(event) {
      const maskVal = event.target.dataset.maskRawValue;
      const value = (!maskVal) ? null : maskVal;

      if (value && value.length >= 2) {
        switch (value.substring(0, 2)) {
          case 'GB': maskaValue.value = 'AA## AAAA #### #### #### ##'; break;
          case 'NL': maskaValue.value = 'AA## AAAA #### #### ##'; break;
          case 'DE': maskaValue.value = 'AA## #### #### #### #### ##'; break;
          case 'PT': maskaValue.value = 'AA## #### #### #### #### #### #'; break;
          case 'IT': maskaValue.value = 'AA## A### #### #### #### #### ###'; break;
          case 'ES': maskaValue.value = 'AA## #### #### ## ##########'; break;
          case 'BE': maskaValue.value = 'AA## ### ####### ##'; break;
          case 'FR':
          default:
            maskaValue.value = 'AA## #### #### ##XX XXXX XXXX X##'; break;
        }
      }

      emit('update:modelValue', value);
    }

    return {
      input,
      onMaska,
      validateInput,
      maskaValue,
    };
  },
};
</script>
