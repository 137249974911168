<template>
  <svg id="flag-icon-css-nl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" :height="height">
    <path fill="#21468b" d="M0 0h512v512H0z" />
    <path fill="#fff" d="M0 0h512v341.3H0z" />
    <path fill="#ae1c28" d="M0 0h512v170.7H0z" />
  </svg>
</template>

<script>
export default {
  name: 'Nl',
  props: {
    height: {
      type: Number,
      default: 16,
    },
  },
};
</script>
