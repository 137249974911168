import http from '@/app/service/http';

const API_PREFIX = '/api';
const VERSION_PREFIX = '/v1';
const ON_BOARDING_PREFIX = '/prospects';
const BASE_API_URL_PREFIX = `${process.env.VUE_APP_API_BASE_URL}${API_PREFIX}`;
const BASE_API_URL = `${BASE_API_URL_PREFIX}${VERSION_PREFIX}${ON_BOARDING_PREFIX}`;

export default {
  addMediaSellingPoint(media) {
    const data = new FormData();
    data.append('file', media);

    return http.client.post(`${BASE_API_URL}/on-boarding-selling-points/medias`, data);
  },
  patchOnBoardingSellingPoint(onBoardingSellingPoint) {
    return http.client.patch(`${BASE_API_URL}/on-boarding-selling-points/${onBoardingSellingPoint.uuid}`,
      onBoardingSellingPoint, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      });
  },
  getOnBoardingSellingPoint() {
    return http.client.get(`${BASE_API_URL}/on-boarding-selling-points`);
  },
  addOnBoardingSellingPoint() {
    return http.client.post(`${BASE_API_URL}/on-boarding-selling-points`, {});
  },
  postStepBoardingSellingPoint(onBoardingSellingPointUuid, state) {
    return http.client.post(`${BASE_API_URL}/on-boarding-selling-points/${onBoardingSellingPointUuid}/workflow`, { state });
  },
  getOnBoardingLegalRepresents(onBoardingSellingPointUuid) {
    return http.client.get(`${BASE_API_URL}/on-boarding-selling-points/${onBoardingSellingPointUuid}/legal-represents`);
  },
  patchLegalRepresent(legalRepresent) {
    return http.client.patch(
      `${BASE_API_URL}/on-boarding-selling-points/legal-represents/${legalRepresent.uuid}`, legalRepresent, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
    );
  },
  postLegalRepresent(legalRepresent) {
    return http.client.post(
      `${BASE_API_URL}/on-boarding-selling-points/legal-represents`, legalRepresent, {
        headers: {
          'Content-Type': 'application/ld+json',
        },
      },
    );
  },
  deleteLegalRepresent(legalRepresent) {
    return http.client.delete(`${BASE_API_URL}/on-boarding-selling-points/legal-represents/${legalRepresent.uuid}`);
  },
};
