import { i18n } from '@/app/service/i18n';

const ChannelEnum = {
  WEB: 'WEB',
  STORE: 'STORE',
  CALL_CENTER: 'CALL_CENTER',

  getReadableValue(value) {
    const val = this.getChoices().find((element) => element.value === value);

    return val !== undefined ? val.label : value;
  },

  getChoices() {
    return [
      { value: this.WEB, label: i18n.global.tc('label.web') },
      { value: this.STORE, label: i18n.global.tc('label.store') },
      { value: this.CALL_CENTER, label: i18n.global.tc('label.call_center') },
    ];
  },
};

export default ChannelEnum;
