<template>
  <Layout :menu-index="3">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="row mb-3">
        <div class="col-12 col-md-9">
          <h3>{{ $t('title.onboarding_informations') }}</h3>
        </div>
      </div>

      <div v-if="hasMessage" class="row">
        <div class="col-12 col-md-9">
          <Alert v-for="(message, index) in messages" :key="index" :msg="message" :index="index" />
        </div>
      </div>

      <div v-if="information" class="row">
        <div class="col-12 col-md-9">
          <form autocomplete="off"
                novalidate
                @submit.prevent
          >
            <InputText
              id="business_name"
              v-model="information.businessName"
              :required="true"
              :label="$t('label.business_name')"
              :placeholder="$t('placeholder.business_name')"
              :validators="[validationForm.validateBusinessNameField]"
              :errors="validationForm.errors.getErrorsFromProperty('businessName')"
            />

            <InputText
              id="company_name"
              v-model="information.socialReason"
              :required="true"
              :label="$t('label.company_name')"
              :placeholder="$t('placeholder.company_name')"
              :validators="[validationForm.validateSocialReasonField]"
              :errors="validationForm.errors.getErrorsFromProperty('socialReason')"
            />

            <InputSelect
              id="company_type"
              v-model="information.societyType"
              :required="true"
              :label="$t('label.company_type')"
              :placeholder="$t('placeholder.company_type')"
              :options="getSocietyTypes"
              :validators="[validationForm.validateSocietyTypeField]"
              :errors="validationForm.errors.getErrorsFromProperty('societyType')"
            />

            <InputText
              id="company_siret"
              v-model="information.societyNumber"
              :required="true"
              :label="$t('label.identification_number')"
              :placeholder="$t('placeholder.identification_number')"
              :validators="[validationForm.validateSocietyNumberField]"
              :errors="validationForm.errors.getErrorsFromProperty('societyNumber')"
            />

            <InputMoney
              id="capital_stock"
              v-model="information.shareCapital"
              :required="true"
              :precision="2"
              :label="$t('label.capital_stock')"
              :placeholder="$t('placeholder.capital_stock')"
              :validators="[validationForm.validateShareCapitalField]"
              :errors="validationForm.errors.getErrorsFromProperty('shareCapital')"
            />

            <InputSelect
              id="country"
              v-model="information.country"
              :required="true"
              :label="$t('label.country')"
              :placeholder="$t('placeholder.country')"
              :options="getCountriesChoices"
              :validators="[validationForm.validateCountryField]"
              :errors="validationForm.errors.getErrorsFromProperty('country')"
            />

            <InputText
              id="code_activity"
              v-model="information.nafCode"
              :required="true"
              :label="$t('label.code_activity')"
              :placeholder="$t('placeholder.code_activity')"
              :validators="[validationForm.validateNafCodeField]"
              :errors="validationForm.errors.getErrorsFromProperty('nafCode')"
            />

            <InputText
              id="address_street"
              v-model="information.address"
              :required="true"
              :label="$t('label.address_street')"
              :placeholder="$t('placeholder.address_street')"
              :validators="[validationForm.validateAddressField]"
              :errors="validationForm.errors.getErrorsFromProperty('address')"
            />

            <div class="row">
              <div class="col-12 col-md-4">
                <InputText
                  id="address_zip_code"
                  v-model="information.postalCode"
                  :required="true"
                  :label="$t('label.address_zip_code')"
                  :placeholder="$t('placeholder.address_zip_code')"
                  :validators="[validationForm.validatePostalCodeField]"
                  :errors="validationForm.errors.getErrorsFromProperty('postalCode')"
                />
              </div>
              <div class="col-12 col-md-8">
                <InputText
                  id="address_city"
                  v-model="information.city"
                  :required="true"
                  :label="$t('label.address_city')"
                  :placeholder="$t('placeholder.address_city')"
                  :validators="[validationForm.validateCityField]"
                  :errors="validationForm.errors.getErrorsFromProperty('city')"
                />
              </div>
            </div>

            <div class="mt-3 float-end">
              <button
                type="submit"
                class="btn btn-lg btn-light text-dark rounded-3 me-2"
                @click="onSave"
              >
                {{ $t('action.save') }}
              </button>

              <button
                type="submit"
                class="btn btn-lg btn-primary text-white rounded-3"
                @click="onSaveAndContinue"
              >
                {{ $t('action.continue') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Alert from '@/app/ui/components/Alert.vue';
import Layout from '@/onBoarding/ui/components/Layout.vue';
import InputText from '@/app/ui/components/form/InputText.vue';
import InputSelect from '@/app/ui/components/form/InputSelect.vue';
import InputMoney from '@/app/ui/components/form/InputMoney.vue';
import CountryEnum from '@/app/enum/CountryEnum';
import InformationFormValidator from '@/onBoarding/validators/activity/InformationFormValidator';
import Loading from '@/app/ui/components/Loading.vue';
import { ADD_MESSAGE_ACTION, TOGGLE_LOADING_ACTION } from '@/app/store/actions';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import {
  GET_ON_BOARDING_INFORMATION_ACTION,
  GET_ON_BOARDING_SOCIETY_TYPES_ACTION,
  PUT_ON_BOARDING_INFORMATION_ACTION,
} from '@/onBoarding/store/activity/actions';

export default {
  name: 'Information',
  components: {
    Alert,
    Layout,
    Loading,
    InputText,
    InputMoney,
    InputSelect,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isLoading = computed(() => store.getters.isLoading);
    const information = computed(() => store.getters.getOnBoardingInformation);
    const getCountriesChoices = computed(() => CountryEnum.getChoices());
    const hasMessage = computed(() => store.getters.hasMessage);
    const messages = computed(() => store.getters.currentMessages);
    const validationForm = computed(() => InformationFormValidator(information.value));
    const isContinue = ref(false);
    const onBoarding = ref(store.getters.getOnBoarding);

    const getSocietyTypes = computed(() => store.getters.getOnBoardingSocietyTypes);

    onMounted(async () => {
      await store.dispatch(TOGGLE_LOADING_ACTION);
      await store.dispatch(GET_ON_BOARDING_INFORMATION_ACTION,
        onBoarding.value.informationSocietyUuid);
      await store.dispatch(TOGGLE_LOADING_ACTION);
      await store.dispatch(GET_ON_BOARDING_SOCIETY_TYPES_ACTION);
    });

    function submit() {
      store.dispatch(TOGGLE_LOADING_ACTION);
      store.dispatch(PUT_ON_BOARDING_INFORMATION_ACTION, {
        information: information.value,
        isContinue: isContinue.value,
      }).then(() => {
        store.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.SUCCESS });
        if (isContinue.value === true) {
          router.push({ name: 'Voucher' });
        }
      }).finally(() => {
        store.dispatch(TOGGLE_LOADING_ACTION);
      });
    }

    function onSave() {
      if (validationForm.value.areValidFields()) {
        isContinue.value = false;
        submit();
      }
    }

    function onSaveAndContinue() {
      if (validationForm.value.areValidFields()) {
        isContinue.value = true;
        submit();
      }
    }

    return {
      getCountriesChoices,
      getSocietyTypes,
      messages,
      hasMessage,
      information,
      isLoading,
      onSave,
      onSaveAndContinue,
      validationForm,
    };
  },
};
</script>
