<template>
  <Layout :menu-index="3">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="row mb-3">
        <div class="col-12 col-md-9">
          <h3>{{ $t('title.retailer_environment') }}</h3>
        </div>
      </div>

      <div v-if="hasMessage" class="row">
        <div class="col-12 col-md-9">
          <Alert v-for="(message, index) in messages" :key="index" :msg="message" :index="index" />
        </div>
      </div>

      <div v-if="retailerEnvironment" class="row">
        <div class="col-12 col-md-9">
          <form autocomplete="off"
                novalidate
                @submit.prevent
          >
            <InputGroupRadio
              id="cancellation_process"
              v-model="retailerEnvironment.cancelProcess"
              :choices="getChoices"
              :required="true"
              :label="$t('label.cancel_process')"
              :question="$t('question.cancel_process')"
              :validators="[validationForm.validateCancelProcessField]"
              :errors="validationForm.errors.getErrorsFromProperty('cancelProcess')"
            />

            <div class="mt-3 float-end">
              <button
                type="submit"
                class="btn btn-lg btn-light text-dark rounded-3 me-2"
                @click="onSave"
              >
                {{ $t('action.save') }}
              </button>

              <button
                type="submit"
                class="btn btn-lg btn-primary text-white rounded-3"
                @click="onSaveAndContinue"
              >
                {{ $t('action.continue') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import Alert from '@/app/ui/components/Alert.vue';
import Layout from '@/onBoarding/ui/components/Layout.vue';
import Loading from '@/app/ui/components/Loading.vue';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import YesNoEnum from '@/app/enum/YesNoEnum';
import InputGroupRadio from '@/app/ui/components/form/InputGroupRadio.vue';
import RetailerEnvironmentFormValidator from '@/onBoarding/validators/society/retailerEnvironmentFormValidator';
import { ADD_MESSAGE_ACTION, TOGGLE_LOADING_ACTION } from '@/app/store/actions';
import { GET_ON_BOARDING_RETAILER_ENVIRONMENT_ACTION, SAVE_ON_BOARDING_RETAILER_ENVIRONMENT_ACTION } from '@/onBoarding/store/society/actions';

export default {
  name: 'Information',
  components: {
    Alert,
    Layout,
    Loading,
    InputGroupRadio,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isContinue = ref(false);
    const onBoarding = ref(store.getters.getOnBoarding);
    const isLoading = computed(() => store.getters.isLoading);
    const getChoices = computed(() => YesNoEnum.getChoices());
    const hasMessage = computed(() => store.getters.hasMessage);
    const messages = computed(() => store.getters.currentMessages);
    const retailerEnvironment = computed(() => store.getters.getOnBoardingRetailerEnvironment);
    const validationForm = computed(() => RetailerEnvironmentFormValidator(
      retailerEnvironment.value,
    ));

    onMounted(async () => {
      await store.dispatch(TOGGLE_LOADING_ACTION);
      await store.dispatch(GET_ON_BOARDING_RETAILER_ENVIRONMENT_ACTION,
        onBoarding.value.retailerEnvironmentUuid);
      await store.dispatch(TOGGLE_LOADING_ACTION);
    });

    function submit() {
      store.dispatch(TOGGLE_LOADING_ACTION);
      store.dispatch(SAVE_ON_BOARDING_RETAILER_ENVIRONMENT_ACTION, {
        retailerEnvironment: retailerEnvironment.value,
        isContinue: isContinue.value,
      }).then(() => {
        store.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.SUCCESS });
        if (isContinue.value === true) {
          router.push({ name: 'Fraud' });
        }
      }).finally(() => {
        store.dispatch(TOGGLE_LOADING_ACTION);
      });
    }

    function onSave() {
      isContinue.value = false;
      submit();
    }

    function onSaveAndContinue() {
      if (validationForm.value.areValidFields()) {
        isContinue.value = true;
        submit();
      }
    }
    return {
      getChoices,
      messages,
      hasMessage,
      retailerEnvironment,
      isLoading,
      onSave,
      onSaveAndContinue,
      validationForm,
    };
  },
};
</script>
