<template>
  <Layout :menu-index="6">
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="row mb-3">
        <div class="col-12 col-md-9">
          <h3>
            {{ $t('title.banking_details') }}
          </h3>
        </div>
      </div>
      <div v-if="hasMessage" class="row">
        <div class="col-12 col-md-9">
          <Alert v-for="(message, index) in messages" :key="index" :msg="message" :index="index" />
        </div>
      </div>

      <ModalDialogue
        ref="modalDialogue"
        :message="$t('modal_dialog.end_course_message')"
      />

      <div class="row">
        <div class="col-12 col-md-9">
          <form autocomplete="off"
                novalidate
                @submit.prevent
          >
            <InputText
              id="account_proprio"
              v-model="bank.holder"
              :required="true"
              :label="$t('label.account_owner')"
              :placeholder="$t('placeholder.account_owner')"
              :validators="[validationForm.validateHolderField]"
              :errors="validationForm.errors.getErrorsFromProperty('holder')"
            />

            <InputIban
              id="account_iban"
              v-model="bank.iban"
              :required="true"
              :label="$t('label.account_iban')"
              :placeholder="$t('placeholder.account_iban')"
              :validators="[validationForm.validateIbanField]"
              :errors="validationForm.errors.getErrorsFromProperty('iban')"
            />

            <InputText
              id="account_bic"
              v-model="bank.bic"
              :required="true"
              :label="$t('label.account_bic')"
              :placeholder="$t('placeholder.account_bic')"
              :validators="[validationForm.validateBicField]"
              :errors="validationForm.errors.getErrorsFromProperty('bic')"
              :mask-value="'AAAAAAXXXXX'"
            />

            <InputMediaOnBoarding
              id="account_rib_file"
              v-model="bank.rib"
              :required="true"
              :label="$t('label.account_rib_file')"
              :accepted="accepted"
              :errors="validationForm.errors.getErrorsFromProperty('rib')"
              :help="$t('help.other_method')"
            />

            <div class="row mb-2 mt-4">
              <div class="col-12 col-md-9">
                <h4>
                  {{ $t('label.store_network') }}
                </h4>
              </div>
            </div>

            <Alert v-if="networkStoreCheck" :msg="networkStoreCheck" />

            <InputGroupRadio id="radio_finance_rib"
                             v-model="bank.storeNetwork"
                             :required="true"
                             :label="$t('label.finance')"
                             :choices="getStoreNetworkEnum"
                             :validators="[validationForm.validateStoreNetworkField]"
                             :errors="validationForm.errors.getErrorsFromProperty('storeNetwork')"
            />

            <div class="mt-3 text-secondary">
              {{ $t('information.legal_notice') }}
              <a href="#" @click.prevent="onGetML">
                {{ $t('action.click_here') }}
              </a>
            </div>

            <div class="mt-3 float-end">
              <a href="#"
                 class="btn btn-lg btn-light text-dark rounded-3 me-2"
                 @click="onSave"
              >
                {{ $t('action.save') }}
              </a>

              <button
                type="submit"
                class="btn btn-lg btn-primary text-white rounded-3"
                @click="onSaveAndContinue"
              >
                {{ $t('action.continue') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Alert from '@/app/ui/components/Alert.vue';
import Layout from '@/onBoarding/ui/components/Layout.vue';
import Loading from '@/app/ui/components/Loading.vue';
import InputText from '@/app/ui/components/form/InputText.vue';
import InputIban from '@/app/ui/components/form/InputIban.vue';
import { GET_ON_BOARDING_BANK_ACTION, PUT_ON_BOARDING_BANK_ACTION } from '@/onBoarding/store/activity/actions';
import { ADD_MESSAGE_ACTION, TOGGLE_LOADING_ACTION } from '@/app/store/actions';
import MessageTypeEnum from '@/app/enum/MessageTypeEnum';
import InputMediaOnBoarding from '@/onBoarding/ui/components/form/InputMediaOnBoarding.vue';
import InputGroupRadio from '@/app/ui/components/form/InputGroupRadio.vue';
import BankFormValidator from '@/onBoarding/validators/activity/bankFormValidator';
import NetworkStoreAssert from '@/onBoarding/validators/bank/networkStoreAssert';
import StoreNetworkEnum from '@/onBoarding/enum/StoreNetworkEnum';
import ModalDialogue from '@/app/ui/components/ModalDialogue.vue';
import AcceptedFileEnum from '@/app/enum/AcceptedFileEnum';

export default {
  name: 'Bank',
  components: {
    InputMediaOnBoarding,
    InputGroupRadio,
    Alert,
    Loading,
    Layout,
    InputText,
    InputIban,
    ModalDialogue,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const modalDialogue = ref(null);
    const isContinue = ref(false);
    const accepted = AcceptedFileEnum.DOCUMENT_ACCEPTED;
    const isLoading = computed(() => store.getters.isLoading);
    const onBoarding = ref(store.getters.getOnBoarding);
    const hasMessage = computed(() => store.getters.hasMessage);
    const messages = computed(() => store.getters.currentMessages);
    const bank = computed(() => store.getters.getOnBoardingBank);
    const validationForm = computed(() => BankFormValidator(bank.value));
    const getStoreNetworkEnum = computed(() => StoreNetworkEnum.getChoices());
    const networkStoreCheck = computed(() => {
      const bothCheck = NetworkStoreAssert(bank.value);
      return bothCheck
        ? store.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.WARNING, content: bothCheck })
        : false;
    });

    onMounted(async () => {
      await store.dispatch(TOGGLE_LOADING_ACTION);
      await store.dispatch(GET_ON_BOARDING_BANK_ACTION,
        onBoarding.value.bankingInformationUuid);
      await store.dispatch(TOGGLE_LOADING_ACTION);
    });

    function submit() {
      store.dispatch(TOGGLE_LOADING_ACTION);
      store.dispatch(PUT_ON_BOARDING_BANK_ACTION, {
        bank: bank.value,
        isContinue: isContinue.value,
      }).then(() => {
        store.dispatch(ADD_MESSAGE_ACTION, { type: MessageTypeEnum.SUCCESS });
        if (isContinue.value === true) {
          router.push({ name: 'FinalActivity' });
        }
      }).finally(() => {
        store.dispatch(TOGGLE_LOADING_ACTION);
      });
    }

    function onGetML() {
      window.open('/doc/mentions_legales.pdf', '_blank');
    }

    function onSave() {
      isContinue.value = false;
      submit();
    }

    async function onSaveAndContinue() {
      if (validationForm.value.areValidFields()) {
        await modalDialogue.value.showModal().then((response) => {
          if (response) {
            isContinue.value = true;
            submit();
          }
        });
      }
    }

    return {
      messages,
      getStoreNetworkEnum,
      hasMessage,
      isLoading,
      bank,
      onGetML,
      onSave,
      onSaveAndContinue,
      networkStoreCheck,
      modalDialogue,
      validationForm,
      accepted,
    };
  },
};
</script>
