import LegalRepresent from '@/onBoardingSellingPoint/model/LegalRepresent';

export default class LegalRepresents {
  constructor(representatives) {
    this.representatives = representatives;
  }

  addEmptyLegalRepresent(onBoardingIri) {
    this.representatives.push(LegalRepresent.createEmpty(onBoardingIri));
  }

  canAddRepresent() {
    return this.representatives.length < 4 || this.representatives.length === 0;
  }

  haveRepresents() {
    return this.representatives.length > 0;
  }

  static fromJson(json) {
    return new LegalRepresents(
      json.length > 0 ? json.map((legalRepresent) => LegalRepresent.fromJson(legalRepresent)) : [],
    );
  }
}
