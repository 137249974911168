<template>
  <div :class="`alert alert-${msg.type} alert-dismissible fade show`" class="pre-wrap" role="alert">
    {{ msg.message }}
    <button
      v-if="index != -1"
      type="button"
      class="btn-close"
      aria-label="Close"
      @click="onClose"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { onMounted } from 'vue';
import { REMOVE_MESSAGE_ACTION } from '@/app/store/actions';
import { REMOVE_LOGIN_MESSAGE_ACTION } from '@/security/store/actions';

export default {
  name: 'Alert',
  props: {
    msg: {
      type: Object,
      default: () => ({
        code: '0',
        message: '',
        type: 'danger',
      }),
    },
    index: {
      type: Number,
      default: -1,
    },
  },
  setup(props) {
    const store = useStore();
    const timeOut = 3000;
    let varTimeOut = false;

    function stopTimeOut() {
      if (varTimeOut !== false) {
        clearTimeout(varTimeOut);
      }
    }

    function onClose() {
      stopTimeOut();
      store.dispatch(REMOVE_MESSAGE_ACTION, props.index);
      store.dispatch(REMOVE_LOGIN_MESSAGE_ACTION, props.index);
    }

    onMounted(() => {
      window.scrollTo(0, 0);
      if (props.index !== -1) {
        varTimeOut = setTimeout(() => {
          store.dispatch(REMOVE_MESSAGE_ACTION, props.msg);
          store.dispatch(REMOVE_LOGIN_MESSAGE_ACTION, props.msg);
        }, timeOut);
      }
    });

    return {
      onClose,
    };
  },
};
</script>
